import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import styles from '../../constants/styles'

const TextInput = ({
  label,
  value,
  placeholder,
  type,
  error,
  autoFocus,
  onChange,
  onKeyPress
}) => (
  <div>
    <Label>{label}</Label>

    <InputContainer error={error}>
      <Input
        type={type || 'text'}
        value={value}
        placeholder={placeholder || ''}
        onChange={onChange}
        autoFocus={autoFocus}
      />
    </InputContainer>

    <ErrorDiv>
      <Error>{error}</Error>
    </ErrorDiv>
  </div>
)

export default TextInput

TextInput.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string.isRequired,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  error: PropTypes.string,
  autoFocus: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  onKeyPress: PropTypes.func
}

const Label = styled.label`
  font-weight: bold;
`

const InputContainer = styled.div`
  width: 100%;
  height: ${styles.height.small};
  margin: ${styles.margin.small} 0;
  border: 1px solid
    ${props => (props.error ? styles.colors.action : styles.colors.lightGrey)};
  border-radius: ${styles.borderRadius.small};
`

const Input = styled.input`
  width: 100%;
  height: 100%;
  font-size: 1em;
  padding: 0 ${styles.padding.medium};
  border: none;
  background-color: transparent;
`

const ErrorDiv = styled.div`
  width: 100%;
`

const Error = styled.span`
  font-size: 0.9em;
  color: ${styles.colors.action};
`
