import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import InfiniteScroll from 'react-infinite-scroller'
import styled from 'styled-components'
import * as actions from '../actions'
import Title from '../components/shared/Title'
import ActionBar from '../components/shared/ActionBar'
import ActionButton from '../components/shared/ActionButton'
import SortDishes from '../components/dishes/SortDishes'
import DishCard from '../components/dishes/DishCard'
import DishCardLoading from '../components/dishes/DishCardLoading'
import styles from '../constants/styles'

class Dishes extends Component {
  constructor(props) {
    super(props)
    this.state = {
      restaurant: 'all'
    }
    this.containerRef = null
    this.fetchDishes = this.fetchDishes.bind(this)
    this.fetchMore = this.fetchMore.bind(this)
    this.handleSort = this.handleSort.bind(this)
  }

  componentDidMount() {
    const { business, dishes } = this.props
    const { sort, ascending } = dishes.index
    window.scrollTo(0, 0)
    const node = ReactDOM.findDOMNode(this.containerRef)
    if (node && window.innerWidth > 800) {
      node.scrollIntoView()
    }
    if (business.fetched) {
      this.fetchDishes({ sort, ascending })
    }
  }

  componentDidUpdate(prevProps) {
    const {
      business, dishes, resetChangeBusiness
    } = this.props
    const { sort, ascending } = dishes.index

    if (business.details._id !== prevProps.business.details._id) {
      resetChangeBusiness()
      this.setState({ restaurant: 'all' })
    }

    if ((business !== prevProps.business) || ((sort !== prevProps.dishes.index.sort) && (business.details._id === prevProps.business.details._id))) {
      if (business.fetched) {
        this.fetchDishes({ sort, ascending })
      }
    }
  }

  fetchDishes({ sort, ascending }) {
    const { fetchDishes, business } = this.props
    const newSearch = true
    const businessId = business.details._id
    const restaurants = business.details.restaurants.map(r => r._id).join('+')
    fetchDishes({
      newSearch, ascending, sort, skip: 0, businessId, restaurants
    })
  }

  fetchMore() {
    const {
      fetchDishes, business, dishes
    } = this.props
    const newSearch = false
    const { skip, sort, ascending } = dishes.index
    const businessId = business.details._id
    const restaurants = business.details.restaurants.map(r => r._id).join('+')
    if (dishes.index.isLoading || !dishes.index.hasMore) return
    fetchDishes({
      newSearch, ascending, sort, skip, businessId, restaurants
    })
  }

  handleSort(s) {
    const { dishes, updateDishSort } = this.props
    const { sort } = dishes.index

    if (sort !== s) {
      const ascending = (s === 'name')
      updateDishSort(s, ascending)
    }
  }

  render() {
    const {
      restaurants, dishes
    } = this.props
    const { restaurant } = this.state
    const dishesItems = dishes.index.items
      .map(_id => dishes.all[_id])
      .filter(
        d => restaurant === 'all'
          || d.restaurants.includes(restaurant)
          || (restaurant === 'none' && d.restaurants.length === 0)
      )

    const loaded = dishes.index.fetched
    const loading = dishes.index.isLoading

    return (
      <Container ref={(con) => { this.containerRef = con }}>
        <Title title="Dishes" />
        <ActionBar justifyContent="flex-end">
          <Link to="/dishes/new">
            <ActionButton>
              <span style={{ padding: `0 ${styles.padding.medium}` }}>
                <i className="fas fa-plus-circle" />
                &nbsp;Add dish
              </span>
            </ActionButton>
          </Link>
        </ActionBar>
        {loaded && restaurants.length > 0 && (
          <SelectContainer>
            <Select
              value={restaurant}
              onChange={e => this.setState({ restaurant: e.target.value })}
            >
              <option value="all">All Restaurants</option>
              {restaurants.map(rest => (
                <option key={rest._id} value={rest._id}>
                  {rest.nickname || `${rest.name} @ ${rest.formatted_address}`}
                </option>
              ))}
              <option value="none">Not In Any Restaurant</option>
            </Select>
          </SelectContainer>
        )}

        {!loaded && (
          <div
            style={{
              width: '100%',
              height: '46px',
              background: styles.colors.white,
              color: 'transparent',
              border: `1px solid ${styles.colors.lightGrey}`
            }}
          >
            select container
          </div>
        )}

        {loaded && dishesItems.length === 0 && !loading && (
          <Loading>
            <b>No dishes found</b>
          </Loading>
        )}

        {!loaded && (
          <div
            style={{
              height: styles.height.small,
              display: 'flex',
              alignItems: 'center',
              fontSize: '0.9em',
              fontWeight: 'bold'
            }}
          >
            <span
              style={{
                background: styles.colors.lightGrey,
                color: 'transparent'
              }}
            >
              SORT
            </span>
            <span
              style={{
                marginLeft: styles.margin.small,
                background: styles.colors.lightGrey,
                color: 'transparent'
              }}
            >
              NAME
            </span>
            <span
              style={{
                marginLeft: styles.margin.small,
                background: styles.colors.lightGrey,
                color: 'transparent'
              }}
            >
              RATING
            </span>
            <span
              style={{
                marginLeft: styles.margin.small,
                background: styles.colors.lightGrey,
                color: 'transparent'
              }}
            >
              REVIEWS
            </span>
          </div>
        )}
        {loaded && dishesItems.length > 0 && (
          <SortDishes
            sort={dishes.index.sort}
            handleSort={this.handleSort}
          />
        )}


        {!loaded && (
          <div style={{ width: '100%' }}>
            {[0, 1, 2, 3, 4, 5, 6, 7].map(i => (
              <DishCardLoading key={`dishCardLoading${i}`} />
            ))}
          </div>
        )}

        {dishesItems.length > 0 && (
          <InfiniteScroll
            pageStart={0}
            loadMore={this.fetchMore}
            hasMore={dishes.index.hasMore}
            useWindow
            loader={
              <i key="spinner" className="fas fa-spinner fa-spin" />
            }
          >
              {dishesItems
                .map(dish => (
                  <DishCard key={dish._id} dish={dish} />
                ))}

          </InfiniteScroll>
        )}
      </Container>
    )
  }
}

Dishes.propTypes = {
  dishes: PropTypes.objectOf(PropTypes.object).isRequired,
  restaurants: PropTypes.arrayOf(PropTypes.object).isRequired,
  business: PropTypes.objectOf(PropTypes.object).isRequired,
  fetchDishes: PropTypes.func.isRequired,
  updateDishSort: PropTypes.func.isRequired,
  resetChangeBusiness: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
  business: state.business.all,
  dishes: state.dishes,
  restaurants: state.restaurants.index.items.map(
    _id => state.restaurants.all[_id]
  ),
  images: state.images.index.items.map(_id => state.images.all[_id]),
  users: state.users.index.items.map(_id => state.users.all[_id]),
  state
})

const mapDispatchToProps = dispatch => ({
  updateField: ({ _id, value }) => {
    dispatch(actions.updateField({ type: 'reviews', _id, value }))
  },
  updateDishSort: (sort, ascending) => {
    dispatch({ type: actions.UPDATE_DISH_SORT, sort, ascending })
  },
  fetchDishes: ({
    newSearch, ascending, sort, skip, businessId, restaurants
  }) => (
    dispatch(actions.dishes.fetchDishes({
      newSearch, ascending, sort, skip, businessId, restaurants
    }))
  ),
  resetChangeBusiness: () => (
    dispatch(actions.dishes.resetChangeBusiness())
  )
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Dishes)

const Container = styled.main`
  padding: 0 ${styles.padding.medium};
`

const Loading = styled.div`
  width: 100%;
  height: calc(40vh);
  font-weight: bold;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const SelectContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: ${styles.height.small};
  border: 1px solid ${styles.colors.lightGrey};
  background-color: ${styles.colors.white};
`

const Select = styled.select`
  padding: ${styles.padding.medium};
  width: 100%;
  height: 100%;
  border: none;
  font-size: 1em;
  box-shadow: none;
  background: transparent;
  background-image: none;
  -webkit-appearance: none;
  cursor: pointer;
`
