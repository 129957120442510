import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import RestaurantSearchCard from '../business/RestaurantSearchCard'
import styles from '../../constants/styles'

const Results = ({
  restaurants, claimed, searched, handleClaim
}) => (
  <Container>
    <div>
      {restaurants
        .filter(rest => !claimed.includes(rest))
        .map((rest, i) => (
          <RestaurantSearchCard
            restaurant={rest}
            key={rest._id}
            selected={claimed.includes(rest)}
            claimed={!!rest.business}
            handleClaim={handleClaim}
            first={i === 0}
          />
        ))}

      {restaurants.length > 0 && (
        <RestaurantCardDiv
          style={{
            textAlign: 'center',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: styles.margin.small
          }}
        >
          <p>
            Don't see your restaurant?&nbsp;
            <StyledLink
              href="https://www.abillion.com/contact"
              rel="noopener noreferrer"
              target="_blank"
            >
              Contact us
            </StyledLink>
          </p>
        </RestaurantCardDiv>
      )}
    </div>

    {restaurants.length === 0
      && searched && (
        <div style={{ textAlign: 'center' }}>
          Oh dear! We didn't manage to find any results. Please
          {' '}
          <StyledLink
            href="https://www.abillion.com/contact"
            rel="noopener noreferrer"
            target="_blank"
          >
            contact us
          </StyledLink>
          {' '}
          for further assistance and we will be in touch!
        </div>
    )}
  </Container>
)

Results.propTypes = {
  restaurants: PropTypes.arrayOf(PropTypes.object).isRequired,
  claimed: PropTypes.arrayOf(PropTypes.object).isRequired,
  searched: PropTypes.bool.isRequired,
  handleClaim: PropTypes.func.isRequired
}

export default Results

const Container = styled.div`
  width: 100%;
  max-width: 1120px;
  padding: 0 ${styles.padding.small};
`

const RestaurantCardDiv = styled.div`
  display: flex;
  flex-flow: row;
  align-items: center;
  padding: ${styles.padding.medium} 0;
  margin-bottom: ${styles.margin.small};
  background: ${styles.colors.veryLightGrey};
`

const StyledLink = styled.a`
  color: ${styles.colors.black};
  text-decoration: underline;

  &: hover {
    color: ${styles.colors.omnivore};
  }
`
