import { combineReducers } from 'redux'
import * as types from '../constants/actionTypes'

const all = (state = {}, action) => {
  switch (action.type) {
    case types.RECEIVED_USERS_INDEX:
      return {
        ...state,
        ...action.data.reduce((obj, user) => {
          const tempObj = { ...obj }
          if (
            !(state[user._id] && state[user._id].complete)
            && user._id !== action.viewer
          ) {
            tempObj[user._id] = user
          }
          return tempObj
        }, {})
      }
    case types.RECEIVED_VIEWER:
      return {
        ...state,
        [action.data._id]: Object.assign({}, action.data)
      }
    case types.ADD_USER:
      return state[action.data._id]
        ? state
        : {
          ...state,
          [action.data._id]: { ...action.data }
        }
    case types.UPDATED_USERS:
      return {
        ...state,
        [action._id]: { ...state[action._id], ...action.value }
      }
    case types.LOG_OUT:
      return {}
    default:
      return state
  }
}

const defaultState = {
  isLoading: false,
  items: [],
  error: false,
  fetched: false
}

const index = (state = defaultState, action) => {
  switch (action.type) {
    case types.FETCHING_USERS_INDEX:
      return {
        ...state,
        isLoading: true,
        items: [],
        error: false
      }
    case types.RECEIVED_USERS_INDEX:
      return {
        isLoading: false,
        items: action.data.map(review => review._id),
        error: false,
        fetched: true
      }
    case types.ERROR_FETCHING_USERS_INDEX:
      return {
        ...state,
        isLoading: false,
        error: true
      }
    case types.ADD_USER:
      return {
        ...state,
        items: state.items
          .filter(i => i !== action.data._id)
          .concat(action.data._id)
      }
    case types.REMOVE_USER:
      return { ...state, items: state.items.filter(i => i !== action._id) }
    case types.LOG_OUT:
      return defaultState
    default:
      return state
  }
}

const defaultViewer = {
  isLoading: false,
  _id: null,
  error: false,
  fetched: false
}

const viewer = (state = defaultViewer, action) => {
  switch (action.type) {
    case types.FETCHING_VIEWER:
      return {
        ...state,
        isLoading: true,
        error: false
      }
    case types.RECEIVED_VIEWER:
      return {
        ...state,
        isLoading: false,
        _id: action.data._id,
        error: false,
        fetched: true
      }
    case types.ERROR_FETCHING_VIEWER:
      return {
        ...state,
        isLoading: false,
        _id: null,
        error: true
      }
    case types.LOG_OUT:
      return defaultViewer
    default:
      return state
  }
}

export default combineReducers({
  all,
  index,
  viewer
})
