import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import constants from '../../constants/constants'
import styles from '../../constants/styles'

function switchIcon(status) {
  switch (status) {
    case constants.lists.updateStatus.LOADING:
      return { icon: 'fa fa-spinner fa-spin', color: styles.colors.black }
    case constants.lists.updateStatus.COMPLETE:
      return { icon: 'fa fa-check', color: styles.colors.vegan }
    case constants.lists.updateStatus.ERROR:
      return { icon: 'fa fa-exclamation-circle', color: styles.colors.action }
    default:
      return { icon: '', color: styles.colors.black }
  }
}

const UpdateStatus = ({ status }) => (
  <Container color={switchIcon(status).color}>
    {!!status && <i className={switchIcon(status).icon} />}
  </Container>
)

UpdateStatus.propTypes = {
  status: PropTypes.string
}

export default UpdateStatus

const Container = styled.div`
  margin-left: ${styles.margin.small};
  color: ${props => props.color};
`
