import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ReactDOM from 'react-dom'
import styled from 'styled-components'
import styles from '../../constants/styles'

class Comment extends Component {
  constructor(props) {
    super(props)
    this.state = {
      comment: ''
    }
    this.postComment = this.postComment.bind(this)
  }

  componentWillReceiveProps(nextProps) {
    const { review } = this.props
    if (
      nextProps.review.responseTo
      && (!review.responseTo
        || review.responseTo._id !== nextProps.review.responseTo._id)
    ) {
      ReactDOM.findDOMNode(this.commentInput).focus()
    }
  }

  postComment(e) {
    const {
      review, viewer, responseTo, postReviewComment
    } = this.props
    const { comment } = this.state
    e.preventDefault()
    if (review.isLoading) {
      return
    }
    if (!comment?.length) return

    postReviewComment({
      review,
      viewer,
      text: comment,
      responseTo
    })

    this.setState({ comment: '' })
  }

  render() {
    const { review, respondToComment } = this.props
    const { comment } = this.state
    return (
      <Container onSubmit={this.postComment}>
        <Responding>
          {review.responseTo && (
            <span>
              Responding to
              {' '}
              {review.responseTo.restaurant ? (
                <span style={{ fontFamily: 'Montserrat', fontWeight: 'bold' }}>
                  {review.responseTo.restaurant.name}
                </span>
              ) : (
                <span style={{ fontFamily: 'Montserrat', fontWeight: 'bold' }}>
                  {review.responseTo.user.username}
                </span>
              )}
              <Cancel
                onClick={() => respondToComment({ review, responseTo: null })}
              >
                CANCEL
              </Cancel>
            </span>
          )}
        </Responding>

        <div style={{ display: 'flex' }}>
          <InputDiv>
            <CommentInput
              type="text"
              ref={(input) => {
                this.commentInput = input
              }}
              placeholder="Add a comment..."
              style={{ fontSize: '0.9em' }}
              value={comment}
              onChange={e => this.setState({ comment: e.target.value })}
            />
          </InputDiv>
          <PostButtonContainer
            hasComment={comment.length > 0}
            onClick={this.postComment}
          >
            {review.isLoading ? (
              <i
                className="fa fa-spinner fa-spin fa-fw"
                style={{ color: styles.colors.black }}
              />
            ) : (
              <PostButton>Post</PostButton>
            )}
          </PostButtonContainer>
        </div>
      </Container>
    )
  }
}

Comment.propTypes = {
  review: PropTypes.objectOf(PropTypes.any).isRequired,
  viewer: PropTypes.objectOf(PropTypes.any).isRequired,
  respondToComment: PropTypes.func.isRequired,
  postReviewComment: PropTypes.func.isRequired
}

const Container = styled.form`
  width: 100%;
  padding: 0 ${styles.padding.small};
`

const Responding = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
  font-size: 0.8em;
  border-top: 1px solid ${styles.colors.lightGrey};
`

const Cancel = styled.span`
  font-size: 0.8em;
  font-weight: 600;
  color: ${styles.colors.action};
  cursor: pointer;
  margin-left: ${styles.margin.small};
`

const InputDiv = styled.div`
  width: calc(100% - ${styles.width.small});
  height: ${styles.height.tiny};
`

const CommentInput = styled.input`
  width: 100%;
  height: 100%;
  border: none;
  font-size: 1em;
  padding: ${styles.padding.small} 0;
`

const PostButtonContainer = styled.div`
  width: ${styles.width.small};
  height: ${styles.height.tiny};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: ${props => (props.hasComment ? 'pointer' : 'default')};
  color: ${props => (props.hasComment ? styles.colors.omnivore : styles.colors.grey)};
`

const PostButton = styled.span`
  font-size: 1em;
`

export default Comment
