const styles = {
  colors: {
    action: '#f23c3c',
    black: '#000000',
    brand: '#2fb3bf',
    gold: '#f2d33c',
    pink: '#FF98CFFF',
    facebook: '#4767AD',
    omnivore: '#2f77bf',
    vegan: '#3bbf2f',
    darkGrey: '#767676',
    grey: '#aaaaaa',
    lightGrey: '#e6e6e6',
    veryLightGrey: '#f7fafa',
    orange: '#f4743b',
    twitter: '#4da7de',
    pinterest: '#AF2626',
    white: '#ffffff',
    recipeTag: '#f9f77f',
    articleTag: '#FFC0DDFF',
    challenge: '#4d5e6d'
  },
  borderRadius: {
    small: '4px',
    medium: '8px',
    large: '16px'
  },
  height: {
    tiny: '30px',
    small: '48px',
    medium: '72px',
    large: '98px'
  },
  margin: {
    tiny: '4px',
    small: '8px',
    medium: '16px',
    large: '32px',
    larger: '50px',
    extraLarge: '10%'
  },
  padding: {
    tiny: '4px',
    small: '8px',
    medium: '12px',
    large: '24px',
    larger: '2%',
    huge: '48px',
    media: '15vw'
  },
  width: {
    tiny: '30px',
    small: '48px',
    smallMedium: '76px',
    medium: '108px',
    mediumLarge: '190px',
    large: '216px',
    extraLarge: '450px'
  }
}
export default styles
