const copy = {
  en: {
    claim: {
      title: 'Claim your business',
      location: {
        headline: 'Search for your restaurant(s)'
      },
      details: {
        headline:
          'Provide us with details. We will be contacting your social media accounts to verify your claim.',
        social:
          'Please provide at least one social media contact as we will be attempting to verify your claim by contacting you through your official business social media channels.',
        business: {
          label: 'Name of Business*',
          placeholder: 'What is your business called?'
        },
        contact: {
          label: 'Name of Contact*',
          placeholder: 'Who should we contact for more details?'
        },
        role: {
          label: 'Role*',
          placeholder: 'What is your role in the business?'
        },
        email: {
          label: 'Email*',
          placeholder: 'Your email address'
        },
        facebook: {
          label: 'Business Facebook URL',
          placeholder: 'Facebook URL for your business'
        },
        instagram: {
          label: 'Business Instagram Handle',
          placeholder: 'Instagram handle for your business'
        },
        twitter: {
          label: 'Business Twitter Handle',
          placeholder: 'Twitter handle for your business'
        },
        linkedin: {
          label: 'Contact Person LinkedIn URL',
          placeholder: 'Your linkedIn profile URL'
        },
        phone: {
          label: 'Phone (direct line)*',
          placeholder: 'Please include your country and area code if applicable'
        },
        remarks: {
          label: 'Remarks',
          placeholder:
            'Is there anything else you would like us to take note of?'
        },
        acknowledgement: restaurants => `I acknowledge that I am authorised to manage the
                selected restaurant${restaurants > 1 ? 's' : ''}`
      },
      link: {
        headline: {
          connected: 'Connected',
          notConnected: 'Connect your abillion account'
        },
        connectButton: 'Connect an existing account',
        signupButton: 'Sign up for free',
        explain: {
          headline: 'Why do I need an account?',
          text:
            'An account is required to access the business dashboard and business-only features. It also provides us with a way to contact you regarding feedback from users on our platform.'
        },
        pending: {
          headline: 'This account has a pending business claim.',
          text1:
            'We are in the process of approving your business claim. You will receive a confirmation email within the next few days. You can',
          contact: 'contact us',
          text2:
            'if you would like to add more restaurants/stores or make changes to your submitted claim.'
        },
        claimed: {
          headline: 'This account is already attached to a claimed business.',
          text1: 'You can',
          contact: 'contact us',
          text2:
            'if you would like to add more restaurants/stores or make changes to your business.'
        }
      },
      buttons: {
        brands: 'Brand',
        restaurants: 'Restaurant',
        or: 'OR'
      }
    },
    landing: {
      header: {
        headline: 'Reach more conscious customers today',
        text:
          'Get discovered by a rapidly growing market of people looking for sustainable options.  ',
        button: 'Claim your business'
      },
      review: {
        headline: 'Know your customers',
        text:
          'Find out what customers think of your food. Read their reviews, get actual feedback on your menu and respond to customer queries.'
      },
      menu: {
        headline: 'Improve menu visibility',
        first: {
          header: 'Make it stunning',
          text:
            'Customers see photos taken by other customers when they view your menu. Upload official photos of dishes to tantalize their taste buds.'
        },
        second: {
          header: 'Stay current',
          text:
            'Keep your menu up to date and provide helpful details, including price and allergen information.'
        },
        third: {
          header: 'Get useful feedback',
          text:
            'See detailed customer ratings, reviews and feedback for individual dishes.'
        }
      },
      poke: {
        headline: 'You have been poked!',
        button: 'Poked',
        secondHeadline: 'Turn this into an opportunity',
        text:
          'Receiving a poke means that your customers think there could be more and better healthy, plant-based choices in your menu, and it also means they care enough to want change. Seize the chance to improve customer satisfaction.'
      },
      dishes: {
        headline: 'See what customers are reviewing'
      },
      challenge: {
        headline: 'Boost your brand image',
        text:
          'abillion donates US $1 to animal rescue groups around the world every time a user posts a review from your business. Our users are increasingly choosing to spend their time and money on socially responsible businesses. Be rewarded for helping to drive sustainable impact.'
      },
      articles: {
        headline: 'What is abillion?',
        text:
          'Stories of companies who are driving forward a more plant-based F&B industry.'
      },
      engage: {
        headline: 'Engage your customers',
        button: 'Claim your business'
      },
      press: {
        headline: 'Featured on'
      },
      growth: {
        title: 'Grow your business with our free tools'
      },
      claim: 'Claim your free listing in 3 minutes'
    }
  }
}

export default copy
