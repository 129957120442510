import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import moment from 'moment'
import styled from 'styled-components'
import * as actions from '../../actions'
import ReviewPhoto from './ReviewPhoto'
import Comment from './Comment'
import Avatar from '../users/Avatar'
import ReviewCommentCard from './ReviewCommentCard'
import LikeButton from './LikeButton'
import styles from '../../constants/styles'
import { capitalizeString } from '../../utilities/format'

// eslint-disable-next-line react/prefer-stateless-function
class ReviewCard extends Component {
  render() {
    const {
      review,
      viewer,
      currentUser,
      photoIndex,
      likeReview,
      respondToComment,
      handleComment,
      postComment,
      handleIndex,
    } = this.props

    const overall = review.rating?.overall?.toFixed(1) ?? 0
    const comments = review.comments
      .filter((comment) => !comment.responseTo)
      .sort(
        (a, b) =>
          new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
      )
      .map((comment) => (
        <ReviewCommentCard
          key={comment._id}
          comment={comment}
          review={review}
          respondToComment={respondToComment}
        />
      ))

    return (
      <Main>
        <Left>
          <DishDetails>
            <Link to={`/dishes/${review.dish._id}`}>
              <DishName>{capitalizeString(review.dish.name)}</DishName>
            </Link>
            <div
              style={{
                fontSize: '0.8em',
                fontWeight: '500',
                color:
                  review.dish.vegType === 'vegan'
                    ? styles.colors.vegan
                    : styles.colors.brand,
              }}
            >
              {review.dish.vegType.toUpperCase()}
            </div>
            <Link to={`/restaurants/${review.restaurant._id}`}>
              <RestoName>
                <i
                  className="fa fa-map-marker-alt"
                  style={{ fontSize: '0.8em' }}
                />
                &nbsp;
                {review.restaurant.name}
              </RestoName>
            </Link>
            <div style={{ color: styles.colors.darkGrey, fontSize: '0.9em' }}>
              {review.restaurant.formatted_address}
            </div>
          </DishDetails>
          <ImageDiv>
            {review.images && review.images.length > 0 && (
              <ReviewPhoto
                images={review.images}
                index={photoIndex}
                handleIndex={handleIndex}
              />
            )}
          </ImageDiv>
        </Left>

        <Right>
          <StarDate style={{ padding: styles.padding.small }}>
            <User>
              <a
                href={`https://www.abillion.com/users/${currentUser.username}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Avatar user={currentUser} />
              </a>
              <a
                href={`https://www.abillion.com/users/${currentUser.username}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Username>{currentUser.username}</Username>
              </a>
            </User>

            <div
              style={{
                height: '100%',
                color: styles.colors.darkGrey,
                fontSize: '0.9em',
              }}
            >
              {moment.utc(review.createdAt).format('DD MMM YY')}
            </div>
          </StarDate>
          <ReviewText>
            <Text id={`${review._id}`}>
              <StarDate>
                {review.type !== 'restaurant' && (
                  <span>
                    {[1, 2, 3, 4, 5].map((score) => (
                      <i
                        key={score}
                        className={`${
                          overall >= score
                            ? 'fas fa-star'
                            : score - 0.5 < overall
                            ? 'fas fa-star-half-alt'
                            : 'far fa-star'
                        }`}
                        color={styles.colors.black}
                      />
                    ))}
                  </span>
                )}
                <LikeButton
                  review={review}
                  viewer={viewer}
                  likeReview={likeReview}
                />
              </StarDate>
              <div>{review.text}</div>
              {review.feedback && (
                <Feedback>
                  <span style={{ fontWeight: 'bold' }}>Feedback:</span>{' '}
                  {review.feedback}
                </Feedback>
              )}
            </Text>
            {comments}
          </ReviewText>
          <Comment
            review={review}
            viewer={viewer}
            user={currentUser}
            respondToComment={respondToComment}
            handleComment={handleComment}
            postReviewComment={postComment}
          />
        </Right>
      </Main>
    )
  }
}

ReviewCard.propTypes = {
  review: PropTypes.objectOf(PropTypes.any).isRequired,
  viewer: PropTypes.objectOf(PropTypes.any).isRequired,
  currentUser: PropTypes.objectOf(PropTypes.any).isRequired,
  photoIndex: PropTypes.number.isRequired,
  likeReview: PropTypes.func.isRequired,
  respondToComment: PropTypes.func.isRequired,
  handleComment: PropTypes.func.isRequired,
  postComment: PropTypes.func.isRequired,
  handleIndex: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => ({
  viewer: state.users.viewer._id ? state.users.all[state.users.viewer._id] : {},
})

const mapDispatchToProps = (dispatch) => ({
  likeReview: ({ review, viewer, toLike }) => {
    dispatch(actions.reviews.like({ review, viewer, toLike }))
  },
  handleComment: ({ review, text }) => {
    dispatch(
      actions.updateField({
        type: 'reviews',
        _id: review._id,
        value: { comment: text },
      })
    )
  },
  respondToComment: ({ review, responseTo }) => {
    dispatch(
      actions.updateField({
        type: 'reviews',
        _id: review._id,
        value: { responseTo },
      })
    )
  },
  postComment: ({ review, viewer, text }) => {
    dispatch(actions.reviews.comment({ review, viewer, text }))
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(ReviewCard)

const Main = styled.div`
  ${''}
  display: flex;
  flex-flow: column;
  margin-bottom: ${styles.margin.small};

  @media (min-width: 1121px) {
    flex-flow: row;
    height: 500px;
  }
`

const Left = styled.div`
  width: 400px;
  display: flex;
  flex-flow: column;
  border-right: 1px solid ${styles.colors.lightGrey};
  @media (max-width: 1120px) {
    border: none;
  }
  @media (max-width: 900px) {
    width: 350px;
  }
  @media (max-width: 800px) {
    width: calc(100vw - 24px);
  }
  @media (min-width: 1120px) {
    height: 500px;
  }
`

const ImageDiv = styled.div`
  width: 400px;
  height: 400px;

  @media (max-width: 900px) {
    width: 350px;
    height: 350px;
  }
  @media (max-width: 800px) {
    width: calc(100vw - 24px);
    height: calc(100vw - 24px);
  }
`

const DishName = styled.div`
  font-weight: bold;
  color: ${styles.colors.black};
  &:hover {
    color: ${styles.colors.omnivore};
  }
`

const DishDetails = styled.div`
  height: 100px;
  display: flex;
  flex-flow: column;
  padding: ${styles.padding.small};
  background-color: ${styles.colors.white};
  overflow-y: scroll;
  ::-webkit-scrollbar {
    display: none;
  }
`

const RestoName = styled.div`
  font-size: 0.9em;
  color: ${styles.colors.darkGrey};
  font-weight: bold;

  &:hover {
    color: ${styles.colors.omnivore};
  }
`

const Right = styled.div`
  width: 400px;
  background-color: ${styles.colors.white};

  @media (min-width: 1121px) {
    width: calc(100% - 400px);
  }

  @media (max-width: 900px) {
    width: 350px;
  }

  @media (max-width: 800px) {
    width: calc(100vw - 24px);
  }
`

const User = styled.div`
  display: flex;
  flex-flow: row;
  height: 100%;
  justify-content: flex-start;
  align-items: center;
`

const Username = styled.div`
  font-size: 1em;
  font-weight: 500;
  margin: 0 ${styles.margin.small};
  color: ${styles.colors.black};

  &: hover {
    color: ${styles.colors.omnivore};
  }
`

const ReviewText = styled.div`
  padding: ${styles.padding.medium};
  padding-top: 0;
  ::-webkit-scrollbar {
    display: none;
  }

  @media (min-width: 1121px) {
    height: calc(400px - 24px);
    overflow-y: scroll;
  }
`

const StarDate = styled.div`
  width: 100%;
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${styles.padding.medium};
`

const Text = styled.div`
  width: 100%;
  display: flex;
  flex-flow: column;
  text-align: left;
  font-size: 1em;
  word-wrap: break-word;
  background-color: ${styles.colors.white};
  padding-bottom: ${styles.padding.small};
`

const Feedback = styled.div`
  background-color: ${styles.colors.veryLightGrey};
  font-size: 0.9em;
  padding: ${styles.padding.tiny};
  margin-top: ${styles.margin.tiny};
`
