import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import ActionButton from './ActionButton'
import styles from '../../constants/styles'

const ActionBar = ({ ...props }) => (
  <Container justifyContent={props.justifyContent}>
    {props.backTo && (
      <Link to={props.backTo}>
        <ActionButton>
          <div style={{ padding: `0 ${styles.padding.medium}` }}>
            <i className="fas fa-chevron-left" />
            {props.backToTitle
              || 'Back'}
          </div>
        </ActionButton>
      </Link>
    )}
    {props.children}
  </Container>
)

ActionBar.propTypes = {
  justifyContent: PropTypes.string,
  backTo: PropTypes.string,
  backToTitle: PropTypes.string
}

export default ActionBar

const Container = styled.div`
  width: 100%;
  height: 64px;
  display: flex;
  justify-content: ${props => props.justifyContent || 'flex-start'};
  align-items: center;
`
