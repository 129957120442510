import { combineReducers } from 'redux'
import * as actions from '../actions'

const all = (state = {}, action) => {
  switch (action.type) {
    case actions.RECEIVED_IMAGES_INDEX:
      // get rid of images for restaurant reviews. i.e. no dish id.
      const filtered = action.data.filter(obj => !!obj.dish)
      return {
        ...state,
        ...filtered.reduce((obj, image) => {
          obj[image._id] = { ...image, dish: image.dish._id }
          return obj
        }, {})
      }
    case actions.UPLOADED_IMAGE:
      return {
        ...state,
        [action.image._id]: action.image
      }
    case actions.UPDATED_IMAGES:
      return {
        ...state,
        [action._id]: { ...state[action._id], ...action.value }
      }
    case actions.LOG_OUT:
      return {}
    default:
      return state
  }
}

const defaultState = {
  isLoading: false,
  items: [],
  vegType: 'all',
  error: false,
  fetched: false
}

const index = (state = defaultState, action) => {
  switch (action.type) {
    case actions.FETCHING_IMAGES_INDEX:
      return {
        ...state,
        isLoading: true,
        items: [],
        error: false
      }
    case actions.RECEIVED_IMAGES_INDEX:
      // get rid of images for restaurant reviews. i.e. no dish id.
      return {
        ...state,
        isLoading: false,
        items: action.data.filter(i => !!i.dish).map(image => image._id),
        error: false,
        fetched: true
      }
    case actions.ERROR_FETCHING_IMAGES_INDEX:
      return {
        ...state,
        isLoading: false,
        error: true
      }
    case actions.UPDATED_IMAGES:
      return {
        ...state,
        items: !action.value
          ? state.items.filter(_id => _id !== action._id)
          : state.items.concat(action._id)
      }
    case actions.UPLOADED_IMAGE:
      return {
        ...state,
        items: [action.image._id].concat(state.items)
      }
    case actions.LOG_OUT:
      return defaultState
    default:
      return state
  }
}

const uploading = (state = {}, action) => {
  switch (action.type) {
    case actions.UPLOADING_IMAGES:
      return {
        ...state,
        [action.dish]: { items: action.images, error: false, count: 0 }
      }
    case actions.UPLOADED_IMAGE:
      return {
        ...state,
        [action.image.dish]: {
          ...state[action.image.dish],
          items: state[action.image.dish].items.filter(i => i.id !== action.id)
        }
      }
    case actions.ERROR_UPLOADING_IMAGE:
      return {
        ...state,
        [action.image.dish]: {
          items: state[action.image.dish].items.filter(i => i.id !== action.id),
          error: true,
          count: state[action.image.dish].count + 1
        }
      }
    case actions.LOG_OUT:
      return {}
    default:
      return state
  }
}

export default combineReducers({
  all,
  index,
  uploading
})
