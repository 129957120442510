import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Logo from './Logo'
import ViewerAvatar from './ViewerAvatar'
import styles from '../../constants/styles'

const MobileTopNav = ({ sideNavActive, toggleSideNav }) => (
  <Container>
    <Third style={{ justifyContent: 'flex-start' }}>
      <Menu
        className={sideNavActive ? 'fas fa-times' : 'fas fa-bars'}
        onClick={toggleSideNav}
      />
    </Third>
    <Third style={{ width: '50%' }}>
      <Logo height="64px" />
    </Third>
    <Third style={{ justifyContent: 'flex-end' }}>
      <ViewerAvatar />
    </Third>
  </Container>
)

MobileTopNav.propTypes = {
  sideNavActive: PropTypes.bool.isRequired,
  toggleSideNav: PropTypes.func.isRequired
}

export default MobileTopNav

const Container = styled.nav`
  width: 100%;
  height: 64px;
  max-height: 64px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 30;
  background-color: ${styles.colors.white};

  @media (min-width: 601px) {
    display: none;
  }
`

const Third = styled.div`
  width: 25%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 ${styles.padding.medium};
`

const Menu = styled.div`
  font-size: 2em;
  cursor: pointer;
`
