import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Transition from 'react-transition-group/Transition'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import styles from '../../constants/styles'
import { capitalizeString } from '../../utilities/format'

const transitionStyles = {
  entering: { top: '-150vh' },
  entered: { top: '64px' },
  exiting: { top: '64px' },
  exited: { top: '-150vh' }
}

const paths = [
  { icon: 'fas fa-home fa-fw', name: 'home', active: true },
  { icon: 'far fa-bell fa-fw', name: 'activity', active: true },
  { icon: 'fas fa-pen-square fa-fw', name: 'reviews', active: true },
  { icon: 'fas fa-utensils fa-fw', name: 'dishes', active: true },
  { icon: 'far fa-building fa-fw', name: 'restaurants', active: true },
  { icon: 'fas fa-users fa-fw', name: 'users', active: true },
  { icon: 'fas fa-cogs fa-fw', name: 'settings', active: true },
  { icon: 'far fa-question-circle fa-fw', name: 'support', active: false }
]

class MobileSideNav extends Component {
  render() {
    const { sideNavActive, toggleSideNav } = this.props
    const newNotifCount = 0

    return (
      <Transition in={sideNavActive} timeout={150}>
        {state => (
          <Container style={{ ...transitionStyles[state] }}>
            <Links>
              {paths.map(path => (
                <Link
                  key={path.name}
                  to={`/${path.name}`}
                  onClick={toggleSideNav}
                  style={{ width: '100%' }}
                >
                  <LinkBox>
                    <Icon
                      className={path.icon}
                      aria-hidden="true"
                      active={path.active}
                    />
                    <Name active={path.active}>
                      {capitalizeString(path.name)}
                    </Name>
                    {path.name === 'activity'
                      && newNotifCount > 0 && (
                        <span
                          style={{
                            marginLeft: styles.margin.small,
                            color: styles.colors.action
                          }}
                        >
                          NEW
                        </span>
                    )}
                  </LinkBox>
                </Link>
              ))}
            </Links>
          </Container>
        )}
      </Transition>
    )
  }
}

MobileSideNav.propTypes = {
  sideNavActive: PropTypes.bool.isRequired,
  toggleSideNav: PropTypes.func.isRequired
}

export default MobileSideNav

const Container = styled.nav`
  width: 100vw;
  height: calc(100vh - 64px);
  position: fixed;
  top: -150vh;
  left: 0;
  z-index: 20;
  background-color: ${styles.colors.white};
  transition: top 150ms linear;
  overflow: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`

const Links = styled.div`
  width: 100%;
  height: 100%;
  overflow: auto;
`

const LinkBox = styled.div`
  width: 100%;
  height: 64px;
  font-size: 1em;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0 ${styles.padding.small};
  color: ${styles.colors.black};
  border-left: 6px solid ${styles.colors.white};

  &:hover {
    background-color: ${styles.colors.veryLightGrey};
    color: ${styles.colors.omnivore};
    border-left: 6px solid ${styles.colors.omnivore};
  }
`

const Icon = styled.span`
  font-size: 1.5em;
  width: 1em;
  display: flex;
  justify-content: center;
  margin-right: ${styles.margin.medium};
`

const Name = styled.span`
  font-size: 1.5em;
`
