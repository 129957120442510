// auth
export const LOG_IN = 'LOG_IN'
export const LOG_OUT = 'LOG_OUT'
export const HANDLE_AUTH_MODAL = 'HANDLE_AUTH_MODAL'
// business
export const RECEIVED_BUSINESS = 'RECEIVED_BUSINESS'
export const ERROR_FETCHING_BUSINESS = 'ERROR_FETCHING_BUSINESS'
export const UPDATE_BUSINESS_FIELD = 'UPDATE_BUSINESS_FIELD'
export const EDITING_BUSINESS_FIELD = 'EDITING_BUSINESS_FIELD'
export const EDITED_BUSINESS_FIELD = 'EDITED_BUSINESS_FIELD'
export const CLEAR_BUSINESS_EDITING = 'CLEAR_BUSINESS_EDITING'

// categories
export const UPDATED_CATEGORIES = 'UPDATED_CATEGORIES'
export const FETCHING_CATEGORIES_INDEX = 'FETCHING_CATEGORIES_INDEX'
export const RECEIVED_CATEGORIES_INDEX = 'RECEIVED_CATEGORIES_INDEX'
export const ERROR_FETCHING_CATEGORIES_INDEX = 'ERROR_FETCHING_CATEGORIES_INDEX'
// dishes
export const UPDATED_DISHES = 'UPDATED_DISHES'
export const FETCHING_DISHES_INDEX = 'FETCHING_DISHES_INDEX'
export const RECEIVED_DISHES_INDEX = 'RECEIVED_DISHES_INDEX'
export const ERROR_FETCHING_DISHES_INDEX = 'ERROR_FETCHING_DISHES_INDEX'
export const UPDATE_DISH_SORT = 'UPDATE_DISH_SORT'
export const UPDATE_NEW_DISH_FIELD = 'UPDATE_NEW_DISH_FIELD'
export const POSTING_NEW_DISH = 'POSTING_NEW_DISH'
export const POSTED_NEW_DISH = 'POSTED_NEW_DISH'
export const ERROR_POSTING_NEW_DISH = 'ERROR_POSTING_NEW_DISH'
export const DISH_UPDATE_LOADING = 'DISH_UPDATE_LOADING'
export const DISH_UPDATE_COMPLETE = 'DISH_UPDATE_COMPLETE'
export const DISH_UPDATE_ERROR = 'DISH_UPDATE_ERROR'
export const DELETED_DISH = 'DELETED_DISH'
export const RESETTING_DISHES_CHANGE_BUSINESS = 'RESETTING_DISHES_CHANGE_BUSINESS'
// images
export const UPDATED_IMAGES = 'UPDATED_IMAGES'
export const FETCHING_IMAGES_INDEX = 'FETCHING_IMAGES_INDEX'
export const RECEIVED_IMAGES_INDEX = 'RECEIVED_IMAGES_INDEX'
export const ERROR_FETCHING_IMAGES_INDEX = 'ERROR_FETCHING_IMAGES_INDEX'
export const UPLOADING_IMAGES = 'UPLOADING_IMAGES'
export const UPLOADED_IMAGE = 'UPLOADED_IMAGE'
export const ERROR_UPLOADING_IMAGE = 'ERROR_UPLOADING_IMAGE'
export const EDITING_IMAGES = 'EDITING_IMAGES'
// nav
export const TOGGLE_SIDE_NAV = 'TOGGLE_SIDE_NAV'
// notifications
export const UPDATED_NOTIFICATIONS = 'UPDATED_NOTIFICATIONS'
export const FETCHING_NOTIFICATIONS_INDEX = 'FETCHING_NOTIFICATIONS_INDEX'
export const RECEIVED_NOTIFICATIONS_INDEX = 'RECEIVED_NOTIFICATIONS_INDEX'
export const ERROR_FETCHING_NOTIFICATIONS_INDEX = 'ERROR_FETCHING_NOTIFICATIONS_INDEX'
export const CHECKED_NOTIFICATIONS = 'CHECKED_NOTIFICATIONS'
export const STOP_CHECKING_FOR_NOTIFICATIONS = 'STOP_CHECKING_FOR_NOTIFICATIONS'
// restaurants
export const UPDATED_RESTAURANTS = 'UPDATED_RESTAURANTS'
export const FETCHING_RESTAURANTS_INDEX = 'FETCHING_RESTAURANTS_INDEX'
export const RECEIVED_RESTAURANTS_INDEX = 'RECEIVED_RESTAURANTS_INDEX'
export const ERROR_FETCHING_RESTAURANTS_INDEX = 'ERROR_FETCHING_RESTAURANTS_INDEX'
export const UPDATE_RESTAURANTS_SORT = 'UPDATE_RESTAURANTS_SORT'
export const CLEAR_RESTAURANT_EDITING = 'CLEAR_RESTAURANT_EDITING'
export const RESTAURANT_UPDATE_LOADING = 'RESTAURANT_UPDATE_LOADING'
export const RESTAURANT_UPDATE_COMPLETE = 'RESTAURANT_UPDATE_COMPLETE'
export const RESTAURANT_UPDATE_ERROR = 'RESTAURANT_UPDATE_ERROR'

// users
export const UPDATED_USERS = 'UPDATED_USERS'
export const FETCHING_USERS_INDEX = 'FETCHING_USERS_INDEX'
export const RECEIVED_USERS_INDEX = 'RECEIVED_USERS_INDEX'
export const ERROR_FETCHING_USERS_INDEX = 'ERROR_FETCHING_USERS_INDEX'
export const ADD_USER = 'ADD_USER'
export const REMOVE_USER = 'REMOVE_USER'

// viewer
export const FETCHING_VIEWER = 'FETCHING_VIEWER'
export const RECEIVED_VIEWER = 'RECEIVED_VIEWER'
export const ERROR_FETCHING_VIEWER = 'ERROR_FETCHING_VIEWER'
