import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import ABLogoGreenSvg from '../../assets/svg/abillion-logo-green'

const Logo = ({ height }) => (
  <Container to="/" height={height}>
    <ABLogoGreenSvg />
  </Container>
)

Logo.propTypes = {
  height: PropTypes.string
}

Logo.defaultProps = {
  height: '96px'
}

export default Logo

const Container = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${props => props.height};
`
