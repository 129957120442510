import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import styles from '../../constants/styles'
import { capitalizeString } from '../../utilities/format'

const paths = [
  { icon: 'fas fa-home fa-fw', name: 'home', active: true },
  { icon: 'far fa-bell fa-fw', name: 'activity', active: true },
  { icon: 'fas fa-pen-square fa-fw', name: 'reviews', active: true },
  { icon: 'fas fa-utensils fa-fw', name: 'dishes', active: true },
  { icon: 'far fa-building fa-fw', name: 'restaurants', active: true },
  { icon: 'fas fa-users fa-fw', name: 'users', active: true },
  { icon: 'fas fa-cogs fa-fw', name: 'settings', active: true },
  { icon: 'far fa-question-circle fa-fw', name: 'support', active: true }
]

class SideNav extends Component {
  constructor(props) {
    super(props)
    this.state = {
      logOutActive: false
    }
    this.toggleLogOut = this.toggleLogOut.bind(this)
  }

  toggleLogOut() {
    const { logOutActive } = this.state
    this.setState({ logOutActive: !logOutActive })
  }

  render() {
    const { logOutActive } = this.state
    const { location } = this.props
    const newNotifCount = 0

    return (
      <Container>
        <Links logOutActive={logOutActive}>
          {paths.map(path => (
            <Link
              key={path.name}
              to={`/${path.name}`}
              style={{ width: '100%' }}
            >
              <LinkBox
                active={
                  location.pathname.slice(1, path.name.length + 1)
                  === `${path.name}`
                }
              >
                <Icon
                  className={path.icon}
                  aria-hidden="true"
                  active={path.active}
                />
                <Name active={path.active}>{capitalizeString(path.name)}</Name>
                {path.name === 'activity' && newNotifCount > 0 && (
                  <span
                    style={{
                      marginLeft: styles.margin.small,
                      color: styles.colors.action
                    }}
                  >
                    NEW
                  </span>
                )}
              </LinkBox>
            </Link>
          ))}
        </Links>
      </Container>
    )
  }
}

SideNav.propTypes = {
  location: PropTypes.shape({ pathname: PropTypes.string }).isRequired
}

export default SideNav

const Container = styled.nav`
  width: 200px;
  background-color: ${styles.colors.white};
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  position: fixed;
  top: 64px;
  left: 0;
  height: calc(100% - 64px);
`

const Links = styled.div`
  width: 100%;
  height: calc(
    100vh - 96px - 64px - ${props => (props.logOutActive ? '64px' : '0px')}
  );
  ${''} overflow: auto;
`

const LinkBox = styled.div`
  width: 100%;
  height: 48px;
  font-size: 1em;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0 ${styles.padding.large};
  color: ${props => (props.active ? styles.colors.omnivore : styles.colors.black)};
  background-color: ${props => (props.active ? styles.colors.veryLightGrey : styles.colors.white)};
  border-left: 6px solid
    ${props => (props.active ? styles.colors.omnivore : styles.colors.white)};

  &:hover {
    background-color: ${styles.colors.veryLightGrey};
    color: ${props => (props.active ? styles.colors.omnivore : styles.colors.darkGrey)};
    border-left: 6px solid
      ${props => (props.active ? styles.colors.omnivore : styles.colors.darkGrey)};
  }
`

const Icon = styled.span`
  font-size: 1em;
  width: 1em;
  display: flex;
  justify-content: center;
  margin-right: ${styles.margin.medium};
  color: ${props => (!props.active ? styles.colors.lightGrey : '')};
`

const Name = styled.span`
  color: ${props => (!props.active ? styles.colors.lightGrey : '')};
`
