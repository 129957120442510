import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import styles from '../../constants/styles'

const BackForward = ({
  errorMessage, canGoBack, canProceed, handleStep
}) => (
  <Container>
    <ErrorContainer transparent={errorMessage.length === 0}>
      <CentralSection>
        <ErrorMessage>{errorMessage}</ErrorMessage>
      </CentralSection>
    </ErrorContainer>
    <ButtonContainer>
      <CentralSection>
        <BackButton
          visible={canGoBack}
          onClick={() => handleStep('back')}
        >
          <i
            className="fa fa-angle-left"
            style={{ marginRight: styles.margin.small }}
          />
          <span>Back</span>
        </BackButton>

        <NextButton
          onClick={() => handleStep('next')}
          active={canProceed}
        >
          <span>Next</span>
          <i
            className="fa fa-angle-right"
            style={{ marginLeft: styles.margin.small }}
          />
        </NextButton>
      </CentralSection>
    </ButtonContainer>
  </Container>
)

export default BackForward

BackForward.propTypes = {
  errorMessage: PropTypes.string.isRequired,
  canGoBack: PropTypes.bool.isRequired,
  canProceed: PropTypes.bool,
  handleStep: PropTypes.func.isRequired
}

const Container = styled.div`
  position: fixed;
  z-index: 1;
  left: 0;
  bottom: 0;
  width: 100%;
  height: calc(64px + 34px);
`

const ErrorContainer = styled.div`
  width: 100%;
  height: 34px;
  background: ${props => (props.transparent ? 'transparent' : styles.colors.white)};
`

const ErrorMessage = styled.span`
  width: 100%;
  text-align: center;
  color: ${styles.colors.action};
`

const ButtonContainer = styled.div`
  width: 100%;
  height: 64px;
  border-top: 1px solid ${styles.colors.lightGrey};
  background: ${styles.colors.white};
`
const CentralSection = styled.div`
  width: 100%;
  max-width: 800px;
  height: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 ${styles.padding.small};
`
const Button = styled.div`
  width: calc(120px - ${styles.padding.small});
  padding: ${styles.padding.medium};
  border-radius: ${styles.borderRadius.small};
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
`

const NextButton = styled(Button)`
  background: ${props => (props.active ? styles.colors.action : styles.colors.lightGrey)};
  border: ${props => (props.active
    ? `2px solid ${styles.colors.action}`
    : `2px solid ${styles.colors.lightGrey}`)};
  color: ${props => (props.active ? styles.colors.white : styles.colors.darkGrey)};
`
const BackButton = styled(Button)`
  background: ${props => (props.visible ? styles.colors.darkGrey : 'transparent')};
  color: ${props => (props.visible ? styles.colors.white : 'transparent')};
  border: ${props => (props.visible ? `2px solid ${styles.colors.darkGrey}` : 'none')};
  cursor: ${props => (props.visible ? 'pointer' : 'default')};
`
