import React, { Component } from 'react'
import styled from 'styled-components'
import styles from '../../constants/styles'

class UserCard extends Component {
  render() {
    return (
      <Container>
        <ProfilePic />
        <div style={{
          height: '36px', marginLeft: styles.margin.small, display: 'flex', flexFlow: 'column', justifyContent: 'center', alignItems: 'flex-start'
        }}
        >
          <Username />
          <Email />
        </div>
      </Container>
    )
  }
}

const Container = styled.div`
  display: flex;
  flex-flow: row;
  alignItems: center;
`

const ProfilePic = styled.div`
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background-color: ${styles.colors.lightGrey};
`

const Username = styled.div`
  background-color: ${styles.colors.lightGrey};
  width: 80px;
  height: 12px;
  margin-bottom: ${styles.margin.small};
`

const Email = styled(Username)`
  width: 200px;
`

export default UserCard
