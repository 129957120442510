import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import styles from '../../constants/styles'
import BrandLogoSvg from '../../assets/svg/brand-logo'
import RestaurantLogoSvg from '../../assets/svg/restaurant-logo'
import allCopy from '../../constants/copy'

const copy = allCopy.en.claim.buttons

const ClaimButtons = ({ title, position, direction }) => (
  <Container>
    {title}
    <ButtonContainer position={position} direction={direction}>
      <Button href="https://brands.abillion.com/">
        <BrandLogoSvg />
        <ButtonText>{copy.brands}</ButtonText>
      </Button>
      <OrText>{copy.or}</OrText>
      <Button href="/claim">
        <RestaurantLogoSvg />
        <ButtonText>{copy.restaurants}</ButtonText>
      </Button>
    </ButtonContainer>
  </Container>
)

export default ClaimButtons

ClaimButtons.propTypes = {
  title: PropTypes.objectOf(PropTypes.any),
  position: PropTypes.string,
  direction: PropTypes.string,
}

ClaimButtons.defaultProps = {
  title: '',
  position: 'flex-start',
  direction: 'column',
}
const Container = styled.div`
  width: 100%;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-align: initial;
  min-width: 140px;
  text-align: center;

  @media (min-width: 1024px) {
    text-align: left;
  }
  @media (max-width: 768px) {
    align-items: center;
    justify-content: center;
    display: contents;
    margin-top: 12px;
  }
`

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: ${(props) => props.direction};
  align-items: center;
  margin-top: 8px;
  justify-content: ${(props) => props.position};
`
const Button = styled.a`
  cursor: pointer;
  display: flex;
  background-color: ${styles.colors.black};
  border-radius: 20px;
  justify-content: center;
  align-items: center;
  padding: 8px 24px;
  height: 40px;
  width: fit-content;
`
const ButtonText = styled.div`
  background-color: ${styles.colors.black};
  color: ${styles.colors.white};
  font-weight: bold;
  font-size: 22px;
  line-height: 18px;
  margin-left: 8px;
  @media (max-width: 768px) {
    font-size: 18px;
  }
`

const OrText = styled.div`
  align-items: center;
  display: flex;
  margin: 0px 12px;
  @media (max-width: 768px) {
    justify-content: center;
  }
`
