import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter, Route, Redirect } from 'react-router-dom'
import { Switch } from 'react-router'
import styled from 'styled-components'
import * as actions from '../actions'
import Landing from './Landing'
import MobileTopNav from '../components/nav/MobileTopNav'
import LandingTopNav from '../components/nav/LandingTopNav'
import SideNav from '../components/nav/SideNav'
import TopNav from '../components/nav/TopNav'
import MobileSideNav from '../components/nav/MobileSideNav'
import Claim from './Claim'
import Unsubscribe from '../components/business/Unsubscribe'
import LoadingOverlay from '../components/shared/LoadingOverlay'
import Home from './Home'
import Activity from './Activity'
import Reviews from './Reviews'
import Dishes from './Dishes'
import Dish from './Dish'
import AddDish from './AddDish'
import EditImages from './EditImages'
import Restaurants from './Restaurants'
import Restaurant from './Restaurant'
import AddRestaurant from './AddRestaurant'
import Users from './Users'
import Settings from './Settings'
import Support from './Support'
import AddUser from '../components/users/AddUser'
import LogOut from './LogOut'
import styles from '../constants/styles'
import Auth from '../modules/Auth'

class App extends Component {
  constructor(props) {
    super(props)
    this.state = {
      scrolledPast: false,
    }
    this.handleLogin = this.handleLogin.bind(this)
    this.handleScroll = this.handleScroll.bind(this)
  }

  componentDidMount() {
    const { scrollPastEarly, fetchUser } = this.props
    window.addEventListener('scroll', this.handleScroll)
    if (Auth.isUserAuthenticated() && !!Auth.getUsername()) {
      fetchUser(Auth.getUsername())
    }
    if (scrollPastEarly) {
      this.setState({
        scrolledPast: true,
      })
    }
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll)
  }

  handleScroll() {
    const { scrollPastEarly } = this.props
    if (scrollPastEarly) return
    const lastScrollY = window.scrollY + 300
    const scrollDistance = window.innerHeight
    this.setState({
      scrolledPast: lastScrollY > scrollDistance,
    })
  }

  handleLogin(userData) {
    const { logIn, fetchUser } = this.props
    logIn(userData)
    fetchUser(userData.user.username)
  }

  render() {
    const {
      isLoggedIn,
      authModalActive,
      viewer,
      sideNavActive,
      toggleSideNav,
      handleAuthModal,
      dishesLoading,
      reviewsLoading,
      imagesLoading,
      notificationsLoading,
      skipDishes,
      location,
    } = this.props

    const { scrolledPast } = this.state
    return (
      <div>
        {isLoggedIn && location.pathname === '/' && (
          <Redirect exact path="/" to="/home" />
        )}

        {!isLoggedIn && (
          <Route
            path="/"
            render={(props) => (
              <LandingTopNav
                handleLogin={this.handleLogin}
                authModalActive={authModalActive}
                handleAuthModal={handleAuthModal}
                scrolledPast={scrolledPast}
                {...props}
              />
            )}
          />
        )}

        {!isLoggedIn && (
          <Switch>
            <Route
              exact
              path="/"
              render={(props) => (
                <Landing {...props} handleLogin={this.handleLogin} />
              )}
            />
            <Route
              exact
              path="/claim"
              render={(props) => <Claim {...props} />}
            />
            <Route
              path="/unsubscribe/:id"
              render={(props) => <Unsubscribe {...props} />}
            />
            <Route path="/" render={() => <Redirect to="/" />} />
          </Switch>
        )}

        {isLoggedIn && (
          <Route
            path="/"
            render={(props) => (
              <MobileTopNav
                viewer={viewer}
                sideNavActive={sideNavActive}
                toggleSideNav={() => toggleSideNav(!sideNavActive)}
                {...props}
              />
            )}
          />
        )}

        {isLoggedIn &&
          (dishesLoading ||
            imagesLoading ||
            notificationsLoading ||
            reviewsLoading) &&
          skipDishes === 0 && (
            <Route path="/" render={(props) => <LoadingOverlay />} />
          )}

        {isLoggedIn && (
          <Route
            path="/"
            render={(props) => (
              <TopNav
                viewer={viewer}
                sideNavActive={sideNavActive}
                toggleSideNav={() => toggleSideNav(!sideNavActive)}
                {...props}
              />
            )}
          />
        )}

        {isLoggedIn && (
          <BelowTopNav>
            <SideNavDiv>
              <Route
                path="/"
                render={(props) => (
                  <SideNav
                    viewer={viewer}
                    notifications={this.state.notifications}
                    {...props}
                  />
                )}
              />
            </SideNavDiv>

            <MainBody>
              <Switch>
                <Route
                  exact
                  path="/home"
                  render={(props) => <Home {...props} />}
                />
                <Route
                  exact
                  path="/restaurants"
                  render={(props) => <Restaurants {...props} />}
                />
                <Route
                  exact
                  path="/restaurants/new"
                  render={(props) => <AddRestaurant {...props} />}
                />
                <Route
                  exact
                  path="/restaurants/:id"
                  render={(props) => <Restaurant {...props} />}
                />
                <Route
                  exact
                  path="/dishes"
                  render={(props) => <Dishes {...props} />}
                />
                <Route
                  exact
                  path="/dishes/new"
                  render={(props) => <AddDish {...props} />}
                />

                <Route
                  exact
                  path="/dishes/:id/images"
                  render={(props) => <EditImages {...props} />}
                />

                <Route
                  path="/dishes/:id"
                  render={(props) => <Dish {...props} />}
                />

                <Route
                  exact
                  path="/reviews"
                  render={(props) => <Reviews {...props} />}
                />

                <Route
                  exact
                  path="/reviews/:id"
                  render={(props) => <Reviews {...props} />}
                />

                <Route
                  exact
                  path="/reviews/:id"
                  render={(props) => <Reviews {...props} />}
                />

                <Route
                  exact
                  path="/users"
                  render={(props) => <Users {...props} />}
                />

                <Route
                  exact
                  path="/users/new"
                  render={(props) => <AddUser {...props} />}
                />

                <Route
                  exact
                  path="/activity"
                  render={(props) => <Activity {...props} />}
                />

                <Route
                  path="/unsubscribe/:id"
                  render={(props) => <Unsubscribe {...props} />}
                />

                <Route
                  exact
                  path="/settings"
                  render={(props) => <Settings {...props} />}
                />

                <Route
                  exact
                  path="/support"
                  render={(props) => <Support {...props} />}
                />

                <Route
                  exact
                  path="/logout"
                  render={(props) => (
                    <LogOut handleLogin={this.handleLogin} {...props} />
                  )}
                />

                {/* if no url matches just go to dashboard */}
                <Route path="/" render={() => <Redirect to="/home" />} />
              </Switch>
            </MainBody>
          </BelowTopNav>
        )}

        <MobileSideNav
          sideNavActive={sideNavActive}
          toggleSideNav={() => toggleSideNav(!sideNavActive)}
        />
      </div>
    )
  }
}

App.propTypes = {
  isLoggedIn: PropTypes.bool.isRequired,
  authModalActive: PropTypes.bool.isRequired,
  viewer: PropTypes.objectOf(PropTypes.any).isRequired,
  fetchUser: PropTypes.func.isRequired,
  scrollPastEarly: PropTypes.bool,
}

App.defaultProps = {
  scrollPastEarly: false,
}
const mapStateToProps = (state) => ({
  isLoggedIn: state.auth.isLoggedIn,
  authModalActive: state.auth.modalActive,
  viewer: state.users.viewer._id ? state.users.all[state.users.viewer._id] : {},
  sideNavActive: state.sideNavActive,
  dishesLoading: state.dishes.index.isLoading,
  imagesLoading: state.images.index.isLoading,
  reviewsLoading: state.reviews.index.isLoading,
  notificationsLoading: state.notifications.index.isLoading,
  skipDishes: state.dishes.index.skip,
})

const mapDispatchToProps = (dispatch) => ({
  logIn: (userData) => {
    dispatch(actions.auth.logIn(userData))
  },
  handleAuthModal: (active) => {
    dispatch({ type: actions.HANDLE_AUTH_MODAL, active })
  },
  fetchUser: (username) => {
    dispatch(actions.users.fetchViewer(username))
  },
  toggleSideNav: (value) => {
    dispatch({ type: actions.TOGGLE_SIDE_NAV, value })
  },
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App))

const BelowTopNav = styled.div`
  width: 100%;
  display: flex;
  flex-flow: row;
  padding-top: 64px;
`

const SideNavDiv = styled.nav`
  width: 200px;
  height: 100vh;
  display: none;
  position: fixed;
  top: 64px;
  left: 0;
  @media (min-width: 801px) {
    display: block;
  }
`

const MainBody = styled.main`
  width: 100%;
  height: 100%;
  min-height: 100vh;
  background-color: ${styles.colors.veryLightGrey};
  -webkit-overflow-scrolling: touch;
  @media (min-width: 801px) {
    padding-left: 200px;
    overflow: auto;
  }
  ::-webkit-scrollbar {
    display: none;
  }
`
