import React, { Component } from 'react'
import PropTypes from 'prop-types'
import axios from 'axios'
import styled from 'styled-components'
import constants from '../../constants/constants'
import styles from '../../constants/styles'
import { capitalizeString } from '../../utilities/format'

class EditCategories extends Component {
  constructor(props) {
    super(props)
    this.state = {
      current: ''
    }
    this.handleCategories = this.handleCategories.bind(this)
    this.suggest = this.suggest.bind(this)
  }

  // add and remove categories and update parent
  handleCategories(type, index) {
    if (type === 'remove') {
      const chosen = this.props.currentDetails
      chosen.splice(index, 1)
      this.props.handleChange(chosen)
    } else if (type === 'add') {
      if (
        this.props.currentDetails.length === 3
        || !this.state.current.length
        || this.props.currentDetails.includes(this.state.current.toLowerCase())
      ) {
        return
      }
      this.props.handleChange(
        this.props.currentDetails.concat(this.state.current)
      )
      this.setState({ current: '' })
    }
  }

  // send suggestion to add a category to our contact email
  suggest() {
    const suggestion = window.prompt(
      'What category do you want to add?',
      this.state.current
    )
    if (!suggestion) {
      return
    }
    const feedback = {
      email: this.props.user.email,
      name: this.props.user.name || this.props.user.username,
      message: `I suggest adding the following category: ${suggestion}`
    }

    axios.post(constants.api.email.contact, feedback)

    window.alert('Thank you for your feedback')
    this.setState({ current: '' })
  }

  render() {
    const categories = this.props.categories

    const filtered = categories.filter(c => c.title.includes(this.state.current.toLowerCase()))

    // console.log('current categories', this.props.currentDetails)

    return (
      <div style={{ width: '100%' }}>
        <List style={{ display: 'flex', flexFlow: 'row wrap' }}>
          {this.props.currentDetails.map((cat, index) => (
            <Chosen key={index}>
              <span>{capitalizeString(cat)}</span>
              <Remove onClick={() => this.handleCategories('remove', index)}>
                <i className="fas fa-times" />
              </Remove>
            </Chosen>
          ))}
        </List>

        {this.props.currentDetails.length < 3 ? (
          <InputContainer>
            <Input
              type="text"
              placeholder="Choose up to three categories"
              value={this.state.current || ''}
              onChange={e => this.setState({ current: e.target.value })}
            />
          </InputContainer>
        ) : (
          <Alert>You can choose a maximum of 3 categories. Remove some to add another.</Alert>
        )}

        {this.state.current.length > 0 && categories.length > 0 && (
          <div>
            {filtered.length > 0 ? (
              <Categories>
                {filtered.map(cat => (
                  <Category
                    key={cat.title}
                    onClick={() => this.setState(
                      { current: cat.title },
                      function () {
                        this.handleCategories('add')
                      }
                    )
                    }
                  >
                    <span>
                      {capitalizeString(cat.title)}
                    </span>
                    <Add>
                      <i className="fas fa-plus" />
                    </Add>
                  </Category>
                ))}
              </Categories>
            ) : (
              <Alert>
                <span>No categories found.</span>
&nbsp;
                <Suggest onClick={this.suggest}>Suggest one</Suggest>
              </Alert>
            )}
          </div>
        )}
      </div>
    )
  }
}

EditCategories.propTypes = {
  user: PropTypes.object.isRequired,
  categories: PropTypes.array.isRequired,
  currentDetails: PropTypes.arrayOf(PropTypes.string).isRequired,
  handleChange: PropTypes.func.isRequired
}

const List = styled.div`
  width: 100%;
  display: flex;
`

const Chosen = styled.div`
  display: flex;
  align-items: center;
  border: 1px solid ${styles.colors.black};
  margin-right: ${styles.margin.small};
  margin-bottom: ${styles.margin.small};
  padding: ${styles.padding.small} ${styles.padding.medium};
  background-color: ${styles.colors.white};
  border-radius: ${styles.borderRadius.small};
`

const Remove = styled.div`
  width: 24px;
  height: 24px;
  margin-left: ${styles.margin.small};
  color: ${styles.colors.action};
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  font-size: 1em;

  &:hover {
    background: ${styles.colors.action};
    color: ${styles.colors.white};
  }
`

const InputContainer = styled.div`
  width: 100%;
  display: flex;
  border: 1px solid ${styles.colors.lightGrey};
  background-color: ${styles.colors.white};
  margin-top: ${styles.margin.small};
`

const Input = styled.input`
  width: 100%;
  font-size: 1em;
  border: 0;
  background-color: transparent;
  padding: ${styles.padding.medium};
`

const Categories = styled.div`
  background-color: ${styles.colors.white};
  border: 1px solid ${styles.colors.lightGrey};
`

const Category = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${styles.padding.medium};
  border-bottom: 1px solid ${styles.colors.lightGrey};
  cursor: pointer;

  &:hover {
    background-color: ${styles.colors.gold};
  }
`

const Add = styled.div`
  color: ${styles.colors.omnivore};
`

const Alert = styled.div`
  display: flex;
  align-items: center;
  margin-top: ${styles.margin.small};
  font-size: 0.8em;
  color: ${styles.colors.action};
`

const Suggest = styled.span`
  color: ${styles.colors.omnivore};
  cursor: pointer;
  text-decoration: underline;
`

export default EditCategories
