import React from 'react'
import styled from 'styled-components'
import styles from '../../constants/styles'

const DishCardLoading = () => (
  <Container>
    <Image />
    <InfoContainer style={{ marginRight: styles.margin.small }}>
      <Name>dish name here</Name>
      <VegType>VEGAN</VegType>
    </InfoContainer>

    <InfoContainer>
      <Rating>stars here</Rating>
    </InfoContainer>

    <MobileInfoContainer style={{ alignItems: 'center' }}>
      <Category>Reviews</Category>
      <Value>20</Value>
    </MobileInfoContainer>

    <MobileInfoContainer>
      <Category>Price</Category>
      <Value>None</Value>
    </MobileInfoContainer>

    <MobileInfoContainer>
      <Category>Description</Category>
      <Value>None</Value>
    </MobileInfoContainer>

    <MobileInfoContainer>
      <Category>Ingredients</Category>
      <Value>None</Value>
    </MobileInfoContainer>

    <MobileInfoContainer>
      <Category>Allergens</Category>
      <Value>None</Value>
    </MobileInfoContainer>

    <MidScreenDiv>
      <MidScreenInfoContainer>
        <Category style={{ width: '108px' }}>Price</Category>
        <Value>
          None
        </Value>
      </MidScreenInfoContainer>

      <MidScreenInfoContainer>
        <Category style={{ width: '108px' }}>Description</Category>
        <Value>
          None
        </Value>
      </MidScreenInfoContainer>

      <MidScreenInfoContainer>
        <Category style={{ width: '108px' }}>Ingredients</Category>
        <Value>
          None
        </Value>
      </MidScreenInfoContainer>

      <MidScreenInfoContainer>
        <Category style={{ width: '108px' }}>Allergens</Category>
        <Value>
          None
        </Value>
      </MidScreenInfoContainer>
    </MidScreenDiv>
  </Container>
)

export default DishCardLoading

const Container = styled.div`
  width: 100%;
  min-height: 64px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background: white;
  margin-bottom: ${styles.margin.small};
  border-radius: ${styles.borderRadius.small};
  padding: ${styles.padding.small};
  overflow: hidden;
`
const Image = styled.div`
  width: 48px;
  height: 48px;
  min-width: 48px;
  border-radius: ${styles.borderRadius.small};
  background: ${styles.colors.lightGrey};
`
const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0 ${styles.padding.small};
  margin-right: ${styles.margin.medium};
`
const Name = styled.div`
  width: 120px;
  background: ${styles.colors.lightGrey};
  margin-bottom: ${styles.margin.small};
  color: transparent;

  @media (min-width: 600px) {
    width: 240px;
  }
  @media (min-width: 800px) {
    width: 320px;
  }
`

const VegType = styled.span`
  font-size: 0.8em;
  font-weight: bold;
  color: transparent;
  background: ${styles.colors.lightGrey};
`

const Rating = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 80px;
  color: transparent;
  background: ${styles.colors.lightGrey};

  @media (max-width: 800px) {
    height: 30px;
  }
`
const MobileInfoContainer = styled(InfoContainer)`
  display: none;
  height: 30px;
  background: ${styles.colors.lightGrey};
  color: transparent;

  @media (min-width: 800px) {
    display: flex;
  }
`
const Category = styled.span`
  font-size: 0.8em;
  font-weight: bold;
  color: transparent;
  background: ${styles.colors.lightGrey};
`

const Value = styled.span`
  font-size: 0.8em;
  font-weight: bold;
  color: transparent;
  background: ${styles.colors.lightGrey};
`

const MidScreenDiv = styled.div`
  display: none;

  @media (min-width: 500px) {
    display: flex;
    flex-direction: column;
  }
  @media (min-width: 800px) {
    display: none;
  }
`
const MidScreenInfoContainer = styled(InfoContainer)`
  flex-direction: row;
  align-items: center;
  margin-right: 0;
`
