import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

const ABLogoGreenSvg = ({ color1, color2 }) => (
  <SvgContainer>
    <svg width="120" height="46" viewBox="0 0 120 46" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M15.4616 33.9118C15.1418 33.6275 14.6844 33.5609 14.2936 33.7386C13.019 34.3181 11.6245 34.6068 10.1079 34.6068C7.31445 34.6068 4.93181 33.6209 2.95775 31.6468C0.988139 29.6705 0 27.2879 0 24.4967C0 21.8121 1.06364 19.2451 2.9622 17.3466C4.93625 15.3725 7.31889 14.3866 10.1123 14.3866C11.5734 14.3866 12.9213 14.6553 14.1581 15.1949C14.6267 15.3992 15.1663 15.3103 15.5615 14.9884L15.5637 14.9861C16.0678 14.5864 16.6562 14.3888 17.3313 14.3888C18.1307 14.3888 18.8124 14.6708 19.3742 15.2348C19.9382 15.7989 20.2202 16.4806 20.2202 17.2777V31.7223C20.2202 32.5217 19.9382 33.2034 19.3742 33.7652C18.8102 34.3292 18.1285 34.6112 17.3313 34.6112C16.6118 34.6068 15.9901 34.3759 15.4616 33.9118ZM13.1722 27.5566C14.0205 26.7084 14.4424 25.6891 14.4424 24.4945C14.4424 23.2998 14.0182 22.2806 13.1722 21.4324C12.324 20.5841 11.3048 20.1622 10.1101 20.1622C8.91545 20.1622 7.89623 20.5863 7.04798 21.4324C6.19974 22.2806 5.77784 23.2998 5.77784 24.4945C5.77784 25.6891 6.20196 26.7084 7.04798 27.5566C7.89623 28.4048 8.91545 28.8267 10.1101 28.8267C11.3048 28.829 12.3262 28.4048 13.1722 27.5566Z" fill={color1} />
      <path d="M28.887 14.0005C28.887 14.6111 29.4821 15.0419 30.0639 14.8554C31.0476 14.5401 32.1001 14.3824 33.2193 14.3824C36.0127 14.3824 38.3954 15.3706 40.3694 17.3424C42.3435 19.3165 43.3294 21.6991 43.3294 24.4925C43.3294 27.2282 42.3834 29.5709 40.4893 31.5205C38.5885 33.479 35.9639 34.6004 33.2326 34.6049C31.7626 34.6071 30.4059 34.3362 29.1646 33.7944C28.6961 33.5901 28.1454 33.6656 27.7612 34.0053C27.7412 34.0231 27.7235 34.0364 27.7124 34.0475C27.2172 34.4205 26.6443 34.6071 25.9981 34.6071C25.1987 34.6071 24.517 34.3251 23.9552 33.761C23.3912 33.197 23.1092 32.5175 23.1092 31.7182V8.60681C23.1092 7.80742 23.3912 7.12572 23.9552 6.56392C24.5192 5.9999 25.1987 5.7179 25.9981 5.7179C26.7975 5.7179 27.4792 5.9999 28.041 6.56392C28.605 7.12794 28.887 7.80964 28.887 8.60681V14.0005ZM36.2836 27.5569C37.1319 26.7086 37.5538 25.6894 37.5538 24.4948C37.5538 23.3001 37.1297 22.2809 36.2836 21.4326C35.4354 20.5844 34.4162 20.1625 33.2215 20.1625C32.0269 20.1625 31.0076 20.5866 30.1594 21.4326C29.3112 22.2809 28.8892 23.3001 28.8892 24.4948C28.8892 25.6894 29.3134 26.7086 30.1594 27.5569C31.0076 28.4051 32.0269 28.827 33.2215 28.827C34.4162 28.8292 35.4354 28.4051 36.2836 27.5569Z" fill={color1} />
      <path d="M48.6649 11.4957C47.8655 11.4957 47.1838 11.2137 46.622 10.6497C46.058 10.0857 45.776 9.40621 45.776 8.60681C45.776 7.80742 46.058 7.12572 46.622 6.56392C47.186 5.9999 47.8655 5.7179 48.6649 5.7179C49.4643 5.7179 50.146 5.9999 50.7078 6.56392C51.2718 7.12794 51.5538 7.80964 51.5538 8.60681C51.5538 9.40621 51.2718 10.0879 50.7078 10.6497C50.146 11.2137 49.4643 11.4957 48.6649 11.4957ZM51.5538 31.7182C51.5538 32.5175 51.2718 33.1993 50.7078 33.761C50.1438 34.3251 49.4621 34.6071 48.6649 34.6071C47.8655 34.6071 47.1838 34.3251 46.622 33.761C46.058 33.197 45.776 32.5175 45.776 31.7182V17.2736C45.776 16.4742 46.058 15.7925 46.622 15.2307C47.186 14.6667 47.8655 14.3846 48.6649 14.3846C49.4643 14.3846 50.146 14.6667 50.7078 15.2307C51.2718 15.7947 51.5538 16.4764 51.5538 17.2736V31.7182Z" fill={color1} />
      <path d="M60.2209 31.7182C60.2209 32.5175 59.9389 33.1993 59.3749 33.761C58.8109 34.3251 58.1291 34.6071 57.332 34.6071C56.5326 34.6071 55.8509 34.3251 55.2891 33.761C54.7251 33.197 54.4431 32.5175 54.4431 31.7182V8.60681C54.4431 7.80742 54.7251 7.12572 55.2891 6.56392C55.8531 5.9999 56.5326 5.7179 57.332 5.7179C58.1314 5.7179 58.8131 5.9999 59.3749 6.56392C59.9389 7.12794 60.2209 7.80964 60.2209 8.60681V31.7182Z" fill={color1} />
      <path d="M68.8879 31.7182C68.8879 32.5175 68.6059 33.1993 68.0419 33.761C67.4779 34.3251 66.7962 34.6071 65.999 34.6071C65.1996 34.6071 64.5179 34.3251 63.9561 33.761C63.3921 33.197 63.1101 32.5175 63.1101 31.7182V8.60681C63.1101 7.80742 63.3921 7.12572 63.9561 6.56392C64.5201 5.9999 65.1996 5.7179 65.999 5.7179C66.7984 5.7179 67.4801 5.9999 68.0419 6.56392C68.6059 7.12794 68.8879 7.80964 68.8879 8.60681V31.7182Z" fill={color1} />
      <path d="M77.5541 31.718C77.5541 32.5174 77.2721 33.1991 76.7081 33.7609C76.144 34.3249 75.4623 34.6069 74.6652 34.6069C73.8658 34.6069 73.1841 34.3249 72.6223 33.7609C72.0583 33.1969 71.7762 32.5174 71.7762 31.718V17.2734C71.7762 16.474 72.0583 15.7923 72.6223 15.2305C73.1863 14.6665 73.8658 14.3845 74.6652 14.3845C75.4646 14.3845 76.1463 14.6665 76.7081 15.2305C77.2721 15.7946 77.5541 16.4763 77.5541 17.2734V31.718Z" fill={color2} />
      <path d="M90.1092 34.6068C87.3158 34.6068 84.9332 33.6209 82.9591 31.6468C80.9851 29.6728 79.9991 27.2901 79.9991 24.4967C79.9991 21.7033 80.9851 19.3206 82.9591 17.3466C84.9332 15.3725 87.3158 14.3866 90.1092 14.3866C92.9027 14.3866 95.2853 15.3747 97.2594 17.3466C99.2334 19.3206 100.219 21.7033 100.219 24.4967C100.219 27.2901 99.2312 29.6728 97.2594 31.6468C95.2853 33.6187 92.9027 34.6068 90.1092 34.6068ZM93.1714 27.5566C94.0196 26.7084 94.4415 25.6891 94.4415 24.4945C94.4415 23.2998 94.0174 22.2806 93.1714 21.4324C92.3231 20.5841 91.3039 20.1622 90.1092 20.1622C88.9146 20.1622 87.8954 20.5863 87.0471 21.4324C86.1989 22.2806 85.777 23.2998 85.777 24.4945C85.777 25.6891 86.2011 26.7084 87.0471 27.5566C87.8954 28.4048 88.9146 28.8267 90.1092 28.8267C91.3039 28.829 92.3231 28.4048 93.1714 27.5566Z" fill={color2} />
      <path d="M107.01 14.7687C107.417 15.0085 107.914 15.0396 108.358 14.8819C109.28 14.5511 110.272 14.3867 111.334 14.3867C113.732 14.3867 115.775 15.2328 117.464 16.9226C119.154 18.6124 120 20.6553 120 23.0535V31.7203C120 32.5196 119.718 33.2013 119.154 33.7631C118.59 34.3272 117.909 34.6092 117.111 34.6092C116.312 34.6092 115.63 34.3272 115.069 33.7631C114.505 33.1991 114.223 32.5196 114.223 31.7203V23.0535C114.223 22.2541 113.94 21.5724 113.376 21.0106C112.812 20.4466 112.131 20.1646 111.334 20.1646C110.534 20.1646 109.852 20.4466 109.291 21.0106C108.727 21.5746 108.445 22.2563 108.445 23.0535V31.7203C108.445 32.5196 108.163 33.2013 107.599 33.7631C107.035 34.3272 106.353 34.6092 105.556 34.6092C104.756 34.6092 104.075 34.3272 103.513 33.7631C102.949 33.1991 102.667 32.5196 102.667 31.7203V17.2734C102.667 16.474 102.949 15.7923 103.513 15.2305C104.077 14.6665 104.756 14.3845 105.556 14.3845C106.093 14.3845 106.579 14.5133 107.01 14.7687Z" fill={color1} />
      <path d="M97.2483 11.5601C97.577 11.0672 97.4016 10.3966 96.8708 10.1301C96.369 9.87917 95.9071 9.5372 95.4786 9.10642C94.4349 8.06943 93.9131 6.81261 93.9131 5.33595C93.9131 3.85929 94.4327 2.60247 95.4741 1.56104C96.5156 0.519606 97.7724 0 99.249 0C100.726 0 101.983 0.519606 103.024 1.56104C104.065 2.60247 104.585 3.85929 104.585 5.33595C104.585 6.14867 104.498 6.7904 104.327 7.26559C104.156 7.74079 104.019 8.06055 103.919 8.22487L100.362 13.3365H100.353C100.275 13.4676 100.182 13.5875 100.068 13.7007C99.7176 14.0516 99.2979 14.2248 98.8049 14.2248C98.312 14.2248 97.8945 14.0516 97.5459 13.7052C97.1995 13.3587 97.0263 12.9391 97.0263 12.4461C97.0263 12.1197 97.104 11.8244 97.2572 11.5579L97.2483 11.5601ZM100.506 6.59277C100.852 6.24637 101.025 5.82669 101.025 5.33373C101.025 4.84077 100.852 4.42331 100.506 4.07468C100.159 3.72828 99.7398 3.55508 99.2468 3.55508C98.7539 3.55508 98.3364 3.72828 97.9878 4.07468C97.6414 4.42109 97.4682 4.84077 97.4682 5.33373C97.4682 5.82669 97.6414 6.24415 97.9878 6.59277C98.3342 6.93918 98.7539 7.11238 99.2468 7.11238C99.7398 7.11238 100.159 6.93918 100.506 6.59277Z" fill={color2} />
      <path d="M66.9402 42.339C67.1334 42.5922 67.2311 42.9031 67.2311 43.2739C67.2311 43.7979 67.0268 44.1999 66.6204 44.4819C66.2141 44.7639 65.6212 44.9038 64.8418 44.9038H61.753V38.9327H64.6708C65.3991 38.9327 65.9565 39.0726 66.3473 39.3502C66.7359 39.63 66.9313 40.0075 66.9313 40.4849C66.9313 40.7758 66.8625 41.0334 66.7226 41.2621C66.5827 41.4908 66.3895 41.6684 66.1386 41.7995C66.4805 41.9038 66.747 42.0859 66.9402 42.339ZM63.1275 39.9697V41.3775H64.502C64.844 41.3775 65.1016 41.3176 65.2792 41.1977C65.4547 41.0778 65.5435 40.9024 65.5435 40.6692C65.5435 40.436 65.4547 40.2606 65.2792 40.1452C65.1038 40.0275 64.844 39.9697 64.502 39.9697H63.1275ZM65.5635 43.6825C65.7478 43.5626 65.841 43.3783 65.841 43.1273C65.841 42.6322 65.4746 42.3857 64.7396 42.3857H63.1275V43.8623H64.7396C65.1038 43.8601 65.3792 43.8024 65.5635 43.6825Z" fill={color2} />
      <path d="M69.5802 44.295C69.105 43.822 68.8674 43.1492 68.8674 42.2721V38.928H70.2486V42.221C70.2486 43.2913 70.6927 43.8243 71.5787 43.8243C72.0117 43.8243 72.3403 43.6955 72.569 43.4357C72.7955 43.1759 72.911 42.7717 72.911 42.221V38.928H74.2766V42.2721C74.2766 43.147 74.039 43.822 73.5638 44.295C73.0886 44.768 72.4247 45.0034 71.572 45.0034C70.7193 45.0034 70.0554 44.768 69.5802 44.295Z" fill={color2} />
      <path d="M76.875 44.8126C76.4331 44.6838 76.08 44.5173 75.8135 44.313L76.2821 43.2715C76.5374 43.4581 76.8416 43.6091 77.1947 43.7245C77.5478 43.8378 77.9008 43.8955 78.2517 43.8955C78.6447 43.8955 78.9334 43.8378 79.1221 43.7201C79.3109 43.6046 79.4041 43.4492 79.4041 43.256C79.4041 43.1139 79.3486 42.9962 79.2376 42.9029C79.1266 42.8097 78.9845 42.7342 78.8113 42.6764C78.6381 42.6187 78.4027 42.5565 78.1073 42.4877C77.6521 42.3789 77.2791 42.2723 76.9904 42.1635C76.6995 42.0547 76.4508 41.8815 76.2443 41.6439C76.0378 41.4041 75.9334 41.0865 75.9334 40.6891C75.9334 40.3427 76.0267 40.0273 76.2155 39.7453C76.402 39.4633 76.6862 39.2413 77.0637 39.0747C77.4412 38.9104 77.9031 38.8282 78.4493 38.8282C78.8312 38.8282 79.2021 38.8749 79.5662 38.9659C79.9304 39.057 80.2479 39.188 80.5211 39.359L80.0947 40.4093C79.544 40.0962 78.9911 39.9407 78.4404 39.9407C78.0541 39.9407 77.7676 40.0029 77.5833 40.1273C77.399 40.2516 77.3057 40.4182 77.3057 40.6224C77.3057 40.8267 77.4123 40.9799 77.6255 41.0799C77.8387 41.1798 78.1651 41.2775 78.6025 41.3752C79.0577 41.484 79.4308 41.5906 79.7195 41.6994C80.0104 41.8082 80.259 41.9792 80.4656 42.2123C80.6721 42.4455 80.7764 42.7608 80.7764 43.1583C80.7764 43.5003 80.681 43.8111 80.49 44.0931C80.299 44.3751 80.0126 44.5972 79.6329 44.7637C79.2509 44.9281 78.7891 45.0102 78.2428 45.0102C77.772 45.0036 77.3168 44.9392 76.875 44.8126Z" fill={color2} />
      <path d="M82.3658 38.9305H83.747V44.9016H82.3658V38.9305Z" fill={color2} />
      <path d="M91.2797 38.9305V44.9016H90.145L87.1672 41.2754V44.9016H85.8016V38.9305H86.9452L89.914 42.5567V38.9305H91.2797Z" fill={color2} />
      <path d="M97.9607 43.7935V44.9016H93.3376V38.9305H97.8497V40.0408H94.7098V41.3376H97.4833V42.4123H94.7098V43.7935H97.9607Z" fill={color2} />
      <path d="M100.295 44.8126C99.8528 44.6838 99.4997 44.5173 99.2332 44.313L99.7018 43.2715C99.9571 43.4581 100.261 43.6091 100.614 43.7245C100.967 43.8378 101.321 43.8955 101.671 43.8955C102.064 43.8955 102.353 43.8378 102.542 43.7201C102.731 43.6046 102.824 43.4492 102.824 43.256C102.824 43.1139 102.768 42.9962 102.657 42.9029C102.546 42.8097 102.404 42.7342 102.231 42.6764C102.058 42.6187 101.822 42.5565 101.527 42.4877C101.072 42.3789 100.699 42.2723 100.41 42.1635C100.119 42.0547 99.8705 41.8815 99.664 41.6439C99.4575 41.4041 99.3531 41.0865 99.3531 40.6891C99.3531 40.3427 99.4464 40.0273 99.6352 39.7453C99.8217 39.4633 100.106 39.2413 100.483 39.0747C100.861 38.9104 101.323 38.8282 101.869 38.8282C102.251 38.8282 102.622 38.8749 102.986 38.9659C103.35 39.057 103.668 39.188 103.941 39.359L103.514 40.4093C102.964 40.0962 102.411 39.9407 101.86 39.9407C101.474 39.9407 101.187 40.0029 101.003 40.1273C100.819 40.2516 100.725 40.4182 100.725 40.6224C100.725 40.8267 100.832 40.9799 101.045 41.0799C101.258 41.1798 101.585 41.2775 102.022 41.3752C102.477 41.484 102.85 41.5906 103.139 41.6994C103.43 41.8082 103.679 41.9792 103.885 42.2123C104.092 42.4455 104.196 42.7608 104.196 43.1583C104.196 43.5003 104.101 43.8111 103.91 44.0931C103.719 44.3751 103.432 44.5972 103.053 44.7637C102.671 44.9281 102.209 45.0102 101.663 45.0102C101.192 45.0036 100.734 44.9392 100.295 44.8126Z" fill={color2} />
      <path d="M106.377 44.8126C105.935 44.6838 105.582 44.5173 105.315 44.313L105.784 43.2715C106.039 43.4581 106.344 43.6091 106.697 43.7245C107.05 43.8378 107.403 43.8955 107.754 43.8955C108.147 43.8955 108.435 43.8378 108.624 43.7201C108.813 43.6046 108.906 43.4492 108.906 43.256C108.906 43.1139 108.851 42.9962 108.739 42.9029C108.628 42.8097 108.486 42.7342 108.313 42.6764C108.14 42.6187 107.905 42.5565 107.609 42.4877C107.154 42.3789 106.781 42.2723 106.492 42.1635C106.201 42.0547 105.953 41.8815 105.746 41.6439C105.54 41.4041 105.435 41.0865 105.435 40.6891C105.435 40.3427 105.529 40.0273 105.717 39.7453C105.904 39.4633 106.188 39.2413 106.566 39.0747C106.943 38.9104 107.405 38.8282 107.951 38.8282C108.333 38.8282 108.704 38.8749 109.068 38.9659C109.432 39.057 109.75 39.188 110.023 39.359L109.597 40.4093C109.046 40.0962 108.493 39.9407 107.942 39.9407C107.556 39.9407 107.27 40.0029 107.085 40.1273C106.901 40.2516 106.808 40.4182 106.808 40.6224C106.808 40.8267 106.914 40.9799 107.127 41.0799C107.341 41.1798 107.667 41.2775 108.104 41.3752C108.56 41.484 108.933 41.5906 109.221 41.6994C109.512 41.8082 109.761 41.9792 109.967 42.2123C110.174 42.4455 110.278 42.7608 110.278 43.1583C110.278 43.5003 110.183 43.8111 109.992 44.0931C109.801 44.3751 109.514 44.5972 109.135 44.7637C108.753 44.9281 108.291 45.0102 107.745 45.0102C107.274 45.0036 106.817 44.9392 106.377 44.8126Z" fill={color2} />
    </svg>
  </SvgContainer>
)

ABLogoGreenSvg.propTypes = {
  color1: PropTypes.string,
  color2: PropTypes.string
}

ABLogoGreenSvg.defaultProps = {
  color1: '#1A2958',
  color2: '#7ABC2F'
}


const SvgContainer = styled.span`
  height: fit-content;
  width: fit-content;
  svg {
    @media (max-width: 600px) {
      height: 36px;
      width: 95px;
    }
  }
`
export default ABLogoGreenSvg
