import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import * as actions from '../actions'
import Title from '../components/shared/Title'
import ActionBar from '../components/shared/ActionBar'
import ActionButton from '../components/shared/ActionButton'
import styles from '../constants/styles'
import {
  calculateFriendliness,
  handleFriendlinessScore,
  capitalizeString,
  sortAlphabetically
} from '../utilities/format'

class Restaurants extends Component {
  constructor(props) {
    super(props)

    this.containerRef = null
  }

  componentDidMount() {
    window.scroll(0, 0)
    const node = ReactDOM.findDOMNode(this.containerRef)
    if (node && window.innerWidth > 800) {
      node.scrollIntoView()
    }
  }

  render() {
    let restaurants = this.props.restaurants.index.items.map(
      _id => this.props.restaurants.all[_id]
    )

    const sortBy = this.props.sort
    restaurants = restaurants.sort((a, b) => (sortBy === 'name'
      ? sortAlphabetically(a.name, b.name)
      : sortBy === 'reviews'
        ? b.dishReviews - a.dishReviews
        : sortBy === 'pokes'
          ? b.votesCount - a.votesCount
          : b.friendliness.average - a.friendliness.average))

    return (
      <Container ref={con => (this.containerRef = con)}>
        <Title title="Restaurants" />

        <ActionBar justifyContent="flex-end">
          <Link to="/restaurants/new">
            <ActionButton>
              <span style={{ padding: `0 ${styles.padding.medium}` }}>
                <i className="fas fa-plus-circle" />
                &nbsp;Add restaurant
              </span>
            </ActionButton>
          </Link>
        </ActionBar>

        <SortContainer>
          <div
            style={{
              fontSize: '0.9em',
              fontWeight: 'bold'
            }}
          >
            <span>SORT</span>
            {['name', 'reviews', 'pokes', 'rating'].map((filter, i) => (
              <Filter
                key={i}
                active={sortBy === filter}
                onClick={() => this.props.updateRestaurantsSort(filter)}
              >
                {filter.toUpperCase()}
              </Filter>
            ))}
          </div>
        </SortContainer>

        <RestaurantListContainer>
          {!this.props.restaurants.index.fetched
            && [0, 1, 2, 3, 4].map(i => (
              <ListCardLoading key={`loadingListCard${i}`} i={i}>
                <LeftSection>
                  <MapIconDiv>
                    <MapIcon
                      className="fas fa-map-marker-alt"
                      style={{ color: styles.colors.lightGrey }}
                    />
                  </MapIconDiv>
                  <NameAddress>
                    <div
                      style={{
                        width: '400px',
                        maxWidth: '100%',
                        height: '16px',
                        background: styles.colors.lightGrey,
                        marginBottom: styles.margin.small,
                        color: 'transparent'
                      }}
                    >
                      testing
                    </div>
                    <div
                      style={{
                        width: '400px',
                        maxWidth: '100%',
                        height: '16px',
                        background: styles.colors.lightGrey,
                        color: 'transparent'
                      }}
                    >
                      testing
                    </div>
                  </NameAddress>
                </LeftSection>
                <RightSection>
                  <StatsContainer>
                    <ReviewBox>
                      <StatHeaderLoading>Reviews</StatHeaderLoading>
                      <StatHeaderLoading>Reviews</StatHeaderLoading>
                    </ReviewBox>
                    <PokeBox>
                      <StatHeaderLoading>Pokes</StatHeaderLoading>
                      <StatHeaderLoading>Pokes</StatHeaderLoading>
                    </PokeBox>
                    <RatingBox>
                      <StatHeaderLoading>Very Friendly</StatHeaderLoading>
                      <StatHeaderLoading>Very Friendly</StatHeaderLoading>
                    </RatingBox>
                  </StatsContainer>
                </RightSection>
              </ListCardLoading>
            ))}
          {this.props.restaurants.index.fetched
            && restaurants.map((r, i) => {
              const friendlinessScore = calculateFriendliness(r)
              let friendlinessString = capitalizeString(
                handleFriendlinessScore(friendlinessScore)
              )
              if (friendlinessString === 'Friendliness Unrated') {
                friendlinessString = 'Unrated'
              }
              return (
                <ListCard
                  key={r._id}
                  i={i}
                  onClick={() => this.props.history.push(`/restaurants/${r._id}`)
                  }
                >
                  <LeftSection>
                    <MapIconDiv>
                      <MapIcon className="fas fa-map-marker-alt" />
                    </MapIconDiv>
                    <NameAddress>
                      <Name>{r.name}</Name>
                      <Address>{r.formatted_address}</Address>
                    </NameAddress>
                  </LeftSection>

                  <RightSection>
                    <StatsContainer>
                      <ReviewBox>
                        <StatHeader>Reviews</StatHeader>
                        <Stat>{r.dishReviews}</Stat>
                      </ReviewBox>
                      <PokeBox>
                        <StatHeader>Pokes</StatHeader>
                        <Stat>{r.votesCount}</Stat>
                      </PokeBox>
                      <RatingBox>
                        <StatHeader>Rating</StatHeader>
                        <StatRating rating={friendlinessString}>
                          {friendlinessString}
                        </StatRating>
                      </RatingBox>
                    </StatsContainer>
                  </RightSection>
                </ListCard>
              )
            })}
        </RestaurantListContainer>
      </Container>
    )
  }
}

Restaurants.propTypes = {
  viewer: PropTypes.object.isRequired,
  restaurants: PropTypes.object.isRequired,
  categories: PropTypes.arrayOf(PropTypes.object).isRequired,
  updateField: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
  viewer: state.users.viewer._id ? state.users.all[state.users.viewer._id] : {},
  restaurants: state.restaurants,
  sort: state.restaurants.index.sort,
  categories: state.categories.index.items.map(_id => state.categories.all[_id])
})

const mapDispatchToProps = dispatch => ({
  updateField: ({ _id, value }) => {
    dispatch(actions.updateField({ type: 'restaurants', _id, value }))
  },
  updateRestaurantsSort: (sort) => {
    dispatch({ type: actions.UPDATE_RESTAURANTS_SORT, sort })
  }
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Restaurants)

const Container = styled.main`
  padding: 0 ${styles.padding.medium};
`

const Filter = styled.span`
  margin-left: ${styles.margin.small};
  color: ${props => (props.active ? styles.colors.omnivore : styles.colors.grey)};
  cursor: pointer;
  &:hover {
    color: ${props => (props.active ? styles.colors.omnivore : styles.colors.brand)};
  }
`

const RestaurantListContainer = styled.div`
  width: 100%;
  margin-bottom: ${styles.margin.medium};
`
const ListCard = styled.div`
  width: 100%;
  background: ${styles.colors.white};
  margin-top: ${props => (props.i === 0 ? '' : `${styles.margin.small}`)};
  padding: ${styles.padding.medium};
  border-radius: ${styles.borderRadius.small};
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;

  &: hover {
    background: ${styles.colors.lightGrey};
  }
`
const LeftSection = styled.div`
  display: flex;
  width: 600px;
  max-width: 100%;
  margin-right: ${styles.margin.small};

  @media (max-width: 800px) {
    width: 100%;
  }
  @media (max-width: 600px) {
    margin-right: 0;
  }
`
const MapIconDiv = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 500px) {
    display: none;
  }
`
const MapIcon = styled.i`
  color: ${styles.colors.omnivore};
  font-size: 1.5em;
  margin-right: ${styles.padding.medium};
`
const Name = styled.span`
  font-size: 1em;
  font-weight: bold;
  margin-bottom: ${styles.margin.tiny};

  @media (max-width: 800px) {
    font-size: 1em;
  }
`
const Address = styled.span`
  font-size: 1em;

  @media (max-width: 800px) {
    font-size: 0.9em;
  }
`
const RightSection = styled.div`
  display: flex;

  @media (max-width: 600px) {
    display: none;
  }
`

const NameAddress = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 100%;
`
const StatsContainer = styled.div`
  display: flex;
  height: 100%;

  @media (max-width: 600px) {
    display: none;
  }
`
const StatBox = styled.div`
  padding: 0 ${styles.padding.medium};
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 1000px) {
    padding: 0 ${styles.padding.small};
  }
`
const ReviewBox = styled(StatBox)`
  ${'' /* @media (max-width: 800px) {
    display: none;
  } */};
`
const PokeBox = styled(StatBox)`
  @media (max-width: 900px) {
    display: none;
  }
`
const RatingBox = styled(StatBox)`
  padding: 0;
  width: 120px;

  @media (max-width: 1100px) {
    display: none;
  }
`

const StatHeader = styled.span`
  font-size: 1em;
  font-weight: bold;
  margin-bottom: ${styles.margin.small};
  color: ${styles.colors.darkGrey};
`
const Stat = styled.span`
  font-size: 1.2em;
  font-weight: 500;
  color: ${styles.colors.black};
`
const StatRating = styled.span`
  font-size: 1em;
  font-weight: 500;
  text-align: center;
  margin-top: ${styles.margin.tiny};
  color: ${props => (props.rating === 'Very Friendly'
    ? styles.colors.vegan
    : props.rating === 'Friendly'
      ? styles.colors.brand
      : props.rating === 'Unrated'
        ? styles.colors.darkGrey
        : styles.colors.action)};
`

const ListCardLoading = styled.div`
  width: 100%;
  height: 75px;
  background: ${styles.colors.white};
  margin-top: ${props => (props.i === 0 ? '' : `${styles.margin.small}`)};
  padding: ${styles.padding.medium};
  border-radius: ${styles.borderRadius.small};
  display: flex;
  justify-content: space-between;
  align-items: center;
`
const StatHeaderLoading = styled(StatHeader)`
  background: ${styles.colors.lightGrey};
  color: transparent;
`

const SortContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: ${styles.height.small};
  justify-content: center;
  align-items: flex-start;
`
