import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { capitalizeString } from '../../utilities/format'
import styles from '../../constants/styles'

const ToggleClosed = ({ isClosed, toggle }) => (
  <Container>
    {[{ label: 'open', status: false }, { label: 'closed', status: true }].map(
      type => (
        <Status
          key={type.label}
          active={type.status === isClosed}
          type={type.label}
          onClick={() => toggle(type.status)}
        >
          {capitalizeString(type.label)}
        </Status>
      )
    )}
  </Container>
)

ToggleClosed.propTypes = {
  isClosed: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired
}

export default ToggleClosed

const Container = styled.ul`
  width: 100%;
  max-width: 720px;
  height: ${styles.height.small};
  display: flex;
  border: 1px solid ${styles.colors.lightGrey};
  border-radius: ${styles.borderRadius.small};
  background-color: ${styles.colors.white};
  overflow: hidden;
`

const Status = styled.li`
  width: 50%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-right: ${props => (props.type === 'open' ? '1px' : 0)} solid
    ${styles.colors.lightGrey};
  background-color: ${props => (props.active
    ? props.type === 'open'
      ? styles.colors.vegan
      : styles.colors.action
    : styles.colors.white)};
  color: ${props => (props.active ? styles.colors.white : styles.colors.black)};
  &:hover {
    background-color: ${props => (props.type === 'open' ? styles.colors.vegan : styles.colors.action)};
    color: ${styles.colors.white};
  }
`
