import React, { Component } from 'react'
import PropTypes from 'prop-types'
import axios from 'axios'
import styled from 'styled-components'
import { isEmailValid } from '../../utilities/format'
import constants from '../../constants/constants'
import styles from '../../constants/styles'
import ABLogoGreenSvg from '../../assets/svg/abillion-logo-green'

function inputColor(props) {
  switch (true) {
    case props.error:
      return styles.colors.action
    case props.hasFocus:
      return styles.colors.black
    default:
      return styles.colors.lightGrey
  }
}

class LogIn extends Component {
  constructor(props) {
    super(props)
    this.state = {
      email: '',
      password: '',
      showPassword: false,
      accessToken: '',
      errors: {
        email: '',
        password: '',
        noBusiness: false
      },
      focus: {
        email: false,
        password: false
      },
      loading: false
    }
    this.handleChange = this.handleChange.bind(this)
    this.checkedForErrors = this.checkedForErrors.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleLogin = this.handleLogin.bind(this)
  }

  handleChange(category, value) {
    const obj = Object.assign({}, this.state)
    obj[category] = value
    obj.errors = {
      email: '',
      password: ''
    }
    this.setState(obj)
  }

  checkedForErrors() {
    const {
      email, password, accessToken, errors
    } = this.state
    let checked = true
    const obj = { ...errors }
    if (password.length < 8 && !accessToken) {
      obj.password = 'The password should be at least 8 characters long'
      checked = false
    }
    if (!isEmailValid(email)) {
      obj.email = 'That email is not formatted correctly'
      checked = false
    }
    if (!email.length) {
      obj.email = 'Please fill in your email'
      checked = false
    }
    this.setState({ errors: obj })
    return checked
  }

  handleSubmit(e) {
    const { email, password } = this.state
    e.preventDefault()
    const user = {
      email,
      password
    }
    if (this.checkedForErrors()) {
      this.handleLogin(user)
      // this.props.closeAuthModal()
    }
  }

  handleLogin(user) {
    const { handleLogin, closeAuthModal } = this.props
    this.setState({ loading: true })
    axios
      .post(
        constants.api.auth.login,
        user
      )
      .then((res) => {
        if (
          res.data.success
          && res.data.user.businesses
          && res.data.user.businesses.length > 0
        ) {
          // update the JWT token as a default header

          handleLogin(res.data)
          closeAuthModal()
        } else if (
          res.data.success
          && (!res.data.user.businesses || !res.data.user.businesses.length)
        ) {
          if (res.data.user.claimPending) {
            this.setState({
              loading: false,
              errors: {
                email:
                  'You already have a business claim that is pending approval.',
                password: '',
                claimPending: true
              }
            })
          } else {
            this.setState({
              loading: false,
              errors: {
                email: 'This email is not associated with a business',
                password: '',
                noBusiness: true
              }
            })
          }
        } else {
          this.setState({
            loading: false,
            errors: {
              email: 'Your email and/or your password is not valid',
              password: 'Your email and/or your password is not valid'
            }
          })
        }
      })
      .catch((err) => {
        console.error(err)
        this.setState({
          loading: false,
          errors: {
            email: 'This account is not associated with a business.',
            password: '',
            noBusiness: true
          }
        })
      })
  }

  render() {
    const { closeAuthModal } = this.props
    const {
      email, password, showPassword, focus, errors, loading
    } = this.state

    return (
      <Container>
        <Background onClick={closeAuthModal} />
        <FormContainer>
          <CloseIcon className="fa fa-fw fa-times" onClick={closeAuthModal} />
          <Form onSubmit={this.handleSubmit}>
            <Header>
              <ABLogoGreenSvg />
            </Header>

            <InputDiv>
              <InputName>Email</InputName>
              <InputContainer error={errors.email} hasFocus={focus.email}>
                <InputText
                  type="email"
                  name="email"
                  value={email}
                  onFocus={() => this.setState({
                    focus: { ...focus, email: true }
                  })
                  }
                  onBlur={() => this.setState({
                    focus: { ...focus, email: false }
                  })
                  }
                  onChange={e => this.handleChange('email', e.target.value)}
                  placeholder="Your email address"
                />
              </InputContainer>
              <ErrorDiv>{errors.email}</ErrorDiv>
            </InputDiv>

            <InputDiv>
              <InputName>Password</InputName>
              <InputContainer error={errors.password} hasFocus={focus.password}>
                <InputText
                  type={showPassword ? 'text' : 'password'}
                  name="password"
                  value={password}
                  onFocus={() => this.setState({
                    focus: { ...focus, password: true }
                  })
                  }
                  onBlur={() => this.setState({
                    focus: { ...focus, password: false }
                  })
                  }
                  onChange={e => this.handleChange('password', e.target.value)}
                  placeholder="Minimum 8 characters"
                />
              </InputContainer>
              <ErrorDiv>{errors.password}</ErrorDiv>
              <ShowHidePassword
                onClick={() => this.setState({ showPassword: !showPassword })}
              >
                {showPassword ? 'Hide' : 'Show'}
                {' '}
                Password
              </ShowHidePassword>
            </InputDiv>

            <Submit onClick={this.handleSubmit}>
              <input
                type="submit"
                style={{ width: '0.1px', height: '0.1px' }}
              />
              {loading ? (
                <i className="fas fa-spinner fa-spin fa-2x" />
              ) : (
                <span>Log in</span>
              )}
            </Submit>
          </Form>
          <br />
          <p
            style={{ marginBottom: styles.margin.medium, textAlign: 'center' }}
          >
            <a
              href="https://www.abillion.com/forgot/"
              style={{ color: styles.colors.darkGrey }}
            >
              Forgot your password?
            </a>
          </p>

          {errors.noBusiness && (
            <div style={{ textAlign: 'center' }}>
              <span>
                {'Don\'t have a business account? '}
                <a
                  href="/claim"
                  style={{ color: styles.colors.omnivore, fontWeight: 600 }}
                >
                  Claim your business here
                </a>
              </span>
            </div>
          )}
          {errors.claimPending && (
            <div style={{ textAlign: 'center' }}>
              <span>
                Please
                {' '}
                <a
                  href="https://abillion.com/contact"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ color: styles.colors.omnivore, fontWeight: 600 }}
                >
                  contact us
                </a>
                {' '}
                if you want to add restaurants and/or stores to your claim
              </span>
            </div>
          )}
        </FormContainer>
      </Container>
    )
  }
}

LogIn.propTypes = {
  handleLogin: PropTypes.func.isRequired,
  closeAuthModal: PropTypes.func.isRequired
}

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`

const Background = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
`

const FormContainer = styled.div`
  position: relative;
  background: ${styles.colors.white};
  width: 480px;
  padding: ${styles.padding.large};
  z-index: 2;
`

const CloseIcon = styled.i`
  position: absolute;
  top: ${styles.margin.medium};
  left: ${styles.margin.medium};
  font-size: 1.5em;
  color: ${styles.colors.darkGrey};
  cursor: pointer;
`

const Header = styled.div`
  font-size: 1.8em;
  font-family: Nunito;
  display: flex;
  justify-content: center;
  align-items: center;
`

const Form = styled.form`
  max-width: 480px;
  width: 100%;
  display: flex;
  flex-flow: column;
  align-items: center;
`

const InputDiv = styled.div`
  margin-bottom: ${styles.margin.medium};
  width: 100%;
`

const InputName = styled.div`
  font-weight: 600;
  margin-bottom: ${styles.margin.small};
`

const InputContainer = styled.div`
  width: 100%;
  height: ${styles.height.small};
  display: flex;
  background-color: ${styles.colors.white};
  border: 1px solid ${props => inputColor(props)};
  border-radius: ${styles.borderRadius.small};
`

const InputText = styled.input`
  width: 100%;
  height: 100%;
  background-color: transparent;
  font-size: 1em;
  padding: ${styles.padding.medium};
`

const ErrorDiv = styled.span`
  color: ${styles.colors.action};
  margin-top: ${styles.margin.small};
  font-size: 0.8em;
`

const Submit = styled.div`
  width: 100%;
  height: ${styles.height.small};
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${styles.colors.action};
  color: ${styles.colors.white};
  cursor: pointer;
  border-radius: ${styles.borderRadius.small};
  font-weight: 600;
`

const ShowHidePassword = styled.span`
  display: block;
  text-align: right;
  color: ${styles.colors.omnivore};
  font-size: 0.8em;
  margin-top: ${styles.margin.small};
  cursor: pointer;
`

export default LogIn
