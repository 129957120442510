import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import DishCardImage from './DishCardImage'
import styles from '../../constants/styles'
import { capitalizeString } from '../../utilities/format'

class DishCard extends Component {
  render() {
    const { dish } = this.props
    const overall = dish.ratings?.average ?? 0

    return (
      <Container to={`/dishes/${dish._id}`}>
        <DishCardImage dish={dish} />

        <InfoContainer style={{ marginRight: styles.margin.small }}>
          <Name>{capitalizeString(dish.name)}</Name>
          <VegType vegType={dish.vegType}>{dish.vegType.toUpperCase()}</VegType>
        </InfoContainer>

        <InfoContainer>
          <Rating>
            {[1, 2, 3, 4, 5].map(s => (
              <i
                key={s}
                style={{ fontSize: '0.9em' }}
                className={`${overall >= s ? 'fas fa-star' : 'far fa-star'}`}
              />
            ))}
          </Rating>
          <MobileReviews>
            <Category style={{ marginRight: styles.margin.small }}>
              Reviews
            </Category>
            <Value>{dish.reviews}</Value>
          </MobileReviews>
        </InfoContainer>

        <MobileInfoContainer>
          <Category>Reviews</Category>
          <Value>{dish.reviews}</Value>
        </MobileInfoContainer>

        <MobileInfoContainer>
          <Category>Price</Category>
          <Value inactive={!dish.officialPrice}>
            {dish.officialPrice ? dish.officialPrice : 'None'}
          </Value>
        </MobileInfoContainer>

        <MobileInfoContainer>
          <Category>Description</Category>
          <Value inactive={!dish.description}>
            {dish.description ? <i className="fas fa-check" /> : 'None'}
          </Value>
        </MobileInfoContainer>

        <MobileInfoContainer>
          <Category>Ingredients</Category>
          <Value inactive={!dish.ingredients || !dish.ingredients.length}>
            {dish.ingredients && dish.ingredients.length > 0 ? (
              <i className="fas fa-check" />
            ) : (
              'None'
            )}
          </Value>
        </MobileInfoContainer>

        <MobileInfoContainer>
          <Category>Allergens</Category>
          <Value>
            {dish.allergens && dish.allergens.length > 0
              ? dish.allergens.length
              : 'None'}
          </Value>
        </MobileInfoContainer>

        <MidScreenDiv>
          <MidScreenInfoContainer>
            <Category style={{ width: '108px' }}>Price</Category>
            <Value inactive={!dish.officialPrice}>
              {dish.officialPrice ? dish.officialPrice : 'None'}
            </Value>
          </MidScreenInfoContainer>

          <MidScreenInfoContainer>
            <Category style={{ width: '108px' }}>Description</Category>
            <Value inactive={!dish.description}>
              {dish.description ? <i className="fas fa-check" /> : 'None'}
            </Value>
          </MidScreenInfoContainer>

          <MidScreenInfoContainer>
            <Category style={{ width: '108px' }}>Ingredients</Category>
            <Value inactive={!dish.ingredients || !dish.ingredients.length}>
              {dish.ingredients && dish.ingredients.length > 0 ? (
                <i className="fas fa-check" />
              ) : (
                'None'
              )}
            </Value>
          </MidScreenInfoContainer>

          <MidScreenInfoContainer>
            <Category style={{ width: '108px' }}>Allergens</Category>
            <Value>
              {dish.allergens && dish.allergens.length > 0
                ? dish.allergens.length
                : 'None'}
            </Value>
          </MidScreenInfoContainer>
        </MidScreenDiv>
      </Container>
    )
  }
}

DishCard.propTypes = {
  dish: PropTypes.object.isRequired
}

const Container = styled(Link)`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: ${styles.margin.small};
  background-color: ${styles.colors.white};
  border-radius: ${styles.borderRadius.small};
  overflow: hidden;
  cursor: pointer;
  padding: ${styles.padding.small};
  color: ${styles.colors.black};
  &:hover {
    background-color: ${styles.colors.lightGrey};
  }
`

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0 ${styles.padding.small};
  margin-right: ${styles.margin.medium};
`

const MobileReviews = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  @media (min-width: 800px) {
    display: none;
  }
`

const MobileInfoContainer = styled(InfoContainer)`
  display: none;
  @media (min-width: 800px) {
    display: flex;
    align-items: center;
  }
`

const MidScreenDiv = styled.div`
  display: none;
  @media (min-width: 500px) {
    display: flex;
    flex-direction: column;
  }
  @media (min-width: 800px) {
    display: none;
  }
`

const MidScreenInfoContainer = styled(InfoContainer)`
  flex-direction: row;
  align-items: center;
  margin-right: 0;
`

const Name = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 120px;

  @media (min-width: 600px) {
    width: 240px;
  }

  @media (min-width: 800px) {
    width: 320px;
  }
`

const VegType = styled.span`
  font-size: 0.8em;
  font-weight: bold;
  color: ${props => (props.vegType === 'vegan' ? styles.colors.vegan : styles.colors.brand)};
`

const Rating = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`

const Category = styled.span`
  font-size: 0.8em;
  font-weight: bold;
  color: ${styles.colors.darkGrey};
`

const Value = styled.span`
  font-size: 0.8em;
  font-weight: bold;
  color: ${props => (props.inactive ? styles.colors.action : styles.colors.omnivore)};
`

export default DishCard
