import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import styles from '../../constants/styles'

const ReviewCommentCard = ({ comment, review, respondToComment }) => (
  <Comment style={{ marginTop: styles.margin.tiny }}>
    <Text>
      <ReviewText>
        {comment.restaurant ? (
          <UsernameLink to={`/restaurants/${comment.restaurant._id}`}>
            {comment.restaurant.name}
            <Verified className="fa fa-check-circle" />
          </UsernameLink>
        ) : (
          <UsernameLink to={`/users/${comment.user.username}`}>
            {comment.user.username}
          </UsernameLink>
        )}
        &nbsp;
        {comment.text}
        {review.responseTo && review.responseTo._id === comment._id ? (
          <Cancel
            onClick={() =>
              respondToComment({
                review,
                responseTo: null,
              })
            }
          >
            CANCEL
          </Cancel>
        ) : (
          <Reply
            onClick={() =>
              respondToComment({
                review,
                responseTo: comment,
              })
            }
          >
            REPLY
          </Reply>
        )}
      </ReviewText>

      {review.comments
        .filter((comment) => comment.responseTo === comment._id)
        .sort(
          (a, b) =>
            new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
        )
        .map((comment, index) => (
          <Comment isReply key={comment._id}>
            <Text>
              <ReviewText>
                {comment.restaurant && comment.receiver ? (
                  <UsernameLink
                    href={`https://abillion.com/restaurants/${comment.restaurant._id}`}
                  >
                    {comment.restaurant.name}
                    <Verified className="fa fa-check-circle" />
                  </UsernameLink>
                ) : (
                  <UsernameLink
                    href={`https://abillion.com/users/${comment.user.username}`}
                  >
                    {comment.user.username}
                  </UsernameLink>
                )}
                &nbsp;
                {comment.text}
                {review.responseTo && review.responseTo._id === comment._id ? (
                  <Cancel
                    onClick={() =>
                      respondToComment({
                        review,
                        responseTo: null,
                      })
                    }
                  >
                    CANCEL
                  </Cancel>
                ) : (
                  <Reply
                    onClick={() =>
                      respondToComment({
                        review,
                        responseTo: comment,
                      })
                    }
                  >
                    REPLY
                  </Reply>
                )}
              </ReviewText>
            </Text>
          </Comment>
        ))}
    </Text>
  </Comment>
)

ReviewCommentCard.propTypes = {
  comment: PropTypes.objectOf(PropTypes.any).isRequired,
  review: PropTypes.objectOf(PropTypes.any).isRequired,
  respondToComment: PropTypes.func.isRequired,
}

const Comment = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  margin-left: ${(props) => (props.isReply ? styles.padding.large : '0')};
  background-color: ${styles.colors.white};
`

const Text = styled.div`
  width: 95%;
  word-wrap: break-word;
  text-align: left;
`

const ReviewText = styled.div`
  font-size: 1em;
  word-wrap: break-word;
  text-align: left;
`

const UsernameLink = styled.a`
  word-break: keep-all;
  font-weight: bold;
  color: ${styles.colors.black};
  font-size: 1em;
`

const Verified = styled.span`
  color: ${styles.colors.omnivore};
  margin-left: 2px;
  font-size: 0.8em;
  vertical-align: top;
`

const Reply = styled.span`
  font-size: 0.7em;
  font-weight: 600;
  color: ${styles.colors.grey};
  cursor: pointer;
  margin-left: ${styles.margin.small};
`

const Cancel = styled(Reply)`
  color: ${styles.colors.action};
`

export default ReviewCommentCard
