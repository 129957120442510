import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import querystring from 'querystring'
import styled from 'styled-components'
import * as actions from '../../actions'
import Avatar from '../users/Avatar'
import constants from '../../constants/constants'
import styles from '../../constants/styles'

class ViewerAvatar extends Component {
  constructor(props) {
    super(props)
    this.state = {
      toggleActive: false,
    }
    this.toggleActive = this.toggleActive.bind(this)
    this.fetchBusiness = this.fetchBusiness.bind(this)
  }

  toggleActive() {
    this.setState({ toggleActive: !this.state.toggleActive })
  }

  fetchBusiness(business) {
    const restaurants = business.restaurants.map((r) => r._id).join('+')

    const actionQuery = {
      sort: 'createdAt',
      ascending: false,
      limit: 100,
      receiver: 'exclude',
      restaurant: restaurants
    }

    this.props.toggleBusiness({
      business,
      actionQuery,
      restaurants,
      viewer: this.props.viewer._id,
    })
    this.setState({ toggleActive: !this.state.toggleActive })
  }

  render() {
    const { viewer, business } = this.props
    return (
      <Container>
        {viewer._id ? (
          <Viewer>
            <Upper onClick={this.toggleActive}>
              <Avatar user={viewer} width="48px" height="48px" />
            </Upper>
            {this.state.toggleActive && (
              <ToggleDiv>
                {viewer.businesses.length > 1 && (
                  <div>
                    {viewer.businesses.map((b) => (
                      <Business
                        key={b._id}
                        active={b.name === business.name}
                        onClick={() => this.fetchBusiness(b)}
                      >
                        {b.name}
                      </Business>
                    ))}
                  </div>
                )}
                <LogOut
                  to="/settings"
                  style={{
                    borderBottom: `1px solid ${styles.colors.lightGrey}`,
                  }}
                >
                  <i
                    style={{
                      marginRight: styles.margin.medium,
                      fontSize: '1.25em',
                    }}
                    className="fas fa-cogs"
                  />
                  Settings
                </LogOut>
                <LogOut to="/logout">
                  <i
                    style={{
                      marginRight: styles.margin.medium,
                      fontSize: '1.25em',
                    }}
                    className="fas fa-sign-out-alt"
                  />
                  Log out
                </LogOut>
              </ToggleDiv>
            )}
          </Viewer>
        ) : (
          <Loading>
            <i className="fas fa-spin fa-spinner" />
          </Loading>
        )}
      </Container>
    )
  }
}

ViewerAvatar.propTypes = {
  viewer: PropTypes.object.isRequired,
  business: PropTypes.object.isRequired,
  toggleBusiness: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => ({
  viewer: state.users.viewer._id ? state.users.all[state.users.viewer._id] : {},
  business: state.business.all.details,
})

const mapDispatchToProps = (dispatch) => ({
  toggleBusiness: ({ business, actionQuery, restaurants, viewer }) => {
    dispatch({
      type: 'RECEIVED_BUSINESS',
      data: business,
    })
    dispatch({
      type: 'RECEIVED_RESTAURANTS_INDEX',
      data: business.restaurants,
    })
    dispatch({
      type: 'RECEIVED_USERS_INDEX',
      data: business.users,
      viewer,
    })
    dispatch(
      actions.fetchData({
        path: `${constants.api.actions}query/${querystring.stringify(
          actionQuery
        )}`,
        type: 'notifications',
        subtype: 'index',
      })
    )
    dispatch(
      actions.fetchData({
        path: `${constants.api.images}query/sort=createdAt&ascending=false&limit=1000&restaurant=${restaurants}`,
        type: 'images',
        subtype: 'index',
      })
    )
    dispatch(actions.reviews.fetchReviewsAction(restaurants))
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(ViewerAvatar)

const Container = styled.div`
  display: flex;
`

const Viewer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`

const Upper = styled.div`
  width: 100%;
  height: 64px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: ${styles.padding.large};
`

const ToggleDiv = styled.div`
  position: absolute;
  top: 64px;
  z-index: 60;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  border: 1px solid ${styles.colors.lightGrey};
`

const LogOut = styled(Link)`
  width: 200px;
  height: 64px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: calc(${styles.padding.large} + 6px);
  background-color: ${styles.colors.white};
  color: ${styles.colors.darkGrey};
  &:hover {
    background-color: ${styles.colors.grey};
    color: ${styles.colors.white};
  }
`

const Business = styled.div`
  width: 200px;
  height: 64px;
  padding: ${styles.padding.medium};
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) =>
    props.active ? styles.colors.brand : styles.colors.white};
  color: ${(props) =>
    props.active ? styles.colors.white : styles.colors.darkGrey};
  font-size: 1em;
  text-align: center;
  border-bottom: 1px solid ${styles.colors.lightGrey};
  &:hover {
    background-color: ${styles.colors.grey};
    color: ${styles.colors.white};
  }
`

const Loading = styled.div`
  width: 100%;
  height: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2em;
`
