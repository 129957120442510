import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import Avatar from '../users/Avatar'
import styles from '../../constants/styles'
import { renderNotificationContent } from './NotificationSwitch'
import { formatDate } from '../../utilities/format'

const NotificationCard = ({ newNotif, notification }) => (
  <Container key={notification._id} newNotif={newNotif}>
    <a
      href={`https://www.abillion.com/users/${notification.sender.username}`}
      rel="noopener noreferrer"
      target="_blank"
    >
      <Avatar user={notification.sender} width="60px" height="60px" />
    </a>
    <div style={{ width: '100%', marginLeft: styles.margin.small }}>
      {renderNotificationContent(notification)}
      <DateSpan>{formatDate(notification.createdAt)}</DateSpan>
    </div>
    <ReviewImageContainer>
      {notification?.review?.images?.[0] && (
        <Link to={`/reviews/${notification.review._id}`}>
          <ReviewImage
            src={notification.review.images[0].path}
            alt="review image"
          />
        </Link>
      )}
    </ReviewImageContainer>
  </Container>
)

NotificationCard.propTypes = {
  newNotif: PropTypes.objectOf(PropTypes.any).isRequired,
  notification: PropTypes.objectOf(PropTypes.any).isRequired,
}

const Container = styled.div`
  width: 100%;
  max-width: 1200px;
  background: ${(props) =>
    (props.newNotif ? 'rgba(250, 255, 250, 1)' : `${styles.colors.white}`)};
  margin-bottom: ${styles.margin.small};
  padding: ${styles.padding.medium};
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  border-radius: ${styles.borderRadius.small};
`

const DateSpan = styled.span`
  color: ${styles.colors.grey};
  font-size: 0.7em;
`

const ReviewImageContainer = styled.div`
  min-width: 60px;
  min-height; 60px;
  margin-left: ${styles.margin.small};
  align-self: center;
  display: flex;
  justify-content: center;
  align-items: center;
`

const ReviewImage = styled.img`
  width: 60px;
  height: 60px;
  border-radius: ${styles.borderRadius.small};
  object-fit: cover;
`

export default NotificationCard
