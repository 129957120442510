import React, { Component } from 'react'
// import PropTypes from 'prop-types'
import styled from 'styled-components'
import constants from '../../constants/constants'
import styles from '../../constants/styles'

class EditHoursRework extends Component {
  constructor(props) {
    super(props)
    this.state = {
      hoursArr: new Array(7),
      dayIndex: 1, // 'monday'
      startIndex: 0,
      endIndex: 96
    }
  }

  componentDidMount() {
    // first time edit hours is opened, initalize an array of size 7. to deal with resto with no info being [] length 0
    if (!this.props.hoursArr.length) {
      this.setState({ hoursArr: new Array(7) })
    } else {
      this.setState({ hoursArr: this.props.hoursArr })
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.hoursArr !== this.props.hoursArr) {
      if (!nextProps.hoursArr.length) {
        this.setState({ hoursArr: new Array(7) })
      } else {
        this.setState({ hoursArr: nextProps.hoursArr })
      }
    }
  }

  // generate arr of obj with 15 min intervals. time is eg 800, 2315
  showTimes() {
    const day = new Array(24).fill(1)
    const hours = day.map((hour, index) => index * 100)
    const minutes = hours
      .map(hour => [hour, hour + 15, hour + 30, hour + 45])
      .reduce((a, b) => a.concat(b), [])
    minutes.push(0)
    return minutes.map((time, index) => ({ index, time }))
  }

  // takes timeInt eg 815 n converts to readable string 08:15 am
  displayTime(time) {
    const hours = Math.floor(time / 100)
    const minutes = time % 100
    const converted = `${
      hours > 12
        ? (hours - 12).toString()
        : hours === 0
          ? '12'
          : hours.toString()
    }:${minutes === 0 ? '00' : minutes.toString()}`.split('')
    if (time < 1200) {
      while (converted.length < 4) {
        converted.unshift('0')
      }
      return `${converted.join('')} am`
    }
    return `${converted.join('')} pm`
  }

  showDates(day) {
    if (!day.length) {
      return []
    }
    const slicedDay = day.slice()
    const arr = []
    while (slicedDay.length) {
      const open = slicedDay.shift()
      const close = slicedDay.shift()
      arr.push([open, close])
    }
    return arr
  }

  addSlot() {
    const _this = this
    const slotIndex = [-1, -1]
    // check if the added time slot will clash with existing slots
    function checked(currentDetails) {
      let check
      if (!currentDetails[_this.state.dayIndex]) {
        return true
      }
      check = _this.showDates(currentDetails[_this.state.dayIndex]).slice()


      if (!check.length) {
        return true
      }
      if (_this.state.startIndex >= _this.state.endIndex) {
        window.alert('That is not a valid time slot')
        return false
      }
      if (!check[0].reduce((a, b) => a + b, 0)) {
        window.alert('Uncheck "Open 24 hours" to add time slots')
        return false
      }
      let checked = true
      check.forEach((slot, index) => {
        const closeIndex = _this
          .showTimes()
          .map(time => time.time)
          .indexOf(slot[1])
        const openIndex = _this
          .showTimes()
          .map(time => time.time)
          .indexOf(slot[0])
        if (openIndex < _this.state.startIndex) {
          slotIndex[0] = index
        }
        if (openIndex > _this.state.startIndex) {
          slotIndex[1] = index
        }
        if (
          (_this.state.endIndex <= closeIndex
            && _this.state.endIndex > openIndex)
          || (_this.state.startIndex < closeIndex
            && _this.state.startIndex >= openIndex)
          || (_this.state.startIndex <= openIndex
            && _this.state.endIndex >= closeIndex)
        ) {
          window.alert(
            'The hours you have chosen clash with your existing schedule. Please choose different hours.'
          )
          checked = false
        }
      })
      return checked
    }

    const temp = this.state.hoursArr.slice()
    if (checked(temp)) {
      // deal with [null, [], []...]
      if (!temp[this.state.dayIndex]) {
        temp[this.state.dayIndex] = []
      }
      const starting = this.showTimes()[this.state.startIndex].time
      const ending = this.showTimes()[this.state.endIndex].time
      switch (true) {
        case slotIndex[0] === -1:
          temp[this.state.dayIndex].unshift(ending)
          temp[this.state.dayIndex].unshift(starting)
          break
        case slotIndex[1] === -1:
          temp[this.state.dayIndex].push(starting)
          temp[this.state.dayIndex].push(ending)
          break
        case slotIndex[0] < slotIndex[1]:
          temp[this.state.dayIndex].splice(slotIndex[1] * 2, 0, ending)
          temp[this.state.dayIndex].splice(slotIndex[1] * 2, 0, starting)
          break
        default:
          break
      }

      this.props.handleChange(temp)
    }
  }

  removeSlot(dayIndex, pairIndex) {
    const temp = this.state.hoursArr.slice()
    temp[dayIndex].splice(pairIndex * 2, 2)
    this.props.handleChange(temp)
  }

  // given dayArr, convert into array of timeslot pairs [[800, 900], [1200, 1300]]
  reduceDay(day) {
    return day.reduce((result, value, index) => {
      if (index % 2 === 0) {
        result.push(day.slice(index, index + 2))
      }
      return result
    }, [])
  }

  toggle24Hours(day, is24) {
    const temp = this.state.hoursArr.slice()
    if (is24) {
      temp[day] = [0, 0]
    } else {
      temp[day] = []
    }
    this.props.handleChange(temp)
  }

  is24Hours(timeslotPairsArr) {
    if (timeslotPairsArr && timeslotPairsArr[0]) {
      const firstPair = timeslotPairsArr[0]
      return firstPair.length === 2 && firstPair[0] === 0 && firstPair[1] === 0
    }
    return false
  }

  render() {
    return (
      <div
        style={{
          background: styles.colors.white,
          border: `1px solid ${styles.colors.lightGrey}`
        }}
      >
        <AddSlot>
          <SelectContainer style={{ width: '110px' }}>
            <Select
              value={this.state.dayIndex}
              onChange={e => this.setState({ dayIndex: parseInt(e.target.value, 10) })
              }
            >
              {constants.days
                .map((day, index) => ({ day, index }))
                .slice(1)
                .map(day => (
                  <option key={day.day} value={day.index}>
                    {day.day}
                  </option>
                ))}
              {constants.days.slice(0, 1).map((day, index) => (
                <option key={day} value={index}>
                  {day}
                </option>
              ))}
            </Select>
          </SelectContainer>
          &nbsp;&nbsp;&nbsp;
          <ResponsiveAddDiv>
            <SelectContainer>
              <Select
                value={this.state.startIndex}
                onChange={e => this.setState({ startIndex: parseInt(e.target.value, 10) })
                }
              >
                {this.showTimes().map(time => (
                  <option key={time.index} value={time.index}>
                    {this.displayTime(time.time)}
                  </option>
                ))}
              </Select>
            </SelectContainer>
            <span>&nbsp;-&nbsp;</span>
            <SelectContainer>
              <Select
                value={this.state.endIndex}
                onChange={e => this.setState({ endIndex: parseInt(e.target.value, 10) })
                }
              >
                {this.showTimes().map(time => (
                  <option key={time.index} value={time.index}>
                    {this.displayTime(time.time)}
                  </option>
                ))}
              </Select>
            </SelectContainer>
            <AddButton onClick={() => this.addSlot()}>Add Hours</AddButton>
            <AddButtonSmall onClick={() => this.addSlot()}>Add</AddButtonSmall>
          </ResponsiveAddDiv>
        </AddSlot>

        <div>
          {[1, 2, 3, 4, 5, 6, 0].map((dayIndex, i) => {
            const dayArr = this.state.hoursArr[dayIndex]
            let timeslotPairsArr
            if (dayArr && dayArr.length) {
              timeslotPairsArr = this.reduceDay(dayArr)
            }
            // console.log('dayArr', dayArr, 'timeslotPairsArr', timeslotPairsArr)
            const open24 = this.is24Hours(timeslotPairsArr)
            return (
              <DayRow key={dayIndex} i={i}>
                <DayDiv>
                  <span style={{ fontWeight: 'bold' }}>
                    {constants.days[dayIndex]}
                  </span>
                  <ToggleDivResponsive
                    onClick={() => this.toggle24Hours(dayIndex, !open24)}
                  >
                    <ToggleButton open24={open24}>24 hrs</ToggleButton>
                  </ToggleDivResponsive>
                </DayDiv>
                <Responsive>
                  <TimeslotsDiv>
                    {!dayArr && <span>No info</span>}
                    {dayArr
                      && (!dayArr.length || dayArr.length === 1) && (
                        <span>Closed</span>
                    )}
                    {dayArr && open24 && <span>Open 24 hours</span>}
                    {dayArr
                      && dayArr.length !== 0
                      && timeslotPairsArr[0].length === 2
                      && !open24
                      && timeslotPairsArr.map((pair, pairIndex) => (
                        <Timeslot key={pairIndex}>
                          <span>
                            {`${this.displayTime(pair[0])
                            } - ${
                              this.displayTime(pair[1])}`}
                          </span>
                          <RemoveButton
                            onClick={() => this.removeSlot(dayIndex, pairIndex)
                              }
                          >
                            <i className="fas fa-times" />
                          </RemoveButton>
                        </Timeslot>
                      ))}
                  </TimeslotsDiv>
                  <ToggleDiv
                    onClick={() => this.toggle24Hours(dayIndex, !open24)}
                  >
                    <ToggleButton open24={open24}>24 hrs</ToggleButton>
                  </ToggleDiv>
                </Responsive>
              </DayRow>
            )
          })}
        </div>
      </div>
    )
  }
}

export default EditHoursRework

const AddSlot = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: ${styles.padding.small};
  background-color: ${styles.colors.white};

  @media (max-width: 600px) {
    flex-direction: column;
    align-items: flex-start;
  }
`
const ResponsiveAddDiv = styled.div`
  display: flex;
  align-items: center;
  margin-right: ${styles.margin.small};

  @media (max-width: 600px) {
    margin-bottom: ${styles.margin.small};
  }
`
const AddButton = styled.span`
  cursor: pointer;
  background: ${styles.colors.omnivore};
  padding: ${styles.padding.medium};
  color: ${styles.colors.white};
  margin-left: ${styles.margin.small};
  border-radius: ${styles.borderRadius.small};

  @media (max-width: 400px) {
    display: none;
  }

  &:hover {
    background: rgba(47, 119, 191, 0.9);
  }
`

const AddButtonSmall = styled.span`
  cursor: pointer;
  background: ${styles.colors.omnivore};
  padding: ${styles.padding.medium} ${styles.padding.small};
  color: ${styles.colors.white};
  margin-left: ${styles.margin.small};
  border-radius: ${styles.borderRadius.small};

  @media (min-width: 401px) {
    display: none;
  }

  &:hover {
    background: rgba(47, 119, 191, 0.9);
  }
`

const SelectContainer = styled.div`
  width: 100px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: ${styles.height.small};
  border: 1px solid ${styles.colors.omnivore};
  background: ${styles.colors.white};
  border-radius: ${styles.borderRadius.small};
`
const Select = styled.select`
  padding: ${styles.padding.medium};
  width: 100%;
  height: 100%;
  border: none;
  font-size: 1em;
  box-shadow: none;
  background: transparent;
  background-image: none;
  -webkit-appearance: none;
  cursor: pointer;
`
const DayRow = styled.div`
  display: flex;
  border-top: 1px solid ${styles.colors.lightGrey};

  @media (max-width: 1000px) {
    flex-direction: column;
  }
`
const DayDiv = styled.div`
  min-width: 120px;
  padding: ${styles.padding.medium};
  display: flex;
  align-items: center;
`
const TimeslotsDiv = styled.div`
  width: 100%;
  padding: ${styles.padding.medium};
  padding-bottom: ${styles.padding.small};
  display: flex;
  flex-flow: row wrap;
  align-items: center;
`
const Timeslot = styled.div`
  background: ${styles.colors.white};
  color: ${styles.colors.black};
  border: 1px solid ${styles.colors.black};
  border-radius: ${styles.borderRadius.small};
  padding: ${styles.padding.small};
  margin-right: ${styles.margin.small};
  margin-bottom: ${styles.margin.small};
  display: flex;
  align-items: center;
`
const RemoveButton = styled.span`
  color: ${styles.colors.action};
  margin-left: ${styles.margin.small};
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  font-size: 1em;
  width: 24px;
  height: 24px;

  &:hover {
    background: ${styles.colors.action};
    color: ${styles.colors.white};
  }
`
const ToggleDiv = styled.div`
  min-width: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: ${styles.padding.small};

  @media (max-width: 400px) {
    display: none;
  }
`
const ToggleDivResponsive = styled.div`
  min-width: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  @media (min-width: 401px) {
    display: none;
  }
`

const ToggleButton = styled.span`
  border: ${props => (props.open24
    ? `1px solid ${styles.colors.omnivore}`
    : `1px solid ${styles.colors.grey}`)};
  padding: ${styles.padding.small};
  background: ${props => (props.open24 ? styles.colors.omnivore : styles.colors.white)};
  color: ${props => (props.open24 ? styles.colors.white : styles.colors.grey)};
  border-radius: ${styles.borderRadius.small};

  &:hover {
    background: ${props => (props.open24 ? 'rgba(47, 119, 191, 0.9)' : styles.colors.brand)};
    color: ${styles.colors.white};
    border: ${props => (props.open24
    ? '1px solid rgba(47, 119, 191, 0.9)'
    : `1px solid ${styles.colors.brand}`)};
  }
`
const Responsive = styled.div`
  display: flex;
  width: 100%;
`
