import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Search from '../business/Search'
import Selected from './Selected'
import Results from './Results'
import allCopy from '../../constants/copy'
import styles from '../../constants/styles'

const copy = allCopy.en.claim

class Step1SelectLocations extends Component {
  componentDidMount() {
    window.scrollTo(0, 0)
  }

  render() {
    const {
      query, country, loading, claimed, restaurants, searched, handleSearch, handleChange, handleClaim
    } = this.props
    return (
      <Container>
        <h2
          style={{
            fontSize: '2em',
            textAlign: 'center',
            marginBottom: styles.margin.large
          }}
        >
          {copy.location.headline}
        </h2>
        <Search
          query={query}
          country={country}
          handleSearch={handleSearch}
          loading={loading}
          handleChange={handleChange}
        />
        <Selected
          claimed={claimed}
          handleClaim={handleClaim}
        />
        <Results
          restaurants={restaurants}
          claimed={claimed}
          searched={searched}
          handleClaim={handleClaim}
        />
      </Container>
    )
  }
}

export default Step1SelectLocations

Step1SelectLocations.propTypes = {
  query: PropTypes.string.isRequired,
  country: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  searched: PropTypes.bool.isRequired,
  claimed: PropTypes.arrayOf(PropTypes.object).isRequired,
  restaurants: PropTypes.arrayOf(PropTypes.object).isRequired,
  handleSearch: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleClaim: PropTypes.func.isRequired
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 800px;
`
