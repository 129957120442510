import React, { Component } from 'react'
import axios from 'axios'
import styled from 'styled-components'
import constants from '../../constants/constants'
import styles from '../../constants/styles'
import { capitalizeString } from '../../utilities/format'

const identities = [
  { type: 'vegan', description: 'I only eat plant-based food' },
  {
    type: 'vegetarian',
    description:
      'I mostly eat plant-based food but I might have eggs, dairy, honey etc'
  },
  {
    type: 'omnivore',
    description: 'I eat both plant-based and non-plant-based food'
  }
]

class ClaimSignUp extends Component {
  constructor(props) {
    super(props)
    this.state = {
      continueSignUp: false, // to show second screen or not
      email: '',
      password: '',
      showPassword: false,
      accessToken: '',
      name: '',
      username: '',
      location: '',
      identity: '', // vegan/vegetarian
      errors: {
        email: '',
        password: '',
        username: '',
        identity: ''
      },
      focus: {
        email: '',
        password: '',
        username: '',
        identity: ''
      },
      loading: false,
      usernameChecked: 'checking'
    }
    this.validateErrors = this.validateErrors.bind(this)
    this.checkForExistingEmail = this.checkForExistingEmail.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  handleChange(category, value) {
    const obj = Object.assign({}, this.state)
    obj[category] = value
    obj.errors = {
      email: '',
      password: ''
    }
    this.setState(obj)
  }

  validateErrors() {
    const obj = this.state.errors
    let errors = false

    if (!this.state.continueSignUp) {
      if (!this.state.email) {
        obj.email = 'Your email is required'
        errors = true
      }
      if (
        this.state.email
        && !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
          this.state.email
        )
      ) {
        obj.email = 'That email is invalid'
        errors = true
      }
      if (!this.state.password && !this.state.accessToken) {
        obj.password = 'Your password is required'
        errors = true
      }
      if (this.state.password.length < 8) {
        obj.password = 'Minimum 8 characters'
        errors = true
      }
      if (this.state.password.length > 99) {
        obj.password = 'Maximum 99 characters'
        errors = true
      }
    } else {
      if (!this.state.username) {
        obj.username = 'Please choose a username'
        errors = true
      }
      if (
        this.state.username
        && !/^[a-z0-9-]{1,20}$/.test(this.state.username)
      ) {
        obj.username = 'Up to 20 lowercase letters and numbers only'
        errors = true
      }
      if (!this.state.identity) {
        obj.identity = 'Please choose an identity'
        errors = true
      }
    }

    this.setState({ errors: obj })
    return errors
  }

  checkForExistingEmail() {
    this.setState({ loading: true })
    axios
      .get(`${constants.api.auth.check}email/${this.state.email}`)
      .then((res) => {
        this.setState({ loading: false })
        if (res.data) {
          const obj = {
            ...this.state.errors,
            email: 'This email address is already taken'
          }
          this.setState({ errors: obj })
        } else {
          this.setState({ continueSignUp: true })
        }
      })
      .catch((err) => {
        this.setState({ loading: false })
        console.error(err)
        window.alert(
          'There was a problem validating your email address. Please try again.'
        )
      })
  }

  handleSubmit(e) {
    e.preventDefault()
    if (!this.state.continueSignUp) {
      if (this.validateErrors()) {
        return
      }
      this.checkForExistingEmail()
    } else {
      if (this.validateErrors()) {
        return
      }
      const newUser = {
        email: this.state.email,
        password: this.state.password,
        name:
          this.state.name && this.state.name.legnth > 2
            ? this.state.name
            : this.state.username.length > 2
              ? this.state.username
              : 'name',
        username: this.state.username,
        identity: this.state.identity,
        avatar: ''
      }
      axios
        .post(constants.api.auth.signup, {
          ...newUser,
          platform: 'business'
        })
        .then((res) => {
          if (res.data.success) {
            // local signup -> post local login and take access token and username
            axios
              .post(constants.api.auth.login, {
                email: newUser.email,
                password: newUser.password
              })
              .then((res) => {
                if (res.data.success) {
                  const { token } = res.data
                  const { username } = res.data.user
                  this.props.handleLogin(token, username, false)
                  this.props.handleAuthModal('')
                }
              })
              .catch((err) => {
                console.error('err', err)
              })
          }
        })
        .catch((err) => {
          console.log('err', err)
        })
    }
  }

  checkUsername(username, isSignUp = false) {
    if (!username) {
      return
    }

    this.setState({
      usernameChecked: 'checking',
      errors: { ...this.state.errors, username: '' }
    })
    axios
      .get(`${constants.api.auth.check}username/${username}`)
      .then((res) => {
        if (res.data) {
          this.setState({
            usernameChecked: 'taken',
            errors: {
              ...this.state.errors,
              username: 'That username is already taken'
            }
          })
        } else {
          this.setState({
            usernameChecked: 'available',
            errors: { ...this.state.errors, username: '' }
          })
        }
      })
      .catch((err) => {
        console.error(err)
        this.setState({ loading: false })
      })
  }

  handleChangeUsername(value) {
    const lastChar = value[value.length - 1]
    // dont allow special chars
    if (/^[a-zA-Z0-9-]{1,20}$/.test(lastChar)) {
      this.setState({ username: value.trim().toLowerCase() }, () => {
        this.checkUsername(this.state.username)
      })
    }
  }

  render() {
    return (
      <Container>
        <Background onClick={() => this.props.handleAuthModal('')} />
        <FormContainer>
          <CloseIcon
            className="fa fa-fw fa-times"
            onClick={() => this.props.handleAuthModal('')}
          />

          {!this.state.continueSignUp && (
            <Form onSubmit={this.handleSubmit}>
              <Header>
                <Logo src={require('../../assets/abillion-logo-black.png')} />
              </Header>

              <InputDiv>
                <InputName>Email</InputName>
                <InputContainer
                  error={this.state.errors.email}
                  hasFocus={this.state.focus.email}
                >
                  <InputText
                    type="email"
                    name="email"
                    value={this.state.email}
                    onFocus={() => this.setState({
                      focus: { ...this.state.focus, email: true }
                    })
                    }
                    onBlur={() => this.setState({
                      focus: { ...this.state.focus, email: false }
                    })
                    }
                    onChange={e => this.handleChange('email', e.target.value)}
                    placeholder="Your email address"
                  />
                </InputContainer>
                <ErrorDiv>{this.state.errors.email}</ErrorDiv>
              </InputDiv>

              <InputDiv>
                <InputName>Password</InputName>
                <InputContainer
                  error={this.state.errors.password}
                  hasFocus={this.state.focus.password}
                >
                  <InputText
                    type={this.state.showPassword ? 'text' : 'password'}
                    name="password"
                    value={this.state.password}
                    onFocus={() => this.setState({
                      focus: { ...this.state.focus, password: true }
                    })
                    }
                    onBlur={() => this.setState({
                      focus: { ...this.state.focus, password: false }
                    })
                    }
                    onChange={e => this.handleChange('password', e.target.value)
                    }
                    placeholder="Minimum 8 characters"
                  />
                </InputContainer>
                <ErrorDiv>{this.state.errors.password}</ErrorDiv>
                <ShowHidePassword
                  onClick={() => this.setState({ showPassword: !this.state.showPassword })
                  }
                >
                  {this.state.showPassword ? 'Hide' : 'Show'}
                  {' '}
                  Password
                </ShowHidePassword>
              </InputDiv>

              <Submit onClick={this.handleSubmit}>
                <input
                  type="submit"
                  style={{ width: '0.1px', height: '0.1px' }}
                />
                {this.state.loading ? (
                  <i className="fas fa-spinner fa-spin fa-2x" />
                ) : (
                  <span>Sign up</span>
                )}
              </Submit>

              <NoAccount>
                <span>Already have an account?</span>
                &nbsp;
                <ModalSwitch
                  onClick={() => {
                    this.props.handleAuthModal('login')
                  }}
                >
                  <span>Log In</span>
                </ModalSwitch>
              </NoAccount>
            </Form>
          )}

          {this.state.continueSignUp && (
            <Form onSubmit={this.handleSubmit}>
              <div style={{ height: '20px' }} />

              <InputDiv>
                <InputName>Pick a Username</InputName>
                <InputContainer
                  error={this.state.errors.username}
                  hasFocus={this.state.focus.username}
                >
                  <InputText
                    type="text"
                    name="username"
                    placeholder="lowercase letters and numbers only"
                    value={this.state.username}
                    onFocus={() => this.setState({
                      focus: { ...this.state.focus, username: true }
                    })
                    }
                    onBlur={() => this.setState({
                      focus: { ...this.state.focus, username: false }
                    })
                    }
                    onChange={(e) => {
                      this.handleChangeUsername(e.target.value)
                    }}
                  />
                  <Icon
                    approved={
                      this.state.usernameChecked === 'available'
                      && this.state.username.length > 0
                    }
                    className={`fa ${
                      !this.state.username.length
                        ? 'fa-user'
                        : this.state.usernameChecked === 'checking'
                          ? 'fa-spinner fa-spin'
                          : this.state.usernameChecked === 'taken'
                            ? 'fa-times'
                            : 'fa-check'
                    }`}
                  />
                </InputContainer>
                <ErrorDiv>{this.state.errors.username}</ErrorDiv>
              </InputDiv>

              <InputDiv>
                <InputName>I am a</InputName>
                {identities.map(identity => (
                  <Identity
                    key={identity.type}
                    active={this.state.identity === identity.type}
                    identity={identity.type}
                    error={this.state.errors.identity}
                    onClick={() => this.setState({
                      identity: identity.type,
                      errors: { ...this.state.errors, identity: '' }
                    })
                    }
                  >
                    <div
                      style={{
                        width: '35%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        borderRight: '1px solid lightGrey'
                      }}
                    >
                      {capitalizeString(identity.type)}
                    </div>
                    <div
                      style={{
                        width: '65%',
                        display: 'flex',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                        padding: styles.padding.medium
                      }}
                    >
                      {identity.description}
                    </div>
                  </Identity>
                ))}
                <ErrorDiv>{this.state.errors.identity}</ErrorDiv>
              </InputDiv>

              <Agree>
                By clicking Sign up, you acknowledge that you have read and
                agreed to our
                <a
                  href="/policies/terms"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {' '}
                  User Agreement
                </a>
                .
              </Agree>

              <Submit onClick={this.handleSubmit}>
                <input
                  type="submit"
                  style={{ width: '0.1px', height: '0.1px' }}
                />
                {this.state.loading ? (
                  <i className="fas fa-spinner fa-spin fa-2x" />
                ) : (
                  <span>Sign up</span>
                )}
              </Submit>
            </Form>
          )}
        </FormContainer>
      </Container>
    )
  }
}

export default ClaimSignUp

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 20;
`

const Background = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
`
const FormContainer = styled.div`
  position: relative;
  background: ${styles.colors.white};
  width: 480px;
  padding: ${styles.padding.large};
  z-index: 2;
`

const CloseIcon = styled.i`
  position: absolute;
  top: ${styles.margin.medium};
  left: ${styles.margin.medium};
  font-size: 1.5em;
  color: ${styles.colors.darkGrey};
  cursor: pointer;
`

const Form = styled.form`
  max-width: 480px;
  width: 100%;
  display: flex;
  flex-flow: column;
  align-items: center;
`
const Header = styled.div`
  font-size: 1.8em;
  font-family: Nunito;
  display: flex;
  justify-content: center;
  align-items: center;
`

const Logo = styled.img`
  width: 178px;
  height: 50px;
  object-fit: contain;
`

const Submit = styled.div`
  width: 100%;
  height: ${styles.height.small};
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${styles.colors.action};
  color: ${styles.colors.white};
  cursor: pointer;
  border-radius: ${styles.borderRadius.small};
  font-weight: 600;
`

const InputDiv = styled.div`
  margin-bottom: ${styles.margin.medium};
  width: 100%;
`
const InputName = styled.div`
  font-weight: 600;
  margin-bottom: ${styles.margin.small};
`
const InputContainer = styled.div`
  width: 100%;
  height: ${styles.height.small};
  display: flex;
  background-color: ${styles.colors.white};
  border: 1px solid
    ${props => (props.error
    ? styles.colors.action
    : props.hasFocus
      ? styles.colors.black
      : styles.colors.lightGrey)};
  border-radius: ${styles.borderRadius.small};
`
const InputText = styled.input`
  width: 100%;
  height: 100%;
  background-color: transparent;
  font-size: 1em;
  padding: ${styles.padding.medium};
`
const ErrorDiv = styled.span`
  color: ${styles.colors.action};
  margin-top: ${styles.margin.small};
  font-size: 0.8em;
`

const NoAccount = styled.div`
  width: 100%;
  max-width: 480px;
  font-size: 1.25em;
  padding: ${styles.padding.large} 0;
  font-weight: 100;
  text-align: center;
`
const Agree = styled(NoAccount)`
  font-size: 0.8em;
  font-weight: normal;
  color: ${styles.colors.darkGrey};
`
const ModalSwitch = styled.span`
  color: ${styles.colors.brand};
  text-decoration: none;
  cursor: pointer;
  font-weight: 300;
`

const Icon = styled.div`
  width: ${styles.width.small};
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${props => (props.approved ? styles.colors.vegan : styles.colors.darkGrey)};
`

const Identity = styled.div`
  width: 100%;
  height: 80px;
  display: flex;
  border: 1px solid
    ${props => (props.error
    ? styles.colors.action
    : props.identity === 'vegan'
      ? styles.colors.vegan
      : props.identity === 'vegetarian'
        ? styles.colors.brand
        : styles.colors.omnivore)};
  border-radius: ${styles.borderRadius.small};
  color: ${props => (props.active ? styles.colors.white : styles.colors.black)};
  background-color: ${props => (!props.active
    ? styles.colors.white
    : props.identity === 'vegan'
      ? styles.colors.vegan
      : props.identity === 'vegetarian'
        ? styles.colors.brand
        : styles.colors.omnivore)};
  cursor: pointer;
  margin-bottom: ${styles.margin.small};
  &:hover {
    background-color: ${props => (props.identity === 'vegan'
    ? styles.colors.vegan
    : props.identity === 'vegetarian'
      ? styles.colors.brand
      : styles.colors.omnivore)};
    color: ${styles.colors.white};
  }
`

const ShowHidePassword = styled.span`
  display: block;
  text-align: right;
  color: ${styles.colors.omnivore};
  font-size: 0.8em;
  margin-top: ${styles.margin.small};
  cursor: pointer;
`
