import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import styled from 'styled-components'
import * as actions from '../actions'
import Title from '../components/shared/Title'
import ActionBar from '../components/shared/ActionBar'
import ActionButton from '../components/shared/ActionButton'
import PhotoGallery from '../components/dishes/PhotoGallery'
import styles from '../constants/styles'

const TEN_MB = 10 * 1024 * 1024

class EditImages extends Component {
  constructor(props) {
    super(props)
    this.state = {
      photoErrors: 0,
    }
    this.addImage = this.addImage.bind(this)
    this.readFile = this.readFile.bind(this)
    this.containerRef = null
  }

  componentDidMount() {
    window.scrollTo(0, 0)
    const node = ReactDOM.findDOMNode(this.containerRef)
    if (node && window.innerWidth > 800) {
      node.scrollIntoView()
    }
    this.props.editingImages()
  }

  addImage(e) {
    this.setState({ photoErrors: 0 })

    const imageToUpload = e.target.files[0]
    this.readFile(imageToUpload)
  }

  readFile(image) {
    const { match, dishes, businessID, uploadImageCloudflare } = this.props
    const reader = new window.FileReader()
    reader.addEventListener('load', () => {
      // warn user if image is too large
      if (image.size >= TEN_MB) {
        this.setState({ photoErrors: 1 })
      } else {
        // otherwise add image
        const toUpload = {
          file: image,
          data: reader.result,
        }

        uploadImageCloudflare({
          image: toUpload,
          dish: dishes.all[match.params.id],
          business: businessID,
        })
      }
    })
    reader.readAsDataURL(image)
  }

  render() {
    const { match, dishes, updateDish, deleteImage } = this.props
    const { photoErrors } = this.state
    const dish = Object.keys(dishes.all).includes(match.params.id)
      ? dishes.all[match.params.id]
      : null
    if (dishes.index.fetched && !dish) {
      return <Redirect to={`/dishes/${match.params.id}`} />
    }
    const currentDishImages = dish
      ? this.props.images
          .filter((i) => i.dish === dish._id)
          .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
      : []
    const images = currentDishImages
      .filter((i) => !!i.business)
      .concat(currentDishImages.filter((i) => !i.business))
    const uploadingImages =
      !!dish && this.props.uploadingImages[dish._id]
        ? this.props.uploadingImages[dish._id].items
        : []
    const uploadingErrorCount =
      !!dish && this.props.uploadingImages[dish._id]
        ? this.props.uploadingImages[dish._id].count
        : 0

    return (
      <Container ref={(con) => (this.containerRef = con)}>
        <Title title={dish ? dish.name : 'Edit Images'} />

        <ActionBar
          backTo={`/dishes/${match.params.id}`}
          backToTitle={
            dish
              ? dish.name
                  .slice(0, 12)
                  .concat(dish.name.length > 12 ? '...' : '')
              : 'Dish'
          }
          justifyContent="space-between"
        >
          {!!dish && uploadingImages.length > 0 ? (
            <i className="fas fa-spinner fa-spin fa-2x" />
          ) : (
            <ActionButton>
              <PhotoLabel>
                <i className="far fa-images" />
                &nbsp;
                <span>Add image</span>
                <PhotoInput
                  id="photoInput"
                  type="file"
                  accept="image/jpeg, image/jpg, image/png"
                  onChange={this.addImage}
                />
              </PhotoLabel>
            </ActionButton>
          )}
        </ActionBar>

        {!dishes.index.fetched && (
          <Loading>
            <div
              className="fas fa-spinner fa-spin"
              style={{ fontSize: '2em' }}
            />
            <h1>Fetching images</h1>
          </Loading>
        )}

        {!!dish && (
          <div>
            <Field>
              {!images.length && !uploadingImages.length && (
                <Loading>
                  <b>No images found</b>
                </Loading>
              )}

              {photoErrors + uploadingErrorCount > 0 && (
                <div
                  style={{
                    backgroundColor: styles.colors.action,
                    fontWeight: '0.7em',
                    color: styles.colors.white,
                    margin: `${styles.margin.medium} 0`,
                    padding: `${styles.padding.small}`,
                  }}
                >
                  There was a problem uploading {photoErrors}{' '}
                  {photoErrors + uploadingErrorCount === 1 ? 'image' : 'images'}
                  . Please try again.
                </div>
              )}

              {images.length > 0 && (
                <div
                  style={{
                    fontWeight: '0.7em',
                    margin: `${styles.margin.medium} 0`,
                  }}
                >
                  Main (
                  <i className="fa fa-crown" />
                  ) and user-uploaded images (
                  <i className="fas fa-utensils" />) cannot be deleted
                </div>
              )}

              <PhotoGallery
                images={images}
                uploadingImages={uploadingImages}
                deleteImage={deleteImage}
                updateDish={updateDish}
                mainImage={dish.image ? dish.image._id : null}
                dishID={dish._id}
              />
            </Field>
          </div>
        )}
      </Container>
    )
  }
}

EditImages.propTypes = {
  businessID: PropTypes.string,
  match: PropTypes.objectOf(PropTypes.any).isRequired,
  dishes: PropTypes.objectOf(PropTypes.any).isRequired,
  images: PropTypes.arrayOf(PropTypes.object).isRequired,
  uploadingImages: PropTypes.objectOf(PropTypes.any).isRequired,
  updateDish: PropTypes.func.isRequired,
  editingImages: PropTypes.func.isRequired,
  deleteImage: PropTypes.func.isRequired,
  uploadImageCloudflare: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => ({
  businessID: state.business.all.details._id,
  dishes: state.dishes,
  images: state.images.index.items.map((_id) => state.images.all[_id]),
  uploadingImages: state.images.uploading,
})

const mapDispatchToProps = (dispatch) => ({
  updateDish: ({ _id, original, value, full }) => {
    dispatch(
      actions.dishes.update({
        _id,
        original,
        value,
        full,
      })
    )
  },
  deleteImage: ({ image }) => {
    const canProceed = window.confirm(
      'Are you sure you want to delete this image?'
    )
    if (canProceed) {
      dispatch(actions.images.deleteImage({ image }))
    }
  },
  uploadImageCloudflare: ({ image, dish, business }) => {
    dispatch(actions.images.uploadImageCloudflare({ image, dish, business }))
  },
  editingImages: () => {
    dispatch({ type: actions.EDITING_IMAGES })
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(EditImages)

const Container = styled.main`
  width: 100%;
  padding: 0 ${styles.padding.medium};
`

const Loading = styled.div`
  width: 100%;
  height: calc(100vh - 96px - 64px - 64px);
  font-weight: bold;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const Field = styled.div`
  margin-bottom: calc(2 * ${styles.margin.medium});
`

const PhotoLabel = styled.label`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 0 ${styles.padding.medium};
`

const PhotoInput = styled.input`
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
`
