import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import TextInput from '../shared/TextInput'
import allCopy from '../../constants/copy'
import styles from '../../constants/styles'

const copy = allCopy.en.claim.details

class Step2BusinessDetails extends Component {
  componentDidMount() {
    window.scrollTo(0, 0)
  }

  render() {
    const {
      name,
      contactPerson,
      role,
      email,
      phone,
      facebook,
      instagram,
      twitter,
      linkedIn,
      remarks,
      acknowledged,
      formErrorObj,
      claimed,
      handleChange
    } = this.props
    return (
      <Container>
        <h2
          style={{
            fontSize: '2em',
            display: 'block',
            textAlign: 'center',
            marginBottom: styles.margin.large
          }}
        >
          {copy.headline}
        </h2>

        <FormDiv>
          <Field>
            <TextInput
              label={copy.business.label}
              value={name}
              placeholder={copy.business.placeholder}
              error={formErrorObj.name}
              onChange={e => handleChange('name', e.target.value)}
            />
          </Field>
          <Field>
            <TextInput
              label={copy.contact.label}
              value={contactPerson}
              placeholder={copy.contact.placeholder}
              error={formErrorObj.contactPerson}
              onChange={e => handleChange('contactPerson', e.target.value)}
            />
          </Field>
          <Field>
            <TextInput
              label={copy.role.label}
              value={role}
              placeholder={copy.role.placeholder}
              error={formErrorObj.role}
              onChange={e => handleChange('role', e.target.value)}
            />
          </Field>
          <Field>
            <TextInput
              label={copy.email.label}
              value={email}
              placeholder={copy.email.placeholder}
              error={formErrorObj.email}
              onChange={e => handleChange('email', e.target.value)}
            />
          </Field>

          <Field>
            <TextInput
              label={copy.phone.label}
              value={phone}
              placeholder={copy.phone.placeholder}
              error={formErrorObj.phone}
              onChange={e => handleChange('phone', e.target.value)}
            />
          </Field>

          <div style={{ marginBottom: styles.margin.medium }}>
            {copy.social}
          </div>

          <Field>
            <TextInput
              label={copy.facebook.label}
              value={facebook}
              placeholder={copy.facebook.placeholder}
              error={formErrorObj.facebook}
              onChange={e => handleChange('facebook', e.target.value)}
            />
          </Field>
          <Field>
            <TextInput
              label={copy.instagram.label}
              value={instagram}
              placeholder={copy.instagram.placeholder}
              error={formErrorObj.instagram}
              onChange={e => handleChange('instagram', e.target.value)}
            />
          </Field>
          <Field>
            <TextInput
              label={copy.twitter.label}
              value={twitter}
              placeholder={copy.twitter.placeholder}
              error={formErrorObj.twitter}
              onChange={e => handleChange('twitter', e.target.value)}
            />
          </Field>
          <Field>
            <TextInput
              label={copy.linkedin.label}
              value={linkedIn}
              placeholder={copy.linkedin.placeholder}
              error={formErrorObj.linkedIn}
              onChange={e => handleChange('linkedIn', e.target.value)}
            />
          </Field>
          <Field>
            <Label>{copy.remarks.label}</Label>
            <Textarea
              type="text"
              name="remarks"
              value={remarks}
              placeholder={copy.remarks.placeholder}
              onChange={e => handleChange('remarks', e.target.value)}
            />
          </Field>

          <Field
            onClick={() => handleChange('acknowledged', !acknowledged)}
            style={{ cursor: 'pointer' }}
          >
            <Acknowledge error={formErrorObj.acknowledged}>
              {acknowledged ? (
                <button
                  type="button"
                  onClick={() => handleChange('acknowledged', false)}
                >
                  <i
                    className="far fa-check-square"
                    style={{
                      fontSize: '1.5em',
                      position: 'relative',
                      left: '0.5px'
                    }}
                  />
                </button>
              ) : (
                <EmptyCheckbox
                  onClick={() => handleChange('acknowledged', true)}
                />
              )}
              <span>
                &nbsp;&nbsp;
                {copy.acknowledgement(claimed.length)}
              </span>
            </Acknowledge>
          </Field>
        </FormDiv>
      </Container>
    )
  }
}

Step2BusinessDetails.propTypes = {
  name: PropTypes.string.isRequired,
  contactPerson: PropTypes.string.isRequired,
  role: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  phone: PropTypes.string.isRequired,
  facebook: PropTypes.string.isRequired,
  instagram: PropTypes.string.isRequired,
  twitter: PropTypes.string.isRequired,
  linkedIn: PropTypes.string.isRequired,
  remarks: PropTypes.string.isRequired,
  acknowledged: PropTypes.bool.isRequired,
  formErrorObj: PropTypes.objectOf(PropTypes.string).isRequired,
  claimed: PropTypes.arrayOf(PropTypes.object).isRequired,
  handleChange: PropTypes.func.isRequired
}

export default Step2BusinessDetails

const Container = styled.div`
  width: 100%;
  max-width: 640px;
  margin: 0 auto;
  padding: 0 ${styles.padding.small};
`

const FormDiv = styled.div`
  width: 100%;
  margin: 0 auto;
`

const Field = styled.div`
  width: 100%;
  display: flex;
  flex-flow: column;
  margin-bottom: ${styles.margin.medium};
`

const Label = styled.div`
  font-size: 0.9em;
  font-weight: 600;
  letter-spacing: 1px;
  margin-bottom: ${styles.margin.small};
  display: flex;
  justify-content: flex-start;
  align-items: center;
`

const Textarea = styled.textarea`
  width: 100%;
  height: 150px;
  display: flex;
  flex-wrap: wrap;
  font-size: 1em;
  resize: none;
  overflow: hidden;
  border: 1px solid ${styles.colors.lightGrey};
  border-radius: ${styles.borderRadius.small};
  padding: ${styles.padding.small};
`

const Acknowledge = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  color: ${props => (props.error ? styles.colors.action : styles.colors.black)};
`

const EmptyCheckbox = styled.div`
  width: 21px;
  height: 21px;
  border: 2px solid ${styles.colors.black};
  border-radius: 2px;
  cursor: pointer;
  margin-top: 2px;
`
