import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import styles from '../../constants/styles'

const Title = ({ title }) => (
  <Container>
    <Text>{title}</Text>
  </Container>
)

Title.propTypes = {
  title: PropTypes.string.isRequired
}

export default Title

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  align-items: center;
  background-color: ${styles.colors.veryLightGrey};
`

const Text = styled.h1`
  width: 100%;
  font-size: 2em;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${styles.colors.black};

  @media (min-width: 600px) {
    font-size: 3em;
    height: 84px;
  }
`
