import axios from 'axios'
import querystring from 'querystring'
import Auth from '../modules/Auth'
import constants from '../constants/constants'
import {
  fetchData,
  RECEIVED_BUSINESS,
  RECEIVED_RESTAURANTS_INDEX,
  RECEIVED_USERS_INDEX,
  FETCHING_VIEWER,
  RECEIVED_VIEWER,
  ERROR_FETCHING_VIEWER,
  ERROR_FETCHING_USERS_INDEX,
  STOP_CHECKING_FOR_NOTIFICATIONS,
} from './index'
import { fetchReviewsAction } from './reviews'

const checkForNotifications =
  ({ restaurants, from }) =>
  (dispatch) => {
    dispatch(
      fetchData({
        path: `${constants.api.actions}query/sort=createdAt&ascending=false&limit=100&receiver=exclude&from=${from}&restaurant=${restaurants}`,
        type: 'notifications',
        subtype: 'index',
      })
    )
  }

// check notification timer
let notificationInterval = null
// check for new notifications every 5 min
const startCheckingForNotifications =
  ({ restaurants }) =>
  (dispatch) => {
    const interval = 5 * 60 * 1000
    clearInterval(notificationInterval)
    notificationInterval = setInterval(() => {
      dispatch(
        checkForNotifications({
          restaurants,
          from: Date.now() - interval + 1,
        })
      )
    }, interval)
  }

export const fetchViewer = (username) => (dispatch) => {
  dispatch({ type: FETCHING_VIEWER })
  axios
    .get(`${constants.api.users}business/${username}`)
    .then((res) => {
      if (!res.data) {
        // log out, remove token, username from localStorage, go back to /
        Auth.removeUsername()
        Auth.deauthenticateUser()
        window.location.replace('/')
      }
      dispatch({ type: RECEIVED_VIEWER, data: res.data })

      const business = res.data.businesses[0]
      dispatch({ type: RECEIVED_BUSINESS, data: business })
      dispatch({ type: RECEIVED_RESTAURANTS_INDEX, data: business.restaurants })

      dispatch({
        type: RECEIVED_USERS_INDEX,
        data: business.users,
        viewer: res.data._id,
      })

      const restaurants = business.restaurants.map((r) => r._id).join('+')
      dispatch(startCheckingForNotifications({ restaurants }))

      // fetch categories
      dispatch(
        fetchData({
          path: constants.api.categories,
          type: 'categories',
          subtype: 'index',
        })
      )

      const actionQuery = {
        sort: 'createdAt',
        ascending: false,
        limit: 100,
        receiver: 'exclude',
        restaurant: restaurants,
      }

      // fetch notifications
      dispatch(
        fetchData({
          path: `${constants.api.actions}query/${querystring.stringify(
            actionQuery
          )}`,
          type: 'notifications',
          subtype: 'index',
        })
      )

      // fetch images
      dispatch(
        fetchData({
          path: `${constants.api.images}query/sort=createdAt&ascending=false&limit=1000&restaurant=${restaurants}`,
          type: 'images',
          subtype: 'index',
        })
      )

      // fetch reviews
      dispatch(fetchReviewsAction(restaurants))
    })
    .catch(() => {
      dispatch({ type: ERROR_FETCHING_VIEWER })
      Auth.removeUsername()
      Auth.deauthenticateUser()
      window.location.replace('/')
    })
}

export const fetchUsers = (username) => (dispatch) => {
  dispatch({ type: FETCHING_VIEWER })
  axios
    .get(`${constants.api.users}business/${username}`)
    .then((res) => {
      dispatch({ type: RECEIVED_VIEWER, data: res.data })
      const { business } = res.data
      dispatch({ type: RECEIVED_USERS_INDEX, data: business.users })
    })
    .catch((err) => {
      dispatch({ type: ERROR_FETCHING_USERS_INDEX })
    })
}

export const stopCheckingForNotifications = () => (dispatch) => {
  clearInterval(notificationInterval)
  dispatch({ type: STOP_CHECKING_FOR_NOTIFICATIONS })
}
