import React from 'react'
import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components'
import ABLogoGreenSvg from '../../assets/svg/abillion-logo-green'
import social from '../../constants/social'
import colors from '../../constants/colors'

const Footer = () => {
  const selectedSocial = social.slice(0, 4)

  return (
    <Wrapper>
      <Container>
        <Left>
          <Link style={{ width: '40%' }} to="/">
            <Logo>
              <ABLogoGreenSvg
                color1={colors.liquorice[100]}
                color2={colors.liquorice[100]}
              />
            </Logo>
          </Link>

          <div>
            <ExternalLink
              href="https://abillion.com/policies/privacy"
              rel="noopener noreferrer"
              target="_blank"
            >
              Privacy Policy
            </ExternalLink>
            <ExternalLink
              href="https://abillion.com/policies/terms"
              rel="noopener noreferrer"
              target="_blank"
            >
              Terms of Use
            </ExternalLink>
            <ExternalLink
              href="https://abillion.com/policies/copyright"
              rel="noopener noreferrer"
              target="_blank"
            >
              Copyright Dispute Policy
            </ExternalLink>
            <ExternalLink
              href="https://www.abillion.com/contact"
              rel="noopener noreferrer"
              target="_blank"
            >
              Contact
            </ExternalLink>
            <ExternalLink
              href="https://www.abillion.com/about"
              rel="noopener noreferrer"
              target="_blank"
            >
              About
            </ExternalLink>
            <InternalLink to="/claim">Claim Your Business</InternalLink>
            <ExternalLink
              href="https://www.abillion.com/press"
              rel="noopener noreferrer"
              target="_blank"
            >
              Press
            </ExternalLink>
          </div>
        </Left>

        <Right>
          <SocialMedia>
            {selectedSocial.map((s, i) => (
              <SocLink
                href={s.link}
                key={s.type}
                target="_blank"
                title={s.title}
                rel="noopener noreferrer"
                isFirst={i === 0}
                isLast={selectedSocial.length === i + 1}
              >
                <SocialIcon isFirst={i === 0} className={`fab fa-${s.icon}`} />
              </SocLink>
            ))}
          </SocialMedia>
          <Copyright>{`© abillion ${new Date().getFullYear()}`}</Copyright>
        </Right>
      </Container>
    </Wrapper>
  )
}

export default Footer

const LinkStyle = css`
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.01em;
  color: ${colors.liquorice[100]};
  cursor: pointer;
  display: inline-block;
  margin-bottom: 24px;
  margin-right: 24px;
  text-decoration: none;

  &:hover {
    border-bottom: 2px solid ${colors.liquorice[100]};
  }
`

const Wrapper = styled.footer`
  align-items: center;
  background-color: ${colors.liquorice[30]};
  color: ${colors.liquorice[100]};
  display: flex;
  justify-content: center;
  padding: 72px 32px 48px 32px;

  @media (max-width: 630px) {
    padding: 32px 32px;
  }
`

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: 1168px;
  width: 100%;

  @media (max-width: 630px) {
    flex-direction: column;
  }
`

const Left = styled.section`
  @media (max-width: 630px) {
    margin-bottom: 8px;
  }
`

const Right = styled.section`
  text-align: right;

  @media (max-width: 630px) {
    text-align: left;
  }
`

const Logo = styled.section`
  margin-bottom: 32px;
`

const InternalLink = styled(Link)`
  ${LinkStyle};
`

const ExternalLink = styled.a`
  ${LinkStyle};

  box-sizing: border-box;
`

const SocialMedia = styled.ul`
  align-items: center;
  display: flex;
  flex-direction: row;
  margin-bottom: 50px;

  @media (max-width: 630px) {
    margin-bottom: 32px;
  }
`

const SocLink = styled.a`
  margin: ${(props) => {
    let value = ''
    if (props.isFirst) {
      value = '0 16px 0 0'
    } else if (props.isLast) {
      value = '0 0 0 16px'
    } else {
      value = '0 16px'
    }
    return value
  }};
  text-decoration: none;
`

const SocialIcon = styled.i`
  color: ${colors.liquorice[100]};
  font-size: ${props => (props.isFirst ? '1.8em' : '2em')};
`

const Copyright = styled.span`
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.01em;
`
