import { combineReducers } from 'redux'
import * as actions from '../actions'

const all = (state = {}, action) => {
  switch (action.type) {
    case actions.RECEIVED_CATEGORIES_INDEX:
      return {
        ...state,
        ...action.data.reduce((obj, category) => {
          obj[category._id] = category
          return obj
        }, {})
      }
    case actions.UPDATED_CATEGORIES:
      return {
        ...state,
        [action._id]: { ...state[action._id], ...action.value }
      }
    default:
      return state
  }
}

const index = (
  state = {
    isLoading: false, items: [], error: false, fetched: false
  },
  action
) => {
  switch (action.type) {
    case actions.FETCHING_CATEGORIES_INDEX:
      return {
        ...state,
        isLoading: true,
        error: false
      }
    case actions.RECEIVED_CATEGORIES_INDEX:
      return {
        ...state,
        isLoading: false,
        items: action.data.map(category => category._id),
        error: false,
        fetched: true
      }
    case actions.ERROR_FETCHING_CATEGORIES_INDEX:
      return {
        ...state,
        isLoading: false,
        items: [],
        error: true
      }
    default:
      return state
  }
}

export default combineReducers({
  all,
  index
})
