// home page
export const REVIEW_HOME = 'https://images.unsplash.com/photo-1502741384106-56538427cde9?ixlib=rb-0.3.5&ixid=eyJhcHBfaWQiOjEyMDd9&s=5515e53c69cb42a34e99b1b8e397cdbe&auto=format&fit=crop&w=1500&q=80'
export const RESTAURANT_HOME = 'https://images.unsplash.com/photo-1515539408953-9403f070ad2e?ixlib=rb-0.3.5&ixid=eyJhcHBfaWQiOjEyMDd9&s=df0649b951b14fe6433602cf64837839&auto=format&fit=crop&w=1500&q=80'
export const USER_HOME = 'https://images.unsplash.com/photo-1533132108820-9212801a2caf?ixlib=rb-0.3.5&ixid=eyJhcHBfaWQiOjEyMDd9&s=41f728bab20b6808483b782dfdad4f83&auto=format&fit=crop&w=1594&q=80'
export const DISH_HOME = 'https://images.unsplash.com/photo-1494571124378-c15772438a53?ixlib=rb-0.3.5&ixid=eyJhcHBfaWQiOjEyMDd9&s=79eadd3c11bdc212b6a00fd23db7d330&auto=format&fit=crop&w=1500&q=80'
// landing page
export const HEADER_LANDING = 'https://images.unsplash.com/photo-1505935428862-770b6f24f629?ixlib=rb-0.3.5&ixid=eyJhcHBfaWQiOjEyMDd9&s=2c5a2cf31c693f5c15fb08135bf1adf2&auto=format&fit=crop&w=2847&q=80'
export const REVIEW_PHONE_LANDING_ALT = 'review phone'
export const REVIEW_PHONE_LANDING = 'https://res.cloudinary.com/abillionveg/image/upload/v1554948597/landing/threescreenshero.png'
export const REVIEW_PHONE_LANDING_MOBILE = 'https://res.cloudinary.com/abillionveg/image/upload/v1554948597/landing/threescreenshero.png'
export const MENU_LANDING = 'https://res.cloudinary.com/abillionveg/image/upload/v1538043829/DSC07755_nftlgi.jpg'
export const POKED_LANDING = 'https://res.cloudinary.com/abillionveg/image/upload/v1537245829/1920px_katherine-chase-518230-unsplash_npiwxv.jpg'
export const CHALLENGE_LANDING = 'https://images.unsplash.com/photo-1517817500400-c961b0488325?ixlib=rb-0.3.5&ixid=eyJhcHBfaWQiOjEyMDd9&s=e1f33a78033e5efe21288d1d573eeac4&auto=format&fit=crop&w=2850&q=80'
export const VIDEO_IMAGE_LANDING = 'https://res.cloudinary.com/abillionveg/image/upload/v1561091147/DSC00152_owjivh.jpg'
