import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import constants from '../../constants/constants'
import styles from '../../constants/styles'

class Search extends Component {
  constructor(props) {
    super(props)
    this.state = {}
    this.enterPressed = this.enterPressed.bind(this)
  }

  enterPressed(event) {
    const code = event.keyCode || event.which
    if (code === 13) {
      // 13 is the "enter" button keycode
      this.props.handleSearch({
        query: this.props.query,
        country: this.props.country
      })
    }
  }

  render() {
    const optionsMain = constants.countriesMain
      .filter(c => c !== 'The World')
      .map(country => (
        <option key={country} value={country}>
          {country}
        </option>
      ))

    const optionsOthers = constants.otherCountries.map(country => (
      <option key={country} value={country}>
        {country}
      </option>
    ))

    const {
      query, country, handleSearch, loading, handleChange, fromBusinessRequest
    } = this.props

    return (
      <Container>
        <SearchRow>
          <InputForm>
            <SearchIcon className="fa fa-search" />
            <SearchInput
              type="text"
              placeholder="Search for a restaurant"
              value={query}
              onChange={e => handleChange('query', e.target.value)}
              onKeyPress={this.enterPressed}
              autoFocus
            />
          </InputForm>

          <SelectDiv>
            <GlobeDiv>
              <i className="fa fa-globe" aria-hidden="true" />
            </GlobeDiv>
            <Select
              value={country}
              onChange={e => handleChange('country', e.target.value)}
            >
              <option value="" disabled>
                Country
              </option>
              {optionsMain}
              <option value="break" disabled>
                --
              </option>
              {optionsOthers}
            </Select>
          </SelectDiv>
        </SearchRow>

        <SubmitButton
          fromBusinessRequest={fromBusinessRequest}
          onClick={() => handleSearch({ query, country })}
        >
          {loading ? (
            <i className="fa fa-spinner fa-spin fa-fw" style={{ fontSize: '1.2em' }} />
          ) : (
            'Search'
          )}
        </SubmitButton>
      </Container>
    )
  }
}

Search.propTypes = {
  query: PropTypes.string.isRequired,
  country: PropTypes.string.isRequired,
  handleSearch: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  handleChange: PropTypes.func.isRequired
}

export default Search

const Container = styled.div`
  display: flex;
  flex-flow: column;
  width: 100%;
  max-width: 800px;

  @media (min-width: 601px) {
    flex-flow: row;
  }
`

const SearchRow = styled.div`
  width: 100%;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: flex-start;

  @media (min-width: 601px) {
    width: 100%;
    flex-flow: row;
  }
`

const GlobeDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${styles.width.small};
  height: 32px;

  @media (min-width: 601px) {
    border-left: 2px solid ${styles.colors.lightGrey};
  }
`

const SelectDiv = styled.div`
  width: 100%;
  display: flex;
  flex-flow: row;
  align-items: center;
  order: -1;
  height: ${styles.height.small};
  border: 1px solid ${styles.colors.lightGrey};
  border-bottom: none;
  background-color: ${styles.colors.white};

  @media (min-width: 601px) {
    order: 1;
    width: 33%;
    border: 1px solid ${styles.colors.lightGrey};
    border-left: none;
    border-right: none;
  }
`

const Select = styled.select`
  padding: ${styles.padding.medium};
  width: 100%;
  height: 100%;
  border: none;
  font-size: 1em;
  box-shadow: none;
  background: transparent;
  background-image: none;
  -webkit-appearance: none;
  cursor: pointer;
`

const InputForm = styled.div`
  width: 100%;
  height: ${styles.height.small};
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: flex-start;

  @media (min-width: 601px) {
    width: 100%;
  }
`

const SearchIcon = styled.div`
  width: ${styles.width.small};
  height: 100%;
  background-color: ${styles.colors.white};
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.1em;
  border: 1px solid ${styles.colors.lightGrey};
  border-right: none;
  @media (min-width: 601px) {
    border-top-left-radius: ${styles.borderRadius.small};
    border-bottom-left-radius: ${styles.borderRadius.small};
  }
`

const SearchInput = styled.input`
  width: 100%;
  height: 100%;
  font-size: 1em;
  padding: ${styles.padding.medium};
  border: 1px solid ${styles.colors.lightGrey};
  border-left: none;

  @media (min-width: 601px) {
    border-right: none;
  }
`

const SubmitButton = styled.div`
  width: 100%;
  height: ${styles.height.small};
  margin-top: ${styles.margin.small};
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${styles.padding.small};
  background-color: ${props => (props.fromBusinessRequest
    ? styles.colors.omnivore
    : styles.colors.action)};
  color: ${styles.colors.white};
  cursor: pointer;

  @media (min-width: 601px) {
    width: 18%;
    margin-top: 0;
    border-top-right-radius: ${styles.borderRadius.small};
    border-bottom-right-radius: ${styles.borderRadius.small};
  }

  &:hover {
    background-color: ${props => (props.fromBusinessRequest
    ? styles.colors.brand
    : styles.colors.omnivore)};
  }
`
