import React, { Component } from 'react'
import styled from 'styled-components'
import styles from '../../constants/styles'

class RestaurantSearchCard extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    const {
      restaurant,
      claimed,
      handleClaim,
      fromSelectedComponent,
      first
    } = this.props
    return (
      <Container first={first} fromSelectedComponent={fromSelectedComponent}>
        <MapIconDiv>
          {!fromSelectedComponent && <i className="fa fa-map-marker-alt" />}
          {fromSelectedComponent && <i className="fa fa-thumbtack" />}
        </MapIconDiv>
        <div style={{ width: '100%' }}>
          <Name>{restaurant.name}</Name>
          <Address>{restaurant.formatted_address}</Address>
        </div>
        {!fromSelectedComponent && (
          <ClaimButton
            onClick={() => handleClaim(restaurant)}
            claimed={claimed}
            pending={restaurant.claimPending}
          >
            {restaurant.business
              ? 'Claimed'
              : restaurant.claimPending
                ? 'Pending'
                : 'Select'}
          </ClaimButton>
        )}
        {fromSelectedComponent && (
          <RemoveButton onClick={() => handleClaim(restaurant)}>
            Remove
          </RemoveButton>
        )}
      </Container>
    )
  }
}

export default RestaurantSearchCard

const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  padding: ${styles.padding.medium};
  background: ${props => (props.fromSelectedComponent
    ? 'rgb(235, 235, 235)'
    : `${styles.colors.veryLightGrey}`)};
  margin-top: ${props => (props.first ? 0 : styles.margin.small)};
`
const MapIconDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${styles.colors.grey};
  font-size: 1.5em;
  margin-right: ${styles.margin.small};

  @media (max-width: 600px) {
    display: none;
  }
`
const Name = styled.h3`
  color: ${styles.colors.black};
  text-decoration: none;
  font-weight: bold;
  font-size: 1em;
`

const Address = styled.div`
  font-size: 1em;
  color: ${styles.colors.darkGrey};
`

const SubmitButton = styled.div`
  width: 100%;
  height: ${styles.height.small};
  margin-top: ${styles.margin.small};
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${styles.padding.small};
  border-radius: ${styles.borderRadius.small};
  background-color: ${styles.colors.action};
  color: ${styles.colors.white};
  cursor: pointer;

  @media (min-width: 601px) {
    width: 18%;
    margin-left: ${styles.margin.small};
    margin-top: 0;
  }
`
const ClaimButton = styled(SubmitButton)`
  width: 20%;
  min-width: 70px;
  height: ${styles.height.tiny};
  margin-left: 0;
  background-color: ${props => (props.claimed
    ? styles.colors.darkGrey
    : props.pending
      ? styles.colors.darkGrey
      : props.selected
        ? styles.colors.omnivore
        : styles.colors.brand)};
  font-size: 0.9em;
  cursor: ${props => (props.claimed ? 'default' : props.pending ? 'default' : 'pointer')};

  @media (min-width: 601px) {
    width: 15%;
  }

  &:hover {
    background-color: ${props => (props.claimed
    ? styles.colors.darkGrey
    : props.pending
      ? styles.colors.darkGrey
      : props.selected
        ? styles.colors.omnivore
        : styles.colors.omnivore)
}
  }
`

const RemoveButton = styled(ClaimButton)`
  background-color: ${styles.colors.action};
`
