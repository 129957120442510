import axios from 'axios'
import constants from '../constants/constants'
import {
  UPLOADING_IMAGES,
  UPLOADED_IMAGE,
  ERROR_UPLOADING_IMAGE,
  updateField,
} from './index'

// from cloudflare it will return an array of variants
const getCloudflareImagePath = (variants) => {
  if (variants.length > 1) {
    return variants.find((imgPath) => imgPath.includes('public'))
  }
  return variants[0]
}

export const deleteImage =
  ({ image }) =>
  (dispatch) => {
    dispatch(updateField({ type: 'images', _id: image._id, value: null }))
    // optimistic delete
    axios.put(`${constants.api.images + image._id}/delete`)
  }

export const uploadImageCloudflare =
  ({ image, dish, business }) =>
  async (dispatch) => {
    if (!image || !dish._id || !business) return

    const now = Date.now().toString()
    dispatch({
      type: UPLOADING_IMAGES,
      images: [{ ...image, id: now }],
      dish: dish._id,
    })

    const formData = new FormData()
    formData.append('file', image.file)

    const cloudflareConfig = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      transformRequest: [
        (data, headers) => {
          // set authorization token to null to use unsigned upload_preset for validation
          // eslint-disable-next-line no-param-reassign
          delete headers.common.Authorization
          return data
        },
      ],
    }

    try {
      // generate signed url
      const signedUrlResponse = await axios.post(
        `${process.env.REACT_APP_USER_API_URL}/v2/images/upload`
      )

      const cloudflareSuccess = await axios.post(
        signedUrlResponse?.data?.uploadURL,
        formData,
        cloudflareConfig
      )

      const toSave = {
        platform: 'business',
        isOfficial: true,
        restaurants: dish.restaurants,
        business,
        dish: dish._id,
        path: getCloudflareImagePath(cloudflareSuccess.data.result.variants),
        public_id: cloudflareSuccess.data.result.id,
        type: image.file.type,
      }
      // Upload information onto our API
      const apiResponse = await axios.post(constants.api.images, toSave)

      dispatch({
        type: UPLOADED_IMAGE,
        image: apiResponse.data.image,
        id: now,
      })
    } catch (error) {
      dispatch({
        type: ERROR_UPLOADING_IMAGE,
        id: now,
        dish: dish._id,
      })
      window.alert(
        `There was a problem uploading your image: ${error}. Please try again.`
      )
    }
  }
