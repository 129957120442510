import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import constants from '../../constants/constants'
import styles from '../../constants/styles'

const SortDishes = ({ sort, handleSort }) => (
  <Container>
    <SortRow>
      <span>SORT</span>
      {constants.dishes.sort.map(s => (
        <Sort
          key={s}
          active={sort === s}
          onClick={() => handleSort(s)}
        >
          {s.toUpperCase()}
        </Sort>
      ))}
    </SortRow>
  </Container>
)

SortDishes.propTypes = {
  sort: PropTypes.string.isRequired,
  handleSort: PropTypes.func.isRequired
}
export default SortDishes

const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: ${styles.height.small};
`
const SortRow = styled.div`
  font-size: 0.9em;
  font-weight: bold;
`

const Sort = styled.span`
  margin-left: ${styles.margin.small};
  color: ${props => (props.active ? styles.colors.omnivore : styles.colors.grey)};
  cursor: pointer;
  &:hover {
    color: ${props => (props.active ? styles.colors.omnivore : styles.colors.brand)};
  }
`
