import { combineReducers } from 'redux'
import * as actions from '../actions'
import constants from '../constants/constants'

const all = (state = {}, action) => {
  switch (action.type) {
    case actions.RECEIVED_DISHES_INDEX:
      return {
        ...state,
        ...action.data.reduce((obj, dish) => {
          obj[dish._id] = Object.assign({}, dish, {
            restaurants: dish.restaurants.map(r => r._id)
          })
          return obj
        }, {})
      }
    case actions.POSTED_NEW_DISH:
      return {
        ...state,
        [action.dish._id]: action.dish
      }
    case actions.UPDATED_DISHES:
      return {
        ...state,
        [action._id]: { ...state[action._id], ...action.value }
      }
    case actions.UPLOADED_IMAGE:
      return {
        ...state,
        [action.image.dish]: {
          ...state[action.image.dish],
          image: state[action.image.dish].image || action.image
        }
      }
    case actions.DISH_UPDATE_LOADING:
      return {
        ...state,
        [action._id]: {
          ...state[action._id],
          updateStatus: {
            ...state[action._id].updateStatus,
            [action.category]: constants.lists.updateStatus.LOADING
          }
        }
      }
    case actions.DISH_UPDATE_COMPLETE:
      return {
        ...state,
        [action._id]: {
          ...state[action._id],
          updateStatus: {
            ...state[action._id].updateStatus,
            [action.category]: constants.lists.updateStatus.COMPLETE
          }
        }
      }
    case actions.DISH_UPDATE_ERROR:
      return {
        ...state,
        [action._id]: {
          ...state[action._id],
          updateStatus: {
            ...state[action._id].updateStatus,
            [action.category]: constants.lists.updateStatus.ERROR
          }
        }
      }
    case actions.DELETED_DISH:
      return {
        ...state,
        [action._id]: null
      }
    case actions.LOG_OUT:
    case actions.UPDATE_DISH_SORT:
    case actions.RESETTING_DISHES_CHANGE_BUSINESS:
      return {}
    default:
      return state
  }
}

const defaultState = {
  isLoading: false,
  items: [],
  error: false,
  fetched: false,
  sort: 'name',
  limit: 20,
  skip: 0,
  hasMore: true,
  ascending: true
}

const index = (state = defaultState, action) => {
  switch (action.type) {
    case actions.FETCHING_DISHES_INDEX:
      return {
        ...state,
        isLoading: true,
        error: false,
        fetched: false
      }
    case actions.RECEIVED_DISHES_INDEX:
      return {
        ...state,
        isLoading: false,
        items: action.newSearch
          ? action.data.map(dish => dish._id)
          : state.items.concat(action.data.map(dish => dish._id)),
        error: false,
        fetched: true,
        hasMore: action.data.length === state.limit,
        skip: action.newSearch
          ? state.limit
          : state.skip + state.limit
      }
    case actions.POSTED_NEW_DISH:
      return {
        ...state,
        items: [action.dish._id].concat(state.items)
      }
    case actions.ERROR_FETCHING_DISHES_INDEX:
      return {
        ...state,
        isLoading: false,
        error: true
      }
    case actions.UPDATE_DISH_SORT:
      return {
        ...state,
        sort: action.sort,
        items: [],
        skip: 0,
        hasMore: true,
        ascending: action.ascending
      }
    case actions.DELETED_DISH:
      return {
        ...state,
        items: state.items.filter(i => i !== action._id)
      }
    case actions.LOG_OUT:
    case actions.RESETTING_DISHES_CHANGE_BUSINESS:
      return defaultState
    default:
      return state
  }
}

const defaultNewDish = {
  name: '',
  officialPrice: null,
  vegType: 'vegan',
  description: '',
  ingredients: [],
  allergens: [],
  restaurants: [],
  unselectedRestaurants: [],
  isVerified: true,
  errors: {},
  isLoading: false,
  reviews: 0
}

const add = (state = defaultNewDish, action) => {
  switch (action.type) {
    case actions.RECEIVED_RESTAURANTS_INDEX:
      return {
        ...state,
        restaurants: action.data.map(item => item._id)
      }
    case actions.UPDATE_NEW_DISH_FIELD:
      return {
        ...state,
        errors: {},
        ...action.value
      }
    case actions.POSTING_NEW_DISH:
      return { ...state, isLoading: true }
    case actions.POSTED_NEW_DISH:
      return { ...state, _id: action.dish._id }
    case actions.ERROR_POSTING_NEW_DISH:
      return {
        ...state,
        isLoading: false,
        errors: {
          posting: 'There was an error adding the dish. Please try again.'
        }
      }
    case actions.EDITING_IMAGES:
      return state._id ? defaultNewDish : state
    case actions.LOG_OUT:
      return defaultNewDish
    default:
      return state
  }
}

export default combineReducers({
  all,
  index,
  add
})
