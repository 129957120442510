import axios from 'axios'
import querystring from 'querystring'
import * as types from '../constants/actionTypes'
import constants from '../constants/constants'
import { fetchData, updateField } from './index'

// revert original on error, update with full if necessary (no normalisation), otherwise use value
export const update = ({
  _id, original, value, full
}) => (dispatch) => {
  dispatch(
    updateField({
      type: 'dishes',
      _id,
      value: full || value
    })
  )
  const category = Object.keys(full || value)[0]
  dispatch({ type: types.DISH_UPDATE_LOADING, _id, category })
  axios
    .put(constants.api.dishes + _id, value)
    .then((res) => {
      if (res.data.err) {
        dispatch(updateField({ type: 'dishes', _id, value: original }))
        dispatch({ type: types.DISH_UPDATE_ERROR, _id, category })
      } else {
        dispatch({ type: types.DISH_UPDATE_COMPLETE, _id, category })
      }
    })
    .catch((err) => {
      console.error('err', err)
      dispatch(updateField({ type: 'dishes', _id, value: original }))
      dispatch({ type: types.DISH_UPDATE_ERROR, _id, category })
    })
}

export const add = dish => (dispatch) => {
  dispatch({ type: types.POSTING_NEW_DISH })
  axios
    .post(constants.api.dishes, dish)
    .then((res) => {
      if (res.data.err) {
        dispatch({ type: types.ERROR_POSTING_NEW_DISH })
      } else {
        dispatch({
          type: types.POSTED_NEW_DISH,
          dish: { ...dish, _id: res.data._id }
        })
      }
    })
    .catch((err) => {
      console.error('err', err)
      dispatch({ type: types.ERROR_POSTING_NEW_DISH })
    })
}

export const deleteDish = _id => (dispatch) => {
  const proceed = window.confirm(
    'Are you sure you want to delete this dish? This action is irreversible.'
  )
  if (!proceed) return

  dispatch(
    updateField({
      type: 'dishes',
      _id,
      value: { deleteStatus: constants.lists.updateStatus.LOADING }
    })
  )
  axios
    .put(`${constants.api.dishes + _id}/delete`)
    .then((res) => {
      if (res.data.err) {
        dispatch(
          updateField({
            type: 'dishes',
            _id,
            value: { deleteStatus: constants.lists.updateStatus.ERROR }
          })
        )
        window.alert(`There was an error deleting the dish: ${res.data.err}`)
      } else {
        dispatch(
          updateField({
            type: 'dishes',
            _id,
            value: { deleteStatus: constants.lists.updateStatus.COMPLETE }
          })
        )
        dispatch({
          type: types.DELETED_DISH,
          _id
        })
      }
    })
    .catch((err) => {
      dispatch(
        updateField({
          type: 'dishes',
          _id,
          value: { deleteStatus: constants.lists.updateStatus.ERROR }
        })
      )
      window.alert(`There was an error deleting the dish: ${err}`)
    })
}

export const fetchDishes = (
  {
    newSearch, ascending, sort, skip, businessId, restaurants
  }
) => (dispatch) => {
  const dishQuery = {
    sort,
    ascending,
    restaurant: restaurants,
    business: businessId,
    vegType: 'both',
    skip,
    limit: 20
  }

  dispatch(
    fetchData({
      path: `${constants.api.dishes}query/${querystring.stringify(
        dishQuery
      )}`,
      type: 'dishes',
      subtype: 'index',
      newSearch
    })
  )
}

export const resetChangeBusiness = () => (dispatch) => {
  dispatch({ type: types.RESETTING_DISHES_CHANGE_BUSINESS })
}
