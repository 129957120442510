import { getOptimizedImagePath } from '@abillionveg/abillionutilities'
import constants from '../constants/constants'

// compares two obj.text strings and sorts them alphabetically
export const sortAlphabetically = (a, b) => {
  if (a < b) return -1
  if (a > b) return 1
  return 0
}

export const isEmailValid = email => !!email
  && !!email.trim()
  && /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    email.trim()
  )

// capitalize all letters/the first letter of a string
export const capitalizeString = (string, all = true) => {
  if (!string) return ''
  const trimmed = string.trim()

  function sentenceCase(word) {
    return word
      .charAt(0)
      .toUpperCase()
      .concat(word.slice(1).toLowerCase())
  }

  return all
    ? trimmed.replace(/\w\S*/g, m => sentenceCase(m))
    : sentenceCase(trimmed)
}

export const calculateFriendliness = (restaurant) => {
  if (!restaurant) {
    if (!restaurant || !restaurant.friendliness) {
      return -1
    }
  }
  const { not, quite, very } = restaurant.friendliness
  const totalVotes = not + quite + very

  return ((quite * 0.5 + very) / totalVotes) * 100
}

export const handleFriendlinessScore = (score) => {
  switch (true) {
    case score >= 0 && score < 33:
      return constants.lists.vegFriendliness[0]
    case score >= 33 && score < 67:
      return constants.lists.vegFriendliness[1]
    case score >= 67:
      return constants.lists.vegFriendliness[2]
    default:
      return 'Friendliness Unrated'
  }
}

// return a transformed cloudinary path
export const optimisedPath = (path, isAvatar = false) => {
  return getOptimizedImagePath(path, isAvatar ? 159 : '')
}

// return user friendly review date
export const formatDate = (date) => {
  const now = Date.now()
  const posted = Date.parse(date)
  const difference = (now - posted) / 1000 / 60 // number of minutes since review
  switch (true) {
    case Math.floor(difference) < 60:
      return `${Math.max(0, Math.floor(difference))} ${
        Math.floor(difference) === 1 ? 'MINUTE' : 'MINUTES'
      } AGO`
    case Math.floor(difference / 60) < 24:
      return `${Math.floor(difference / 60)} ${
        Math.floor(difference / 60) === 1 ? 'HOUR' : 'HOURS'
      } AGO`
    case Math.floor(difference / 60 / 24) < 7:
      return `${Math.floor(difference / 60 / 24)} ${
        Math.floor(difference / 60 / 24) === 1 ? 'DAY' : 'DAYS'
      } AGO`
    case Math.floor(difference / 60 / 24 / 7) <= 4:
      return `${Math.floor(difference / 60 / 24 / 7)} ${
        Math.floor(difference / 60 / 24 / 7) === 1 ? 'WEEK' : 'WEEKS'
      } AGO`
    case Math.floor(difference / 60 / 24 / 30) < 12:
      return `${Math.max(1, Math.floor(difference / 60 / 24 / 30))} ${
        Math.max(1, Math.floor(difference / 60 / 24 / 30)) === 1
          ? 'MONTH'
          : 'MONTHS'
      } AGO`
    case Math.floor(difference / 60 / 24 / 365) >= 1:
      return `${Math.max(1, Math.floor(difference / 60 / 24 / 365))} ${
        Math.max(1, Math.floor(difference / 60 / 24 / 365)) === 1
          ? 'YEAR'
          : 'YEARS'
      } AGO`
    default:
      return ''
  }
}
