import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import styled from 'styled-components'
import * as actions from '../actions'
import Title from '../components/shared/Title'
import ActionBar from '../components/shared/ActionBar'
import EditDescription from '../components/dishes/EditDescription'
import EditIngredients from '../components/dishes/EditIngredients'
import EditAllergens from '../components/dishes/EditAllergens'
import EditLocations from '../components/dishes/EditLocations'
import styles from '../constants/styles'

class AddDish extends Component {
  constructor(props) {
    super(props)
    this.state = {}
    this.checkForErrors = this.checkForErrors.bind(this)
    this.addDish = this.addDish.bind(this)
    this.containerRef = null
  }

  componentDidMount() {
    window.scrollTo(0, 0)
    const node = ReactDOM.findDOMNode(this.containerRef)
    if (node && window.innerWidth > 800) {
      node.scrollIntoView()
    }
    this.props.setDishRestaurants({ restaurants: this.props.restaurants })
  }

  checkForErrors(dish) {
    const errors = {}
    if (!dish.name) {
      errors.name = 'Dish name is required'
    }

    if (!!dish.officialPrice && isNaN(dish.officialPrice)) {
      errors.officialPrice = 'The price must be a number'
    }

    if (!dish.vegType) {
      errors.vegType = 'Please select a veg-type'
    }

    this.props.updateDish({ value: { errors } })
    return Object.keys(errors).length > 0
  }

  addDish() {
    const {
      dish, restaurants, businessID, addDish
    } = this.props
    if (!this.checkForErrors(dish)) {
      const countries = Object.keys(
        restaurants
          .filter(r => dish.restaurants.includes(r._id))
          .reduce((obj, rest) => {
            obj[rest.country] = 1
            return obj
          }, {})
      )

      addDish({ ...dish, countries, business: businessID })
    }
  }

  render() {
    const { dish, restaurants, updateDish } = this.props
    const isActive = !!dish.name && !!dish.vegType

    if (dish._id) {
      return <Redirect to={`/dishes/${dish._id}/images`} />
    }

    return (
      <Container ref={con => (this.containerRef = con)}>
        <Title title="Add Dish" />

        <ActionBar
          backTo="/dishes"
          backToTitle="Dishes"
          justifyContent="space-between"
        />
        <Edit>
          <Field>
            <Label>NAME*</Label>
            <Input
              type="text"
              name="name"
              defaultValue={dish.name}
              placeholder="Add a dish name"
              onChange={e => updateDish({ value: { name: e.target.value } })}
            />
            {!!dish.errors.name && <Error>{dish.errors.name}</Error>}
          </Field>

          <Field>
            <Label>PRICE</Label>
            <Input
              type="number"
              name="price"
              defaultValue={dish.officialPrice}
              min="0"
              step="0.1"
              placeholder="Add a dish price"
              onChange={e => updateDish({
                value: { officialPrice: e.target.value }
              })
              }
            />
            {!!dish.errors.officialPrice && (
              <Error>{dish.errors.officialPrice}</Error>
            )}
          </Field>

          <Field>
            <Label>DESCRIPTION</Label>
            <EditDescription
              _id={dish._id}
              description={dish.description}
              updateDish={updateDish}
            />
          </Field>

          <Field>
            <Label>INGREDIENTS</Label>
            <EditIngredients
              _id={dish._id}
              ingredients={dish.ingredients}
              updateDish={updateDish}
            />
          </Field>

          <Field>
            <Label>ALLERGENS</Label>
            <EditAllergens
              _id={dish._id}
              allergens={dish.allergens}
              updateDish={updateDish}
            />
          </Field>

          <VegNote>Please ensure that this dish is 100% plant-based.</VegNote>

          {restaurants.length > 0 ? (
            <Field>
              <Label>LOCATIONS</Label>
              <EditLocations
                _id={dish._id}
                allRestaurants={restaurants}
                restaurants={dish.restaurants}
                updateDish={updateDish}
              />
            </Field>
          ) : (
            <Field>
              Fetching restaurants&nbsp;
              <p className="fa fa-spinner fa-spin" />
            </Field>
          )}

          <AddDishDiv>
            {Object.values(dish.errors).map(e => (
              <Error key={e}>{e}</Error>
            ))}

            {dish.isLoading ? (
              <AddDishButton active>
                <i className="fa fa-spinner fa-spin" />
              </AddDishButton>
            ) : (
              <AddDishButton active={isActive} onClick={this.addDish}>
                Add dish
              </AddDishButton>
            )}

            <span style={{ color: styles.colors.darkGrey, fontSize: '0.8em' }}>
              Manage images after adding the dish
            </span>
          </AddDishDiv>
        </Edit>
      </Container>
    )
  }
}

AddDish.propTypes = {
  dish: PropTypes.object.isRequired,
  businessID: PropTypes.string,
  restaurants: PropTypes.arrayOf(PropTypes.object).isRequired,
  updateDish: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
  dish: state.dishes.add,
  businessID: state.business.all.details._id,
  restaurants: state.restaurants.index.items.map(
    _id => state.restaurants.all[_id]
  )
})

const mapDispatchToProps = dispatch => ({
  updateField: ({ _id, value }) => {
    dispatch(actions.updateField({ type: 'dishes', _id, value }))
  },
  updateDish: ({ value }) => {
    dispatch({ type: actions.UPDATE_NEW_DISH_FIELD, value })
  },
  setDishRestaurants: ({ restaurants }) => {
    dispatch({ type: actions.RECEIVED_RESTAURANTS_INDEX, data: restaurants })
  },
  addDish: (dish) => {
    dispatch(actions.dishes.add(dish))
  }
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddDish)

const Container = styled.main`
  width: 100%;
  padding: ${styles.padding.medium};
`

const Edit = styled.div`
  padding-top: ${styles.padding.medium};
`

const Field = styled.div`
  margin-bottom: calc(2 * ${styles.margin.medium});
`

const Input = styled.input`
  width: 100%;
  max-width: 720px;
  height: ${styles.height.small};
  border: 1px solid ${styles.colors.lightGrey};
  border-radius: ${styles.borderRadius.small};
  padding-left: ${styles.padding.small};
  font-size: 1em;
  background-color: ${styles.colors.white};
`

const Label = styled.div`
  font-size: 0.9em;
  font-weight: 600;
  letter-spacing: 1px;
  margin-bottom: ${styles.margin.small};
  display: flex;
  justify-content: flex-start;
  align-items: center;
`

const AddDishDiv = styled.div`
  width: 100%;
  max-width: 720px;
  padding-bottom:${styles.padding.medium};
  background-color: ${styles.colors.veryLightGrey};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const VegNote = styled.div`
  width: 100%;
  max-width: 720px;
  background-color: ${styles.colors.vegan};
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${styles.colors.white};
  margin-bottom: calc(2 * ${styles.margin.medium});
  padding: ${styles.padding.medium};
`

const Error = styled.div`
  color: ${styles.colors.action};
  margin-top: ${styles.margin.small};
`

const AddDishButton = styled.div`
  width: 100%;
  max-width: 240px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1em;
  cursor: pointer;
  margin: ${styles.margin.small} 0;
  border-radius: ${styles.borderRadius.small};
  background-color: ${props => (props.active ? styles.colors.omnivore : styles.colors.grey)};
  color: ${styles.colors.white};
  &:hover {
    background-color: ${props => (props.active ? styles.colors.brand : styles.colors.darkGrey)};
  }
`
