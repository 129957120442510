import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import styles from '../../constants/styles'
import { capitalizeString, optimisedPath } from '../../utilities/format'

const Avatar = ({ user, width, height }) => (
  <div>
    {user?.avatar ? (
      <Image
        src={optimisedPath(`${user.avatar}`, true)}
        onError={(e) => {
          e.target.src = `${
            user.identity === 'vegan'
              ? 'https://res.cloudinary.com/abillionveg/image/upload/c_scale,w_300/v1526349986/green_isilnq.png'
              : user.identity === 'vegetarian'
              ? 'https://res.cloudinary.com/abillionveg/image/upload/c_scale,w_300/v1526349986/seafoam_nkqtwr.png'
              : 'https://res.cloudinary.com/abillionveg/image/upload/c_scale,w_300/v1526456868/dark-blue_jkyh8y.png'
          }`
        }}
        alt="avatar"
        width={width}
        height={height}
      />
    ) : (
      <DefaultAvatar identity={user?.identity} width={width} height={height}>
        <InnerAvatar identity={user?.identity}>
          <span>
            {user?.username ? capitalizeString(user.username[0]) : ''}
          </span>
        </InnerAvatar>
      </DefaultAvatar>
    )}
  </div>
)

Avatar.propTypes = {
  user: PropTypes.object.isRequired,
  width: PropTypes.string,
  height: PropTypes.string,
}

const Image = styled.img`
  width: ${(props) => props.width || '36px'};
  height: ${(props) => props.height || '36px'};
  min-height: 36px;
  object-fit: cover;
  border-radius: 50%;
  border: 1px solid ${styles.colors.lightGrey};
`

const DefaultAvatar = styled.div`
  width: ${(props) => props.width || '36px'};
  height: ${(props) => props.height || '36px'};
  border-radius: 50%;
  border: 2px solid
    ${(props) =>
      props.identity === 'vegan'
        ? styles.colors.vegan
        : props.identity === 'vegetarian'
        ? styles.colors.brand
        : styles.colors.omnivore};
  background-color: ${styles.colors.white};
  padding: 2px;
`

const InnerAvatar = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1em;
  color: ${styles.colors.white};
  background-color: ${(props) =>
    props.identity === 'vegan'
      ? styles.colors.vegan
      : props.identity === 'vegetarian'
      ? styles.colors.brand
      : styles.colors.omnivore};
`

export default Avatar
