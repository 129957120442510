import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Avatar from './Avatar'
import styles from '../../constants/styles'

class UserCard extends Component {
  render() {
    const { user } = this.props
    return (
      <Container>
        <a
          href={`https://www.abillion.com/users/${user.username}`}
          rel="noopener noreferrer"
          target="_blank"
        >
          <Avatar user={user} />
        </a>
        <div style={{ marginLeft: styles.margin.small }}>
          <a
            href={`https://www.abillion.com/users/${user.username}`}
            rel="noopener noreferrer"
            target="_blank"
          >
            <Username>{user.username}</Username>
          </a>
          <div style={{ color: styles.colors.darkGrey }}>{user.email}</div>
        </div>
      </Container>
    )
  }
}

UserCard.propTypes = {
  user: PropTypes.shape({
    username: PropTypes.string,
    email: PropTypes.string,
    identity: PropTypes.string
  }).isRequired
}

const Container = styled.div`
  display: flex;
  flex-flow: row;
  width: 100%;
`

const Username = styled.div`
  font-weight: bold;
  color: ${styles.colors.black};

  &: hover {
    color: ${styles.colors.omnivore};
  }
`

export default UserCard
