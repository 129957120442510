import React, { Component } from 'react'
import axios from 'axios'
import styled from 'styled-components'
import querystring from 'querystring'
import Meta from '../components/landing/Meta'
import Progress from '../components/claim/Progress'
import Step1SelectLocations from '../components/claim/Step1SelectLocations'
import BackForward from '../components/claim/BackForward'
import Step2BusinessDetails from '../components/claim/Step2BusinessDetails'
import Step3LinkAccount from '../components/claim/Step3LinkAccount'
import constants from '../constants/constants'
import allCopy from '../constants/copy'
import styles from '../constants/styles'

const copy = allCopy.en.claim

class Claim extends Component {
  constructor(props) {
    super(props)
    this.state = {
      // general
      completionStage: 1,
      formErrorObj: {
        name: '',
        role: '',
        contactPerson: '',
        email: '',
        phone: '',
        acknowledged: ''
      },
      errors: '',
      // step 1 select locations
      loading: false,
      searched: false,
      query: '',
      country: '',
      restaurants: [],
      google: [],
      claimed: [],
      // step 2 business details
      name: '',
      role: '',
      contactPerson: '',
      email: '',
      phone: '',
      facebook: '',
      instagram: '',
      twitter: '',
      linkedIn: '',
      remarks: '',
      acknowledged: false,
      // step 3 link account
      userToken: '',
      claimPending: false,
      // reportLoading pre-req, submitted:true post-req.
      reportLoading: false,
      submitted: false,
      submittedErrorMessage: ''
    }
    this.handleSearch = this.handleSearch.bind(this)
    this.searchGoogle = this.searchGoogle.bind(this)
    this.handleClaim = this.handleClaim.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleStep = this.handleStep.bind(this)
    this.handleLogin = this.handleLogin.bind(this)
  }

  componentDidMount() {
    window.scrollTo(0, 0)
  }

  // searches for restaurants in our db matching search input and setState restaurants[]. if insufficient results search google
  handleSearch({ query, country }) {
    if (!query || !country) {
      return
    }
    this.setState({
      loading: true,
      searched: false
    })

    const restaurantQuery = {
      text: query,
      country,
      limit: 50,
      query: 'text',
      platform: 'business'
    }

    axios
      .get(
        `${constants.api.restaurants}?${querystring.stringify(
          restaurantQuery
        )}`,
        {
          headers: { skip: 'true' }
        }
      )
      .then((res) => {
        this.setState({ restaurants: res.data })
        if (res.data.length < 7) {
          this.searchGoogle()
        } else {
          this.setState({
            loading: false,
            searched: true
          })
        }
      })
      .catch(err => console.error(err))
  }

  // searches for restaurants from google and check if it contains stuff alrdy in our db. setState restaurants[] with combined results
  searchGoogle(query) {
    axios
      .get(
        `${constants.api.google.search}type=text&country=${this.state.country}&query=${this.state.query}`,
        { headers: { skip: 'true' } }
      )
      .then((res) => {
        this.setState({
          google: res.data.results || [],
          loading: false,
          searched: true
        })
        // check if google results contain restaurants in our database and replace them
        if (res.data.results.length > 0) {
          res.data.results
            .filter(
              google => !this.state.restaurants
                .map(rest => rest.google_place_id)
                .includes(google.place_id)
            )
            .forEach((rest, index) => {
              axios
                .get(constants.api.google.check + rest.place_id, {
                  headers: { skip: 'true' }
                })
                .then((response) => {
                  if (
                    !this.state.restaurants
                      .map(rest => rest._id)
                      .includes(response.data._id)
                  ) {
                    this.setState({
                      restaurants: this.state.restaurants.concat(response.data)
                    })
                  }
                })
                .catch((err) => {
                  console.error(err)
                })
            })
        } else {
          this.setState({
            loading: false,
            searched: true
          })
        }
      })
      .catch((err) => {
        console.error(err)
        this.setState({ loading: false })
      })
  }

  // select or unselect a location. locations which are already claimed or are pending claim cannot be selected. clear errors on location select / unselect
  handleClaim(rest) {
    if (!!rest.business || rest.claimPending) return

    let updatedArr = []
    if (this.state.claimed.includes(rest)) {
      updatedArr = this.state.claimed.filter(e => e !== rest)
    } else {
      updatedArr = [...this.state.claimed, rest]
    }

    this.setState({
      claimed: updatedArr,
      errors: ''
    })
  }

  // setstate when input fields change, clear errors if input changes for Step2BusinessDetails
  handleChange(category, value) {
    this.setState({
      [category]: category === 'email' ? value.toLowerCase() : value
    })

    if (
      [
        'name',
        'role',
        'contactPerson',
        'email',
        'phone',
        'remarks',
        'acknowledged'
      ].includes(category)
    ) {
      this.setState({
        errors: '',
        formErrorObj: {
          name: '',
          role: '',
          contactPerson: '',
          email: '',
          phone: '',
          acknowledged: ''
        }
      })
    }
  }

  // submit claim to backend in step 3
  handleSubmit() {
    const {
      name,
      claimed,
      contactPerson,
      role,
      email,
      phone,
      facebook,
      instagram,
      twitter,
      linkedIn,
      remarks,
      userToken
    } = this.state
    // set reportLoading to true to prevent multiple submissions
    this.setState({
      reportLoading: true
    })

    const business = {
      name,
      restaurants: claimed.map(rest => rest._id),
      brands: [],
      contactPerson,
      role,
      email,
      phone,
      facebook,
      instagram,
      twitter,
      linkedIn,
      remarks,
      status: 'pending'
    }

    // send business details to backend. if success set submitted to true, if error set submittedErrorMessage
    axios
      .post(constants.api.businesses, business, {
        headers: {
          skip: true,
          Authorization: `Bearer ${userToken}`
        }
      })
      .then((res) => {
        if (res.data.success) {
          this.setState({
            reportLoading: false,
            submitted: true
          })
        }
      })
      .catch((err) => {
        console.error('err', err)
        this.setState({
          reportLoading: false,
          submittedErrorMessage:
            'Oh dear! There was an error in submitting your details. Please try again later.'
        })
      })
  }

  // validateStep checks for errors. used to check whether to proceed, and also whether to display greyed out or active buttons
  validateStep(setError = false) {
    // validate the step and set error msgs if required
    if (this.state.completionStage === 1) {
      if (setError) {
        this.setState({ errors: 'Please select a restaurant' })
      }
      return this.state.claimed.length > 0
    }
    if (this.state.completionStage === 2) {
      let valid = true
      let formErrorObj = {
        name: '',
        contactPerson: '',
        email: '',
        phone: '',
        acknowledged: ''
      }
      if (this.state.name.trim().length === 0) {
        valid = false
        formErrorObj = {
          ...formErrorObj,
          name: 'Please indicate the name of your business'
        }
      }
      if (this.state.role.trim().length === 0) {
        valid = false
        formErrorObj = {
          ...formErrorObj,
          role: 'Please indicate your role in this business'
        }
      }
      if (this.state.contactPerson.trim().length === 0) {
        valid = false
        formErrorObj = {
          ...formErrorObj,
          contactPerson: 'A contact person is required'
        }
      }
      if (
        this.state.email
        && !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
          this.state.email
        )
      ) {
        valid = false
        formErrorObj = {
          ...formErrorObj,
          email: 'Please enter a valid email address'
        }
      }
      if (this.state.email.length === 0) {
        valid = false
        formErrorObj = {
          ...formErrorObj,
          email: 'Your email address is required'
        }
      }
      if (this.state.phone.trim().length === 0) {
        valid = false
        formErrorObj = {
          ...formErrorObj,
          phone: 'Your phone number is required'
        }
      }
      if (!this.state.acknowledged) {
        valid = false
        formErrorObj = {
          ...formErrorObj,
          acknowledged:
            'Please acknowledge that you are authorised to manage the above restaurant(s)'
        }
      }
      if (setError) {
        this.setState({
          errors: valid ? '' : 'Please fill in all required fields',
          formErrorObj
        })
      }
      return valid
    }
    if (this.state.completionStage === 3) {
      return !!this.state.userToken && !this.state.claimPending
    }
  }

  // handles back, forward button logic. if forward, validate step and set error msgs.
  handleStep(direction) {
    if (direction === 'back' && this.state.completionStage !== 1) {
      this.setState({
        completionStage: this.state.completionStage - 1,
        errors: '',
        formErrorObj: {
          name: '',
          contactPerson: '',
          email: '',
          phone: '',
          acknowledged: ''
        }
      })
    }
    // validate step, set error if not valid, or clear errors if valid, and go to next step
    if (direction === 'next' && this.validateStep(true)) {
      this.setState(
        {
          completionStage: this.state.completionStage + 1,
          errors: '',
          formErrorObj: {
            name: '',
            contactPerson: '',
            email: '',
            phone: '',
            acknowledged: ''
          }
        },
        () => {
          if (this.state.completionStage === 4) {
            this.handleSubmit()
          }
        }
      )
    }
  }

  handleLogin(token, pending = false) {
    this.setState({
      userToken: token,
      claimPending: pending,
      errors: ''
    })
  }

  clearState() {
    this.setState({
      loading: false,
      searched: false,
      reportLoading: false,
      submitted: false,
      submittedErrorMessage: '',
      query: '',
      country: 'selectCountry',
      restaurants: [],
      claimed: [],
      name: '',
      contactPerson: '',
      email: '',
      phone: '',
      facebook: '',
      instagram: '',
      twitter: '',
      linkedIn: '',
      remarks: '',
      acknowledged: false,
      completionStage: 1,
      errors: '',
      userToken: '',
      claimPending: false,
      formErrorObj: {
        name: '',
        contactPerson: '',
        email: '',
        phone: '',
        acknowledged: ''
      }
    })
  }

  render() {
    const {
      completionStage,
      loading,
      errors,
      query,
      country,
      claimed,
      restaurants,
      searched,
      name,
      contactPerson,
      role,
      email,
      phone,
      facebook,
      instagram,
      twitter,
      linkedIn,
      remarks,
      acknowledged,
      formErrorObj,
      userToken,
      claimPending
    } = this.state
    const meta = {
      title: 'Claim your business | abillion',
      url: 'https://business.abillion.com/claim'
    }

    return (
      <Container>
        <Meta meta={meta} />
        <Title>{copy.title}</Title>

        <Progress completionStage={completionStage} />

        {completionStage === 1 && (
          <Step1SelectLocations
            query={query}
            country={country}
            loading={loading}
            claimed={claimed}
            restaurants={restaurants}
            searched={searched}
            handleSearch={this.handleSearch}
            handleChange={this.handleChange}
            handleClaim={this.handleClaim}
          />
        )}

        {completionStage === 2 && (
          <Step2BusinessDetails
            name={name}
            contactPerson={contactPerson}
            role={role}
            email={email}
            phone={phone}
            facebook={facebook}
            instagram={instagram}
            twitter={twitter}
            linkedIn={linkedIn}
            remarks={remarks}
            acknowledged={acknowledged}
            formErrorObj={formErrorObj}
            claimed={claimed}
            handleChange={this.handleChange}
          />
        )}

        {completionStage === 3 && (
          <Step3LinkAccount
            userToken={userToken}
            claimPending={claimPending}
            handleLogin={this.handleLogin}
          />
        )}

        {!this.state.reportLoading && !this.state.submitted && (
          <BackForward
            errorMessage={errors}
            canGoBack={completionStage === 2 || completionStage === 3}
            canProceed={this.validateStep()}
            handleStep={this.handleStep}
          />
        )}

        {this.state.submitted && (
          <div
            style={{
              margin: '0 auto',
              textAlign: 'center',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              maxWidth: '570px'
            }}
          >
            <h2
              style={{
                fontSize: '2em',
                marginBottom: styles.margin.large
              }}
            >
              Your request has been submitted
            </h2>
            <i
              className="far fa-check-circle fa-5x"
              style={{
                fontSize: '7em',
                color: styles.colors.brand,
                marginBottom: styles.margin.large
              }}
            />

            <ExplanationDiv>
              <QuestionMarkDiv>
                <i
                  className="far fa-question-circle fa-2x"
                  style={{ color: styles.colors.darkGrey }}
                />
              </QuestionMarkDiv>
              <TextContainer>
                <h2 style={{ marginBottom: styles.margin.small }}>
                  What happens next?
                </h2>
                <h4 style={{ lineHeight: 1.5 }}>
                  We will verify your submission before we enable your access to
                  business-only features. You can look forward to receiving a
                  confirmation email within the next few days.
                </h4>
              </TextContainer>
            </ExplanationDiv>

            <a
              href="/"
              style={{
                marginTop: styles.margin.larger,
                padding: styles.padding.medium,
                borderRadius: styles.borderRadius.small,
                border: `1px solid ${styles.colors.omnivore}`,
                color: styles.colors.omnivore
              }}
            >
              Return home
            </a>
          </div>
        )}

        {this.state.submittedErrorMessage && (
          <div
            style={{
              margin: '0 auto',
              textAlign: 'center',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              maxWidth: '570px'
            }}
          >
            <h2
              style={{
                fontSize: '2em',
                marginBottom: styles.margin.large
              }}
            >
              There appears to be an error
            </h2>
            <i
              className="fas fa-exclamation-circle fa-5x"
              style={{
                fontSize: '7em',
                color: styles.colors.darkGrey,
                marginBottom: styles.margin.large
              }}
            />

            <h2>{this.state.submittedErrorMessage}</h2>
            <div
              style={{
                marginTop: styles.margin.larger,
                padding: styles.padding.medium,
                borderRadius: styles.borderRadius.small,
                border: `1px solid ${styles.colors.omnivore}`,
                color: styles.colors.omnivore
              }}
              onClick={() => this.clearState()}
            >
              Try again
            </div>
          </div>
        )}
      </Container>
    )
  }
}

const Container = styled.main`
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  min-height: calc(100vh - 64px - 64px - 34px);
  margin-top: 64px;
  margin-bottom: calc(64px + 34px);
  display: flex;
  flex-direction: column;

  @media (max-width: 800px) {
    padding: 0 ${styles.padding.small};
  }
`

const Title = styled.h1`
  font-weight: bold;
  color: ${styles.colors.black};
  text-align: center;
  font-size: 2.5em;
  margin: ${styles.margin.large} auto;
`

const ExplanationDiv = styled.div`
  display: flex;
  max-width: 570px;
`
const QuestionMarkDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-right: ${styles.margin.small};
`
const TextContainer = styled.div`
  display: flex,
  flex-direction: column;
  text-align: justify;
`

export default Claim
