import React, { Component } from 'react'
import styled from 'styled-components'
import styles from '../../constants/styles'

class Progress extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  renderCircle(stage, completed) {
    if (stage > completed) {
      return (
        <InactiveCircle>
          <Stage>{stage}</Stage>
        </InactiveCircle>
      )
    } if (stage === completed) {
      return (
        <ActiveCircle>
          <Stage>{stage}</Stage>
        </ActiveCircle>
      )
    }
    return (
      <CompleteCircle>
        <Stage>{stage}</Stage>
      </CompleteCircle>
    )
  }

  render() {
    const { completionStage } = this.props
    return (
      <Container>
        {this.renderCircle(1, completionStage)}

        <HorizRule completed={completionStage > 1} />

        {this.renderCircle(2, completionStage)}

        <HorizRule completed={completionStage > 2} />

        {this.renderCircle(3, completionStage)}
      </Container>
    )
  }
}

export default Progress

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: ${styles.margin.large};
`
const HorizRule = styled.hr`
  width: 20%;
  border-top: ${props => (props.completed
    ? `2px solid ${styles.colors.black}`
    : `2px solid ${styles.colors.darkGrey}`)};
  margin: 0 30px;

  @media (max-width: 600px) {
    width: 15%;
    margin: 0 15px;
  }
`
const InactiveCircle = styled.div`
  width: 48px;
  height: 48px;
  border-radius: 50%;
  border: 2px solid ${styles.colors.darkGrey};
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${styles.colors.darkGrey};
`
const ActiveCircle = styled(InactiveCircle)`
  background-color: ${styles.colors.omnivore};
  border: 2px solid ${styles.colors.omnivore};
  color: ${styles.colors.white};
`
const CompleteCircle = styled(InactiveCircle)`
  background-color: ${styles.colors.brand};
  border: 2px solid ${styles.colors.brand};
  color: ${styles.colors.white};
`
const Stage = styled.span`
  font-size: 1.5em;
`
