import React, { Component } from 'react'
import styled from 'styled-components'
import styles from '../../constants/styles'

class LoadingOverlay extends Component {
  render() {
    return (
      <Container>
        <Icon className="fas fa-spin fa-spinner" />
        <Text>Fetching business...</Text>
      </Container>
    )
  }
}

export default LoadingOverlay

const Container = styled.div`
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 50;
  background-color: rgba(0, 0, 0, .8);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const Icon = styled.i`
  font-size: 3em;
  color: ${styles.colors.white};
`

const Text = styled.div`
  font-weight: bold;
  font-size: 1.5em;
  color: ${styles.colors.white};
  margin-top: ${styles.margin.small};
`
