import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import styled from 'styled-components'
import * as actions from '../actions'
import Title from '../components/shared/Title'
import ActionButton from '../components/shared/ActionButton'
import ErrorBoundary from '../components/shared/ErrorBoundary'
import ReviewCard from '../components/reviews/ReviewCard'
import ReviewLoading from '../components/reviews/ReviewLoading'
import SmallReviewCard from '../components/reviews/SmallReviewCard'
import styles from '../constants/styles'

class Reviews extends Component {
  constructor(props) {
    super(props)
    this.state = {
      mobileView: 'reviewList',
      selectedReview: 0,
      selectedReviewIndex: 0,
      photoIndex: 0,
      dishIndex: '0',
      restaurantIndex: '0',
    }
    this.toggleUp = this.toggleUp.bind(this)
    this.toggleDown = this.toggleDown.bind(this)
    this.handleIndex = this.handleIndex.bind(this)
  }

  componentDidMount() {
    const { match } = this.props
    window.scroll(0, 0)
    if (match.params.id && match.params.id.length > 0) {
      this.setState({
        selectedReview: match.params.id,
        mobileView: 'mainReview',
      })
    }
    const node = ReactDOM.findDOMNode(this.containerRef)
    if (node && window.innerWidth > 800) {
      node.scrollIntoView()
    }
  }

  componentWillReceiveProps(nextProps) {
    const { match } = this.props
    if (match.params.id !== nextProps.match.params.id) {
      this.setState({ selectedReview: nextProps.match.params.id })
    }
  }

  handleIndex(index) {
    this.setState({ photoIndex: index })
  }

  toggleUp(noPress, arr) {
    const { selectedReviewIndex } = this.state
    if (!noPress) {
      const newIndex = selectedReviewIndex - 1
      this.setState({
        selectedReview: arr[newIndex]._id,
        selectedReviewIndex: newIndex,
      })
    }
  }

  toggleDown(noPress, arr) {
    const { selectedReviewIndex } = this.state
    if (!noPress) {
      const newIndex = selectedReviewIndex + 1
      this.setState({
        selectedReview: arr[newIndex]._id,
        selectedReviewIndex: newIndex,
      })
    }
  }

  render() {
    const { viewer, reviews, restaurants, dishes, updateField } = this.props
    const {
      restaurantIndex,
      dishIndex,
      mobileView,
      index,
      photoIndex,
      selectedReviewIndex,
    } = this.state

    const filtered = reviews.index.items
      .map((_id) => reviews.all[_id])
      .filter(
        (review) =>
          restaurantIndex === '0' ||
          (restaurantIndex > 0 &&
            review.restaurant._id === restaurants[restaurantIndex - 1]._id)
      )
      .filter(
        (review) =>
          dishIndex === '0' ||
          (dishIndex > 0 &&
            review.type === 'dish' &&
            dishes.length > 0 &&
            review.dish._id === dishes[dishIndex - 1]._id)
      )
    const selectedReview =
      this.state.selectedReview === 0
        ? filtered[0]
        : reviews.all[this.state.selectedReview]

    return (
      <Container
        ref={(con) => {
          this.containerRef = con
        }}
      >
        <Title title="Reviews" />

        {!reviews.index.fetched && (
          <div style={{ width: '100%' }}>
            <ReviewLoading />
          </div>
        )}

        {reviews.index.fetched && (
          <div>
            <Filter>
              {dishes.length > 0 && (
                <SelectContainer>
                  <Select
                    value={dishIndex}
                    onChange={(e) =>
                      this.setState({ dishIndex: e.target.value })
                    }
                  >
                    <option value="0">All Dishes</option>
                    {dishes
                      .sort((a, b) => {
                        const first = a.name.toLowerCase()
                        const second = b.name.toLowerCase()
                        if (first < second) {
                          return -1
                        }
                        if (first > second) {
                          return 1
                        }
                        return 0
                      })
                      .map((dish, i) => (
                        <option key={dish._id} value={i + 1}>
                          {dish.name}
                        </option>
                      ))}
                  </Select>
                </SelectContainer>
              )}

              {restaurants.length > 0 && (
                <SelectContainer>
                  <Select
                    value={index}
                    onChange={(e) =>
                      this.setState({ restaurantIndex: e.target.value })
                    }
                  >
                    <option value="0">All Restaurants</option>
                    {restaurants.map((rest, i) => (
                      <option key={rest._id} value={i + 1}>
                        {rest.nickname ||
                          `${rest.name} @ ${rest.formatted_address}`}
                      </option>
                    ))}
                  </Select>
                </SelectContainer>
              )}
            </Filter>

            {filtered.length > 0 ? (
              <WebView>
                <ReviewList>
                  <div
                    style={{
                      padding: styles.padding.medium,
                      marginBottom: styles.margin.small,
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      fontWeight: '500',
                    }}
                  >
                    {filtered.length}
                    {filtered.length === 1 ? ' review' : ' reviews'}
                  </div>

                  {filtered.map((review, i) => (
                    <ErrorBoundary key={review._id}>
                      <Link
                        to={`/reviews/${review._id}`}
                        onClick={() =>
                          this.setState({
                            selectedReview: review._id,
                            photoIndex: 0,
                            selectedReviewIndex: i,
                          })
                        }
                        style={{ cursor: 'pointer' }}
                      >
                        <SmallReviewCard
                          review={review}
                          viewer={viewer}
                          updateField={updateField}
                          selected={
                            selectedReview
                              ? selectedReview._id === review._id
                              : false
                          }
                        />
                      </Link>
                    </ErrorBoundary>
                  ))}
                </ReviewList>
                <MainReview>
                  {selectedReview && selectedReview.user ? (
                    <ErrorBoundary>
                      <ReviewCard
                        review={selectedReview}
                        currentUser={selectedReview.user}
                        handleIndex={this.handleIndex}
                        photoIndex={photoIndex}
                      />
                    </ErrorBoundary>
                  ) : (
                    <Loading>
                      <i
                        className="fas fa-spinner fa-spin"
                        style={{ fontSize: '2em' }}
                      />
                    </Loading>
                  )}
                </MainReview>
              </WebView>
            ) : (
              <WebView>
                <NoResults>
                  0 reviews - No reviews found for your filters.
                </NoResults>
              </WebView>
            )}

            {filtered.length > 0 ? (
              <MobileView>
                {mobileView === 'reviewList' && (
                  <ReviewList>
                    <div
                      style={{
                        marginBottom: styles.margin.small,
                        fontWeight: '500',
                        padding: styles.padding.small,
                        textAlign: 'center',
                      }}
                    >
                      {filtered.length}
                      {filtered.length === 1 ? ' review' : ' reviews'}
                    </div>
                    {filtered.map((review, i) => (
                      <ErrorBoundary key={review._id}>
                        <Link
                          to={`/reviews/${review._id}`}
                          onClick={() =>
                            this.setState({
                              selectedReview: review._id,
                              selectedReviewIndex: i,
                              mobileView: 'mainReview',
                            })
                          }
                          style={{ cursor: 'pointer' }}
                        >
                          <SmallReviewCard
                            review={review}
                            viewer={viewer}
                            updateField={updateField}
                            selected={
                              selectedReview
                                ? selectedReview._id === review._id
                                : false
                            }
                          />
                        </Link>
                      </ErrorBoundary>
                    ))}
                  </ReviewList>
                )}

                {mobileView === 'mainReview' && (
                  <div
                    style={{
                      width: '100vw',
                      display: 'flex',
                      justifyContent: 'center',
                      flexFlow: 'column',
                      alignItems: 'center',
                    }}
                  >
                    <MobileBack>
                      <ActionButton
                        onClick={() =>
                          this.setState({ mobileView: 'reviewList' })
                        }
                      >
                        <span style={{ padding: `0 ${styles.padding.medium}` }}>
                          <i className="fas fa-angle-left" />
                          &nbsp;Reviews
                        </span>
                      </ActionButton>
                      <div>
                        <Arrow
                          className="fas fa-arrow-circle-up"
                          noPress={selectedReviewIndex === 0}
                          onClick={() =>
                            this.toggleUp(selectedReviewIndex === 0, filtered)
                          }
                        />
                        &nbsp;&nbsp;
                        <Arrow
                          className="fas fa-arrow-circle-down"
                          noPress={selectedReviewIndex === filtered.length - 1}
                          onClick={() =>
                            this.toggleDown(
                              selectedReviewIndex === filtered.length - 1,
                              filtered
                            )
                          }
                        />
                      </div>
                    </MobileBack>
                    <div>
                      {selectedReview && selectedReview.user ? (
                        <ErrorBoundary>
                          <ReviewCard
                            review={selectedReview}
                            currentUser={selectedReview.user}
                            handleIndex={this.handleIndex}
                            photoIndex={photoIndex}
                          />
                        </ErrorBoundary>
                      ) : (
                        <Loading>
                          <i
                            className="fas fa-spinner fa-spin"
                            style={{ fontSize: '2em' }}
                          />
                        </Loading>
                      )}
                    </div>
                  </div>
                )}
              </MobileView>
            ) : (
              <NoResults style={{ fontSize: '1.1em' }}>
                0 reviews - No reviews found for your filters.
              </NoResults>
            )}
          </div>
        )}
      </Container>
    )
  }
}

Reviews.propTypes = {
  match: PropTypes.objectOf(PropTypes.any).isRequired,
  viewer: PropTypes.objectOf(PropTypes.any).isRequired,
  dishes: PropTypes.arrayOf(PropTypes.object).isRequired,
  restaurants: PropTypes.arrayOf(PropTypes.object).isRequired,
  reviews: PropTypes.objectOf(PropTypes.any).isRequired,
  updateField: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => ({
  viewer: state.users.viewer._id ? state.users.all[state.users.viewer._id] : {},
  dishes: state.dishes.index.items.map((_id) => state.dishes.all[_id]),
  restaurants: state.restaurants.index.items.map(
    (_id) => state.restaurants.all[_id]
  ),
  reviews: state.reviews,
})

const mapDispatchToProps = (dispatch) => ({
  updateField: ({ _id, value }) => {
    dispatch(actions.updateField({ type: 'reviews', _id, value }))
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(Reviews)

const Container = styled.main`
  padding: 0 ${styles.padding.medium};
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
`

const Filter = styled.div`
  width: 100%;
  display: flex;

  @media (max-width: 800px) {
    display: none;
  }
`

const WebView = styled.div`
  display: none;

  @media (min-width: 801px) {
    display: flex;
    flex-flow: row;
    width: 100%;
  }
`

const MobileView = styled.div`
  display: flex;
  flex-flow: column;

  @media (min-width: 801px) {
    display: none;
  }
`

const ReviewList = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column;
  background-color: ${styles.colors.white};
  margin-right: ${styles.margin.small};
  padding-top: ${styles.padding.small};

  @media (min-width: 801px) {
    height: 100vh;
    overflow-y: scroll;
  }

  @media (min-width: 1121px) {
    width: 30%;
    height: 500px;
    overflow-y: scroll;
  }
`

const SelectContainer = styled.div`
  width: 50%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: ${styles.height.small};
  border: 1px solid ${styles.colors.lightGrey};
  background-color: ${styles.colors.white};
  margin-bottom: ${styles.margin.small};
`

const Select = styled.select`
  padding: ${styles.padding.medium};
  width: 100%;
  height: 100%;
  border: none;
  font-size: 1em;
  box-shadow: none;
  background: transparent;
  background-image: none;
  -webkit-appearance: none;
  cursor: pointer;
`

const MainReview = styled.div`
  width: 70%;

  @media (max-width: 1120px) {
    width: 400px;
  }
  @media (max-width: 900px) {
    width: 350px;
  }
`

const MobileBack = styled.div`
  width: calc(100vw);
  padding: ${styles.padding.small};
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${styles.colors.white};
  margin-bottom: ${styles.margin.small};
  font-weight: 500;
`

const Loading = styled.div`
  width: 100%;
  height: calc(100vh - 96px - 64px);
  font-weight: bold;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const Arrow = styled.i`
  cursor: ${(props) => (props.noPress ? 'default' : 'pointer')};
  color: ${(props) =>
    props.noPress ? styles.colors.grey : styles.colors.black};
  font-size: 2em;
  &:hover {
    color: ${styles.colors.omnivore};
  }
`

const NoResults = styled(Loading)`
  font-size: 1.5em;
  font-weight: 400;
`
