import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import styles from '../../constants/styles'

const EditPrice = ({ defaultPrice, updatePrice }) => (
  <Input
    type="number"
    name="price"
    defaultValue={defaultPrice}
    min="0"
    step="0.1"
    placeholder="Add a dish price"
    onChange={updatePrice}
  />
)

export default EditPrice

EditPrice.propTypes = {
  updatePrice: PropTypes.func.isRequired
}

const Input = styled.input`
  width: 100%;
  max-width: 720px;
  height: ${styles.height.small};
  border: 1px solid ${styles.colors.lightGrey};
  border-radius: ${styles.borderRadius.small};
  padding-left: ${styles.padding.small};
  font-size: 1em;
  background-color: ${styles.colors.white};
`
