import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import styles from '../../constants/styles'
import { optimisedPath } from '../../utilities/format'

const DishCardImage = ({ dish }) => (
  <Container>
    {dish.image && dish.image.path ? (
      <Image src={optimisedPath(dish.image.path)} alt={dish.name} />
    ) : (
      <div style={{ backgroundColor: styles.colors.grey }} />
    )}
  </Container>
)

DishCardImage.propTypes = {
  dish: PropTypes.object.isRequired
}

export default DishCardImage

const Container = styled.div`
  width: 48px;
  min-width: 48px;
  height: 48px;
  border-radius: ${styles.borderRadius.small};
  overflow: hidden;
  background-color: ${styles.colors.darkGrey};
`

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`
