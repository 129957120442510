import React from 'react'
import styled from 'styled-components'

const RestaurantLogoSvg = () => (
  <SvgContainer>
    <svg width="23" height="20" viewBox="0 0 23 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M21.3778 4.75C21.2657 4.175 20.7549 3.75 20.1569 3.75H2.26734C1.66936 3.75 1.15859 4.175 1.04646 4.75L0 10V11.25C0 11.9375 0.560606 12.5 1.24579 12.5V18.75C1.24579 19.4375 1.8064 20 2.49158 20H12.4579C13.1431 20 13.7037 19.4375 13.7037 18.75V12.5H18.6869V18.75C18.6869 19.4375 19.2475 20 19.9327 20C20.6178 20 21.1785 19.4375 21.1785 18.75V12.5C21.8636 12.5 22.4242 11.9375 22.4242 11.25V10L21.3778 4.75ZM11.2121 17.5H3.73737V12.5H11.2121V17.5ZM2.49158 2.5H19.9327C20.6178 2.5 21.1785 1.9375 21.1785 1.25C21.1785 0.5625 20.6178 0 19.9327 0H2.49158C1.8064 0 1.24579 0.5625 1.24579 1.25C1.24579 1.9375 1.8064 2.5 2.49158 2.5Z" fill="white" />
    </svg>
  </SvgContainer>
)

const SvgContainer = styled.span`
  height: fit-content;
  width: fit-content;
  padding-top: 5px;
`
export default RestaurantLogoSvg
