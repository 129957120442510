class Auth {
  // authenticate a user - save a token string in Local Storage
  static authenticateUser(token) {
    window.localStorage.setItem('token', token)
  }

  // save user's username
  static saveUsername(username) {
    window.localStorage.setItem('username', username)
  }

  // // save user's restaurant _id
  static saveRestaurantID(restaurantID) {
    window.localStorage.setItem('restaurantID', restaurantID)
  }

  // save user's search location
  static saveUserSearch(country) {
    window.localStorage.setItem('search', country)
  }

  // check if a user is authenticated - check if a token is saved in Local Storage
  static isUserAuthenticated() {
    return window.localStorage.getItem('token') !== null
  }

  // deauthenticate a user - remove a token from Local Storage
  static deauthenticateUser() {
    window.localStorage.removeItem('token')
  }

  // remove user's username
  static removeUsername() {
    window.localStorage.removeItem('username')
  }

  // remove user's userID
  static removeUserID() {
    window.localStorage.removeItem('userID')
  }

  // get token
  static getToken() {
    return window.localStorage.getItem('token')
  }

  // get user
  static getUsername() {
    return window.localStorage.getItem('username')
  }

  // get userID
  static getRestaurantID() {
    return window.localStorage.getItem('restaurantID')
  }

  // get user's home
  static getUserSearch() {
    return window.localStorage.getItem('search')
  }
}

export default Auth
