const press = [
  {
    org: 'BBC',
    logo:
      'https://res.cloudinary.com/abillionveg/image/upload/v1579848802/press%20logos/bbc_logo.png',
    link:
      'https://drive.google.com/file/d/1UZ-dnA636vvx0yetVtmfatx55FW_6AW1/view',
    title: 'Wellness in the workplace',
    snippet:
      'Companies are thinking about how you make the workplace a great place for people.'
  },
  {
    org: 'CNBC',
    logo:
      'https://res.cloudinary.com/abillionveg/image/upload/v1541657017/press%20logos/CNBC_logo_horizontal-700x110_qyszmy.png',
    link:
      'https://www.cnbc.com/2018/07/13/how-start-ups-position-healthy-food-to-make-a-lot-of-business-sense.html',
    title:
      'How start-ups are positioning healthy food to make \'a lot of business sense\'',
    snippet:
      'As part of that movement to capture interest in the space, Singaporean start-up abillionveg launched an app this year that allows users to create reviews of restaurants’ plant-based dishes.'
  },
  {
    org: 'Bloomberg',
    logo:
      'https://res.cloudinary.com/abillionveg/image/upload/v1572848735/press%20logos/bloomberg-logo.jpg',
    link:
      'https://www.bloomberg.com/news/videos/2019-11-01/a-billion-vegans-by-2030-video',
    title:
      'Looking for a vegan meal near you?',
    snippet:
      '@abillionveg founder Vikas Garg tells @RosalieEsilva how the review platform wants to inspire a billion people to go plant-based by 2030.'
  },
  {
    org: 'Tech In Asia',
    logo:
      'https://res.cloudinary.com/abillionveg/image/upload/v1566468077/press%20logos/tech_in_asia_logo_1.png',
    link:
      'https://www.techinasia.com/vegan-food-review-platform-abillionveg-banks-2m-seed-500-startups',
    title: 'Vegan food review platform banks $2m in seed round led by 500 Startups',
    snippet:
      'abillionveg, a Singapore-based reviews and recommendations platform for vegan-friendly food, restaurants, and products, raised US$2 million in a seed round led by US-based 500 Startups.'
  }
]

export default press
