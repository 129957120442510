import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Logo from './Logo'
import ViewerAvatar from './ViewerAvatar'
import styles from '../../constants/styles'

const TopNav = ({ viewer }) => (
  <Container>
    <Web>
      <LogoDiv>
        <Logo height="64px" />
      </LogoDiv>
    </Web>

    <AvatarDiv style={{ justifyContent: 'flex-end' }}>
      {viewer._id && (
        <div style={{ cursor: 'pointer' }}>
          <ViewerAvatar />
        </div>
      )}
    </AvatarDiv>
  </Container>
)

TopNav.propTypes = {
  viewer: PropTypes.shape({ _id: PropTypes.string }).isRequired
}

export default TopNav

const Container = styled.nav`
  display: none;

  @media (min-width: 600px) {
    width: 100%;
    height: 64px;
    max-height: 64px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 30;
    background-color: ${styles.colors.white};
  }
`

const Web = styled.div`
  width: 100%;
  display: none;

  @media (min-width: 601px) {
    display: flex;
  }
`

const LogoDiv = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0 ${styles.padding.medium};
  cursor: pointer;
  @media (min-width: 601px) {
    padding: 0;
    justify-content: center;
    width: 200px;
  }
`

const AvatarDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 ${styles.padding.medium};
`
