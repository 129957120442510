import { combineReducers } from 'redux'
import * as actions from '../actions'
import Auth from '../modules/Auth'

const isLoggedIn = (state = Auth.isUserAuthenticated() || false, action) => {
  switch (action.type) {
    case actions.LOG_IN:
      return true
    case actions.LOG_OUT:
      return false
    default:
      return state
  }
}

const modalActive = (state = false, action) => {
  switch (action.type) {
    case actions.HANDLE_AUTH_MODAL:
      return action.active
    default:
      return state
  }
}

export default combineReducers({
  isLoggedIn,
  modalActive
})
