import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import RestaurantSearchCard from '../business/RestaurantSearchCard'
import styles from '../../constants/styles'

class Selected extends Component {
  constructor(props) {
    super(props)
    this.state = {
      show: false
    }
  }

  render() {
    const { claimed, handleClaim } = this.props
    const { show } = this.state
    return (
      <Container active={claimed.length > 0}>
        <HeaderContainer active={claimed.length > 0}>
          <ExpandableHeaderDiv>
            <div style={{ width: '80px' }} />
            <SelectedTextDiv>
              <span>
                {claimed.length}
                {' '}
selected
              </span>
            </SelectedTextDiv>
            <div style={{ width: '80px' }}>
              {claimed.length > 0
                && (
                <ShowHideToggle onClick={() => this.setState({ show: !show })}>
                  <span style={{ marginRight: styles.margin.small }}>
                    {show ? 'Hide' : 'Show'}
                  </span>
                  <i className={show ? 'fa fa-angle-up' : 'fa fa-angle-down'} style={{ fontSize: '1.5em' }} />
                </ShowHideToggle>
                )
              }
            </div>
          </ExpandableHeaderDiv>
        </HeaderContainer>
        {show
          && claimed.length > 0 && (
            <SelectedContainer>
              {claimed.map(rest => (
                <RestaurantSearchCard
                  restaurant={rest}
                  fromSelectedComponent
                  handleClaim={handleClaim}
                  key={rest._id}
                />
              ))}
            </SelectedContainer>
        )}
      </Container>
    )
  }
}

export default Selected

Selected.propTypes = {
  claimed: PropTypes.arrayOf(PropTypes.object).isRequired,
  handleClaim: PropTypes.func.isRequired
}

const Container = styled.div`
  margin: ${styles.margin.medium} 0;
  display: flex;
  flex-direction: column;
  border: ${props => (props.active ? `1px solid ${styles.colors.omnivore}` : `1px solid ${styles.colors.lightGrey}`)};
  border-radius: ${styles.borderRadius.small};
  overflow: hidden;
`

const HeaderContainer = styled.div`
  width: 100%;
  padding: ${styles.padding.small};
  background: ${props => (props.active ? styles.colors.omnivore : styles.colors.white)};
  color: ${props => (props.active ? styles.colors.white : styles.colors.black)};
`

const ExpandableHeaderDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 ${styles.padding.small};
`

const SelectedTextDiv = styled.div`
  padding: ${styles.padding.small};
  display: flex;
  align-items: center;
  justify-content: center;
`
const ShowHideToggle = styled.div`
  padding: ${styles.padding.small};
  cursor: pointer;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
`

const SelectedContainer = styled.div`
  width: 100%;
  padding: ${styles.padding.small};
  padding-top: ${styles.padding.tiny};
`
