import { combineReducers } from 'redux'
import * as actions from '../actions'
import constants from '../constants/constants'

const all = (state = {}, action) => {
  switch (action.type) {
    case actions.RECEIVED_RESTAURANTS_INDEX:
      return {
        ...state,
        ...action.data.reduce((obj, restaurant) => {
          obj[restaurant._id] = Object.assign({}, restaurant, {
            dishes: restaurant.dishes.map((d) => {
              if (typeof d === 'string') {
                return d
              }
              return d._id
            })
          })
          return obj
        }, {})
      }

    case actions.UPDATED_RESTAURANTS:
      return {
        ...state,
        [action._id]: { ...state[action._id], ...action.value }
      }
    case actions.UPDATED_DISHES:
      switch (true) {
        case !!action.value.restaurants:
          const changed = Object.assign({}, state)
          for (const key in changed) {
            if (action.value.restaurants.includes(key)) {
              changed[key].dishes = Array.from(
                new Set(changed[key].dishes.concat(action._id))
              )
            } else {
              changed[key].dishes = changed[key].dishes.filter(
                _id => _id !== action._id
              )
            }
          }
          return changed
        default:
          return state
      }
    case actions.RESTAURANT_UPDATE_LOADING:
      return {
        ...state,
        [action._id]: {
          ...state[action._id],
          updateStatus: {
            ...state[action._id].updateStatus,
            [action.field]: constants.lists.updateStatus.LOADING
          }
        }
      }
    case actions.RESTAURANT_UPDATE_COMPLETE:
      return {
        ...state,
        [action._id]: {
          ...state[action._id],
          updateStatus: {
            ...state[action._id].updateStatus,
            [action.field]: constants.lists.updateStatus.COMPLETE
          }
        }
      }
    case actions.RESTAURANT_UPDATE_ERROR:
      return {
        ...state,
        [action._id]: {
          ...state[action._id],
          updateStatus: {
            ...state[action._id].updateStatus,
            [action.field]: constants.lists.updateStatus.ERROR
          }
        }
      }
    case actions.CLEAR_RESTAURANT_EDITING:
      return {
        ...state,
        [action._id]: {
          ...state[action._id],
          updateStatus: {}
        }
      }
    case actions.LOG_OUT:
      return {}
    default:
      return state
  }
}

const defaultState = {
  isLoading: false,
  items: [],
  error: false,
  fetched: false,
  sort: 'name'
}

const index = (state = defaultState, action) => {
  switch (action.type) {
    case actions.FETCHING_RESTAURANTS_INDEX:
      return {
        ...state,
        isLoading: true,
        error: false
      }
    case actions.RECEIVED_RESTAURANTS_INDEX:

      return {
        ...state,
        isLoading: false,
        items: action.data.map(item => item._id),
        error: false,
        fetched: true
      }
    case actions.ERROR_FETCHING_RESTAURANTS_INDEX:
      return {
        ...state,
        isLoading: false,
        error: true
      }
    case actions.UPDATE_RESTAURANTS_SORT:
      return {
        ...state,
        sort: action.sort
      }
    case actions.LOG_OUT:
      return defaultState
    default:
      return state
  }
}

export default combineReducers({
  all,
  index
})
