import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'

const def = {
  title: 'abillion for Business',
  url: 'https://business.abillion.com/',
  image:
    'https://res.cloudinary.com/abillionveg/image/upload/v1526618992/abillionveg-splash_m0y2es.png',
  description:
    'Get feedback and engage with customers by claiming your listing. Upload your menu and details so customers find you easier. Creating a business page is free.',
  keywords:
    'business, claim business listing, restaurant owners, manage menu and dishes, customer reviews, vegan, plant-based'
}

const Meta = ({ meta }) => (
  <Helmet>
    <title>{meta.title || def.title}</title>
    <meta property="og:title" content={meta.title || def.title} />
    <meta property="og:url" content={meta.url || def.url} />
    <meta property="og:image" content={meta.image || def.image} />
    <meta name="description" content={meta.description || def.description} />
    <meta
      property="og:description"
      content={meta.description || def.description}
    />

    <meta name="keywords" content={meta.keywords || def.keywords} />

    <meta name="twitter:title" content={meta.title || def.title} />
    <meta name="twitter:image:src" content={meta.image || def.image} />
    <meta
      name="twitter:description"
      content={meta.description || def.description}
    />
    <link rel="canonical" href={meta.url || def.url} />
  </Helmet>
)

Meta.propTypes = {
  meta: PropTypes.objectOf(PropTypes.many).isRequired
}

export default Meta
