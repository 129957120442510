import React from 'react'
import styled from 'styled-components'
import allCopy from '../../constants/copy'
import styles from '../../constants/styles'
import press from '../../constants/pressList'

const copy = allCopy.en.landing

const Press = () => (
  <FullWidthSection>
    <CentralColumn
      style={{
        textAlign: 'center'
      }}
    >
      <PressHeadline>
        {copy.press.headline}
      </PressHeadline>
      <PressContainer>
        {press.slice(0, 4).map(n => (
          <PressCard
            key={n.link}
            href={n.link}
            target="_blank"
            rel="noopener noreferrer"
          >
            <PressLogo src={n.logo} />
          </PressCard>
        ))}
      </PressContainer>
    </CentralColumn>
  </FullWidthSection>
)

const FullWidthSection = styled.div`
  width: 100%;
  margin-top: 48px;
`
const CentralColumn = styled.div`
  width: 100vw;
  max-width: 1200px;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  margin: ${styles.margin.large} auto;
  @media (max-width: 450px) {
    margin: auto;
  }
`
const PressContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  width: 80vw;
  margin: 16px 0px;
  @media (min-width: 1300px) {
    width: 70vw;
  }
`

const PressHeadline = styled.h2`
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  line-height: 56px;
  font-size: 44px;
  @media (min-width: 768px) and (max-width: 900px) {
    font-size: 40px;
  }
  @media (max-width: 1023px) {
    text-align: center;
    margin-top: 48px;
  }
  @media (max-width: 600px) {
    font-size: 26px;
    line-height: 32px;
  }
`
const PressCard = styled.a`
  width: calc(100% / 5);
  display: flex;
  justify-content: center;
  height: 8vh;
  @media (max-width: 600px) {
    width: calc(100% / 2);
  }
`
const PressLogo = styled.img`
  width: 120px;
  object-fit: contain;
  margin: 0 20px;
  @media (min-width: 800px) {
    width: 80%;
  }
`

export default Press
