import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Logo from './Logo'
import LogIn from '../auth/LogIn'
import styles from '../../constants/styles'
import color from '../../constants/colors'

const LandingTopNav = ({
  authModalActive,
  handleAuthModal,
  handleLogin,
  scrolledPast
}) => (
  <Container scrolledPast={scrolledPast}>
    <Logo height="64px" />

    <AuthDiv>
      <a
        href="https://brands.abillion.com/login"
        target="_blank"
        rel="noopener noreferrer"
      >
        <LogInButton type="button">
          <span>BRAND LOG IN</span>
        </LogInButton>
      </a>
      <LogInButton type="button" onClick={() => handleAuthModal(true)}>
        <span>RESTAURANT LOG IN</span>
      </LogInButton>
    </AuthDiv>

    {authModalActive && (
      <LogIn
        handleLogin={handleLogin}
        closeAuthModal={() => handleAuthModal(false)}
      />
    )}
  </Container>
)

LandingTopNav.propTypes = {
  handleAuthModal: PropTypes.func.isRequired,
  handleLogin: PropTypes.func.isRequired,
  authModalActive: PropTypes.bool.isRequired,
  scrolledPast: PropTypes.bool.isRequired
}

export default LandingTopNav

const Container = styled.nav`
  width: 100%;
  height: 72px;
  position: ${(props) => (props.scrolledPast ? 'fixed' : 'absolute')};
  top: 0;
  left: 0;
  z-index: 20;
  background-color: ${(props) =>
    props.scrolledPast || window.location.pathname !== '/'
      ? styles.colors.white
      : 'transparent'};
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  align-items: center;
  padding: 0px ${styles.margin.medium};
`

const AuthDiv = styled.div`
  ${''} display: flex;
  flex-flow: column;
  justify-content: flex-end;
  align-items: flex-end;
  flex-wrap: wrap;

  @media (min-width: 601px) {
    flex-flow: row;
    align-items: center;
  }
`
const LogInButton = styled.button`
  border: 1px solid ${color.liquorice[100]};
  color: ${color.liquorice[100]};
  padding: 3px 12px;
  border-radius: 20px;
  cursor: pointer;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 9px;
  line-height: 16px;
  text-align: center;
  text-transform: uppercase;
  margin-top: 8px;

  @media (min-width: 601px) {
    font-size: 13px;
    margin-left: 8px;
    margin-top: 0px;
  }
`
