// Photo for Review Card
import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import styles from '../../constants/styles'
import { optimisedPath } from '../../utilities/format'

const ReviewPhoto = ({ images, index, handleIndex }) => (
  <ReviewPhotoContainer>
    <Photos>
      {images.map((image, i) => (
        <Image
          key={image._id}
          src={optimisedPath(image.path)}
          alt="review"
          index={i}
        />
      ))}
    </Photos>

    {images.length > 1 && (
      <Count>
        <Arrow
          active={index > 0}
          onClick={() => handleIndex(Math.max(0, index - 1))}
        >
          <i className="fa fa-arrow-circle-left" aria-hidden="true" />
        </Arrow>

        <Arrow
          active={index < images.length - 1}
          onClick={() => handleIndex(Math.min(images.length - 1, index + 1))}
        >
          <i className="fa fa-arrow-circle-right" aria-hidden="true" />
        </Arrow>
      </Count>
    )}

    {images.length > 1 && (
      <DotList>
        {images.map((image, i) => (
          <Dot
            key={i}
            active={index === i}
            onClick={() => handleIndex(i)}
          />
        ))}
      </DotList>
    )}
  </ReviewPhotoContainer>
)

ReviewPhoto.propTypes = {
  images: PropTypes.arrayOf(PropTypes.object).isRequired,
  index: PropTypes.number.isRequired,
  handleIndex: PropTypes.func.isRequired,
}

const ReviewPhotoContainer = styled.div`
  width: 100%;
  position: relative;
  overflow: hidden;
`

const Photos = styled.div`
  width: 400px;
  height: 400px;
  display: flex;
  overflow: hidden;

  @media (max-width: 900px) {
    width: 350px;
    height: 350px;
  }
  @media (max-width: 800px) {
    width: calc(100vw - 24px);
    height: calc(100vw - 24px);
  }
`

const Image = styled.img`
  width: 100%;
  min-width: 100%;
  height: 100%;
  min-height: 100%;
  object-fit: cover;
  background-color: ${styles.colors.lightGrey};
  position: relative;
  left: ${(props) => `calc(${props.index} * -(100vw) +24px)`};

  @media (min-width: 850px) {
    left: ${(props) => `calc(${props.index} * -400px)`};
  }
`

const Count = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 ${styles.padding.medium};
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
`

const Arrow = styled.div`
  font-size: 1.5em;
  color: ${(props) => (props.active ? styles.colors.white : 'transparent')};
  &:hover {
    cursor: ${(props) => (props.active ? 'pointer' : 'default')};
    color: ${(props) =>
      props.active ? styles.colors.omnivore : 'transparent'};
  }
`

const DotList = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: ${styles.margin.medium};
  z-index: 10;
`

const Dot = styled.div`
  width: 8px;
  height 8px;
  border-radius: 50%;
  margin: 0 4px;
  background-color: ${(props) =>
    props.active ? styles.colors.omnivore : styles.colors.white};
  cursor: pointer;
`

export default ReviewPhoto
