import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import styles from '../../constants/styles'
import { optimisedPath } from '../../utilities/format'

const PhotoDisplay = ({ image, dishID }) => (
  <Container>
    {!!image && !!image.path ? (
      <Image src={optimisedPath(image.path)} alt={image.path} />
    ) : (
      <ImageCTA>No image</ImageCTA>
    )}

    <EditAndAdd to={`/dishes/${dishID}/images`}>
      <i className="far fa-images" />
&nbsp;Add/edit images
    </EditAndAdd>
  </Container>
)

export default PhotoDisplay

PhotoDisplay.propTypes = {
  image: PropTypes.object,
  dishID: PropTypes.string.isRequired
}

const Container = styled.div`
  width: 100%;
  height: 100vw;
  max-width: 400px;
  position: relative;

  @media (min-width: 400px) {
    height: 400px;
  }
  @media (min-width: 800px) {
    min-width: 400px;
  }
`

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`

const ImageCTA = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  background-color: ${styles.colors.lightGrey};
`

const EditAndAdd = styled(Link)`
  width: 80%;
  height: ${styles.height.small};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: ${styles.borderRadius.small};
  background-color: ${styles.colors.omnivore};
  color: ${styles.colors.white};
  position: absolute;
  bottom: 12px;
  left: 10%;
  z-index: 10;
  cursor: pointer;
  &:hover {
    background-color: ${styles.colors.white};
    color: ${styles.colors.omnivore};
  }
`
