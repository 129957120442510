import React, { Component } from 'react'
import axios from 'axios'
import { connect } from 'react-redux'
// import * as actions from '../../actions'
import querystring from 'querystring'
import styled from 'styled-components'
import constants from '../../constants/constants'
import styles from '../../constants/styles'

class ContactUs extends Component {
  constructor(props) {
    super(props)
    this.state = {
      errors: {
        email: '',
        name: '',
        message: ''
      },
      email: '',
      name: '',
      message: '',
      loading: false,
      successMsg: ''
    }
    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.checkForErrors = this.checkForErrors.bind(this)
  }

  componentDidMount() {
    window.scrollTo(0, 0)
  }

  checkForErrors() {
    const obj = this.state.errors
    let errors = false
    if (!this.props.business && !this.state.name) {
      obj.name = 'Your name is required'
      errors = true
    }
    if (!this.state.message) {
      obj.message = 'A message is required'
      errors = true
    }
    if (!this.props.business && !this.state.email) {
      obj.email = 'Your email is required'
      errors = true
    }
    if (
      this.state.email
      && !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        this.state.email
      )
    ) {
      obj.email = 'That email is invalid'
      errors = true
    }
    this.setState({ errors: obj })
    return errors
  }

  handleChange(type, value) {
    const obj = this.state
    obj[type] = value
    obj.errors[type] = ''
    this.setState(obj)
  }

  handleSubmit(e) {
    e.preventDefault()

    if (this.checkForErrors()) {
      return
    }
    if (this.state.loading) {
      return
    }

    this.setState({ loading: true })

    const feedback = {
      email: this.props.business.email || this.state.email,
      name: this.props.business.contactPerson || this.state.name,
      message: this.state.message
    }

    this.setState({
      loading: false,
      successMsg: 'Thank you, your feedback has been received!',
      message: ''
    })

    axios
      .post(constants.api.email.contact, querystring.stringify(feedback), {
        headers: { skip: true }
      })
      // .then(() => {
      //   console.log('Feedback was received')
      // })
      .catch((err) => {
        console.error(err)
        this.setState({
          loading: false,
          successMsg:
            'Oh no! There was an issue sending your message, please try again.'
        })
      })
  }

  render() {
    return (
      <MainContainer>
        <Form>
          <div style={{ marginBottom: styles.margin.medium }}>
            <h1>What can we help you with?</h1>
          </div>

          {!this.props.business && (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <Header>Name*</Header>
              <Cell error={this.state.errors.name}>
                <Input
                  type="text"
                  name="name"
                  placeholder="Your name"
                  value={this.state.name}
                  onChange={e => this.handleChange('name', e.target.value)}
                />
              </Cell>
              <ErrorDiv>{this.state.errors.name}</ErrorDiv>

              <Header>Email*</Header>
              <Cell error={this.state.errors.email}>
                <Input
                  type="text"
                  name="email"
                  placeholder="Email address"
                  value={this.state.email}
                  onChange={e => this.handleChange('email', e.target.value)}
                />
              </Cell>
              <ErrorDiv>{this.state.errors.email}</ErrorDiv>
            </div>
          )}

          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <CellTextArea error={this.state.errors.message}>
              <InputTextArea
                type="text"
                name="message"
                placeholder="Please be as detailed as possible"
                value={this.state.message}
                onChange={e => this.handleChange('message', e.target.value)}
              />
            </CellTextArea>
            <ErrorDiv>{this.state.errors.message}</ErrorDiv>
          </div>

          <EndDiv>
            <SubmitButton
              onClick={this.handleSubmit}
              autoFocus
              hasText={this.state.message.length > 0}
              submitted={this.state.successMsg.length > 0}
            >
              {this.state.loading ? (
                <i className="fa fa-spinner fa-spin fa-2x fa-fw" />
              ) : (
                <span>Submit</span>
              )}
            </SubmitButton>
          </EndDiv>

          <div
            style={{
              textAlign: 'center',
              marginTop: styles.margin.medium
            }}
          >
            {this.state.successMsg}
          </div>
        </Form>
      </MainContainer>
    )
  }
}

const mapStateToProps = state => ({
  business: state.business.all.details
})

const mapDispatchToProps = dispatch => ({
  //
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ContactUs)

const MainContainer = styled.main`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  ${''}
  padding: ${styles.padding.medium} 0;
`

const Form = styled.form`
  width: 100%;
  max-width: 720px;
  margin-top: 0;
  padding: ${styles.padding.medium} 0;
`

const Header = styled.div`
  width: 100%;
  font-weight: 600;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: ${styles.margin.small};
`

const Cell = styled.div`
  width: 100%;
  height: ${styles.height.small};
  background-color: ${styles.colors.white};
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border: 1px solid
    ${props => (props.errors ? styles.colors.action : styles.colors.grey)};
  border-radius: ${styles.borderRadius.small};
`

const CellTextArea = styled(Cell)`
  height: calc(1.5 * ${styles.height.large});
`

const Input = styled.input`
  width: 100%;
  height: ${styles.height.small};
  font-size: 1em;
  padding: ${styles.padding.medium};
  border: none;
  background-color: transparent;
`

const InputTextArea = styled.textarea`
  width: 100%;
  height: calc(1.5 * ${styles.height.large});
  font-size: 1em;
  padding: ${styles.padding.medium};
  border: none;
  background-color: transparent;
  font-size: 1em;
  resize: none;
`

const SubmitButton = styled.div`
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 ${styles.padding.medium};
  background-color: ${props => (props.hasText ? styles.colors.omnivore : styles.colors.grey)};
  color: ${styles.colors.white};
  border-radius: ${styles.borderRadius.small};
  &:hover {
    background-color: ${styles.colors.brand};
  }
  cursor: pointer;
`

const EndDiv = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`

const ErrorDiv = styled.div`
  width: 100%;
  text-align: left;
  color: ${styles.colors.action};
  margin-top: ${styles.margin.small};
  margin-bottom: ${styles.margin.medium};
  font-size: 0.8em;
`
