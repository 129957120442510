import { combineReducers } from 'redux'
import * as actions from '../actions'

const all = (state = {}, action) => {
  switch (action.type) {
    case actions.RECEIVED_NOTIFICATIONS_INDEX:
      return {
        ...state,
        ...action.data.reduce((obj, user) => {
          obj[user._id] = user
          return obj
        }, {})
      }
    case actions.UPDATED_NOTIFICATIONS:
      return {
        ...state,
        [action._id]: { ...state[action._id], ...action.value }
      }
    case actions.LOG_OUT:
    case actions.STOP_CHECKING_FOR_NOTIFICATIONS:
      return {}
    default:
      return state
  }
}

const defaultState = {
  isLoading: false,
  items: [],
  error: false,
  fetched: false
}

const index = (state = defaultState, action) => {
  switch (action.type) {
    case actions.FETCHING_NOTIFICATIONS_INDEX:
      return {
        ...state,
        isLoading: true,
        error: false
      }
    case actions.RECEIVED_NOTIFICATIONS_INDEX:
      return {
        ...state,
        isLoading: false,
        items: action.data
          .map(notification => notification._id),
        error: false,
        fetched: true
      }
    case actions.ERROR_FETCHING_NOTIFICATIONS_INDEX:
      return {
        ...state,
        isLoading: false,
        error: true
      }
    case actions.STOP_CHECKING_FOR_NOTIFICATIONS:
      return {
        isLoading: false,
        items: [],
        error: false,
        fetched: false
      }
    case actions.LOG_OUT:
      return defaultState
    default:
      return state
  }
}

export default combineReducers({
  all,
  index
})
