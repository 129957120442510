import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import styled from 'styled-components'
import * as actions from '../actions'
import Title from '../components/shared/Title'
import ActionBar from '../components/shared/ActionBar'
import UpdateStatus from '../components/shared/UpdateStatus'
import PhotoDisplay from '../components/dishes/PhotoDisplay'
import RequiredInput from '../components/shared/RequiredInput'
import EditPrice from '../components/dishes/EditPrice'
import EditDescription from '../components/dishes/EditDescription'
import EditIngredients from '../components/dishes/EditIngredients'
import EditAllergens from '../components/dishes/EditAllergens'
import EditLocations from '../components/dishes/EditLocations'
import styles from '../constants/styles'

class Dish extends Component {
  constructor(props) {
    super(props)
    this.containerRef = null
  }

  componentDidMount() {
    window.scrollTo(0, 0)
    const node = ReactDOM.findDOMNode(this.containerRef)
    if (node && window.innerWidth > 800) {
      node.scrollIntoView()
    }
  }

  render() {
    const { dishes, restaurants, updateDish, deleteDish } = this.props
    const dish = Object.keys(dishes.all).includes(this.props.match.params.id)
      ? dishes.all[this.props.match.params.id]
      : null

    if (dishes.index.fetched && !dish) {
      return <Redirect to="/dishes" />
    }
    return (
      <Container ref={(con) => (this.containerRef = con)}>
        <Title title={dish ? dish.name : 'Dish Details'} />
        <ActionBar backTo="/dishes" backToTitle="Dishes" />

        {!dishes.index.fetched && (
          <Loading>
            <div
              className="fas fa-spinner fa-spin"
              style={{ fontSize: '2em' }}
            />
            <h1>Fetching dish</h1>
          </Loading>
        )}

        {dishes.index.fetched && (
          <div style={{ marginTop: styles.margin.medium }}>
            <StatsBar>
              <StatBox>
                <StatHeaderScore>Overall Score</StatHeaderScore>
                <Stat style={{ display: 'flex', flexFlow: 'row' }}>
                  <Score>{dish.ratings?.average ?? 0}</Score>
                  /5
                </Stat>
              </StatBox>
              <StatBox noMargin>
                <StatHeader>Reviews</StatHeader>
                <Stat style={{ color: styles.colors.omnivore }}>
                  {dish.reviews || 0}
                </Stat>
              </StatBox>
            </StatsBar>

            <Field style={{ marginTop: styles.margin.small }}>
              <Label>IMAGES</Label>
              <PhotoDisplay image={dish.image} dishID={dish._id} />
            </Field>

            <Field>
              <Label>
                NAME*
                <UpdateStatus
                  status={dish.updateStatus ? dish.updateStatus.name : ''}
                />
              </Label>
              <RequiredInput
                defaultValue={dish.name}
                errorMessage="Dish name is required"
                onSubmit={(name) =>
                  updateDish({
                    _id: dish._id,
                    original: { name: dish.name },
                    value: { name },
                  })
                }
              />
            </Field>

            <Field>
              <Label>
                PRICE
                <UpdateStatus
                  status={
                    dish.updateStatus ? dish.updateStatus.officialPrice : ''
                  }
                />
              </Label>
              <EditPrice
                defaultPrice={dish.officialPrice || ''}
                updatePrice={(e) =>
                  updateDish({
                    _id: dish._id,
                    original: { officialPrice: dish.price },
                    value: { officialPrice: e.target.value },
                  })
                }
              />
            </Field>

            <Field>
              <Label>
                DESCRIPTION
                <UpdateStatus
                  status={
                    dish.updateStatus ? dish.updateStatus.description : ''
                  }
                />
              </Label>
              <EditDescription
                _id={dish._id}
                description={dish.description}
                updateDish={updateDish}
              />
            </Field>

            <Field>
              <Label>
                INGREDIENTS
                <UpdateStatus
                  status={
                    dish.updateStatus ? dish.updateStatus.ingredients : ''
                  }
                />
              </Label>
              <EditIngredients
                _id={dish._id}
                ingredients={dish.ingredients}
                updateDish={updateDish}
              />
            </Field>

            <Field>
              <Label>
                ALLERGENS
                <UpdateStatus
                  status={dish.updateStatus ? dish.updateStatus.allergens : ''}
                />
              </Label>
              <EditAllergens
                _id={dish._id}
                allergens={dish.allergens}
                updateDish={updateDish}
              />
            </Field>

            {restaurants.length > 0 ? (
              <Field>
                <Label>
                  LOCATIONS
                  <UpdateStatus
                    status={
                      dish.updateStatus ? dish.updateStatus.restaurants : ''
                    }
                  />
                </Label>
                <EditLocations
                  _id={dish._id}
                  allRestaurants={restaurants}
                  restaurants={dish.restaurants}
                  updateDish={updateDish}
                />
              </Field>
            ) : (
              <Field>
                Fetching restaurants&nbsp;
                <p className="fa fa-spinner fa-spin" />
              </Field>
            )}

            {dish.reviews === 0 && (
              <DeleteButton onClick={() => deleteDish(dish._id)}>
                Delete dish
              </DeleteButton>
            )}

            {dish.deleteStatus === 'loading' && (
              <DeleteModal>
                <i
                  className="fa fa-spinner fa-spin"
                  style={{ fontSize: '2em' }}
                />
                <h1>Deleting dish</h1>
              </DeleteModal>
            )}
          </div>
        )}
      </Container>
    )
  }
}

Dish.propTypes = {
  businessID: PropTypes.string,
  dishes: PropTypes.object.isRequired,
  restaurants: PropTypes.arrayOf(PropTypes.object).isRequired,
  updateDish: PropTypes.func.isRequired,
  deleteDish: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => ({
  businessID: state.business.all.details._id,
  dishes: state.dishes,
  restaurants: state.restaurants.index.items.map(
    (_id) => state.restaurants.all[_id]
  ),
})

const mapDispatchToProps = (dispatch) => ({
  updateDish: ({ _id, original, value, full }) => {
    dispatch(
      actions.dishes.update({
        _id,
        original,
        value,
        full,
      })
    )
  },
  deleteDish: (_id) => {
    dispatch(actions.dishes.deleteDish(_id))
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(Dish)

const Container = styled.main`
  width: 100%;
  padding: 0 ${styles.padding.medium};
`

const StatsBar = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 800px;

  @media (max-width: 600px) {
    flex-flow: row wrap;
    justify-content: flex-start;
  }

  @media (max-width: 420px) {
    flex-direction: column-reverse;
  }
`
const StatBox = styled.div`
  width: 49%;
  background: ${styles.colors.white};
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${styles.padding.small};

  @media (max-width: 600px) {
    width: 48%;
    margin-right: ${(props) => (props.noMargin ? 0 : '2%')};
  }

  @media (max-width: 420px) {
    margin-bottom: ${(props) => (props.noMargin ? styles.margin.small : 0)};
    width: 100%;
  }
`

const StatHeader = styled.span`
  font-size: 1em;
  font-weight: bold;
  margin-bottom: ${styles.margin.small};
  color: ${styles.colors.darkGrey};
`
const StatHeaderScore = styled(StatHeader)`
  cursor: pointer;
  &:hover {
    color: ${styles.colors.omnivore};
  }
`
const Stat = styled.span`
  font-size: 1.2em;
  font-weight: 500;
  color: ${styles.colors.black};
`

const Loading = styled.div`
  width: 100%;
  height: calc(100vh - 96px - 64px - 64px);
  font-weight: bold;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const Score = styled.div`
  color: ${styles.colors.omnivore};
`

const Field = styled.div`
  margin-bottom: calc(2 * ${styles.margin.medium});
`

const Label = styled.div`
  font-size: 0.9em;
  font-weight: 600;
  letter-spacing: 1px;
  margin-bottom: ${styles.margin.small};
  display: flex;
  justify-content: flex-start;
  align-items: center;
`

const DeleteButton = styled.div`
  width: 100%;
  max-width: 240px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1em;
  cursor: pointer;
  margin: ${styles.margin.small} 0;
  border-radius: ${styles.borderRadius.small};
  background-color: ${styles.colors.action};
  color: ${styles.colors.white};
`

const DeleteModal = styled.div`
  position: fixed;
  background-color: ${styles.colors.black}aa;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 50;
  color: ${styles.colors.white};
`
