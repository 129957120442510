import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import styles from '../../constants/styles'

const ActionButton = ({ ...props }) => (
  <Container onClick={props.onClick}>{props.children}</Container>
)

ActionButton.propTypes = {
  onClick: PropTypes.func
}

export default ActionButton

const Container = styled.div`
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid ${styles.colors.omnivore};
  background-color: ${styles.colors.omnivore};
  color: ${styles.colors.white};
  border-radius: ${styles.borderRadius.small};
  &:hover {
    background-color: ${styles.colors.white};
    color: ${styles.colors.omnivore};
  }
  cursor: pointer;
`
