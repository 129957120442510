import axios from 'axios'
import * as types from '../constants/actionTypes'
import constants from '../constants/constants'

export const updateBusinessField = ({
  _id, // business id
  field, // key
  value, // new value
  originalValue // value if revert is needed
}) => (dispatch) => {
  // set loading status
  dispatch({ type: types.EDITING_BUSINESS_FIELD, field })

  // update redux business.all.details
  dispatch({
    type: types.UPDATE_BUSINESS_FIELD,
    field,
    value
  })

  axios
    .put(constants.api.businesses + _id, {
      [field]: value
    })
    .then((res) => {
      if (res.data.success) {
        dispatch({ type: types.EDITED_BUSINESS_FIELD, field })
      } else {
        dispatch({
          type: types.UPDATE_BUSINESS_FIELD,
          field,
          originalValue
        })
        dispatch({ type: types.EDITED_BUSINESS_FIELD, field })
      }
    })
    .catch((err) => {
      console.error('err', err)
      dispatch({
        type: types.UPDATE_BUSINESS_FIELD,
        field,
        originalValue
      })
      dispatch({ type: types.EDITED_BUSINESS_FIELD, field })
    })
}

export const tempFunction = () => {}
