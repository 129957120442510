import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import axios from 'axios'
import { connect } from 'react-redux'
import styled from 'styled-components'
import constants from '../../constants/constants'
import Title from '../shared/Title'
import ActionBar from '../shared/ActionBar'
import * as actions from '../../actions'
import UserCard from './UserCard'
import * as types from '../../constants/actionTypes'
import styles from '../../constants/styles'

class AddUser extends Component {
  constructor(props) {
    super(props)
    this.state = {
      search: '',
      searching: false,
      searched: false,
      loading: false,
      found: {
        results: [],
        list: {}
      }
    }
    this.searchForUsers = this.searchForUsers.bind(this)
    this.handleAuthorisation = this.handleAuthorisation.bind(this)
    this.enterPressed = this.enterPressed.bind(this)
  }

  componentDidMount() {
    window.scroll(0, 0)
  }

  searchForUsers() {
    if (this.state.search.length === 0) {
      return
    }
    this.setState({ searching: true, searched: false })
    axios
      .get(`${constants.api.users}query/name=${this.state.search}`)
      .then((res) => {
        if (res.data.length > 0) {
          res.data.forEach((user) => {
            if (!this.state.found.results.includes(user._id)) {
              const obj = {}
              obj[user._id] = user
              this.setState({
                searching: false,
                searched: true,
                found: {
                  results: this.state.found.results.concat(user._id),
                  list: { ...this.state.found.list, ...obj }
                }
              })
            }
          })
        } else {
          this.setState({ searched: true, searching: false })
        }
      })
      .catch(err => console.error(err))
  }

  handleAuthorisation(id, type) {
    const { viewer, business, addUser } = this.props
    const { loading } = this.state
    if (
      id === viewer._id
      || loading
      || !business.all.details
      || !business.all.details._id
    ) {
      return
    }
    this.setState({ loading: id })
    axios
      .put(`${constants.api.businesses + business.all.details._id}/users`, {
        type,
        user: id
      })
      .then((res) => {
        this.setState({ loading: false })
        if (res.data.success) {
          const found = { ...this.state.found }
          if (found.list[id]) {
            found.list[id].businesses = type === 'add'
              ? found.list[id].businesses
                .filter(b => b !== business.all.details._id)
                .concat(business.all.details._id)
              : found.list[id].businesses.filter(
                b => b !== business.all.details._id
              )
            this.setState({ found })
          }
          addUser(id, found.list[id])
        }
      })
      .catch((err) => {
        this.setState({ loading: false })
        console.error(err)
      })
  }

  enterPressed(event) {
    const code = event.keyCode || event.which
    if (code === 13) {
      // 13 is the "enter" button keycode
      ReactDOM.findDOMNode(this.searchButton).click()
    }
  }

  render() {
    const { users, business } = this.props
    const mappedUsers = users.index.items.map(_id => users.all[_id])
    const { found } = this.state
    const businessID = business.all.details && business.all.details._id
      ? business.all.details._id
      : ''

    return (
      <Container>
        <Title title="Add User" />

        <ActionBar backTo="/users" backToTitle="Users" />
        <SearchDiv>
          <SearchInput
            type="text"
            autoFocus
            onKeyPress={this.enterPressed}
            placeholder="Username, name or email"
            value={this.state.search}
            onChange={e => this.setState({ search: e.target.value })}
          />
          <SearchButton
            ref={button => (this.searchButton = button)}
            onClick={() => this.searchForUsers()}
          >
            {this.state.searching ? (
              <i className="fas fa-spinner fa-spin" />
            ) : (
              'Search'
            )}
          </SearchButton>
        </SearchDiv>

        <OverflowContainer>
          {!this.state.searching
            && this.state.search.length > 0
            && this.state.searched
            && found.results.length === 0 && (
              <div>
                <b>No users found</b>
              </div>
          )}

          {!this.state.searching
            && this.state.search.length > 0
            && found.results
              .filter(
                user => found.list[user].username.includes(
                  this.state.search.toLowerCase().trim()
                )
                  || found.list[user].name
                    .toLowerCase()
                    .includes(this.state.search.toLowerCase().trim())
                  || found.list[user].email.includes(
                    this.state.search.toLowerCase().trim()
                  )
              )
              .sort((a, b) => {
                const first = found.list[a].username.toLowerCase()
                const second = found.list[b].username.toLowerCase()
                if (first < second) {
                  return -1
                }
                if (first > second) {
                  return 1
                }
                return 0
              })
              .map(user => (
                <UserCardContainer key={user}>
                  <UserCard user={found.list[user]} />
                  <RightSection>
                    {this.state.loading === user ? (
                      <i className="fas fa-spinner fa-spin" />
                    ) : found.list[user].businesses.includes(businessID) ? (
                      mappedUsers.includes(user) ? (
                        <span style={{ color: styles.colors.action }}>
                          Authorised
                        </span>
                      ) : (
                        <span style={{ color: styles.colors.grey }}>Admin</span>
                      )
                    ) : (
                      <Authorise
                        onClick={() => this.handleAuthorisation(user, 'add')}
                      >
                        Authorise
                      </Authorise>
                    )}
                  </RightSection>
                </UserCardContainer>
              ))}
        </OverflowContainer>
      </Container>
    )
  }
}

AddUser.propTypes = {
  viewer: PropTypes.objectOf(PropTypes.any).isRequired,
  users: PropTypes.objectOf(PropTypes.any).isRequired,
  business: PropTypes.objectOf(PropTypes.any).isRequired,
  addUser: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
  viewer: state.users.viewer._id ? state.users.all[state.users.viewer._id] : {},
  users: state.users,
  business: state.business
})

const mapDispatchToProps = dispatch => ({
  addUser: (_id, value) => {
    dispatch(actions.updateField({ type: 'users', id: _id, value }))
    dispatch({ type: types.ADD_USER, data: value })
  }
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddUser)

const Container = styled.main`
  padding: 0 ${styles.padding.medium};
`

const OverflowContainer = styled.div`
  height: calc(100vh - 96px - 64px - 42px - 2 * ${styles.margin.small});
  overflow: scroll;

  @media (max-width: 800px) {
    height: calc(
      100vh - 96px - 64px - 64px - 42px - 2 * ${styles.margin.small}
    );
  }
`
const RightSection = styled.div`
  margin-left: ${styles.margin.small};

  @media (max-width: 600px) {
    margin-left: 0;
    margin-top: ${styles.margin.small};
  }
`
const SearchDiv = styled.div`
  width: 100%;
  max-width: 720px;
  display: flex;
  flex-flow: row;
  border-radius: ${styles.borderRadius.small};
  margin-bottom: ${styles.margin.small};
`

const SearchInput = styled.input`
  padding: ${styles.padding.medium};
  border-radius: ${styles.borderRadius.small};
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border: 1px solid ${styles.colors.grey};
  border-right: none;
  font-size: 1em;
  width: 85%;
`

const SearchButton = styled.div`
  width: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${styles.colors.omnivore};
  border: 1px solid ${styles.colors.omnivore};
  border-top-right-radius: ${styles.borderRadius.small};
  border-bottom-right-radius: ${styles.borderRadius.small};
  color: ${styles.colors.white};
  cursor: pointer;
`

const UserCardContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${styles.padding.medium};
  border-radius: ${styles.borderRadius.small};
  background-color: ${styles.colors.white};
  margin-bottom: ${styles.margin.small};

  @media (max-width: 600px) {
    flex-direction: column;
    align-items: flex-start;
  }
`

const Authorise = styled.div`
  padding: ${styles.padding.small};
  background-color: ${styles.colors.omnivore};
  border: 1px solid ${styles.colors.omnivore};
  color: ${styles.colors.white};
  border-radius: ${styles.borderRadius.small};
  cursor: pointer;

  &:hover {
    background-color: ${styles.colors.white};
    color: ${styles.colors.omnivore};
  }
`
