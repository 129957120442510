import React, { Component } from 'react'
import PropTypes from 'prop-types'
import axios from 'axios'
import styled from 'styled-components'
import constants from '../../constants/constants'
import styles from '../../constants/styles'

// claim log in doesnt use Auth. login logic is different from business login in TopNav.
class ClaimLogIn extends Component {
  constructor(props) {
    super(props)
    this.state = {
      email: '',
      password: '',
      showPassword: false,
      accessToken: '',
      errors: {
        email: '',
        password: ''
      },
      focus: {
        email: false,
        password: false
      },
      loading: false
    }
    this.handleChange = this.handleChange.bind(this)
    this.checkedForErrors = this.checkedForErrors.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleLogin = this.handleLogin.bind(this)
  }

  handleChange(category, value) {
    const obj = Object.assign({}, this.state)
    obj[category] = value
    obj.errors = {
      email: '',
      password: ''
    }
    this.setState(obj)
  }

  checkedForErrors() {
    let checked = true
    const obj = Object.assign({}, this.state.errors)
    if (this.state.password.length < 8 && !this.state.accessToken) {
      obj.password = 'The password should be at least 8 characters long'
      checked = false
    }
    if (
      !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        this.state.email
      )
    ) {
      obj.email = 'That email is not formatted correctly'
      checked = false
    }
    if (!this.state.email.length) {
      obj.email = 'Please fill in your email'
      checked = false
    }
    this.setState({ errors: obj })
    return checked
  }

  handleSubmit(e) {
    e.preventDefault()
    const user = {
      email: this.state.email,
      password: this.state.password
    }
    if (this.checkedForErrors()) {
      this.handleLogin(user)
    }
  }

  handleLogin(user) {
    this.setState({ loading: true })
    axios
      .post(
        constants.api.auth.login,
        user
      )
      .then((res) => {
        if (res.data.success) {
          const { token, user } = res.data
          this.props.handleLogin(token, user.username, user.claimPending)
          this.props.handleAuthModal('')
        }
      })
      .catch((err) => {
        console.error(err)
        this.setState({
          loading: false,
          errors: {
            email: 'Your email and/or your password is not valid',
            password: 'Your email and/or your password is not valid'
          }
        })
      })
  }

  render() {
    return (
      <Container>
        <Background onClick={() => this.props.handleAuthModal('')} />
        <FormContainer>
          <CloseIcon
            className="fa fa-fw fa-times"
            onClick={() => this.props.handleAuthModal('')}
          />

          <Form onSubmit={this.handleSubmit}>
            <Header>
              <Logo src={require('../../assets/abillion-logo-black.png')} />
            </Header>

            <InputDiv>
              <InputName>Email</InputName>
              <InputContainer
                error={this.state.errors.email}
                hasFocus={this.state.focus.email}
              >
                <InputText
                  type="email"
                  name="email"
                  value={this.state.email}
                  onFocus={() => this.setState({
                    focus: { ...this.state.focus, email: true }
                  })
                  }
                  onBlur={() => this.setState({
                    focus: { ...this.state.focus, email: false }
                  })
                  }
                  onChange={e => this.handleChange('email', e.target.value)}
                  placeholder="Your email address"
                />
              </InputContainer>
              <ErrorDiv>{this.state.errors.email}</ErrorDiv>
            </InputDiv>

            <InputDiv>
              <InputName>Password</InputName>
              <InputContainer
                error={this.state.errors.password}
                hasFocus={this.state.focus.password}
              >
                <InputText
                  type={this.state.showPassword ? 'text' : 'password'}
                  name="password"
                  value={this.state.password}
                  onFocus={() => this.setState({
                    focus: { ...this.state.focus, password: true }
                  })
                  }
                  onBlur={() => this.setState({
                    focus: { ...this.state.focus, password: false }
                  })
                  }
                  onChange={e => this.handleChange('password', e.target.value)}
                  placeholder="Minimum 8 characters"
                />
              </InputContainer>
              <ErrorDiv>{this.state.errors.password}</ErrorDiv>
              <ShowHidePassword
                onClick={() => this.setState({ showPassword: !this.state.showPassword })
                }
              >
                {this.state.showPassword ? 'Hide' : 'Show'}
                {' '}
                Password
              </ShowHidePassword>
            </InputDiv>

            <Submit onClick={this.handleSubmit}>
              <input
                type="submit"
                style={{ width: '0.1px', height: '0.1px' }}
              />
              {this.state.loading ? (
                <i className="fas fa-spinner fa-spin fa-2x" />
              ) : (
                <span>Log in</span>
              )}
            </Submit>
          </Form>
          <br />

          <NoAccount>
            <span>Don't have an account?</span>
            &nbsp;
            <ModalSwitch
              onClick={() => {
                this.props.handleAuthModal('signup')
              }}
            >
              <span>Sign Up</span>
            </ModalSwitch>
          </NoAccount>

          <p>
            <a href="https://www.abillion.com/forgot/">
              Forgot your password?
            </a>
          </p>
          <p>
            Other problems?
            {' '}
            <a href="mailto:contact@abillion.com">Contact us</a>
          </p>
        </FormContainer>
      </Container>
    )
  }
}

ClaimLogIn.propTypes = {
  handleLogin: PropTypes.func.isRequired
}

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 20;
`

const Background = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
`

const FormContainer = styled.div`
  position: relative;
  background: ${styles.colors.white};
  width: 480px;
  padding: ${styles.padding.large};
  z-index: 2;
`

const CloseIcon = styled.i`
  position: absolute;
  top: ${styles.margin.medium};
  left: ${styles.margin.medium};
  font-size: 1.5em;
  color: ${styles.colors.darkGrey};
  cursor: pointer;
`

const Header = styled.div`
  font-size: 1.8em;
  font-family: Nunito;
  display: flex;
  justify-content: center;
  align-items: center;
`

const Form = styled.form`
  max-width: 480px;
  width: 100%;
  display: flex;
  flex-flow: column;
  align-items: center;
`

const InputDiv = styled.div`
  margin-bottom: ${styles.margin.medium};
  width: 100%;
`
const InputName = styled.div`
  font-weight: 600;
  margin-bottom: ${styles.margin.small};
`
const InputContainer = styled.div`
  width: 100%;
  height: ${styles.height.small};
  display: flex;
  background-color: ${styles.colors.white};
  border: 1px solid
    ${props => (props.error
    ? styles.colors.action
    : props.hasFocus
      ? styles.colors.black
      : styles.colors.lightGrey)};
  border-radius: ${styles.borderRadius.small};
`
const InputText = styled.input`
  width: 100%;
  height: 100%;
  background-color: transparent;
  font-size: 1em;
  padding: ${styles.padding.medium};
`
const ErrorDiv = styled.span`
  color: ${styles.colors.action};
  margin-top: ${styles.margin.small};
  font-size: 0.8em;
`

const Submit = styled.div`
  width: 100%;
  height: ${styles.height.small};
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${styles.colors.action};
  color: ${styles.colors.white};
  cursor: pointer;
  border-radius: ${styles.borderRadius.small};
  font-weight: 600;
`

const Logo = styled.img`
  width: 178px;
  height: 50px;
  object-fit: contain;
`
const NoAccount = styled.div`
  width: 100%;
  max-width: 480px;
  font-size: 1.25em;
  padding: ${styles.padding.large} 0;
  font-weight: 100;
  text-align: center;
`

const ModalSwitch = styled.span`
  color: ${styles.colors.brand};
  text-decoration: none;
  cursor: pointer;
  font-weight: 300;
`

const ShowHidePassword = styled.span`
  display: block;
  text-align: right;
  color: ${styles.colors.omnivore};
  font-size: 0.8em;
  margin-top: ${styles.margin.small};
  cursor: pointer;
`
export default ClaimLogIn
