import axios from 'axios'
import {
  FETCHING_REVIEWS_INDEX,
  RECEIVED_REVIEWS_INDEX,
  ERROR_FETCHING_REVIEWS_INDEX,
  LIKE_REVIEW,
  UNLIKE_REVIEW,
} from './constants/reviews'
import constants from '../constants/constants'
import { updateField } from './index'

export const fetchReviewsAction = (restaurants) => (dispatch) => {
  dispatch({ type: FETCHING_REVIEWS_INDEX })
  axios
    .get(
      `${constants.api.reviews}query/sort=createdAt&ascending=false&restaurant=${restaurants}`
    )
    .then((res) => {
      dispatch({
        type: RECEIVED_REVIEWS_INDEX,
        data: res.data,
      })
    })
    .catch((err) => {
      dispatch({ type: ERROR_FETCHING_REVIEWS_INDEX })
    })
}

export const like =
  ({ review, viewer, toLike }) =>
  (dispatch) => {
    // return if viewer is not loaded yet
    if (!viewer._id || !review) return

    if (toLike) {
      dispatch({ type: LIKE_REVIEW, _id: review._id })
      axios.post(`${constants.api.reviews + review._id}/likes`, {
        platform: 'business',
      })
    } else {
      dispatch({ type: UNLIKE_REVIEW, _id: review._id })
      axios.delete(`${constants.api.reviews + review._id}/likes`, {
        platform: 'business',
      })
    }
  }

export const report =
  ({ review, viewer, text }) =>
  (dispatch) => {
    // return if viewer is not loaded yet
    if (!review || !viewer._id || !text) {
      return
    }
    axios.post(constants.api.reports, {
      review: review._id,
      type: 'review',
      text,
    })
  }

export const comment =
  ({ review, viewer, text }) =>
  (dispatch) => {
    // return if viewer is not loaded yet
    if (!review || !viewer._id || !text) {
      return
    }
    const comment = {}
    comment.review = review._id
    comment.text = text
    // comments from biz dashboard are always official, and need to hv restaurant id
    comment.restaurant = review.restaurant._id
    comment.receiver = review.responseTo
      ? review.responseTo.user._id
      : review.user._id

    comment.responseTo = review.responseTo
      ? review.responseTo.responseTo || review.responseTo._id
      : null
    dispatch(
      updateField({
        type: 'reviews',
        _id: review._id,
        value: { isLoading: true },
      })
    )

    axios
      .put(`${constants.api.reviews + review._id}/comments`, comment)
      .then((res) => {
        dispatch(
          updateField({
            type: 'reviews',
            _id: review._id,
            value: { isLoading: false },
          })
        )
        if (res.data.success) {
          // add metadata to the comment
          comment._id = res.data._id
          comment.user = viewer
          comment.createdAt = Date.now()
          comment.restaurant = review.restaurant
          comment.brand = null
          // comment.business = null

          dispatch(
            updateField({
              type: 'reviews',
              _id: review._id,
              value: {
                comments: review.comments.concat(comment),
                comment: '',
                responseTo: null,
              },
            })
          )
        }
      })
      .catch((error) => {
        console.error(error)

        dispatch(
          updateField({
            type: 'reviews',
            _id: review._id,
            value: { isLoading: false },
          })
        )
      })
  }
