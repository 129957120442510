const social = [
  {
    type: 'facebook',
    link: '//facebook.com/abillionapp',
    title: 'Follow us on Facebook',
    icon: 'facebook-f'
  },
  {
    type: 'instagram',
    link: '//instagram.com/abillion',
    title: 'Follow us on Instagram',
    icon: 'instagram'
  },
  {
    type: 'twitter',
    link: '//twitter.com/abillionapp',
    title: 'Follow us on Twitter',
    icon: 'twitter'
  },
  {
    type: 'linkedin',
    link: 'https://www.linkedin.com/company/abillion',
    title: 'Follow us on LinkedIn',
    icon: 'linkedin'
  },
  {
    type: 'apple',
    link: '//apps.apple.com/us/app/abillionveg/id1377119949?mt=8',
    title: 'Find us on the App Store',
    icon: 'apple'
  },
  {
    type: 'google',
    link: '//play.google.com/store/apps/details?id=com.abvmobile',
    title: 'Find us on Google Play',
    icon: 'android'
  }
]

export default social
