import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import ClaimLogIn from './ClaimLogIn'
import ClaimSignUp from './ClaimSignUp'
import allCopy from '../../constants/copy'
import styles from '../../constants/styles'

const copy = allCopy.en.claim.link

class Step3LinkAccount extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showAuthModal: '',
      username: ''
    }
    this.handleAuthModal = this.handleAuthModal.bind(this)
    this.handleLogin = this.handleLogin.bind(this)
  }

  componentDidMount() {
    window.scrollTo(0, 0)
  }

  handleAuthModal(type) {
    this.setState({ showAuthModal: type })
  }

  handleLogin(token, username, pending) {
    this.setState({ username })
    this.props.handleLogin(token, pending)
  }

  render() {
    return (
      <Container>
        <h2 style={{ fontSize: '2em' }}>
          {this.props.userToken
            ? copy.headline.connected
            : copy.headline.notConnected}
        </h2>

        {!this.props.userToken && (
          <AccountButtonDiv>
            <ConnectButton
              onClick={() => this.setState({ showAuthModal: 'login' })}
            >
              {copy.connectButton}
            </ConnectButton>
            <div
              style={{
                margin: `${styles.margin.medium} 0`,
                fontSize: '1.5em'
              }}
            >
              or
            </div>
            <Button onClick={() => this.setState({ showAuthModal: 'signup' })}>
              {copy.signupButton}
            </Button>
          </AccountButtonDiv>
        )}
        {this.props.userToken && (
          <ConnectedContainer>
            <i
              className="far fa-user-circle fa-5x"
              style={{ fontSize: '7em', color: styles.colors.black }}
            />
            <h1 style={{ color: styles.colors.black }}>
              <strong>{this.state.username}</strong>
            </h1>

            <ChangeAccount
              onClick={() => {
                this.setState({ username: '' })
                this.props.handleLogin('')
              }}
            >
              <span>CHANGE</span>
            </ChangeAccount>
          </ConnectedContainer>
        )}

        {!this.props.userToken && (
          <ExplanationDiv>
            <QuestionMarkDiv>
              <i
                className="far fa-question-circle fa-2x"
                style={{ color: styles.colors.darkGrey }}
              />
            </QuestionMarkDiv>
            <TextContainer>
              <h2 style={{ marginBottom: styles.margin.small }}>
                {copy.explain.headline}
              </h2>
              <h4 style={{ lineHeight: 1.5 }}>{copy.explain.text}</h4>
            </TextContainer>
          </ExplanationDiv>
        )}

        {this.props.userToken && this.props.claimPending && (
          <ExplanationDiv>
            <QuestionMarkDiv>
              <i
                className="fas fa-exclamation-circle fa-2x"
                style={{ color: styles.colors.darkGrey }}
              />
            </QuestionMarkDiv>
            <TextContainer>
              <h2 style={{ marginBottom: styles.margin.small }}>
                {copy.pending.text1}
              </h2>
              <h4 style={{ lineHeight: 1.5 }}>
                {' '}
                <a
                  href="https://abillion.com/contact"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ color: styles.colors.omnivore, fontWeight: 600 }}
                >
                  {copy.pending.contact}
                </a>
                {' '}
                {copy.pending.text2}
              </h4>
            </TextContainer>
          </ExplanationDiv>
        )}

        {this.state.showAuthModal === 'login' && (
          <ClaimLogIn
            handleAuthModal={type => this.handleAuthModal(type)}
            handleLogin={this.handleLogin}
          />
        )}
        {this.state.showAuthModal === 'signup' && (
          <ClaimSignUp
            handleAuthModal={type => this.handleAuthModal(type)}
            handleLogin={this.handleLogin}
          />
        )}
      </Container>
    )
  }
}

Step3LinkAccount.propTypes = {
  userToken: PropTypes.string.isRequired,
  claimPending: PropTypes.bool,
  handleLogin: PropTypes.func.isRequired
}

Step3LinkAccount.defaultProps = {
  claimPending: false
}

export default Step3LinkAccount

const Container = styled.div`
  width: 100%;
  max-width: 800px;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Button = styled.div`
  ${''} width: 320px;
  padding: ${styles.padding.medium};
  background: ${styles.colors.omnivore};
  border: 1px solid ${styles.colors.omnivore};
  border-radius: ${styles.borderRadius.small};
  color: ${styles.colors.white};
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: 1.5em;
`

const ConnectButton = styled(Button)`
  text-align: center;
  background: none;
  border: 1px solid ${styles.colors.omnivore};
  color: ${styles.colors.omnivore};
`

const AccountButtonDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: ${styles.margin.larger} 0;
`

const ConnectedContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: ${styles.margin.larger} 0;
  color: ${styles.colors.brand};
`

const ChangeAccount = styled.div`
  color: ${styles.colors.darkGrey};
  ${''}
  padding: ${styles.padding.medium};
  border-radius: ${styles.borderRadius.small};
  ${''}
  cursor: pointer;
`

const ExplanationDiv = styled.div`
  display: flex;
  max-width: 570px;
`
const QuestionMarkDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-right: ${styles.margin.small};
`
const TextContainer = styled.div`
  display: flex,
  flex-direction: column;
  text-align: justify;
`
