import React, { Component } from 'react'
import PropTypes from 'prop-types'

// For now, only catches any JS error that bubbles up from ReviewCard comp and sends an exception alert without triggering app restart
class ErrorBoundary extends Component {
  constructor(props) {
    super(props)
    this.state = { caughtByErrorBoundary: false }
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { caughtByErrorBoundary: true }
  }

  componentDidCatch(error) {
    // TODO: if we hv error logging in future
  }

  render() {
    const { children } = this.props
    const { caughtByErrorBoundary } = this.state
    if (caughtByErrorBoundary) {
      return <div />
    }
    return children
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
}

export default ErrorBoundary
