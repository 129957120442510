import { combineReducers } from 'redux'
import * as actions from '../actions'

const defaultState = {
  details: {},
  saving: {
    name: '',
    contactPerson: '',
    address: '',
    email: '',
    phone: ''
  },
  error: false,
  fetched: false
}

const all = (state = defaultState, action) => {
  switch (action.type) {
    case actions.RECEIVED_BUSINESS:
      return {
        ...state,
        details: action.data,
        fetched: true
      }
    case actions.ERROR_FETCHING_BUSINESS:
      return {
        ...state,
        error: true
      }
    case actions.UPDATE_BUSINESS_FIELD:
      return {
        ...state,
        details: {
          ...state.details,
          [action.field]: action.value
        }
      }
    case actions.EDITING_BUSINESS_FIELD:
      return {
        ...state,
        saving: {
          ...state.saving,
          [action.field]: true
        }
      }
    case actions.EDITED_BUSINESS_FIELD:
      return {
        ...state,
        saving: {
          ...state.saving,
          [action.field]: false
        }
      }
    case actions.CLEAR_BUSINESS_EDITING:
      return {
        ...state,
        saving: {
          name: '',
          contactPerson: '',
          address: '',
          email: '',
          phone: ''
        }
      }
    case actions.LOG_OUT:
      return defaultState
    default:
      return state
  }
}

export default combineReducers({
  all
})
