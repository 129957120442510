import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import axios from 'axios'
// import * as actions from '../../actions'
import styled from 'styled-components'
import Title from '../components/shared/Title'
import ActionBar from '../components/shared/ActionBar'
import Search from '../components/business/Search'
import styles from '../constants/styles'
import constants from '../constants/constants'

class AddRestaurant extends Component {
  constructor(props) {
    super(props)
    this.state = {
      query: '',
      country: 'selectCountry',
      loading: false,
      searched: false,
      restaurants: [],
      google: [],
      selectedRestaurant: null,
      showForm: false,
      name: '',
      formatted_address: '',
      remarks: '',
      error: {
        name: false,
        address: false
      },
      submitting: false,
      submitted: false
    }
    // this.handleSearch = this.handleSearch.bind(this)
    this.handleClaim = this.handleClaim.bind(this)
    this.containerRef = null
  }

  searchGoogle(query) {
    axios
      .get(
        `${constants.api.google.search}type=text&country=${this.state.country}&query=${this.state.query}`,
        { headers: { skip: 'true' } }
      )
      .then((res) => {
        this.setState({
          google: res.data.results || [],
          loading: false,
          searched: true
        })
        // check if google results contain restaurants in our database and replace them
        if (res.data.results.length > 0) {
          res.data.results
            .filter(
              google => !this.state.restaurants
                .map(rest => rest.google_place_id)
                .includes(google.place_id)
            )
            .forEach((rest) => {
              axios
                .get(constants.api.google.check + rest.place_id, {
                  headers: { skip: 'true' }
                })
                .then((response) => {
                  if (
                    !this.state.restaurants
                      .map(rest => rest._id)
                      .includes(response.data._id)
                  ) {
                    this.setState({
                      restaurants: this.state.restaurants.concat(response.data)
                    })
                  }
                })
                .catch((err) => {
                  console.error(err)
                })
            })
        } else {
          this.setState({
            loading: false,
            searched: true
          })
        }
      })
      .catch((err) => {
        console.error(err)
        this.setState({ loading: false })
      })
  }

  handleSearch({ query, country }) {
    if (!query || country === 'selectCountry') {
      console.log('empty query')
      return
    }
    // console.log('handlesearch', query, country)
    this.setState({
      loading: true,
      searched: false,
      restaurants: [],
      google: [],
      selectedRestaurant: null
    })

    axios
      .get(
        `${constants.api.restaurants}query/restaurantNames=${query}&country=${country}&limit=50`,
        { headers: { skip: 'true' } }
      )
      .then((res) => {
        this.setState({ restaurants: res.data })
        if (res.data.length < 7) {
          this.searchGoogle()
        } else {
          this.setState({
            loading: false,
            searched: true
          })
        }
      })
      .catch(err => console.error(err))
  }

  handleClaim(rest) {
    if (rest.business) return
    if (rest.claimPending) return

    this.setState({ selectedRestaurant: rest })
  }

  handleChange(value, category) {
    this.setState({ [category]: value })
  }

  handleSubmit() {
    if (this.state.selectedRestaurant) {
      this.submitRequest()
    } else {
      this.submitManual()
    }
  }

  submitRequest() {
    const { viewer, business } = this.props
    const { selectedRestaurant } = this.state
    this.setState(
      {
        submitting: true
      },
      () => {
        axios
          .post(constants.api.reports, {
            type: 'addRestaurant',
            text: `Name: ${viewer.name}. Email: ${viewer.email}. Restaurant name: ${selectedRestaurant.name}. Restaurant address: ${selectedRestaurant.formatted_address}. Remarks: ADD_RESTAURANT_TO_BUSINESS -- Add restaurant ${selectedRestaurant._id} to business ${business.all.details._id}`
          })
          .then(() => {
            this.setState({ submitting: false, submitted: true })
          })
          .catch((err) => {
            console.error('err', err)
            this.setState({ submitting: false, submitted: false })
          })
      }
    )
  }

  submitManual() {
    // validate fields
    const { viewer, business } = this.props
    const { name, formatted_address, remarks } = this.state
    this.setState({
      error: {
        name: !name.length,
        address: !formatted_address.length
      }
    })
    if (!name.length || !formatted_address.length) return

    this.setState({ submitting: true }, () => {
      axios
        .post(constants.api.reports, {
          type: 'addRestaurant',
          text: `Name: ${viewer.name}. Email: ${viewer.email}. Restaurant name: ${name}. Restaurant address: ${formatted_address}. Remarks: ADD_RESTAURANT_TO_BUSINESS -- Add resto to business ${business.all.details._id}. remarks -- ${remarks}`
        })
        .then(() => {
          this.setState({ submitting: false, submitted: true })
        })
        .catch((err) => {
          console.error('err', err)
          this.setState({ submitting: false, submitted: false })
        })
    })
  }

  backToSearch() {
    // return to search, search results
    this.setState({
      showForm: false,
      selectedRestaurant: null,
      name: '',
      formatted_address: '',
      remarks: '',
      error: {
        name: '',
        address: ''
      }
    })
    if (this.state.submitted) {
      this.setState({
        query: '',
        country: 'selectCountry',
        restaurants: [],
        google: [],
        submitting: false,
        submitted: false,
        loading: false,
        searched: false
      })
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0)
    const node = ReactDOM.findDOMNode(this.containerRef)
    if (node) {
      node.scrollIntoView()
    }
    if (this.props.restaurants.length) {
      this.setState({ country: this.props.restaurants[0].country })
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.restaurants !== this.props.restaurants) {
      if (nextProps.restaurants.length) {
        this.setState({ country: nextProps.restaurants[0].country })
      }
    }
  }

  render() {
    const {
      query,
      country,
      loading,
      searched,
      restaurants,
      selectedRestaurant,
      showForm,
      submitting,
      submitted
    } = this.state
    return (
      <Container ref={con => (this.containerRef = con)}>
        <Title title="Add a Restaurant" />

        {!showForm && !selectedRestaurant && (
          <ActionBar backTo="/restaurants" backToTitle="Restaurants" />
        )}
        {(showForm || selectedRestaurant) && (
          <BackBar>
            <BackButton onClick={() => this.backToSearch()}>
              <i className="fas fa-chevron-left" />
              &nbsp;
              {'Search for restaurants'}
            </BackButton>
          </BackBar>
        )}
        <div style={{ maxWidth: '720px' }}>
          {(selectedRestaurant || showForm) && (
            <p style={{ margin: `${styles.margin.medium} 0` }}>
              To add a restaurant to your business, you can submit a request for
              approval. We will verify the requested restaurant and get back to
              you as soon as possible.
            </p>
          )}

          {!selectedRestaurant && !showForm && (
            <Search
              query={query}
              country={country}
              handleSearch={({ query, country }) => this.handleSearch({ query, country })
              }
              loading={loading}
              handleChange={(field, value) => this.handleChange(value, field)}
              fromBusinessRequest
            />
          )}

          {searched && !selectedRestaurant && !showForm && (
            <ResultsContainer>
              {restaurants.map((rest, i) => (
                <RestaurantSearchResult
                  key={rest._id}
                  first={i === 0}
                  onClick={() => this.handleClaim(rest)}
                >
                  <MapIconDiv>
                    <i className="fa fa-map-marker-alt" />
                  </MapIconDiv>
                  <div style={{ width: '100%' }}>
                    <Name>{rest.name}</Name>
                    <Address>{rest.formatted_address}</Address>
                  </div>
                </RestaurantSearchResult>
              ))}

              <UnableToFindDiv
                onClick={() => this.setState({ showForm: true })}
              >
                <UnableToFind>
                  Unable to find your restaurant? Click here
                </UnableToFind>
              </UnableToFindDiv>
            </ResultsContainer>
          )}

          {selectedRestaurant && (
            <RestaurantSearchResult first selected>
              <MapIconDiv>
                <i className="fa fa-map-marker-alt" />
              </MapIconDiv>
              <div style={{ width: '100%' }}>
                <Name>{this.state.selectedRestaurant.name}</Name>
                <Address>
                  {this.state.selectedRestaurant.formatted_address}
                </Address>
              </div>
              <RemoveButton
                onClick={() => this.setState({ selectedRestaurant: null })}
              >
                Change
              </RemoveButton>
            </RestaurantSearchResult>
          )}

          {showForm && (
            <FormContainer>
              <HeaderDiv>NAME*</HeaderDiv>
              <TextInput
                type="text"
                placeholder="eg. Hard Rock Cafe"
                value={this.state.name}
                onChange={e => this.handleChange(e.target.value, 'name')}
              />

              <HeaderDiv>ADDRESS*</HeaderDiv>
              <TextInput
                type="text"
                placeholder="eg. 123 Orchard Road #12-34 Singapore 123456"
                value={this.state.formatted_address}
                onChange={e => this.handleChange(e.target.value, 'formatted_address')
                }
              />

              <HeaderDiv>REMARKS</HeaderDiv>
              <TextArea
                rows="5"
                placeholder="Additional remarks"
                value={this.state.remarks}
                onChange={e => this.handleChange(e.target.value, 'remarks')}
              />
            </FormContainer>
          )}

          {(showForm || selectedRestaurant) && (
            <SubmitDiv>
              {!submitting && !submitted && (
                <SubmitButton onClick={() => this.handleSubmit()}>
                  Submit request
                </SubmitButton>
              )}
              {submitting && (
                <SubmitButton disabled>
                  <i className="fas fa-spinner fa-spin" />
                </SubmitButton>
              )}
              {!submitting && submitted && (
                <SubmitButton background="omnivore" disabled>
                  Submitted
                </SubmitButton>
              )}
              {this.state.error.name && (
                <span style={{ color: styles.colors.action }}>
                  Please enter the name of your restaurant / store
                </span>
              )}
              {!this.state.error.name && this.state.error.address && (
                <span style={{ color: styles.colors.action }}>
                  Please enter an address
                </span>
              )}
              {submitted && (
                <span style={{ color: styles.colors.black }}>
                  We have received your request and will get back to you soon!
                </span>
              )}
            </SubmitDiv>
          )}
        </div>
      </Container>
    )
  }
}

AddRestaurant.propTypes = {
  viewer: PropTypes.objectOf(PropTypes.any).isRequired,
  restaurants: PropTypes.objectOf(PropTypes.any).isRequired,
  business: PropTypes.objectOf(PropTypes.any).isRequired
}

const mapStateToProps = state => ({
  viewer: state.users.viewer._id ? state.users.all[state.users.viewer._id] : {},
  restaurants: state.restaurants.index.items.map(
    _id => state.restaurants.all[_id]
  ),
  categories: state.categories.index.items.map(
    _id => state.categories.all[_id]
  ),
  business: state.business
})

export default connect(
  mapStateToProps,
  null
)(AddRestaurant)

const Container = styled.main`
  padding: 0 ${styles.padding.medium};
`

const BackBar = styled.div`
  width: 100%;
  height: 64px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`
const BackButton = styled.span`
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 ${styles.padding.medium};
  background-color: ${styles.colors.omnivore};
  color: ${styles.colors.white};
  border-radius: ${styles.borderRadius.small};
  &:hover {
    background-color: ${styles.colors.brand};
  }
  cursor: pointer;
`

const ResultsContainer = styled.div`
  margin-top: ${styles.margin.medium};
  max-height: calc(100vh - 96px - 64px - 48px - (2 * ${styles.margin.medium}));
  overflow: scroll;

  @media (max-width: 800px) {
    max-height: calc(
      100vh - 96px - 64px - 64px - 48px - (2 * ${styles.margin.medium})
    );
  }

  @media (max-width: 600px) {
    max-height: calc(
      100vh - 96px - 64px - 48px - 48px - 48px - 48px -
        (3 * ${styles.margin.medium})
    );
  }
`

const RestaurantSearchResult = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  padding: ${styles.padding.medium};
  background: ${styles.colors.white};
  margin-top: ${props => (props.first ? 0 : styles.margin.small)};
  cursor: pointer;
  border-radius: ${styles.borderRadius.small};

  &:hover {
    background: ${props => (props.selected ? styles.colors.white : styles.colors.lightGrey)};
  }
`

const MapIconDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${styles.colors.grey};
  font-size: 1.5em;
  margin-right: ${styles.margin.small};

  @media (max-width: 600px) {
    display: none;
  }
`
const Name = styled.h3`
  color: ${styles.colors.black};
  text-decoration: none;
  font-weight: bold;
  font-size: 1em;
`

const Address = styled.div`
  font-size: 1em;
  color: ${styles.colors.darkGrey};
`
const RemoveButton = styled.div`
  width: 20%;
  min-width: 70px;
  height: ${styles.height.tiny};
  margin-top: ${styles.margin.small};
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${styles.padding.small};
  border-radius: ${styles.borderRadius.small};
  background-color: ${styles.colors.action};
  color: ${styles.colors.white};
  cursor: pointer;

  @media (min-width: 601px) {
    width: 15%;
    margin-left: ${styles.margin.small};
    margin-top: 0;
  }
`
const UnableToFindDiv = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: ${styles.margin.medium};
  cursor: pointer;
  background: ${styles.colors.omnivore};
  border-radius: ${styles.borderRadius.small};

  &:hover {
    background: ${styles.colors.brand};
  }
`
const UnableToFind = styled.span`
  padding: ${styles.padding.medium};
  font-weight: bold;
  color: ${styles.colors.white};
  border-radius: ${styles.borderRadius.small};
  text-align: center;
`
const FormContainer = styled.div`
  margin-top: ${styles.margin.medium};
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

const HeaderDiv = styled.div`
  font-size: 0.9em;
  font-weight: 600;
  margin-bottom: ${styles.margin.small};
`

const TextInput = styled.input`
  height: 48px;
  width: 100%;
  background: ${styles.colors.white};
  font-size: 1em;
  color: ${styles.colors.black};
  padding: ${styles.padding.small};
  border: 1px solid ${styles.colors.lightGrey};
  margin-bottom: ${styles.margin.medium};
`

const TextArea = styled.textarea`
  width: 100%;
  margin: ${styles.margin.small} 0;
  font-size: 1.1em;
  color: ${styles.colors.black};
  padding: ${styles.padding.small};
  border: 1px solid ${styles.colors.lightGrey};
  resize: none;
`

const SubmitDiv = styled.div`
  margin-top: ${styles.margin.large};
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`

const SubmitButton = styled.div`
  cursor: ${props => (props.disabled ? 'auto' : 'pointer')};
  min-width: 140px;
  padding: ${styles.padding.medium};
  background: ${styles.colors.omnivore};
  color: ${styles.colors.white};
  border-radius: ${styles.borderRadius.small};
  margin-bottom: ${styles.margin.medium};
  display: flex;
  justify-content: center;

  &:hover {
    background: ${styles.colors.brand};
  }
`
