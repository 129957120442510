import React, { Component } from 'react'
import styled from 'styled-components'
import LoadingSmallReviewCard from './LoadingSmallReviewCard'
import styles from '../../constants/styles'

class ReviewLoading extends Component {
  constructor(props) {
    super(props)
    this.state = {
      mobileView: 'reviewList',
    }
  }

  render() {
    const { mobileView } = this.state
    return (
      <Container
        ref={(con) => {
          this.containerRef = con
        }}
      >
        <div style={{ width: '100%' }}>
          {mobileView !== 'mainReview' && <FilterBar />}

          <WebView>
            <ReviewList>
              <div
                style={{
                  padding: styles.padding.medium,
                  marginBottom: styles.margin.small,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  fontWeight: '500',
                }}
              >
                <i className="fas fa-spinner fa-spin" />
              </div>

              {[0, 1, 2, 3, 4, 5, 6, 7].map((i) => (
                <LoadingSmallReviewCard key={i} />
              ))}
            </ReviewList>
            <MainReview>
              <Loading>
                <i
                  className="fas fa-spinner fa-spin"
                  style={{ fontSize: '2em' }}
                />
                Fetching reviews
              </Loading>
            </MainReview>
          </WebView>

          <MobileView>
            {mobileView === 'reviewList' && (
              <ReviewList>
                <div
                  style={{
                    marginBottom: styles.margin.small,
                    fontWeight: '500',
                    padding: styles.padding.small,
                    textAlign: 'center',
                  }}
                >
                  <i className="fas fa-spinner fa-spin" />
                </div>

                {[0, 1, 2, 3, 4, 5, 6, 7].map((i) => (
                  <LoadingSmallReviewCard key={i} />
                ))}
              </ReviewList>
            )}

            {mobileView === 'mainReview' && (
              <div
                style={{
                  width: '100vw',
                  display: 'flex',
                  justifyContent: 'center',
                  flexFlow: 'column',
                  alignItems: 'center',
                }}
              >
                <Loading>
                  <i
                    className="fas fa-spinner fa-spin"
                    style={{ fontSize: '2em' }}
                  />
                </Loading>
              </div>
            )}
          </MobileView>
        </div>
      </Container>
    )
  }
}

const Container = styled.div`
  width: 100%;
  padding: 0 ${styles.padding.medium};
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
`

const FilterBar = styled.div`
  width: 100%;
  height: ${styles.height.small};
  background-color: ${styles.colors.lightGrey};
  display: flex;
  margin-bottom: ${styles.margin.small};
`

const WebView = styled.div`
  display: none;

  @media (min-width: 801px) {
    display: flex;
    flex-flow: row;
    width: 100%;
  }
`

const MobileView = styled.div`
  display: flex;
  flex-flow: column;

  @media (min-width: 801px) {
    display: none;
  }
`

const ReviewList = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column;
  background-color: ${styles.colors.white};
  margin-right: ${styles.margin.small};
  padding-top: ${styles.padding.small};

  @media (min-width: 801px) {
    width: 50%;
    height: 100vh;
    overflow-y: scroll;
  }

  @media (min-width: 1120px) {
    width: 30%;
    height: 500px;
    overflow-y: scroll;
  }
`

const MainReview = styled.div`
  width: 70%;
`

const Loading = styled.div`
  width: 100%;
  height: calc(100vh - 96px - 64px);
  font-weight: bold;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export default ReviewLoading
