import axios from 'axios'
import Auth from '../modules/Auth'
import * as types from '../constants/actionTypes'

export const logIn = userData => (dispatch) => {
  dispatch({ type: types.LOG_IN })
  axios.defaults.headers.common.Authorization = `bearer ${userData.token}`
  Auth.authenticateUser(userData.token)
  Auth.saveUsername(userData.user.username)
}

export const logOut = () => (dispatch) => {
  dispatch({ type: types.LOG_OUT })
  Auth.removeUsername()
  Auth.deauthenticateUser()
}
