import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import * as actions from '../actions'

class LogOut extends Component {
  constructor(props) {
    super(props)
    this.state = {
      redirect: false
    }
  }

  componentDidMount() {
    this.props.logOut()
    this.setState({ redirect: true })
  }

  render() {
    if (this.state.redirect) {
      return <Redirect to="/" />
    }
    return <div />
  }
}

LogOut.propTypes = {
  logOut: PropTypes.func.isRequired
}

const mapDispatchToProps = dispatch => ({
  logOut: () => {
    dispatch(actions.auth.logOut())
  }
})

export default connect(
  null,
  mapDispatchToProps
)(LogOut)
