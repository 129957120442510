import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import styled from 'styled-components'
import Avatar from '../users/Avatar'
import styles from '../../constants/styles'

class SmallReviewCard extends Component {
  constructor(props) {
    super(props)
    this.state = {
      expanded: false,
    }
    this.expand = this.expand.bind(this)
  }

  expand(review) {
    const { expanded } = this.state
    const text = review.text.split(' ')
    const { length } = review.text
    if (length < 120 || expanded) {
      return review.text
    }
    let blank = ''
    let total = 0
    while (total < 110) {
      const first = text.shift()
      blank += first
      blank += ' '
      total += first.length + 1
    }
    return (
      <span>
        {blank}
        <More onClick={() => this.setState({ expanded: true })}>...</More>
      </span>
    )
  }

  render() {
    const { review, selected } = this.props
    const overall = review?.rating?.overall ?? 0

    return (
      <Review key={`${review._id}`} selected={selected}>
        <Avatar user={review.user} width="36px" height="36px" />

        <div style={{ marginLeft: styles.margin.small, width: '100%' }}>
          <div
            style={{
              width: '100%',
              display: 'flex',
              flexFlow: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginBottom: styles.margin.tiny,
            }}
          >
            <Username>{review.user?.username}</Username>
            <div
              style={{
                color: styles.colors.darkGrey,
                paddingRight: styles.padding.small,
                fontSize: '0.8em',
                textAlign: 'right',
                display: 'flex',
                justifyContent: 'flex-end',
              }}
            >
              {moment.utc(review.createdAt).format('DD MMM YY')}
            </div>
          </div>
          {[1, 2, 3, 4, 5].map((score) => (
            <i
              key={score}
              className={`${
                overall >= score
                  ? 'fas fa-star'
                  : score - 0.5 < overall
                  ? 'fas fa-star-half-alt'
                  : 'far fa-star'
              }`}
              style={{ fontSize: '0.8em', color: styles.colors.darkGrey }}
            />
          ))}
          &nbsp;
          <span style={{ fontSize: '0.9em' }}>{this.expand(review)}</span>
        </div>
      </Review>
    )
  }
}

SmallReviewCard.propTypes = {
  review: PropTypes.objectOf(PropTypes.any).isRequired,
  selected: PropTypes.bool.isRequired,
}

const Review = styled.div`
  width: 100%;
  display: flex;
  flex-flow: row;
  color: ${styles.colors.black};
  padding: ${styles.padding.medium} ${styles.padding.small};
  background-color: ${(props) =>
    props.selected ? styles.colors.veryLightGrey : styles.colors.white};
  border-left: 5px solid
    ${(props) =>
      props.selected ? styles.colors.omnivore : styles.colors.white};

  &: hover {
    background-color: ${styles.colors.veryLightGrey};
    border-left: 5px solid
      ${(props) =>
        props.selected ? styles.colors.omnivore : styles.colors.darkGrey};
  }
`

const Username = styled.div`
  font-weight: bold;
  color: ${styles.colors.black};
`

const More = styled.span`
  font-weight: 600;
  color: ${styles.colors.grey};
  cursor: pointer;
`

export default SmallReviewCard
