import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import styles from '../../constants/styles'

function getCountriesFromRestaurants(restaurants) {
  if (!restaurants || !restaurants.length) return []
  return Object.keys(
    restaurants.reduce((obj, rest) => {
      obj[rest.country] = 1
      return obj
    }, {})
  )
}

function getCategoriesFromRestaurants(restaurants) {
  if (!restaurants || !restaurants.length) return []
  return Object.keys(
    restaurants.reduce((obj, rest) => {
      rest.categories.forEach((c) => {
        obj[c] = 1
      })
      return obj
    }, {})
  )
}

const EditLocations = ({
  _id, allRestaurants, restaurants, updateDish
}) => (
  <Container>
    <SelectAll
      onClick={() => {
        updateDish({
          _id,
          original: { restaurants },
          value: {
            restaurants:
              restaurants.length === allRestaurants.length
                ? []
                : allRestaurants.map(r => r._id)
          }
        })

        updateDish({
          _id,
          original: {
            countries: getCountriesFromRestaurants(
              allRestaurants.filter(r => restaurants.includes(r._id))
            )
          },
          value: {
            countries:
              restaurants.length === allRestaurants.length
                ? []
                : getCountriesFromRestaurants(allRestaurants)
          }
        })

        updateDish({
          _id,
          original: {
            categories: getCategoriesFromRestaurants(
              allRestaurants.filter(r => restaurants.includes(r._id))
            )
          },
          value: {
            categories:
              restaurants.length === allRestaurants.length
                ? []
                : getCategoriesFromRestaurants(allRestaurants)
          }
        })

        updateDish({
          _id,
          original: {
            unselectedRestaurants: allRestaurants
              .map(r => r._id)
              .filter(_id => !restaurants.includes(_id))
          },
          value: {
            unselectedRestaurants:
              restaurants.length === allRestaurants.length
                ? allRestaurants.map(r => r._id)
                : []
          }
        })
      }}
    >
      <b>
        {restaurants.length === allRestaurants.length
          ? 'Unselect all restaurants'
          : 'Select all restaurants'}
      </b>
    </SelectAll>

    {allRestaurants.map((rest, index) => (
      <RestaurantCard
        key={rest._id}
        active={restaurants.includes(rest._id)}
        onClick={() => {
          updateDish({
            _id,
            original: { restaurants },
            value: {
              restaurants: restaurants.includes(rest._id)
                ? restaurants.filter(r => r !== rest._id)
                : restaurants.concat(rest._id)
            }
          })

          updateDish({
            _id,
            original: {
              countries: getCountriesFromRestaurants(
                allRestaurants.filter(r => restaurants.includes(r._id))
              )
            },
            value: {
              countries: restaurants.includes(rest._id)
                ? getCountriesFromRestaurants(
                  allRestaurants.filter(r => restaurants
                    .filter(res => res !== rest._id)
                    .includes(r._id))
                )
                : getCountriesFromRestaurants(
                  allRestaurants.filter(r => restaurants.concat(rest._id).includes(r._id))
                )
            }
          })

          updateDish({
            _id,
            original: {
              categories: getCategoriesFromRestaurants(
                allRestaurants.filter(r => restaurants.includes(r._id))
              )
            },
            value: {
              categories: restaurants.includes(rest._id)
                ? getCategoriesFromRestaurants(
                  allRestaurants.filter(r => restaurants
                    .filter(res => res !== rest._id)
                    .includes(r._id))
                )
                : getCategoriesFromRestaurants(
                  allRestaurants.filter(r => restaurants.concat(rest._id).includes(r._id))
                )
            }
          })

          updateDish({
            _id,
            original: {
              unselectedRestaurants: allRestaurants
                .map(r => r._id)
                .filter(_id => !restaurants.includes(_id))
            },
            value: {
              unselectedRestaurants: restaurants.includes(rest._id)
                ? allRestaurants
                  .map(r => r._id)
                  .filter(
                    _id => !restaurants.includes(_id) || _id === rest._id
                  )
                : allRestaurants
                  .map(r => r._id)
                  .filter(
                    _id => !restaurants.includes(_id) && _id !== rest._id
                  )
            }
          })
        }}
      >
        <Check active={restaurants.includes(rest._id)}>
          {restaurants.includes(rest._id) && <i className="fa fa-check" />}
        </Check>
        <div
          style={{
            color: restaurants.includes(rest._id)
              ? styles.colors.black
              : styles.colors.darkGrey
          }}
        >
          <b>{rest.name}</b>
          <br />
          <span style={{ fontSize: '0.9em' }}>{rest.formatted_address}</span>
        </div>
      </RestaurantCard>
    ))}
  </Container>
)

EditLocations.propTypes = {
  _id: PropTypes.string,
  allRestaurants: PropTypes.arrayOf(PropTypes.object).isRequired,
  restaurants: PropTypes.arrayOf(PropTypes.string).isRequired,
  updateDish: PropTypes.func.isRequired
}

export default EditLocations

const Container = styled.div``

const RestaurantCard = styled.div`
  width: 100%;
  max-width: 720px;
  display: flex;
  justify-content: flex-flex-start;
  align-items: center;
  padding: ${styles.padding.small};
  margin-bottom: ${styles.margin.small};
  border: 1px solid
    ${props => (props.active ? styles.colors.omnivore : styles.colors.grey)};
  color: ${props => (props.active ? styles.colors.white : styles.colors.black)};
  border-radius: ${styles.borderRadius.small};
  cursor: pointer;
  &:hover {
    border: 1px solid
      ${props => (props.active ? styles.colors.omnivore : styles.colors.brand)};
    background-color: ${styles.colors.lightGrey};
  }
`

const SelectAll = styled(RestaurantCard)`
  background-color: ${styles.colors.grey};
  color: ${styles.colors.white};
  &:hover {
    border: 1px solid ${styles.colors.brand};
    background-color: ${styles.colors.brand};
  }
`

const Check = styled.div`
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  padding: 4px;
  font-size: 1.2em;
  margin-right: ${styles.margin.small};
  background-color: ${props => (props.active ? styles.colors.omnivore : styles.colors.white)};
  border: 1px solid
    ${props => (props.active ? styles.colors.white : styles.colors.grey)};
  color: ${styles.colors.white};
`
