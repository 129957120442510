import axios from 'axios'
import constants from '../constants/constants'
import {
  updateField,
  RESTAURANT_UPDATE_LOADING,
  RESTAURANT_UPDATE_COMPLETE,
  RESTAURANT_UPDATE_ERROR
} from './index'

export const updateRestaurantField = ({
  _id,
  field, // key
  value,
  originalValue
}) => (dispatch) => {
  dispatch(
    updateField({
      type: 'restaurants',
      _id,
      value: { [field]: value }
    })
  )

  dispatch({
    type: RESTAURANT_UPDATE_LOADING,
    _id,
    field
  })
  axios
    .put(constants.api.restaurants + _id, { [field]: value })
    .then((res) => {
      if (res.data.success) {
        dispatch({
          type: RESTAURANT_UPDATE_COMPLETE,
          _id,
          field
        })
      } else {
        dispatch(
          updateField({
            type: 'restaurants',
            _id,
            value: { [field]: originalValue }
          })
        )
        dispatch({
          type: RESTAURANT_UPDATE_ERROR,
          _id,
          field
        })
      }
    })
    .catch((err) => {
      console.error('err', err)
      dispatch(
        updateField({
          type: 'restaurants',
          _id,
          value: { [field]: originalValue }
        })
      )
      dispatch({
        type: RESTAURANT_UPDATE_ERROR,
        _id,
        field
      })
    })
}
