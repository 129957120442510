import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import styles from '../../constants/styles'

class RequiredInput extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isEditing: false,
      input: props.defaultValue || '',
      error: false
    }
    this.toggleEdit = this.toggleEdit.bind(this)
    this.updateInput = this.updateInput.bind(this)
    this.enterPressed = this.enterPressed.bind(this)
  }

  toggleEdit() {
    const { isEditing, input } = this.state
    if (isEditing) {
      if (input) {
        this.setState({ isEditing: !isEditing })
        this.props.onSubmit(input)
      } else {
        this.setState({ error: true })
      }
    } else {
      this.setState({ isEditing: !isEditing })
    }
  }

  updateInput(e) {
    this.setState({ input: e.target.value, error: false })
  }

  enterPressed(e) {
    const code = e.keyCode || e.which
    if (code === 13) {
      // 13 is the "enter" button keycode
      this.toggleEdit()
    }
  }

  render() {
    const { defaultValue, errorMessage, placeholder } = this.props

    return (
      <div>
        <Container>
          {this.state.isEditing ? (
            <Input
              autoFocus
              type="text"
              defaultValue={defaultValue}
              placeholder={placeholder || 'Input required'}
              onChange={this.updateInput}
              onKeyPress={this.enterPressed}
            />
          ) : (
            <div style={{ width: '100%', paddingLeft: styles.padding.small }}>
              {defaultValue}
            </div>
          )}
          <EditSaveButton
            isEditing={this.state.isEditing}
            onClick={this.toggleEdit}
          >
            <i
              className={
                this.state.isEditing ? 'fas fa-check' : 'fas fa-pencil-alt'
              }
            />
          </EditSaveButton>
        </Container>
        {this.state.error && (
          <div
            style={{
              color: styles.colors.action,
              marginTop: styles.margin.small
            }}
          >
            {errorMessage}
          </div>
        )}
      </div>
    )
  }
}

export default RequiredInput

RequiredInput.propTypes = {
  defaultValue: PropTypes.string.isRequired,
  errorMessage: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired
}

const Container = styled.div`
  width: 100%;
  max-width: 720px;
  height: ${styles.height.small};
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-radius: ${styles.borderRadius.small};
  overflow: hidden;
  background-color: ${styles.colors.lightGrey};
`

const Input = styled.input`
  width: 100%;
  height: 100%;
  border-top-left-radius: ${styles.borderRadius.small};
  border-bottom-left-radius: ${styles.borderRadius.small};
  border: 1px solid ${styles.colors.lightGrey};
  border-right: 0;
  padding-left: ${styles.padding.small};
  font-size: 1em;
  background-color: ${styles.colors.white};
`

const EditSaveButton = styled.div`
  width: 48px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-color: ${styles.colors.omnivore};
  color: ${styles.colors.white};
  &:hover {
    background-color: ${styles.colors.grey};
  }
`
