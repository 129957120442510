import React, { Component } from 'react'
import PropTypes from 'prop-types'
// import constants from '../../constants/constants'
import styled from 'styled-components'
import styles from '../../constants/styles'

class EditIngredients extends Component {
  constructor(props) {
    super(props)
    this.state = {
      ingredient: ''
    }
    this.addIngredient = this.addIngredient.bind(this)
    this.enterPressed = this.enterPressed.bind(this)
  }

  addIngredient() {
    if (!this.state.ingredient) return
    const { _id, ingredients } = this.props

    this.props.updateDish({
      _id,
      original: { ingredients },
      value: { ingredients: ingredients.concat(this.state.ingredient.trim()) }
    })

    this.setState({ ingredient: '' })
  }

  enterPressed(event) {
    const code = event.keyCode || event.which
    if (code === 13) {
      // 13 is the "enter" button keycode
      this.addIngredient()
    }
  }

  render() {
    const { _id, ingredients, updateDish } = this.props
    return (
      <Container>
        <Selected>
          {ingredients.map(i => (
            <Ingredient key={i}>
              {i}
              <Remove
                className="fas fa-times"
                onClick={() => updateDish({
                  _id,
                  original: { ingredients },
                  value: {
                    ingredients: ingredients.filter(all => all !== i)
                  }
                })
                }
              />
            </Ingredient>
          ))}
        </Selected>

        <Selected style={{ flexWrap: 'nowrap' }}>
          <Input
            type="text"
            name="ingredients"
            value={this.state.ingredient}
            ref={(el) => {
              this.input = el
            }}
            placeholder="Enter an ingredient"
            onChange={e => this.setState({ ingredient: e.target.value })}
            onKeyPress={this.enterPressed}
          />

          <AddIngredient onClick={this.addIngredient}>Add</AddIngredient>
        </Selected>
      </Container>
    )
  }
}

EditIngredients.propTypes = {
  _id: PropTypes.string,
  ingredients: PropTypes.array.isRequired,
  updateDish: PropTypes.func.isRequired
}

export default EditIngredients

const Container = styled.div`
  ${'' /* display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap; */};
`

const Selected = styled.div`
  max-width: 720px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
`

const Ingredient = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-right: ${styles.margin.small};
  margin-bottom: ${styles.margin.small};
  border: 1px solid ${styles.colors.black};
  background-color: ${styles.colors.white};
  color: ${styles.colors.black};
  border-radius: ${styles.borderRadius.small};
  padding: ${styles.padding.small} ${styles.padding.medium};
`

const Remove = styled.div`
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  font-size: 1em;
  background-color: ${styles.colors.white};
  color: ${styles.colors.action};
  margin-left: ${styles.margin.small};
  cursor: pointer;
  &:hover {
    background-color: ${styles.colors.action};
    color: ${styles.colors.white};
  }
`

const Input = styled.input`
  width: 100%;
  max-width: 240px;
  height: ${styles.height.small};
  border: 1px solid ${styles.colors.lightGrey};
  border-radius: ${styles.borderRadius.small};
  padding-left: ${styles.padding.small};
  font-size: 1em;
`

const AddIngredient = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: ${styles.margin.small};
  background-color: ${styles.colors.omnivore};
  color: ${styles.colors.white};
  border-radius: ${styles.borderRadius.small};
  padding: ${styles.padding.medium};
  cursor: pointer;
  &:hover {
    background-color: ${styles.colors.brand};
  }
`
