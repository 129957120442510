import React, { Component } from 'react'
// import PropTypes from 'prop-types'
import styled from 'styled-components'
import Title from '../components/shared/Title'
import ContactUs from '../components/support/ContactUs'
import styles from '../constants/styles'

class Support extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentDidMount() {
    window.scroll(0, 0)
  }

  render() {
    return (
      <Container>
        <Title title="Support" />
        <ContactUs />
      </Container>
    )
  }
}

Support.propTypes = {}

const Container = styled.main`
  padding: 0 ${styles.padding.medium};
`


export default Support
