import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled, { keyframes } from 'styled-components'
import styles from '../../constants/styles'

class LikeButton extends Component {
  constructor(props) {
    super(props)
    this.state = {
      animate: false,
    }
  }

  render() {
    const { review, viewer, likeReview, iconOnly } = this.props
    const { animate } = this.state
    const hasLiked = review.isLiked

    return (
      <LikeContainer>
        <LikeButtonHeart
          onClick={() => likeReview({ review, viewer, toLike: !hasLiked })}
          animate={animate}
          hasLiked={hasLiked}
        >
          <i
            className={`${hasLiked ? 'fas fa-heart' : 'far fa-heart'}`}
            aria-hidden="true"
          />
        </LikeButtonHeart>
        <LikeCounter>
          {`${review.likesCount}${
            !iconOnly ? (review.likesCount === 1 ? ' LIKE' : ' LIKES') : ''
          }`}
        </LikeCounter>
      </LikeContainer>
    )
  }
}

LikeButton.propTypes = {
  review: PropTypes.objectOf(PropTypes.any).isRequired,
  viewer: PropTypes.objectOf(PropTypes.any),
  likeReview: PropTypes.func.isRequired,
  iconOnly: PropTypes.bool,
}

const LikeContainer = styled.div`
  display: flex;
  flex-flow: row;
  justify-content: flex-start;
  align-items: center;
`

const bounce = keyframes`
  0% {
    transform: scale(1);
  }

  25% {
    transform: scale(0.5);
  }

  50% {
    transform: scale(1);

  }

  75% {
    transform: scale(1.5);

  }

  100% {
    transform: scale(1);
  }
`

const LikeButtonHeart = styled.div`
  color: ${(props) =>
    props.hasLiked ? styles.colors.action : styles.colors.black};
  font-size: 1.2em;
  font-weight: 100;
  cursor: pointer;
  margin-right: ${styles.margin.small};
  animation: ${(props) => (props.animate ? `${bounce} 0.3s ease-out` : '')};

  &:hover {
    color: ${styles.colors.action};
  }
`

const LikeCounter = styled.div`
  color: ${styles.colors.black};
  font-size: 0.9em;
  font-weight: 500;
`

export default LikeButton
