import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import axios from 'axios'
import styled from 'styled-components'
import * as actions from '../actions'
import Title from '../components/shared/Title'
import ActionBar from '../components/shared/ActionBar'
import ActionButton from '../components/shared/ActionButton'
import UserCard from '../components/users/UserCard'
import LoadingUserCard from '../components/users/LoadingUserCard'
import * as types from '../constants/actionTypes'
import constants from '../constants/constants'
import styles from '../constants/styles'

class Users extends Component {
  constructor(props) {
    super(props)
    this.state = {
      search: '',
      removed: [],
      loading: false,
    }
    this.handleAuthorisation = this.handleAuthorisation.bind(this)
  }

  componentDidMount() {
    const { viewer, fetchUsers } = this.props
    window.scroll(0, 0)
    fetchUsers(viewer.username)
    const node = ReactDOM.findDOMNode(this.containerRef)
    if (node && window.innerWidth > 800) {
      node.scrollIntoView()
    }
  }

  handleAuthorisation(user, type) {
    const { business, viewer, removeUser } = this.props
    const { loading } = this.state
    if (
      user._id === viewer._id ||
      loading ||
      !business.all.details ||
      !business.all.details._id
    ) {
      return
    }

    const result = window.confirm('Are you sure you want to remove this user?')

    if (result) {
      this.setState({ loading: user._id })
      axios
        .put(`${constants.api.businesses + business.all.details._id}/users`, {
          type,
          user: user._id,
        })
        .then((res) => {
          this.setState({ loading: false })
          if (res.data.success) {
            const updatedUser = Object.assign({}, user)
            if (user) {
              updatedUser.businesses =
                type === 'add'
                  ? updatedUser.businesses
                      .filter((b) => b !== business.all.details._id)
                      .concat(business.all.details._id)
                  : updatedUser.businesses.filter(
                      (b) => b !== business.all.details._id
                    )
            }
            const { removed } = this.state
            removed.push(user._id)
            this.setState({ removed })
            removeUser(user._id, updatedUser)
          }
        })
        .catch((err) => {
          this.setState({ loading: false })
          console.error(err)
        })
    }
  }

  render() {
    const { viewer, users } = this.props
    const { loading, removed } = this.state
    return (
      <Container ref={(con) => (this.containerRef = con)}>
        <Title title="Users" />
        <ActionBar justifyContent="flex-end">
          <Link to="/users/new">
            <ActionButton>
              <span style={{ padding: `0 ${styles.padding.medium}` }}>
                <i className="fas fa-plus-circle" />
                &nbsp;Add user
              </span>
            </ActionButton>
          </Link>
        </ActionBar>

        {!users.index.fetched &&
          [0, 1, 2, 3, 4, 5, 6, 7].map((i) => (
            <UserCardContainer key={i}>
              <LoadingUserCard />
            </UserCardContainer>
          ))}

        {users.index.fetched && (
          <UsersContainer>
            {users.index.items
              .map((_id) => users.all[_id])
              .sort((a, b) => {
                const first = a.username.toLowerCase()
                const second = b.username.toLowerCase()
                if (first < second) {
                  return -1
                }
                if (first > second) {
                  return 1
                }
                return 0
              })
              .map((user) => (
                <UserCardContainer key={user._id}>
                  <div style={{ width: '100%', maxWidth: '300px' }}>
                    <UserCard user={user} isSelf={viewer._id === user._id} />
                  </div>

                  <RightSection>
                    <RemoveDiv>
                      {loading === user._id ? (
                        <i className="fas fa-spinner fa-spin" />
                      ) : viewer._id === user._id ? (
                        <span />
                      ) : (
                        !removed.includes(user._id) && (
                          <Remove
                            onClick={() =>
                              this.handleAuthorisation(user, 'remove')
                            }
                          >
                            Remove
                          </Remove>
                        )
                      )}
                      {!loading && removed.includes(user._id) && (
                        <div style={{ color: styles.colors.grey }}>Removed</div>
                      )}
                    </RemoveDiv>
                  </RightSection>
                </UserCardContainer>
              ))}
          </UsersContainer>
        )}
      </Container>
    )
  }
}

Users.propTypes = {
  viewer: PropTypes.objectOf(PropTypes.any).isRequired,
  users: PropTypes.objectOf(PropTypes.any).isRequired,
  business: PropTypes.objectOf(PropTypes.any).isRequired,
}

const mapStateToProps = (state) => ({
  viewer: state.users.viewer._id ? state.users.all[state.users.viewer._id] : {},
  users: state.users,
  business: state.business,
})

const mapDispatchToProps = (dispatch) => ({
  fetchUsers: (username) => {
    dispatch(actions.users.fetchUsers(username))
  },
  removeUser: (_id, value) => {
    dispatch(actions.updateField({ type: 'users', id: _id, value }))
    dispatch({ type: types.REMOVE_USER, _id })
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(Users)

const UserCardContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${styles.padding.medium};
  border-radius: ${styles.borderRadius.small};
  background-color: ${styles.colors.white};
  margin-bottom: ${styles.margin.small};

  @media (max-width: 600px) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
`

const Container = styled.main`
  padding: 0 ${styles.padding.medium};
`

const Remove = styled.div`
  padding: ${styles.padding.small};
  background-color: ${styles.colors.omnivore};
  border: 1px solid ${styles.colors.omnivore};
  color: ${styles.colors.white};
  border-radius: ${styles.borderRadius.small};
  cursor: pointer;

  &:hover {
    background-color: ${styles.colors.white};
    color: ${styles.colors.omnivore};
  }
`

const UsersContainer = styled.div`
  height: calc(100vh - 96px - 64px - 96px);

  overflow: scroll;
  ::-webkit-scrollbar {
    display: none;
  }

  @media (min-width: 801px) {
    height: calc(100vh - 96px - 96px);
  }
`

const RightSection = styled.div`
  width: 220px;
  display: flex;
  justify-content: flex-end;

  @media (max-width: 600px) {
    justify-content: flex-start;
    margin-top: ${styles.margin.small};
  }
`

const RemoveDiv = styled.div`
  ${''};
`
