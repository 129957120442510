import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import styles from '../../constants/styles'

const EditDescription = ({ _id, description, updateDish }) => (
  <Container
    defaultValue={description}
    placeholder="Add a description of your dish"
    name="description"
    onChange={e => updateDish({
      _id,
      original: { description },
      value: { description: e.target.value }
    })
    }
  />
)

EditDescription.propTypes = {
  _id: PropTypes.string,
  description: PropTypes.string,
  updateDish: PropTypes.func.isRequired
}

export default EditDescription

const Container = styled.textarea`
  width: 100%;
  max-width: 720px;
  height: 160px;
  display: flex;
  flex-wrap: wrap;
  font-size: 1em;
  resize: none;
  overflow: hidden;
  border: 1px solid ${styles.colors.lightGrey};
  border-radius: ${styles.borderRadius.small};
  padding: ${styles.padding.small};
  background-color: ${styles.colors.white};
`
