import React from 'react'
import styled from 'styled-components'
import styles from '../../constants/styles'

const NotificationCard = () => (
  <Container>
    <div style={{ display: 'flex', flexFlow: 'row' }}>
      <ProfilePic />
      <div
        style={{
          marginLeft: styles.margin.medium,
          paddingTop: styles.padding.small,
        }}
      >
        <Activity />
        <DateSpan />
      </div>
    </div>
    <ReviewImage />
  </Container>
)

const Container = styled.div`
  width: 100%;
  max-width: 1200px;
  background: ${(props) =>
    props.newNotif ? 'rgba(250, 255, 250, 1)' : `${styles.colors.white}`};
  margin-bottom: ${styles.margin.small};
  padding: ${styles.padding.medium};
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  border-radius: ${styles.borderRadius.small};
`

const ProfilePic = styled.div`
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: ${styles.colors.lightGrey};
`

const DateSpan = styled.div`
  width: 10vw;
  height: 10px;
  background-color: ${styles.colors.lightGrey};
`

const Activity = styled(DateSpan)`
  width: 40vw;
  margin-bottom: ${styles.margin.small};
  height: 15px;

  @media (min-width: 801px) {
    width: 50vw;
  }
`

const ReviewImage = styled.div`
  width: 60px;
  min-width: 60px;
  height: 60px;
  border-radius: ${styles.borderRadius.small};
  background-color: ${styles.colors.lightGrey};
`

export default NotificationCard
