import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import styled from 'styled-components'
import * as actions from '../actions'
import Title from '../components/shared/Title'
import ErrorBoundary from '../components/shared/ErrorBoundary'
import NotificationCard from '../components/notifications/NotificationCard'
import LoadingNotificationCard from '../components/notifications/LoadingNotificationCard'
import styles from '../constants/styles'

class Activity extends Component {
  componentDidMount() {
    window.scroll(0, 0)
    const node = ReactDOM.findDOMNode(this.containerRef)
    if (node && window.innerWidth > 800) {
      node.scrollIntoView()
    }
  }

  componentWillUnmount() {
    const { updateCheckedTime } = this.props
    updateCheckedTime()
  }

  render() {
    const { viewer, notifications } = this.props
    const checkedBusinessNotification = Date.parse(
      viewer.checkedBusinessNotification
    )
    return (
      <Container
        ref={(con) => {
          this.containerRef = con
        }}
      >
        <Title title="Activity" />
        {notifications.index.fetched &&
          notifications.index.items.length === 0 && (
            <b>
              Your notifications will appear here when users post reviews, poke
              you or respond to your comments.
            </b>
          )}

        {!notifications.index.fetched &&
          [0, 1, 2, 3, 4, 5, 6, 7].map((i) => (
            <LoadingNotificationCard key={i} />
          ))}

        <Notifications>
          {notifications.index.fetched &&
            notifications.index.items
              .map((_id) => notifications.all[_id])
              .filter(
                (n) =>
                  !['savedRestaurantSuccess', 'viewedRestaurant'].includes(
                    n.type
                  )
              )
              .map((n) => {
                const timestamp = Date.parse(n.createdAt)
                const newNotif = timestamp >= checkedBusinessNotification
                return (
                  <ErrorBoundary key={n._id}>
                    <NotificationCard
                      key={n._id}
                      newNotif={newNotif}
                      notification={n}
                    />
                  </ErrorBoundary>
                )
              })}
        </Notifications>
      </Container>
    )
  }
}

Activity.propTypes = {
  viewer: PropTypes.objectOf(PropTypes.any).isRequired,
  notifications: PropTypes.objectOf(PropTypes.any).isRequired,
  updateCheckedTime: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => ({
  viewer: state.users.viewer._id ? state.users.all[state.users.viewer._id] : {},
  notifications: state.notifications,
})

const mapDispatchToProps = (dispatch) => ({
  updateCheckedTime: () => {
    dispatch(actions.notifications.updateCheckedTime())
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(Activity)

const Container = styled.main`
  padding: 0 ${styles.padding.medium};
`

const Notifications = styled.div`
  padding-top: ${styles.padding.medium};
`
