import React, { Component } from 'react'
import ReactSwipe from 'react-swipe'
import styled from 'styled-components'
import { optimisedPath } from '../../utilities/format'
import allCopy from '../../constants/copy'
import styles from '../../constants/styles'
import colors from '../../constants/colors'

const articles = [
  {
    title: 'A platform for positive change',
    description:
      'abillion is a growing community helping people discover vegan food and cruelty-free products around the world.',
    image: 'https://res.cloudinary.com/abillionveg/image/upload/v1614928732/business_landing/what-is-abillion-1_0.5x.jpg'
  },
  {
    title: 'A place for everyone',
    description:
      'We connect people and businesses for a more sustainable world. We welcome businesses that offer at least one vegan option.',
    image: 'https://res.cloudinary.com/abillionveg/image/upload/v1614928732/business_landing/what-is-abillion-2_0.5x.jpg'

  },
  {
    title: 'Social impact',
    description: 'We help people make better choices and we donate USD $1 to charities, animal sanctuaries and social causes for every review posted.',
    image: 'https://res.cloudinary.com/abillionveg/image/upload/v1614928732/business_landing/what-is-abillion-3_0.5x.jpg'
  }
]

const copy = allCopy.en.landing.articles

class Articles extends Component {
  constructor(props) {
    super(props)
    this.state = {
      activeIndex: 0
    }
    this.handleSwipe = this.handleSwipe.bind(this)
  }

  handleSwipe(index) {
    this.setState({ activeIndex: index })
  }

  render() {
    const { activeIndex } = this.state
    return (
      <Container>
        <Headline>{copy.headline}</Headline>
        <ArticlesDiv>
          {articles.map(article => (
            <ArticleTile key={article.title}>
              <CardContainer>
                <Image
                  src={optimisedPath(article.image, false, true)}
                  alt={article.title}
                  title={article.title}
                />

                <Text>
                  <Title>{article.title}</Title>
                  <CardDescription>{article.description}</CardDescription>
                </Text>
              </CardContainer>
            </ArticleTile>
          ))}
        </ArticlesDiv>

        <Mobile>
          <ReactSwipe
            childCount={3}
            swipeOptions={{
              transitionEnd: this.handleSwipe,
              startSlide: activeIndex
            }}
          >
            {articles.slice(0, 3).map(article => (
              <ArticleDiv key={article.title}>
                <CardContainer>
                  <Image
                    src={optimisedPath(article.image, false)}
                    alt={article.title}
                    title={article.title}
                  />

                  <Text>
                    <Title>{article.title}</Title>
                    <CardDescription>{article.description}</CardDescription>
                  </Text>
                </CardContainer>
              </ArticleDiv>
            ))}
          </ReactSwipe>
          <DotsContainer>
            <Dots>
              {[0, 1, 2].map((item, index) => (
                <Dot
                  key={item}
                  active={activeIndex === index}
                />
              ))}
            </Dots>
          </DotsContainer>
        </Mobile>
      </Container>
    )
  }
}

const Container = styled.div`
  width: 100vw;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  margin-top: 36px;
  padding: ${styles.padding.medium};
`
const Headline = styled.h2`
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  line-height: 56px;
  font-size: 44px;
  margin-bottom: 48px;
  @media (min-width: 768px) and (max-width: 900px) {
    font-size: 40px;
  }
  @media (max-width: 1023px) {
    text-align: center;
    margin-top: 48px;
  }
  @media (max-width: 600px) {
    margin-bottom: 32px;
    font-size: 26px;
    line-height: 32px;
  }
`

const ArticlesDiv = styled.div`
  display: flex;
  @media (max-width: 900px) {
    display: none;
  }
`

const ArticleTile = styled.div`
  margin: 0px 24px;
`

const Mobile = styled.div`
  display: flex;
  flex-flow: column;
  width: 100%;
  @media (min-width: 901px) {
    display: none;
  }
`
const DotsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: center;
  margin-top: 2px;
`
const Dots = styled.div`
  display: flex;
  justify-content: space-between;
  width: 48px;
`
const Dot = styled.span`
  background-color: ${props => (props.active ? colors.peachy[100] : colors.liquorice[70])};
  height: 8px;
  width: 8px;
  border-radius: 10px;
  box-shadow: 0px 0px 5px ${colors.liquorice[70]};
`

const ArticleDiv = styled.div`
  width: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
`

const CardContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  max-width: 300px;
  @media (min-width: 450px) and (max-width: 900px) {
    max-width: fit-content;
    align-items: center;
     width: 444px;
  }
`

const Image = styled.img`
  width: 100%;
  object-fit: cover;
  background-color: ${styles.colors.lightGrey};
  border-radius: ${styles.borderRadius.large};
  margin: 2px 2px 2px 0px;
`

const Text = styled.div`
  padding: 16px 0px;
  display: flex;
  flex-flow: column;
  text-align: left;
  background-color: ${styles.colors.white};
  ${''};
`

const Title = styled.span`
  font-family: Montserrat;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  text-align: center;
  color: ${styles.colors.black};
`

const CardDescription = styled.span`
  padding-top: 4px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 28px;
  text-align: center;
`

export default Articles
