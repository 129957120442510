import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import styles from '../../constants/styles'

function renderNotificationContent(n) {
  switch (n.type) {
    case 'replyReviewSuccess':
      return (
        <ActivityText>
          <Username
            href={`https://www.abillion.com/users/${n.sender.username}`}
            rel="noopener noreferrer"
            target="_blank"
          >
            {n.sender.username}
          </Username>{' '}
          replied to your comment on the review of{' '}
          <Dish to={`/dishes/${n.review.dish._id}`}>{n.review.dish.name}</Dish>:
          {n.comment}
        </ActivityText>
      )
    case 'pokeRestaurantSuccess':
      return (
        <ActivityText>
          <Username
            href={`https://www.abillion.com/users/${n.sender.username}`}
            rel="noopener noreferrer"
            target="_blank"
          >
            {n.sender.username}
          </Username>{' '}
          poked{' '}
          <Restaurant to={`/restaurants/${n.restaurant._id}`}>
            {n.restaurant.name}
          </Restaurant>
        </ActivityText>
      )
    case 'postReviewSuccess':
      if (n.review.isPoked) {
        return (
          <ActivityText>
            <Username
              href={`https://www.abillion.com/users/${n.sender.username}`}
              rel="noopener noreferrer"
              target="_blank"
            >
              {n.sender.username}
            </Username>{' '}
            poked{' '}
            <Restaurant to={`/restaurants/${n.restaurant._id}`}>
              {n.restaurant.name}
            </Restaurant>{' '}
            in a review of{' '}
            <Dish to={`/dishes/${n.review.dish._id}`}>
              {n.review.dish.name}
            </Dish>
          </ActivityText>
        )
      }
      return (
        <ActivityText>
          <Username
            href={`https://www.abillion.com/users/${n.sender.username}`}
            rel="noopener noreferrer"
            target="_blank"
          >
            {n.sender.username}
          </Username>{' '}
          reviewed{' '}
          <Dish to={`/dishes/${n.review.dish._id}`}>{n.review.dish.name}</Dish>{' '}
          at{' '}
          <Restaurant to={`/restaurants/${n.restaurant._id}`}>
            {n.restaurant.name}
          </Restaurant>
        </ActivityText>
      )

    default:
      return <div style={{ width: '100%' }} />
  }
}

const ActivityText = styled.p`
  font-size: 1em;
`
const Username = styled.a`
  color: black;
  font-weight: bold;

  &: hover {
    color: ${styles.colors.omnivore};
  }
`
const Restaurant = styled(Link)`
  font-weight: bold;
  color: ${styles.colors.black};
  &:hover {
    color: ${styles.colors.omnivore};
  }
`
const Dish = styled(Link)`
  font-weight: bold;
  color: ${styles.colors.black};
  &:hover {
    color: ${styles.colors.omnivore};
  }
`

// eslint-disable-next-line import/prefer-default-export
export { renderNotificationContent }
