const colors = {
  arctic: {
    100: '#06cbdd',
    75: '#44d8e5',
    50: '#82e5ee',
    25: '#c1f2f6'
  },
  bandung: {
    100: '#ea005e',
    75: '#ef4086',
    50: '#f480ae',
    25: '#fabfd7'
  },
  blackcurrant: {
    100: '#190372',
    75: '#524295',
    50: '#8c81b8',
    25: '#c5c0dc'
  },
  liquorice: {
    100: '#212121',
    90: '#424242',
    80: '#616161',
    70: '#757575',
    60: '#9e9e9e',
    50: '#bdbdbd',
    40: '#e0e0e0',
    30: '#eeeeee',
    20: '#f7f7f7',
    10: '#fafafa'
  },
  nooch: {
    100: '#ffa000',
    75: '#ffb840',
    50: '#ffcf80',
    25: '#ffe7bf'
  },
  ocean: {
    100: '#2979ff',
    75: '#5e9aff',
    50: '#94bcff',
    25: '#c9ddff'
  },
  peachy: {
    100: '#ff9a82',
    75: '#ffb3a1',
    50: '#ffccc0',
    25: '#ffe6e0'
  },
  pesto: {
    100: '#8ea925',
    75: '#aabe5b',
    50: '#c6d492',
    25: '#e3e9c8'
  },
  strawberry: {
    100: '#ff4343',
    75: '#ff7272',
    50: '#ffa1a1',
    25: '#ffd0d0'
  }
}

export default colors
