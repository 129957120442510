import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import Title from '../components/shared/Title'
import ActionButton from '../components/shared/ActionButton'
import NotificationCard from '../components/notifications/NotificationCard'
import LoadingNotificationCard from '../components/notifications/LoadingNotificationCard'
import * as imageAssets from '../constants/imageAssets'
import styles from '../constants/styles'

class Home extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentDidMount() {
    window.scrollTo(0, 0)
  }

  render() {
    const {
      viewer,
      users,
      restaurants,
      dishes,
      reviews,
      notifications,
      business,
      businessRedux
    } = this.props
    const checkedBusinessNotification = Date.parse(
      viewer.checkedBusinessNotification
    )

    return (
      <Container>
        <Title
          title={
            business && business.name && business.name.length > 0
              ? business.name
              : 'Home'
          }
        />
        <Overview>
          <Row>
            <LargeLink to="/reviews">
              <Large
                style={{ marginRight: styles.margin.small }}
                image={imageAssets.REVIEW_HOME}
              >
                <Text>
                  {reviews.items.length === 1 ? 'Review' : 'Reviews'}
                  <br />
                  {reviews.fetched ? (
                    <Number>{reviews.items.length}</Number>
                  ) : (
                    <i
                      className="fas fa-spinner fa-spin"
                      style={{ fontSize: '1.2em' }}
                    />
                  )}
                </Text>
              </Large>
            </LargeLink>
            <SmallLink to="/restaurants">
              <Small image={imageAssets.RESTAURANT_HOME}>
                <Text>
                  {restaurants.items.length === 1
                    ? 'Restaurant'
                    : 'Restaurants'}
                  <br />
                  {restaurants.fetched ? (
                    <Number>{restaurants.items.length}</Number>
                  ) : (
                    <i
                      className="fas fa-spinner fa-spin"
                      style={{ fontSize: '1.2em' }}
                    />
                  )}
                </Text>
              </Small>
            </SmallLink>
          </Row>
          <Row>
            <SmallLink to="/users">
              <Small
                style={{ marginRight: styles.margin.small }}
                image={imageAssets.USER_HOME}
              >
                <Text>
                  {users.items.length === 1 ? 'User' : 'Users'}
                  <br />
                  {users.fetched ? (
                    <Number>{users.items.length}</Number>
                  ) : (
                    <i
                      className="fas fa-spinner fa-spin"
                      style={{ fontSize: '1.2em' }}
                    />
                  )}
                </Text>
              </Small>
            </SmallLink>
            <LargeLink to="/dishes">
              <Large image={imageAssets.DISH_HOME}>
                <Text>
                  {dishes.items.length === 1 ? 'Dish' : 'Dishes'}
                  <br />
                  {businessRedux.fetched ? (
                    <Number>{business.restaurants.reduce((a, b) => a + b.dishes.length, 0)}</Number>
                  ) : (
                    <i
                      className="fas fa-spinner fa-spin"
                      style={{ fontSize: '1.2em' }}
                    />
                  )}
                </Text>
              </Large>
            </LargeLink>
          </Row>
        </Overview>

        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}
        >
          <Subheader>Recent Activity</Subheader>

          <Link to="/activity">
            <ActionButton>
              <span style={{ padding: `0 ${styles.padding.medium}` }}>
                See more&nbsp;
                <i className="fas fa-chevron-right" />
              </span>
            </ActionButton>
          </Link>
        </div>
        <Overview>
          {!notifications.index.fetched
            && [0, 1, 2, 3, 4, 5, 6, 7].map(i => (
              <LoadingNotificationCard key={i} />
            ))}

          {notifications.index.items
            .map(_id => notifications.all[_id])
            .filter(
              n => n.type !== 'saveRestaurantSuccess'
                && n.type !== 'viewedRestaurant'
            )
            .slice(0, 7)
            .map((n) => {
              const timestamp = Date.parse(n.createdAt)
              const newNotif = timestamp >= checkedBusinessNotification
              return (
                <NotificationCard
                  key={n._id}
                  newNotif={newNotif}
                  notification={n}
                />
              )
            })}
        </Overview>
      </Container>
    )
  }
}

Home.propTypes = {
  viewer: PropTypes.object.isRequired,
  users: PropTypes.object.isRequired,
  restaurants: PropTypes.object.isRequired,
  dishes: PropTypes.object.isRequired,
  reviews: PropTypes.object.isRequired,
  notifications: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
  viewer: state.users.viewer._id ? state.users.all[state.users.viewer._id] : {},
  users: state.users.index,
  restaurants: state.restaurants.index,
  dishes: state.dishes.index,
  images: state.images.index,
  reviews: state.reviews.index,
  notifications: state.notifications,
  business: state.business.all.details,
  businessRedux: state.business.all
})


export default connect(
  mapStateToProps,
  null
)(Home)

const Container = styled.main`
  width: 100%;
  padding: 0 ${styles.padding.medium};
`

const Subheader = styled.div`
  font-size: 1.4em;
  font-weight: 500;
`

const Overview = styled.div`
  width: 100%;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  align-items: flex-start;
  margin: ${styles.margin.medium} 0;
`

const Row = styled.div`
  display: flex;
  flex-flow: row;
  width: 100%;
  margin-bottom: ${styles.margin.small};
`
// background-image: url(${props => props.image});
const LargeLink = styled(Link)`
  width: 60%;
  height: 150px;

  @media (min-width: 800px) {
    width: 70%;
    height: 220px;
  }
`

const SmallLink = styled(LargeLink)`
  width: 40%;

  @media (min-width: 800px) {
    width: 30%;
  }
`

const Large = styled.div`
  height: 100%;
  border-radius: ${styles.borderRadius.small};
  background-image: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0.2) 0%,
      rgba(0, 0, 0, 0.3) 100%
    ),
    url(${props => props.image});
  background-position: 50% 50%;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    background-image: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0.4) 0%,
        rgba(0, 0, 0, 0.5) 100%
      ),
      url(${props => props.image});
  }
`

const Small = styled.div`
  height: 100%;
  border-radius: ${styles.borderRadius.small};
  background-image: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0.2) 0%,
      rgba(0, 0, 0, 0.3) 100%
    ),
    url(${props => props.image});
  background-position: 50% 50%;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    background-image: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0.4) 0%,
        rgba(0, 0, 0, 0.5) 100%
      ),
      url(${props => props.image});
  }
`

const Text = styled.span`
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  letter-spacing: 1px;
  font-size: 1.1em;
  color: ${styles.colors.white};

  @media (min-width: 800px) {
    font-size: 1.8em;
  }
`

const Number = styled.span`
  font-size: 1.5em;
  @media (min-width: 800px) {
    font-size: 2.5em;
  }
`
