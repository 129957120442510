import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import axios from 'axios'
import styled from 'styled-components'
import * as actions from '../actions'
import Title from '../components/shared/Title'
import RequiredInput from '../components/shared/RequiredInput'
import UpdateStatus from '../components/shared/UpdateStatus'
import constants from '../constants/constants'
import styles from '../constants/styles'

const sections = [
  {
    icon: 'fas fa-home',
    name: 'name*',
    category: 'name',
    placeholder: 'Business name',
    error: 'The business name is required'
  },
  {
    icon: 'far fa-envelope',
    name: 'email*',
    category: 'email',
    placeholder: 'example@email.com',
    error: 'An email address is required'
  },
  {
    icon: 'far fa-user',
    name: 'contact person',
    category: 'contactPerson',
    placeholder: 'Eg. Steve Robbins'
  },
  {
    icon: 'fas fa-phone',
    name: 'phone',
    category: 'phone',
    placeholder: '+12 3456789'
  }
]

class Settings extends Component {
  constructor(props) {
    super(props)
    this.state = {
      emailSpinner: false
    }
    this.containerRef = null
  }

  componentDidMount() {
    window.scroll(0, 0)
    const node = ReactDOM.findDOMNode(this.containerRef)
    if (node && window.innerWidth > 800) {
      node.scrollIntoView()
    }
    this.props.clearBusinessEditing()
  }

  handleChange(value, category) {
    if (['name', 'address', 'email'].includes(category)) {
      if (value.length < 1) return
    }
    const originalValue = this.props.business.details[category]

    this.props.updateBusinessField({
      _id: this.props.business.details._id,
      field: category,
      value,
      originalValue
    })
  }

  handleChangeRequired(value, category) {
    const originalValue = this.props.business.details[category]
    this.props.updateBusinessField({
      _id: this.props.business.details._id,
      field: category,
      value,
      originalValue
    })
  }

  handleAllOrNone(type) {
    const allRestaurants = this.props.restaurants.map(r => ({
      _id: r._id,
      emailStatus: r.emailStatus
    }))

    // not truly waiting for all to complete
    this.setState({ emailSpinner: true }, () => {
      if (type === 'all') {
        // set all to emailSent
        allRestaurants
          .map(r => r._id)
          .forEach(_id => axios
            .put(`${constants.api.restaurants + _id}/emailStatus`, {
              emailStatus: 'emailSent'
            })
            .then((res) => {
              if (res.data.success) {
                this.props.updateRestaurantEmailStatus({
                  _id,
                  value: { emailStatus: 'emailSent' }
                })
              }
              this.setState({ emailSpinner: false })
            })
            .catch((err) => {
              console.error('err', err)
              this.setState({ emailSpinner: false })
            }))
      } else if (type === 'none') {
        // set all to doNotSend
        allRestaurants
          .map(r => r._id)
          .forEach(_id => axios
            .put(`${constants.api.restaurants + _id}/emailStatus`, {
              emailStatus: 'doNotSend'
            })
            .then((res) => {
              if (res.data.success) {
                this.props.updateRestaurantEmailStatus({
                  _id,
                  value: { emailStatus: 'doNotSend' }
                })
              }
              this.setState({ emailSpinner: false })
            })
            .catch((err) => {
              console.error('err', err)
              this.setState({ emailSpinner: false })
            }))
      }
    })
  }

  handleSubscription(currentEmailStatus, restaurantId) {
    // send axios, updateRestaurant redux
    const updatedStatus = currentEmailStatus === 'doNotSend' ? 'emailSent' : 'doNotSend'

    this.props.updateRestaurantEmailStatus({
      _id: restaurantId,
      value: { emailStatus: updatedStatus }
    })
    this.setState({ emailSpinner: true }, () => {
      axios
        .put(`${constants.api.restaurants + restaurantId}/emailStatus`, {
          emailStatus: updatedStatus
        })
        .then((res) => {
          this.setState({ emailSpinner: false })
          if (!res.data.success) {
            this.props.updateRestaurantEmailStatus({
              _id: restaurantId,
              value: { emailStatus: currentEmailStatus }
            })
          }
        })
        .catch((err) => {
          this.setState({ emailSpinner: false })
          this.props.updateRestaurantEmailStatus({
            _id: restaurantId,
            value: { emailStatus: currentEmailStatus }
          })
        })
    })
  }

  render() {
    const { business } = this.props
    if (!business || !business.details || !business.details.name) {
      return (
        <Loading>
          <div className="fas fa-spinner fa-spin" style={{ fontSize: '2em' }} />
          <h1>Fetching settings</h1>
        </Loading>
      )
    }

    const details = business.details
    const saving = business.saving

    // let restaurants = details.restaurants || []
    const restaurants = this.props.restaurants
    const subscribedCount = restaurants.filter(
      r => r.emailStatus !== 'doNotSend'
    ).length
    const numResto = restaurants.length

    return (
      <Container ref={con => (this.containerRef = con)}>
        <Title title="Settings" />
        <OverflowContainer>
          <FormContainer>
            {sections.map((section, i) => {
              const category = section.category
              // console.log('details', details[category])
              return (
                <div
                  key={section.name}
                  style={{ width: '100%', marginBottom: styles.margin.medium }}
                >
                  <HeaderDiv>
                    <span>{section.name.toUpperCase()}</span>
                    {typeof saving[category] === 'boolean'
                      && saving[category] && <UpdateStatus status="loading" />}
                    {typeof saving[category] === 'boolean'
                      && !saving[category] && <UpdateStatus status="complete" />}
                  </HeaderDiv>
                  {!['name', 'email'].includes(category) && (
                    <TextInput
                      type="text"
                      placeholder={section.placeholder}
                      value={details[category] || ''}
                      onChange={e => this.handleChange(e.target.value, category)
                      }
                    />
                  )}
                  {details[category]
                    && ['name', 'email'].includes(category) && (
                      <RequiredInput
                        defaultValue={details[category]}
                        placeholder={section.placeholder}
                        errorMessage={section.error}
                        onSubmit={input => this.handleChangeRequired(input, category)
                        }
                      />
                  )}
                </div>
              )
            })}

            <HeaderDiv>
              EMAIL SUBSCRIPTIONS
              {this.state.emailSpinner && <UpdateStatus status="loading" />}
            </HeaderDiv>
            <SubscribeContainer>
              <ButtonBar>
                <SelectUnselect
                  onClick={() => this.handleAllOrNone(
                    subscribedCount === numResto ? 'none' : 'all'
                  )
                  }
                >
                  {subscribedCount === numResto ? 'Unselect All' : 'Select All'}
                </SelectUnselect>
              </ButtonBar>
              {restaurants
                && restaurants.map(rest => (
                  <RestaurantCard
                    key={rest._id}
                    active={rest.emailStatus !== 'doNotSend'}
                    onClick={() => this.handleSubscription(rest.emailStatus, rest._id)
                    }
                  >
                    <b>{rest.name}</b>
                    <span style={{ fontSize: '0.9em' }}>
                      {rest.formatted_address}
                    </span>
                  </RestaurantCard>
                ))}
            </SubscribeContainer>
          </FormContainer>
        </OverflowContainer>
      </Container>
    )
  }
}

Settings.propTypes = {
  business: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
  business: state.business.all,
  restaurants: state.restaurants.index.items.map(
    _id => state.restaurants.all[_id]
  )
})

const mapDispatchToProps = dispatch => ({
  updateBusinessField: ({
    _id, field, value, originalValue
  }) => {
    dispatch(
      actions.business.updateBusinessField({
        _id,
        field,
        value,
        originalValue
      })
    )
  },
  updateRestaurantEmailStatus: ({ _id, value }) => {
    dispatch({
      type: actions.UPDATED_RESTAURANTS,
      _id,
      value // key:value obj
    })
  },
  clearBusinessEditing: () => {
    dispatch({
      type: actions.CLEAR_BUSINESS_EDITING
    })
  }
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Settings)

const Loading = styled.div`
  width: 100%;
  height: calc(100vh - 96px - 64px);
  font-weight: bold;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const Container = styled.main`
  padding: 0 ${styles.padding.medium};
`
const OverflowContainer = styled.div`
  width: 100%;
  height: calc(100vh - 96px - ${styles.margin.medium});
  overflow: scroll;

  @media (max-width: 800px) {
    height: calc(100vh - 96px - ${styles.margin.medium} - 64px);
  }
`
const FormContainer = styled.div`
  width: 100%;
  max-width: 720px;
  margin-top: ${styles.margin.medium};
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`
const HeaderDiv = styled.div`
  font-size: 0.9em;
  font-weight: 600;
  margin-bottom: ${styles.margin.small};
  display: flex;
`

const TextInput = styled.input`
  height: 48px;
  width: 100%;
  background: ${styles.colors.white};
  font-size: 1em;
  color: ${styles.colors.black};
  padding: ${styles.padding.small};
  border: 1px solid ${styles.colors.lightGrey};
  border-radius: ${styles.borderRadius.small};
`

const SubscribeContainer = styled.div`
  background: white;
  padding: ${styles.padding.small};
  padding-bottom: 0;
  border: 1px solid ${styles.colors.lightGrey};
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 720px;
`

const ButtonBar = styled.div`
  display: flex;
  margin-bottom: ${styles.margin.small};
`
const SelectUnselect = styled.span`
  padding: ${styles.padding.small};
  border: 1px solid ${styles.colors.lightGrey};
  border-radius: ${styles.borderRadius.small};
  cursor: pointer;
  margin-right: ${styles.margin.small};

  &:hover {
    background: ${styles.colors.omnivore};
    border: 1px solid ${styles.colors.omnivore};
    color: ${styles.colors.white};
  }
`

const RestaurantCard = styled.div`
  width: 100%;
  max-width: 720px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: ${styles.padding.small};
  margin-bottom: ${styles.margin.small};
  border: 1px solid
    ${props => (props.active ? styles.colors.omnivore : styles.colors.lightGrey)};
  background-color: ${props => (props.active ? styles.colors.omnivore : styles.colors.lightGrey)};
  color: ${props => (props.active ? styles.colors.white : styles.colors.black)};
  border-radius: ${styles.borderRadius.small};
  cursor: pointer;
`
