import { combineReducers } from 'redux'
import auth from './auth'
import * as actions from '../actions'
import categories from './categories'
import dishes from './dishes'
import images from './images'
import notifications from './notifications'
import restaurants from './restaurants'
import reviews from './reviews'
import users from './users'
import business from './business'

const sideNavActive = (state = false, action) => {
  switch (action.type) {
    case actions.TOGGLE_SIDE_NAV:
      return action.value
    default:
      return state
  }
}

const app = combineReducers({
  business,
  sideNavActive,
  auth,
  categories,
  dishes,
  images,
  notifications,
  reviews,
  restaurants,
  users
})

export default app
