import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import constants from '../../constants/constants'
import styles from '../../constants/styles'

const EditAllergens = ({ _id, allergens, updateDish }) => (
  <Container>
    {constants.dishes.allergens.map(a => (
      <Allergen
        key={a}
        onClick={() => updateDish({
          _id,
          original: { allergens },
          value: {
            allergens: allergens.includes(a)
              ? allergens.filter(all => all !== a)
              : allergens.concat(a)
          }
        })
        }
        active={allergens.includes(a)}
      >
        {a}
      </Allergen>
    ))}
  </Container>
)

EditAllergens.propTypes = {
  _id: PropTypes.string,
  allergens: PropTypes.array.isRequired,
  updateDish: PropTypes.func.isRequired
}

export default EditAllergens

const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
`

const Allergen = styled.div`
  width: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${styles.padding.medium};
  cursor: pointer;
  margin-right: ${styles.margin.small};
  margin-bottom: ${styles.margin.small};
  border: 1px solid
    ${props => (props.active ? styles.colors.omnivore : styles.colors.grey)};
  background-color: ${props => (props.active ? styles.colors.omnivore : styles.colors.white)};
  color: ${props => (props.active ? styles.colors.white : styles.colors.black)};
  border-radius: ${styles.borderRadius.small};
  &:hover {
    border: 1px solid
      ${props => (props.active ? styles.colors.omnivore : styles.colors.brand)};
    background-color: ${props => (props.active ? styles.colors.omnivore : styles.colors.brand)};
    color: ${styles.colors.white};
  }
`
