import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ExifOrientationImg from 'react-exif-orientation-img'
import styled from 'styled-components'
import styles from '../../constants/styles'
import { optimisedPath } from '../../utilities/format'

class PhotoGallery extends Component {
  render() {
    const {
      dishID,
      images,
      uploadingImages,
      mainImage,
      updateDish,
      deleteImage
    } = this.props
    return (
      <Container>
        {uploadingImages.map((i, index) => (
          <ImageDiv key={index} uploading>
            <ExifOrientationImg
              src={i.data}
              alt="Uploading image"
              style={{ width: '100%', height: '100%', objectFit: 'cover' }}
            />
            <Uploading>
              <i className="fas fa-spinner fa-spin fa-2x" />
            </Uploading>
          </ImageDiv>
        ))}
        {images.map(image => (
          <ImageDiv key={image._id} isMainImage={mainImage === image._id}>
            <Image src={optimisedPath(image.path)} alt={image._id} />

            {!image.business && (
              <BusinessIcon>
                <i className="fas fa-utensils" title="Uploaded by users" />
              </BusinessIcon>
            )}

            <SetAsMain
              isMainImage={mainImage === image._id}
              isBusinessImage={!!image.business}
              onClick={() => (mainImage === image._id
                ? {}
                : updateDish({
                  _id: dishID,
                  original: mainImage,
                  value: { image: image._id },
                  full: { image }
                }))
              }
            >
              {mainImage === image._id ? (
                <i className="fa fa-crown" />
              ) : (
                <span>
                  <i className="fa fa-crown" />
                  &nbsp;Main
                </span>
              )}
            </SetAsMain>

            {mainImage !== image._id && !!image.business && (
              <Delete
                onClick={() => (mainImage === image._id ? {} : deleteImage({ image }))
                }
              >
                <i className="far fa-trash-alt" />
                &nbsp;Delete
              </Delete>
            )}
          </ImageDiv>
        ))}
      </Container>
    )
  }
}

PhotoGallery.propTypes = {
  dishID: PropTypes.string.isRequired,
  images: PropTypes.arrayOf(PropTypes.object).isRequired,
  uploadingImages: PropTypes.arrayOf(PropTypes.object).isRequired,
  mainImage: PropTypes.string,
  updateDish: PropTypes.func.isRequired,
  deleteImage: PropTypes.func.isRequired
}

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
`

const ImageDiv = styled.div`
  width: calc((100vw - 2 * ${styles.padding.medium} - 4px) / 2);
  height: calc((100vw - 2 * ${styles.padding.medium} - 4px) / 2);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 2px;
  margin-bottom: 2px;
  position: relative;
  cursor: ${props => (props.uploading ? 'wait' : 'default')};
  background-color: ${styles.colors.white};

  @media (min-width: 600px) {
    width: calc((100vw - 2 * ${styles.padding.medium} - 6px) / 3);
    height: calc((100vw - 2 * ${styles.padding.medium} - 6px) / 3);
  }

  @media (min-width: 800px) {
    width: calc((100vw - 200px - 2 * ${styles.padding.medium} - 6px) / 3);
    height: calc((100vw - 200px - 2 * ${styles.padding.medium} - 6px) / 3);
  }

  @media (min-width: 1200px) {
    width: calc((100vw - 200px - 2 * ${styles.padding.medium} - 8px) / 4);
    height: calc((100vw - 200px - 2 * ${styles.padding.medium} - 8px) / 4);
  }
`

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`

const Uploading = styled.div`
  width: 100%;
  height: 100%;
  font-size: 1em;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${`${styles.colors.black}80`};
  color: ${styles.colors.white};
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
`

const BusinessIcon = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  font-size: 0.75em;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${styles.colors.white};
  color: ${styles.colors.black};
  position: absolute;
  top: 8px;
  right: 8px;
  z-index: 20;
`

const SetAsMain = styled.div`
  width: ${props => (props.isMainImage || !props.isBusinessImage ? '92%' : '44%')};
  height: ${styles.height.small};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: ${styles.borderRadius.small};
  background-color: ${props => (props.isMainImage ? styles.colors.gold : `${styles.colors.omnivore}80`)};
  color: ${styles.colors.white};
  position: absolute;
  bottom: 12px;
  left: 4%;
  z-index: 10;
  text-align: center;
  cursor: ${props => (props.isMainImage ? 'default' : 'pointer')};
  &:hover {
    background-color: ${props => (props.isMainImage ? styles.colors.gold : styles.colors.brand)};
  }
  @media (max-width: 374px) {
    font-size: 0.8em;
  }
`

const Delete = styled.div`
  width: 44%;
  height: ${styles.height.small};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: ${styles.borderRadius.small};
  background-color: ${`${styles.colors.action}80`};
  color: ${styles.colors.white};
  position: absolute;
  bottom: 12px;
  right: 4%;
  z-index: 10;
  text-align: center;
  cursor: pointer;
  &:hover {
    background-color: ${styles.colors.brand};
  }
  @media (max-width: 374px) {
    font-size: 0.8em;
  }
`

export default PhotoGallery
