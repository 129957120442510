import React, { useState, useLayoutEffect } from 'react'
import styled from 'styled-components'
import Articles from '../components/landing/Articles'
import Footer from '../components/landing/Footer'
import styles from '../constants/styles'
import colors from '../constants/colors'
import Press from '../components/landing/Press'
import ClaimButtons from '../components/claim/ClaimButtons'
import allCopy from '../constants/copy'
import Meta from '../components/landing/Meta'

const copy = allCopy.en.landing

function useWindowSize() {
  const [size, setSize] = useState([0, 0])
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight])
    }
    window.addEventListener('resize', updateSize)
    updateSize()
    return () => window.removeEventListener('resize', updateSize)
  }, [])
  return size
}

const Landing = () => {
  const [width] = useWindowSize()
  return (
    <Container>
      <Meta meta={{}} />

      <LandingFullWidth>
        <LandingTextColumn>
          <LandingHeadline>{copy.header.headline}</LandingHeadline>
          <LandingText>{copy.header.text}</LandingText>
          <ClaimButtons
            title="CLAIM YOUR"
            direction={width >= 1024 ? 'row' : 'column'}
          />
        </LandingTextColumn>
        <LandingImageColumn />
      </LandingFullWidth>

      <VeganDiningClubContainer>
        <a
          href="https://www.abillion.com/articles/vegan-dining-club"
          target="_blank"
          rel="noopener noreferrer"
        >
          <VeganDiningClubBanner
            alt="Tap into 12+ million hungry foodies! Join our Vegan Dining Club."
            src="https://imagedelivery.net/olI9wp0b6luWFB9nPfnqjQ/fe5303f9-fb71-42f1-f87c-974a8be74c00/w=1152"
          />
        </a>
      </VeganDiningClubContainer>

      {/* ARTICLES */}
      <FullWidthSection>
        <Articles />
      </FullWidthSection>

      <ComponentContainer>
        <ComponentSubContainer style={{ backgroundColor: 'transparent' }}>
          <GrowthTitle>{copy.growth.title}</GrowthTitle>
          <GrowthContainer>
            <GrowthItemImageConatiner src="https://res.cloudinary.com/abillionveg/image/upload/v1611549305/business_landing/biz-landing-brand-review_2x.png" />
            <TextRight>
              <GrowthItemTextConatiner>
                <GrowthTextTitle>Respond to reviews</GrowthTextTitle>
                <GrowthSubText>
                  Hear what your customers are saying. Get feedback and reply to
                  their reviews.
                </GrowthSubText>
              </GrowthItemTextConatiner>
            </TextRight>
          </GrowthContainer>
          <GrowthContainer>
            {width < 900 && (
              <GrowthItemImageConatiner src="https://res.cloudinary.com/abillionveg/image/upload/v1611549305/business_landing/biz-landing-dish_2x.png" />
            )}
            <GrowthItemTextConatiner>
              <GrowthTextTitle>Take control of your listing</GrowthTextTitle>
              <GrowthSubText>
                Customize your listing. Upload your products or dishes and make
                your business stand out.
              </GrowthSubText>
            </GrowthItemTextConatiner>
            {width > 900 && (
              <GrowthItemImageConatiner src="https://res.cloudinary.com/abillionveg/image/upload/v1611549305/business_landing/biz-landing-dish_2x.png" />
            )}
          </GrowthContainer>
        </ComponentSubContainer>
      </ComponentContainer>

      <ComponentContainer>
        <ComponentSubContainer style={{ maxWidth: '90%' }}>
          <ClaimTitle>{copy.claim}</ClaimTitle>
          <ClaimButtons
            position="center"
            direction={width >= 768 ? 'row' : 'column'}
          />
        </ComponentSubContainer>
      </ComponentContainer>

      {/* PRESS */}
      <Press />

      <Footer />
    </Container>
  )
}
export default Landing

const Container = styled.div`
  min-height: calc(100vh - 64px);
`
const LandingFullWidth = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 0px 48px;
  padding-top: 64px;
  min-height: 85vh;
  overflow: hidden;
  background-color: #f9f1ee;
  background: url('https://res.cloudinary.com/abillionveg/image/upload/v1608979041/business-v2/bg-mobile_3x.png')
    bottom center no-repeat scroll;
  background-size: cover;

  @media (min-width: 768px) {
    background-image: url('https://res.cloudinary.com/abillionveg/image/upload/v1608979041/business-v2/bg-tablet_3x.png');
    align-items: center;
    min-height: 70vh;
  }

  @media (min-width: 1024px) {
    background-image: url('https://res.cloudinary.com/abillionveg/image/upload/v1608979041/business-v2/bg-desktop_3x.png');
    min-height: 70vh;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  @media (min-width: 1024px) and (min-height: 1280px) {
    min-height: 52vh;
  }

  @media (min-width: 1024px) and (min-height: 880px) {
    min-height: 52vh;
  }

  @media (min-height: 1366px) {
    min-height: 35vh;
  }

  @media (max-width: 768px) {
    min-height: 58vh;
    padding: 0px 30px;
    padding-top: 64px;
  }
`

const VeganDiningClubContainer = styled.div`
  margin-top: 8px;
  display: flex;
  justify-content: center;
`

const VeganDiningClubBanner = styled.img`
  width: min(1000px, calc(100vw - 16px));
  object-fit: contain;
  border-radius: 4px;
`

const LandingHeadline = styled.h2`
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  line-height: 56px;
  font-size: 44px;
  margin-bottom: ${styles.margin.medium};
  @media (min-width: 768px) and (max-width: 900px) {
    font-size: 40px;
  }
  @media (max-width: 1023px) {
    text-align: center;
    margin-top: 48px;
  }
  @media (max-width: 600px) {
    margin-bottom: 32px;
    font-size: 26px;
    line-height: 32px;
  }
`

const LandingText = styled.span`
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 22px;
  line-height: 32px;
  margin-bottom: 48px;
  text-align: center;
  @media (max-width: 767px) {
    font-size: 13px;
    line-height: 20px;
    margin-bottom: 12px;
  }
  @media (min-width: 1024px) {
    text-align: left;
  }
`

const FullWidthSection = styled.div`
  width: 100%;
`

const ComponentContainer = styled.div`
  width: 100%;
`

const ComponentSubContainer = styled.div`
  max-width: 70vw;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  margin: 0px auto;
  background-color: ${colors.peachy[100]};
  border-radius: 15px;
  height: inherit;
  padding: 48px 0px;
  @media (max-width: 1440px) {
    max-width: 90vw;
  }
  @media (max-width: 600px) {
    margin: auto ${styles.margin.medium};
  }
  @media (min-width: 768px) and (max-width: 900px) {
    max-width: 453px;
  }
`
const ClaimTitle = styled.div`
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 44px;
  line-height: 56px;
  width: 47%;
  text-align: center;
  margin-bottom: 48px;
  min-width: 525px;
  @media (max-width: 600px) {
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    width: 70%;
    margin-bottom: 24px;
    min-width: auto;
  }
`
const GrowthTitle = styled.div`
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 44px;
  line-height: 56px;
  text-align: center;
  @media (max-width: 600px) {
    font-size: 26px;
    line-height: 32px;
    width: 80%;
  }
`
const GrowthContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: -webkit-fill-available;
  margin-top: 48px;
  @media (max-width: 900px) {
    flex-direction: column;
    height: fit-content;
  }
`
const GrowthItemImageConatiner = styled.img`
  height: inherit;
  justify-content: center;
  display: flex;
  align-items: center;
  width: 600px;
  height: 440px;
  object-fit: contain;
  @media (max-width: 600px) {
    width: inherit;
    width: 277px;
    height: 203px;
  }
  @media (min-width: 768px) and (max-width: 900px) {
    width: 444px;
    height: 325px;
  }
  @media (min-width: 900px) and (max-width: 1279px) {
    width: 430px;
    height: 323px;
  }
`
const GrowthItemTextConatiner = styled.div`
  height: inherit;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 0px 20px;
  @media (max-width: 900px) {
    width: inherit;
    margin: 16px 16px 0px 16px;
    padding: 0px;
  }
`
const GrowthTextTitle = styled.div`
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 44px;
  line-height: 56px;
  @media (max-width: 900px) {
    text-align: center;
    font-size: 32px;
    line-height: 39px;
  }
  @media (max-width: 900px) {
    font-weight: 600;
  }
  @media (max-width: 600px) {
    font-size: 20px;
    line-height: 28px;
    text-align: center;
  }
`
const GrowthSubText = styled.div`
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 26px;
  line-height: 32px;
  margin-top: 32px;
  @media (max-width: 900px) {
    text-align: center;
    font-size: 22px;
    line-height: 28px;
    margin-top: 16px;
  }
  @media (max-width: 600px) {
    font-size: 13px;
    line-height: 20px;
    text-align: center;
    margin-top: 8px;
  }
`
const TextRight = styled.div`
  padding-left: 28px;
  @media (max-width: 900px) {
    padding: 0;
  }
`
const LandingTextColumn = styled.div`
  height: max-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (min-width: 1024px) and (max-height: 1366px) {
    min-width: 540px;
  }
  @media (min-width: 768px) and (max-width: 1024px) {
    width: 80%;
  }
`

const LandingImageColumn = styled.div`
  height: 34vh;
  background-size: contain;
  margin-top: 35px;
  background-image: url('https://res.cloudinary.com/abillionveg/image/upload/v1611550355/business_landing/hero-mobile_2x.png');
  @media (min-width: 768px) {
    height: 40vh;
    width: 80vh;
    margin: 48px auto;
    background-image: url('https://res.cloudinary.com/abillionveg/image/upload/v1611549305/business_landing/hero-desktop_2x.png');
  }

  @media (min-width: 768px) and (min-height: 1024px) {
    height: 35vh;
    width: 67vh;
  }

  @media (min-width: 1024px) {
    min-width: 65vw;
    margin-top: 5vh;
    margin-left: 0px;
  }

  @media (min-width: 1024px) and (max-height: 768px) {
    height: 54vh;
    min-width: 75vw;
    margin-left: 0vw;
  }

  @media (min-width: 1024px) and (min-height: 1280px) {
    height: 37vh;
  }

  @media (min-width: 1280px) and (max-height: 880px) {
    min-width: 65vw;
    margin-top: 5vh;
    margin-left: 6vw;
    height: 60vh;
  }

  @media (min-width: 1280px) and (min-height: 1024px) {
    height: 58vh;
  }

  @media (min-width: 1366px) and (max-height: 1024px) {
    height: 53vh;
    min-width: 77vw;
  }

  @media (min-width: 1400px) and (max-width: 1800px) {
    margin-left: 16vw;
    min-width: 70vw;
    height: 52vh;
    margin-top: 48px;
  }

  @media (min-width: 1400px) and (max-height: 900px) {
    margin-left: 7vw;
  }

  @media (min-width: 1800px) {
    margin-left: 22vw;
    min-width: 60vw;
    height: 55vh;
    margin-top: 40px;
  }

  @media (min-height: 1366px) {
    height: 34vh;
  }
`
