import { combineReducers } from 'redux'
import * as actions from '../actions'
import {
  UPDATED_REVIEWS,
  FETCHING_REVIEWS_INDEX,
  RECEIVED_REVIEWS_INDEX,
  ERROR_FETCHING_REVIEWS_INDEX,
  LIKE_REVIEW,
  UNLIKE_REVIEW,
} from '../actions/constants/reviews'

const all = (state = {}, action) => {
  switch (action.type) {
    case RECEIVED_REVIEWS_INDEX:
      return {
        ...state,
        ...action.data.reduce((obj, review) => {
          obj[review._id] = { ...review, comment: '' }
          return obj
        }, {}),
      }
    case UPDATED_REVIEWS:
      return {
        ...state,
        [action._id]: { ...state[action._id], ...action.value },
      }
    case LIKE_REVIEW:
      return {
        ...state,
        [action._id]: {
          ...state[action._id],
          isLiked: true,
          likesCount: state[action._id].likesCount + 1,
        },
      }
    case UNLIKE_REVIEW:
      return {
        ...state,
        [action._id]: {
          ...state[action._id],
          isLiked: false,
          likesCount: state[action._id].likesCount - 1,
        },
      }
    case actions.LOG_OUT:
      return {}
    default:
      return state
  }
}

const defaultState = {
  isLoading: false,
  items: [],
  error: false,
  fetched: false,
}

const index = (state = defaultState, action) => {
  switch (action.type) {
    case FETCHING_REVIEWS_INDEX:
      return {
        ...state,
        isLoading: true,
        items: [],
        error: false,
      }
    case RECEIVED_REVIEWS_INDEX:
      return {
        isLoading: false,
        items: action.data.map((review) => review._id),
        error: false,
        fetched: true,
      }
    case ERROR_FETCHING_REVIEWS_INDEX:
      return {
        ...state,
        isLoading: false,
        error: true,
      }
    case actions.LOG_OUT:
      return defaultState
    default:
      return state
  }
}

export default combineReducers({
  all,
  index,
})
