import axios from 'axios'
import constants from '../constants/constants'
import { CHECKED_NOTIFICATIONS } from './index'

export const updateCheckedTime = () => (dispatch) => {
  const now = Date.now()
  axios.put(`${constants.api.users}/update/checkedBusinessNotification`, {
    checkedBusinessNotification: now
  })
  dispatch({ type: CHECKED_NOTIFICATIONS, time: now })
}
