import React from 'react'
import styled from 'styled-components'
import styles from '../../constants/styles'

const SmallReviewCard = () => (
  <Review>
    <ProfilePic />
    <div style={{ marginLeft: styles.margin.small, width: '100%' }}>
      <div
        style={{
          width: '100%',
          display: 'flex',
          flexFlow: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: styles.margin.tiny,
        }}
      >
        <Username />
        <DateSpan />
      </div>
      <ReviewText />
      <ReviewText />
      <ReviewText style={{ width: '50%' }} />
    </div>
  </Review>
)

const Review = styled.div`
  width: 100%;
  height: 120px;
  display: flex;
  flex-flow: row;
  color: ${styles.colors.black};
  padding: ${styles.padding.medium} ${styles.padding.small};
  background-color: ${(props) =>
    props.selected ? styles.colors.veryLightGrey : styles.colors.white};
  border-left: 5px solid
    ${(props) =>
      props.selected ? styles.colors.omnivore : styles.colors.white};

  &: hover {
    background-color: ${styles.colors.veryLightGrey};
    border-left: 5px solid
      ${(props) =>
        props.selected ? styles.colors.omnivore : styles.colors.darkGrey};
  }
`

const ProfilePic = styled.div`
  width: 36px;
  min-width: 36px;
  height: 36px;
  border-radius: 50%;
  background-color: ${styles.colors.lightGrey};
`

const DateSpan = styled.div`
  width: 50px;
  height: 10px;
  background-color: ${styles.colors.lightGrey};
`

const Username = styled.div`
  background-color: ${styles.colors.lightGrey};
  width: 80px;
  height: 12px;
  margin-bottom: ${styles.margin.small};
`

const ReviewText = styled.div`
  background-color: ${styles.colors.lightGrey};
  width: 100%;
  height: 10px;
  margin-bottom: ${styles.margin.small};
`

export default SmallReviewCard
