import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import { connect } from 'react-redux'
import styled from 'styled-components'
import * as actions from '../actions'
import EditCategories from '../components/restaurants/EditCategories'
import ToggleClosed from '../components/restaurants/ToggleClosed'
import EditHoursRework from '../components/restaurants/EditHoursRework'
import Title from '../components/shared/Title'
import ActionBar from '../components/shared/ActionBar'
import RequiredInput from '../components/shared/RequiredInput'
import UpdateStatus from '../components/shared/UpdateStatus'
import styles from '../constants/styles'
import {
  calculateFriendliness,
  handleFriendlinessScore,
  capitalizeString
} from '../utilities/format'

const sections = [
  {
    icon: 'fas fa-home',
    name: 'name*',
    category: 'name',
    placeholder: 'Name of restaurant/store'
  },
  {
    icon: 'fas fa-map-marker-alt',
    name: 'address*',
    category: 'formatted_address',
    placeholder: 'eg. 220 Orchard Road #12-34, Singapore 123456'
  },
  {
    icon: 'fas fa-home',
    name: 'nickname',
    category: 'nickname',
    placeholder: 'Alternative name'
  },
  {
    icon: 'far fa-clock',
    name: 'Open',
    category: 'isClosed'
  },
  {
    icon: 'far fa-clock',
    name: 'hours',
    category: 'hours'
  },
  {
    icon: 'fas fa-list-ul',
    name: 'categories',
    category: 'categories'
  },
  {
    icon: 'far fa-comment-alt',
    name: 'description',
    category: 'description',
    placeholder: 'Enter a description'
  },
  {
    icon: 'far fa-envelope',
    name: 'email',
    category: 'email',
    placeholder: 'example@email.com'
  },
  {
    icon: 'fas fa-desktop',
    name: 'website',
    category: 'website',
    placeholder: 'example@website.com'
  },
  {
    icon: 'fas fa-phone',
    name: 'phone',
    category: 'phone',
    placeholder: 'eg. +12 345-6789'
  },
  {
    icon: 'fab fa-instagram',
    name: 'instagram',
    category: 'instagram'
  },
  {
    icon: 'fab fa-facebook',
    name: 'facebook',
    category: 'facebook'
  },
  {
    icon: 'fab fa-twitter',
    name: 'twitter',
    category: 'twitter'
  }
]

class Restaurant extends Component {
  constructor(props) {
    super(props)
    this.state = {
      // input loading states moved to redux restaurants.edit.saving
    }
    this.containerRef = null
  }

  componentDidMount() {
    window.scrollTo(0, 0)
    const node = ReactDOM.findDOMNode(this.containerRef)
    if (node && window.innerWidth > 800) {
      node.scrollIntoView()
    }
  }

  componentWillUnmount() {
    this.props.clearRestaurantEditing(this.props.match.params.id)
  }

  handleChange(value, category) {
    const resto = this.props.restaurants.all[this.props.match.params.id]
    const original = resto[category]

    if (
      [
        'name',
        'nickname',
        'formatted_address',
        'description',
        'email',
        'website',
        'phone',
        'categories',
        'hours'
      ].includes(category)
    ) {
      if (
        (category === 'name' || category === 'formatted_address')
        && value.length < 1
      ) return
      this.props.updateRestaurantField({
        _id: this.props.match.params.id,
        field: category,
        value,
        originalValue: original
      })
    }
    if (['instagram', 'facebook', 'twitter'].includes(category)) {
      if (value.length >= 1) {
        this.props.updateRestaurantField({
          _id: this.props.match.params.id,
          field: category,
          value: value.slice(1),
          originalValue: original
        })
      }
    }
  }

  handleChangeRequired(value, category) {
    const resto = this.props.restaurants.all[this.props.match.params.id]
    const original = resto[category]

    this.props.updateRestaurantField({
      _id: this.props.match.params.id,
      field: category,
      value,
      originalValue: original
    })
  }

  render() {
    const resto = this.props.restaurants.all[this.props.match.params.id]

    if (!resto) {
      return (
        <Loading>
          <div className="fas fa-spinner fa-spin" style={{ fontSize: '2em' }} />
          <h1>Loading restaurant</h1>
        </Loading>
      )
    }

    const friendlinessScore = calculateFriendliness(resto)
    let friendlinessString = capitalizeString(
      handleFriendlinessScore(friendlinessScore)
    )
    if (friendlinessString === 'Friendliness Unrated') {
      friendlinessString = 'Unrated'
    }

    return (
      <Container ref={con => (this.containerRef = con)}>
        <Title title={!!resto && resto.name ? resto.name : 'Edit Restaurant'} />

        <ActionBar backTo="/restaurants" backToTitle="Restaurants" />

        <ContentContainer>
          {resto && (
            <StatsBar>
              <StatBox>
                <StatHeader>Dishes</StatHeader>
                <Stat>{resto.dishes.length}</Stat>
              </StatBox>
              <StatBox noMargin>
                <StatHeader>Reviews</StatHeader>
                <Stat>{resto.dishReviews}</Stat>
              </StatBox>
              <StatBox>
                <StatHeader>Pokes</StatHeader>
                <Stat>{resto.votesCount}</Stat>
              </StatBox>
              <StatBox noMargin>
                <StatHeader>Rating</StatHeader>
                <StatRating rating={friendlinessString}>
                  {friendlinessString}
                </StatRating>
              </StatBox>
            </StatsBar>
          )}

          {resto && (
            <DetailsContainer>
              {sections.map((s, i) => {
                const category = s.category
                return (
                  <Section key={i}>
                    <HeaderDiv>
                      <span>{s.name.toUpperCase()}</span>
                      {resto.updateStatus
                        && resto.updateStatus[category]
                        && resto[category].length !== 0 && (
                          <UpdateStatus status={resto.updateStatus[category]} />
                      )}
                    </HeaderDiv>

                    <DetailDiv>
                      {![
                        'categories',
                        'hours',
                        'isClosed',
                        'instagram',
                        'facebook',
                        'twitter',
                        'name',
                        'formatted_address'
                      ].includes(category) && (
                        <TextInput
                          value={resto[category]}
                          onChange={e => this.handleChange(e.target.value, category)
                          }
                          placeholder={s.placeholder}
                        />
                      )}

                      {(category === 'name'
                        || category === 'formatted_address') && (
                        <RequiredInput
                          defaultValue={resto[category]}
                          errorMessage={
                            category === 'name'
                              ? 'Restaurant name is required'
                              : 'Restaurant address is required'
                          }
                          placeholder={
                            category === 'name'
                              ? 'Enter restaurant name'
                              : 'Enter restaurant address'
                          }
                          onSubmit={input => this.handleChangeRequired(input, category)
                          }
                        />
                      )}
                      {['instagram', 'facebook', 'twitter'].includes(
                        category
                      ) && (
                        <TextInput
                          value={resto[category] ? `@${resto[category]}` : '@'}
                          onChange={e => this.handleChange(e.target.value, category)
                          }
                          placeholder={s.placeholder}
                        />
                      )}

                      {category === 'isClosed' && (
                        <ToggleClosed
                          isClosed={resto.isClosed}
                          toggle={value => this.props.updateRestaurantField({
                            _id: this.props.match.params.id,
                            field: 'isClosed',
                            value,
                            originalValue: !value
                          })
                          }
                        />
                      )}

                      {category === 'hours' && (
                        <EditHoursRework
                          hoursArr={resto.hours}
                          handleChange={arr => this.handleChange(arr, 'hours')}
                        />
                      )}
                      {category === 'categories' && (
                        <EditCategories
                          currentDetails={resto.categories}
                          categories={this.props.categories}
                          user={this.props.viewer}
                          handleChange={arr => this.handleChange(arr, 'categories')
                          }
                        />
                      )}
                    </DetailDiv>
                  </Section>
                )
              })}
            </DetailsContainer>
          )}
        </ContentContainer>
      </Container>
    )
  }
}

const mapStateToProps = state => ({
  viewer: state.users.viewer._id ? state.users.all[state.users.viewer._id] : {},
  restaurants: state.restaurants,
  categories: state.categories.index.items.map(_id => state.categories.all[_id])
})

const mapDispatchToProps = dispatch => ({
  updateRestaurantField: ({
    _id, field, value, originalValue
  }) => {
    dispatch(
      actions.restaurants.updateRestaurantField({
        _id,
        field,
        value,
        originalValue
      })
    )
  },
  clearRestaurantEditing: (_id) => {
    dispatch({
      type: 'CLEAR_RESTAURANT_EDITING',
      _id
    })
  }
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Restaurant)

const Container = styled.main`
  padding: 0 ${styles.padding.medium};
`

const Loading = styled.div`
  width: 100%;
  height: calc(100vh - 96px - 64px);
  font-weight: bold;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`
const ContentContainer = styled.div`
  margin-bottom: ${styles.margin.medium};
`

const StatsBar = styled.div`
  display: flex;
  justify-content: space-between;
  margin: ${styles.margin.medium} 0;
  width: 100%;
  max-width: 720px;

  @media (max-width: 600px) {
    flex-flow: row wrap;
    justify-content: flex-start;
  }
`
const StatBox = styled.div`
  width: 23%;
  background: ${styles.colors.white};

  margin-bottom: ${styles.margin.small};
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${styles.padding.small};

  @media (max-width: 600px) {
    width: 48%;
    margin-right: ${props => (props.noMargin ? 0 : '2%')};
  }
`

const StatHeader = styled.span`
  font-size: 1em;
  font-weight: bold;
  margin-bottom: ${styles.margin.small};
  color: ${styles.colors.darkGrey};
`
const Stat = styled.span`
  font-size: 1.2em;
  font-weight: 500;
  color: ${styles.colors.black};
`
const StatRating = styled.span`
  font-size: 1em;
  font-weight: 500;
  text-align: center;
  margin-top: ${styles.margin.tiny};
  color: ${props => (props.rating === 'Very Friendly'
    ? styles.colors.vegan
    : props.rating === 'Friendly'
      ? styles.colors.brand
      : props.rating === 'Unrated'
        ? styles.colors.darkGrey
        : styles.colors.action)};
`

const DetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${styles.margin.medium};
`
const Section = styled.div`
  margin-bottom: ${styles.margin.medium};
`
const HeaderDiv = styled.div`
  font-size: 0.9em;
  font-weight: 600;
  margin-bottom: ${styles.margin.small};
  display: flex;
  align-items: center;
`
const DetailDiv = styled.div`
  width: 100%;
  max-width: 720px;
  height: 100%;
  border: ${props => (props.active
    ? `2px solid ${styles.colors.brand}`
    : '2px solid transparent')};
`

const TextInput = styled.input`
  height: 48px;
  width: 100%;
  background: ${styles.colors.white};
  font-size: 1em;
  color: ${styles.colors.black};
  padding-left: ${styles.padding.small};
  border: 1px solid ${styles.colors.lightGrey};
  border-radius: ${styles.borderRadius.small};
`
