import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router } from 'react-router-dom'
import { createStore, applyMiddleware } from 'redux'
import { Provider } from 'react-redux'
import thunk from 'redux-thunk'
import axios from 'axios'
import reducer from './reducers'
import App from './containers/App'
import Auth from './modules/Auth'
import './index.css'

const middleware = [thunk]
const store = createStore(reducer, applyMiddleware(...middleware))

// add the JWT token as a default header
axios.defaults.headers.common.Authorization = `bearer ${Auth.getToken()}`

ReactDOM.render(
  <Provider store={store}>
    <Router>
      <App />
    </Router>
  </Provider>,
  document.getElementById('root')
)
