import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import axios from 'axios'
import styled from 'styled-components'
import constants from '../../constants/constants'
import styles from '../../constants/styles'

class Unsubscribe extends Component {
  constructor(props) {
    super(props)
    this.state = {
      name: '',
      loading: true,
      unsubStatus: ''
    }
  }

  componentDidMount() {
    // fetch the rest from the params id
    axios
      .get(
        `${constants.api.restaurants}${this.props.match.params.id}/fetchName`,
        { headers: { skip: 'true' } }
      )
      .then((res) => {
        this.setState({ name: res.data.name })
      })
    // change the emailStatus on backend
    axios
      .put(
        `${constants.api.restaurants}${this.props.match.params.id}/emailStatus`,
        { emailStatus: 'doNotSend' },
        { headers: { skip: 'true' } }
      )
      .then((res) => {
        if (res.data.success) {
          this.setState({
            loading: false,
            unsubStatus: 'success'
          })
        } else {
          this.setState({
            loading: false,
            unsubStatus: 'error'
          })
        }
      })
  }

  render() {
    return (
      <Main>
        {this.state.loading ? (
          <Unsubbed>
            <Title style={{ marginBottom: styles.margin.large }}>
              Unsubscribing...
            </Title>
            <div
              style={{
                height: '150px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <i
                className="fa fa-spinner fa-spin fa-3x fa-fw"
                style={{
                  color: styles.colors.brand,
                  marginBottom: styles.margin.large
                }}
              />
            </div>
            <Title>{this.state.name || 'Fetching name...'}</Title>
          </Unsubbed>
        ) : (
          <div>
            {this.state.unsubStatus === 'success' && (
              <Unsubbed>
                <Title style={{ marginBottom: styles.margin.large }}>
                  You have been unsubscribed
                </Title>
                <img
                  src="https://res.cloudinary.com/abillionveg/image/upload/v1538127010/web-settings-confirmed_rhni4f.png"
                  alt="check"
                  width="150"
                  height="150"
                  style={{
                    height: '150px',
                    width: '150px',
                    marginBottom: styles.margin.large
                  }}
                />
                <Title style={{ marginBottom: styles.margin.medium }}>
                  {this.state.name || 'Fetching name...'}
                </Title>
                <div>
                  Before you go, click
                  {' '}
                  <Link to="/" style={{ textDecoration: 'underline' }}>
                    here
                  </Link>
                  {' '}
                  to see what abillion could do for you.
                </div>
              </Unsubbed>
            )}

            {this.state.unsubStatus === 'error' && (
              <div>Sorry, an error has occurred. Please try again.</div>
            )}
          </div>
        )}
      </Main>
    )
  }
}

const Main = styled.div`
  width: 100%;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - 64px);
  padding: ${styles.padding.large} ${styles.padding.media};
`

const Unsubbed = styled.div`
  width: 100%;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
`

const Title = styled.h3`
  font-size: 2em;
  font-family: 'Montserrat';
  font-weight: bold;
`

export default Unsubscribe
